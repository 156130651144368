import { distance, point } from "@turf/turf";
import mansionIcon from "assets/icons/ic_map_type_apartment.svg";
import mansionGrayIcon from "assets/icons/ic_map_type_apartment2.svg";
import blockIcon from "assets/icons/ic_map_type_block.svg";
import blockGrayIcon from "assets/icons/ic_map_type_block2.svg";
import businessIcon from "assets/icons/ic_map_type_business.svg";
import businessGrayIcon from "assets/icons/ic_map_type_business2.svg";
import groundIcon from "assets/icons/ic_map_type_ground.svg";
import groundGrayIcon from "assets/icons/ic_map_type_ground2.svg";
import mixIcon from "assets/icons/ic_map_type_mix.svg";
import mixGrayIcon from "assets/icons/ic_map_type_mix2.svg";
import houseNewIcon from "assets/icons/ic_map_type_new.svg";
import houseNewGrayIcon from "assets/icons/ic_map_type_new2.svg";
import houseUsedIcon from "assets/icons/ic_map_type_used.svg";
import houseUsedGrayIcon from "assets/icons/ic_map_type_used2.svg";
import { daysOfNew, tileBaseZoom } from "common/consts/estates";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { MinimalEstate } from "common/queries/minimalEstates";
import { add, differenceInYears, format } from "date-fns";
import { sum } from "lodash";
import { tileToNWLatLng } from "./mapUtilities";
import { squareToTsubo } from "./utilities";

// 抽出日付至
export const toDt = () =>
  format(
    add(
      process.env.REACT_APP_TODAY === undefined
        ? new Date()
        : new Date(process.env.REACT_APP_TODAY),
      { days: 1 }
    ),
    "yyyy-MM-dd"
  );

// 抽出日付自
export const fromDt = () =>
  format(
    add(
      process.env.REACT_APP_TODAY === undefined
        ? new Date()
        : new Date(process.env.REACT_APP_TODAY),
      { days: daysOfNew * -1 }
    ),
    "yyyy-MM-dd"
  );

window.console.info(fromDt());
window.console.info(toDt());

export const getEstateIconUrl = (
  estate:
    | MinimalEstate
    | { estateType: string; status: string }
    | undefined
    | null
): string =>
  estate?.estateType === EstateTypeNameEnum.土地
    ? estate?.status === StatusNameEnum.公開 ||
      estate?.status === SearchModeEnum.draft
      ? groundIcon
      : groundGrayIcon
    : estate?.estateType === EstateTypeNameEnum.分譲地
    ? estate?.status === StatusNameEnum.公開 ||
      estate?.status === SearchModeEnum.draft
      ? blockIcon
      : blockGrayIcon
    : estate?.estateType === EstateTypeNameEnum.新築建売
    ? estate?.status === StatusNameEnum.公開 ||
      estate?.status === SearchModeEnum.draft
      ? houseNewIcon
      : houseNewGrayIcon
    : estate?.estateType === EstateTypeNameEnum.中古戸建
    ? estate?.status === StatusNameEnum.公開 ||
      estate?.status === SearchModeEnum.draft
      ? houseUsedIcon
      : houseUsedGrayIcon
    : estate?.estateType === EstateTypeNameEnum.マンション
    ? estate?.status === StatusNameEnum.公開 ||
      estate?.status === SearchModeEnum.condominium ||
      estate?.status === SearchModeEnum.draft
      ? mansionIcon
      : mansionGrayIcon
    : estate?.estateType === EstateTypeNameEnum.事業用
    ? estate?.status === StatusNameEnum.公開 ||
      estate?.status === SearchModeEnum.draft
      ? businessIcon
      : businessGrayIcon
    : estate?.estateType === "？"
    ? estate?.status === StatusNameEnum.公開 ||
      estate?.status === SearchModeEnum.draft
      ? mixIcon
      : mixGrayIcon
    : "";

// 平均坪単価算出
export const calcUnitPrice = (estate: MinimalEstate | null | undefined) => {
  const grounds =
    estate?.blocks?.filter(
      (x) =>
        x.estateType === EstateTypeNameEnum.土地 ||
        x.estateType === EstateTypeNameEnum.分譲地
    ) || [];

  const totalPrice =
    (grounds.length || 0) > 0
      ? sum(grounds.map((x) => x.estatePrice) || [0])
      : estate?.estateType === EstateTypeNameEnum.土地 ||
        estate?.estateType === EstateTypeNameEnum.分譲地
      ? estate?.unitPrice || 0
      : 0;

  const totalTsuboArea =
    (grounds.length || 0) > 0 ? sum(grounds.map((x) => x.tsuboArea) || [0]) : 1;

  const unitPrice =
    totalTsuboArea === 0
      ? 0
      : Math.round((totalPrice * 10) / totalTsuboArea) / 10;

  if (unitPrice !== 0) {
    return String(unitPrice) + (unitPrice % 1 === 0 ? ".0" : "");
  }

  return "";
};

// 閲覧制限物件の土地・分譲地であるかを判定
const conditionOfSoldGroundEstate = (estate: MinimalEstate) =>
  estate.sold &&
  [EstateTypeNameEnum.土地, EstateTypeNameEnum.分譲地].includes(
    estate.estateType as EstateTypeNameEnum
  );

// 閲覧制限物件の新築建売・中古戸建・事業用であるかを判定
const conditionOfSoldBuildingEstate = (estate: MinimalEstate) =>
  estate.sold &&
  [
    EstateTypeNameEnum.新築建売,
    EstateTypeNameEnum.中古戸建,
    EstateTypeNameEnum.事業用
  ].includes(estate.estateType as EstateTypeNameEnum);

// 閲覧制限物件のマンションであるかを判定
const conditionOfSoldCondominiumEstate = (estate: MinimalEstate) =>
  estate.sold &&
  [EstateTypeNameEnum.マンション].includes(
    estate.estateType as EstateTypeNameEnum
  );

// 閲覧制限物件のデータ加工
export const constructSoldEstate = (
  estate: MinimalEstate,
  forArchive: boolean,
  forceLotToGround: boolean
) => {
  const soldGroundEstate = conditionOfSoldGroundEstate(estate);
  const soldBuildingEstate = conditionOfSoldBuildingEstate(estate);
  const soldCondominiumEstate = conditionOfSoldCondominiumEstate(estate);

  if (soldCondominiumEstate && !forArchive) {
    if (estate.tsuboArea === null || estate.tsuboArea === undefined) {
      estate.tsuboArea = estate.squareArea
        ? squareToTsubo(estate.squareArea)
        : null;
    }
  } else if (forArchive) {
    const position = tileToNWLatLng(estate.tileOnZoom16 || "", tileBaseZoom);

    estate.latitude = position.latitude;
    estate.longitude = position.longitude;

    if (soldGroundEstate || soldBuildingEstate) {
      estate.fullAddress = [estate.prefecture, estate.city, estate.area].join(
        ""
      );
    }

    if (soldGroundEstate && forceLotToGround) {
      estate.estateType = EstateTypeNameEnum.土地;
    }
  }

  if (estate.notFoundAt) {
    estate.updatedAt = estate.notFoundAt;
  }

  return estate;
};

// 売出時期の構築
export const dateToTerm = (date: string | null | undefined) => {
  if (date) {
    const year = Number(format(new Date(date), "yyyy"));
    const month = Number(format(new Date(date), "MM"));

    if (month >= 1 && month <= 3) {
      return year * 100 + 1;
    } else if (month >= 4 && month <= 6) {
      return year * 100 + 4;
    } else if (month >= 7 && month <= 9) {
      return year * 100 + 7;
    } else if (month >= 10 && month <= 12) {
      return year * 100 + 10;
    }
  }
};

// 売出時期の構築
export const dateToTermString = (date: string | null | undefined) => {
  if (date) {
    const year = format(new Date(date), "yyyy");
    const month = Number(format(new Date(date), "MM"));

    if (month >= 1 && month <= 3) {
      return `${year}年1月〜3月`;
    } else if (month >= 4 && month <= 6) {
      return `${year}年4月〜6月`;
    } else if (month >= 7 && month <= 9) {
      return `${year}年7月〜9月`;
    } else if (month >= 10 && month <= 12) {
      return `${year}年10月〜12月`;
    }
  }
};

// 表示する日付の構築
export const constructDate = (estate: MinimalEstate) => ({
  title: estate.sold
    ? "売出時期"
    : estate.status === StatusNameEnum.公開
    ? "登録日"
    : "更新日",
  value: estate.sold
    ? dateToTermString(estate?.updatedAt)
    : estate.status === StatusNameEnum.公開
    ? estate?.createdAt !== null
      ? format(new Date(estate?.createdAt || "1900-01-01"), "yyyy/MM/dd")
      : null
    : estate?.updatedAt !== null
    ? format(new Date(estate?.updatedAt || "1900-01-01"), "yyyy/MM/dd")
    : null
});

// 築年数計算
export const calcOldPrimitive = (
  completionMonth: number | null | undefined
) => {
  if (completionMonth && String(completionMonth).length === 6) {
    const completionDate = new Date(
      Math.trunc(completionMonth / 100),
      completionMonth % 100,
      1
    );

    return differenceInYears(new Date(), completionDate);
  }

  return null;
};

// 築年数計算
export const calcOld = (estate: MinimalEstate) => {
  return calcOldPrimitive(estate.completionMonth);
};

// 2物件間の距離(m)
export const calcDistance = (
  estate1: MinimalEstate,
  estate2: MinimalEstate
) => {
  const pointFrom = point([estate1.longitude || 0, estate1.latitude || 0]);
  const pointTo = point([estate2.longitude || 0, estate2.latitude || 0]);

  return distance(pointFrom, pointTo, { units: "meters" });
};

// 重複なく物件リストに追加
export const addEstateToList = (
  newEstate: MinimalEstate | null | undefined,
  estates: MinimalEstate[]
) => {
  if (newEstate?.id) {
    if (estates.find((e) => e.id === newEstate?.id)) {
      return estates;
    }

    const newEstates = [...estates, newEstate];
    const ids = Array.from(new Set(newEstates.map((e) => e.id)));

    return ids.map((id) => newEstates.find((e) => e.id === id)!);
  }

  return estates;
};
