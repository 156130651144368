import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { GroupEnum } from "common/enums/GroupEnum";
import { createPublicEstate } from "common/functions/api/publicEstates";
import { sleep } from "common/functions/utilities";
import { MinimalEstate } from "common/queries/minimalEstates";
import { MainContextContainer } from "components/Main";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { EstatesListProps } from "./interfaces";

export const useEstateListHooks = (props: EstatesListProps) => {
  const toolTipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "rgba(255, 240, 0, 1)",
            color: "black",
            borderRadius: "4px",
            border: "1px solid black",
            margin: 0
          },
          popper: {
            opacity: 0.9
          }
        }
      }
    }
  });

  const dataGridTheme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontFamily: "inherit"
          },
          columnHeader: {
            backgroundColor: "rgba(255, 240, 192, 1)"
          },
          columnHeaderTitle: {
            fontWeight: "700"
          }
        }
      }
    }
  });

  const progressTheme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: {
            color: "rgba(235, 220, 0, 1)"
          }
        }
      }
    }
  });

  const mainContext = useContext(MainContextContainer);

  const [selectedEstatesForPrint, setSelectedEstatesForPrint] = useState<
    string[]
  >([]);

  const isAdministrator = useMemo(() => {
    return mainContext.groups?.some(
      (group) => group === GroupEnum.Administrator
    );
  }, [mainContext.groups]);

  const isDomain = useCallback(
    (estate: MinimalEstate) => {
      return (
        estate.companyId?.startsWith(`${mainContext.company?.recNo ?? "X"}-`) ||
        estate.subCompanyId?.startsWith(
          `${mainContext.company?.recNo ?? "X"}-`
        ) ||
        estate.subCompany2Id?.startsWith(
          `${mainContext.company?.recNo ?? "X"}-`
        )
      );
    },
    [mainContext.company]
  );

  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const onDisplayEstate = useCallback(
    (estate: MinimalEstate) => {
      if (estate.id && mainContext.company?.id) {
        const w = window.open("/detail/waiting", "_blank");

        createPublicEstate(estate.id, mainContext.company.id)
          .then((res) => {
            if (w && res?.id) {
              const url = `/detail/${res.id}`;
              w.location.href = url;
            }
          })
          .catch((err) => {
            w?.close();
            window.console.error(err);
          });
      }
    },
    [mainContext.company?.id]
  );

  const onSelectEstateForPrint = useCallback(
    (estate: MinimalEstate, checked: boolean) => {
      setSelectedEstatesForPrint((prev) => {
        if (estate.id) {
          if (prev.includes(estate.id)) {
            if (!checked) {
              return prev.filter((id) => id !== estate.id);
            }
          } else if (checked) {
            return [...prev, estate.id];
          }
        }

        return prev;
      });
    },
    []
  );

  const onClearSelectedEstatesForPrint = useCallback(() => {
    setSelectedEstatesForPrint([]);
  }, []);

  const onPrintSelectedEstates = useCallback(async () => {
    const selectedEstates = props.estates.filter((estate) =>
      selectedEstatesForPrint.includes(estate.id ?? "")
    );

    if (selectedEstates.length > 0) {
      if (mainContext.company?.id) {
        const w = window.open("/detail/waiting", "_blank");
        const publicEstateIds: string[] = [];

        for (const estate of selectedEstates) {
          if (estate.id) {
            const res = await createPublicEstate(
              estate.id,
              mainContext.company.id
            ).catch((err) => {
              w?.close();
              window.console.error(err);
            });

            if (res === undefined) {
              return;
            }

            if (res?.id) {
              publicEstateIds.push(res.id);
            }

            await sleep(500);
          }
        }

        if (w) {
          const url = `/detail/${publicEstateIds.join(",")}`;
          w.location.href = url;
        }
      }
    }
  }, [mainContext.company?.id, props.estates, selectedEstatesForPrint]);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver(() => {
      const conditionSelector = document.querySelector("#conditionSelector");

      if (conditionSelector) {
        const height = conditionSelector.getBoundingClientRect().height;

        document.body.style.setProperty(
          "--condition-selector-height",
          `${height}px`
        );
      }
    });

    resizeObserverRef.current.observe(document.body);

    return () => {
      resizeObserverRef.current?.disconnect();
      resizeObserverRef.current = null;
    };
  }, []);

  useEffect(() => {
    setSelectedEstatesForPrint([]);
  }, [props.estates]);

  return {
    toolTipTheme,
    dataGridTheme,
    progressTheme,
    selectedEstatesForPrint,
    isAdministrator,
    isDomain,
    onDisplayEstate,
    onSelectEstateForPrint,
    onClearSelectedEstatesForPrint,
    onPrintSelectedEstates
  } as const;
};
