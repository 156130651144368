import { createTheme } from "@mui/material";
import { ReportKindNameEnum } from "common/enums/ReportKindEnum";
import { parsePlaceResult } from "common/functions/placesUtilities";
import { ReportKind } from "common/interfaces/ReportKind";
import { MainContextContainer } from "components/Main";
import { ChangeEventValue } from "components/Parts/Atoms/SumoraMap/interfaces";
import { useEstateForLocationInputHooks } from "hooks/estateHooks";
import { useMapHooks } from "hooks/mapHooks";
import { useReportHooks } from "hooks/reportHooks";
import { useReportKindHooks } from "hooks/reportKindHooks";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { ReportInputMode } from "./enums";
import { ReportInputDialogProps } from "./interfaces";

export const useReportInputDialogHooks = (props: ReportInputDialogProps) => {
  const mainContext = useContext(MainContextContainer);

  const theme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          scrollPaper: {
            className: "twbasic01"
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            fontFamily: "inherit",
            fontWeight: "700 !important",
            color: "black",

            "&::before": {
              backgroundColor: "rgba(210, 200, 175, 1)",
              borderRadius: "1000px",
              content: '""',
              display: "inline-block",
              height: "1.5em",
              marginRight: "6px",
              width: "7px"
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&::before": {
              display: "none"
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            fontFamily: "inherit"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: "0px 5px !important"
          },
          input: {
            padding: "12px 5px !important"
          },
          notchedOutline: {
            border: "none"
          }
        }
      }
    }
  });

  const [specifyMessage, setSpecifyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [forceDisable, setForceDisable] = useState(false);

  const {
    estate,
    setEstate,
    busy: busyForEstate,
    disable
  } = useEstateForLocationInputHooks(
    props.estateId,
    mainContext.userInfo?.username,
    mainContext.setFatal
  );

  const {
    report,
    forceReport,
    setReport,
    busy: busyForReport,
    createReport
  } = useReportHooks(
    null,
    mainContext.user,
    mainContext.company,
    estate,
    mainContext.setFatal
  );

  const { reportKinds } = useReportKindHooks(mainContext.setFatal);

  const {
    map,
    setMap,
    location,
    setLocation,
    center,
    setCenter,
    zoom,
    setZoom,
    places,
    placesService,
    setPlacesService,

    initialize: initializeMapStates
  } = useMapHooks();

  const [address, setAddress] = useState(estate.fullAddress);

  const onReportKindChange = useCallback(
    (value: ReportKind | null | undefined) => {
      if (value) {
        setReport.reportKind((value as ReportKind).name);
      }
    },
    [setReport]
  );

  const onDescriptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setReport.description(event.target.value);
    },
    [setReport]
  );

  const onAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAddress(event.target.value);
    },
    []
  );

  const onPlacesChange: (
    places: google.maps.places.PlaceResult,
    latitude?: number | null,
    longitude?: number | null
  ) => Promise<void> = useCallback(
    async (places, latitude, longitude) => {
      const { location, prefecture, city, area, remain } =
        await parsePlaceResult(places, true);

      if (latitude && longitude) {
        setEstate.latitude(latitude);
        setEstate.longitude(longitude);
      } else if (location) {
        setEstate.latitude(location.lat());
        setEstate.longitude(location.lng());
      }

      const fullAddresses: string[] = [];

      fullAddresses.push(prefecture);
      fullAddresses.push(city);
      fullAddresses.push(area);
      fullAddresses.push(remain);

      setEstate.fullAddress(fullAddresses.join(""));
    },
    [setEstate]
  );

  const onToPlaceClick = useCallback(
    (
      address: string | null | undefined,
      latitude?: number | null,
      longitude?: number | null
    ) => {
      setBusy(true);

      const usingPlacesService =
        placesService ?? (map && new google.maps.places.PlacesService(map));

      if (usingPlacesService) {
        if (!placesService) {
          setPlacesService(usingPlacesService);
        }

        usingPlacesService.textSearch(
          {
            query: address || undefined,
            type: "address"
          },
          (results) => {
            if (results?.at(0)) {
              if (results[0].place_id) {
                usingPlacesService?.getDetails(
                  {
                    placeId: results[0].place_id,
                    fields: ["geometry", "address_components", "website"]
                  },
                  (results2) => {
                    if (results2) {
                      onPlacesChange(results2, latitude, longitude).finally(
                        () => setBusy(false)
                      );
                    } else {
                      setSpecifyMessage("該当箇所を検索できませんでした。");
                      setBusy(false);
                    }
                  }
                );
              } else {
                onPlacesChange(results[0], latitude, longitude).finally(() =>
                  setBusy(false)
                );
              }
            } else {
              setSpecifyMessage("該当住所を検索できませんでした。");
              setBusy(false);
            }
          }
        );
      }
    },
    [map, onPlacesChange, placesService, setPlacesService]
  );

  const onAddressToMapButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onToPlaceClick(address);
    },
    [onToPlaceClick, address]
  );

  const onMapChange = useCallback(
    (value: ChangeEventValue) => {
      setCenter(value.center);
      setZoom(value.zoom);
    },
    [setCenter, setZoom]
  );

  const onMapReady = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [setMap]
  );

  const onLocationChange = useCallback(
    (value: google.maps.LatLng) => {
      onToPlaceClick(`${value.lat()},${value.lng()}`, value.lat(), value.lng());
    },
    [onToPlaceClick]
  );

  const onClose = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      if (props.onClose) {
        props.onClose(event);
      }
    },
    [props]
  );

  const onOkButtonClick = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
    ) => {
      let error = false;

      if (props.mode === ReportInputMode.Create) {
        if (!report.reportKind) {
          setErrorMessage("報告内容を選択してください。");
          error = true;
        }

        if (
          report.reportKind === ReportKindNameEnum.位置情報が違う &&
          (report.latitude === null ||
            report.latitude === undefined ||
            report.longitude === null ||
            report.longitude === undefined)
        ) {
          setErrorMessage("位置を指定してください。");
          error = true;
        }

        if (
          report.reportKind === ReportKindNameEnum.その他 &&
          (report.description ?? "").trim() === ""
        ) {
          setErrorMessage("その他の場合は報告内容を記入してください。");
          error = true;
        }
      }

      if (error) {
      } else {
        setReport.companyId(mainContext.user?.companyId);
        setReport.companyName(
          [
            mainContext.user?.companyName ?? "",
            mainContext.user?.companyBranchName ?? ""
          ].join(" ")
        );
        setReport.owner(mainContext.userInfo?.username);
        setReport.userName(mainContext.user?.name);

        setConfirm(true);
      }
    },
    [
      mainContext.user?.companyBranchName,
      mainContext.user?.companyId,
      mainContext.user?.companyName,
      mainContext.user?.name,
      mainContext.userInfo?.username,
      props.mode,
      report.description,
      report.latitude,
      report.longitude,
      report.reportKind,
      setReport
    ]
  );

  const onConfirmClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      createReport()
        .then((value) => {
          if (value) {
            props.onOkButtonClick(props.mode || ReportInputMode.Create, {
              ...value,
              __typename: "Report"
            });

            setThanks(true);
          }
        })
        .finally(() => setConfirm(false));
    },
    [createReport, props]
  );

  const onConfirmCloseClick = useCallback(() => {
    setConfirm(false);
  }, []);

  const onConfirmClose = onConfirmCloseClick;

  const onThanksCloseClick = useCallback(() => {
    setThanks(false);
    onClose({} as MouseEvent<HTMLButtonElement>);
  }, [onClose]);

  const onThanksClose = onThanksCloseClick;

  useEffect(() => {
    if (!props.open) {
      initializeMapStates();

      setSpecifyMessage("");
      setErrorMessage("");
      setBusy(false);
      setConfirm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (props.estateId) {
      forceReport({
        estateId: props.estateId
      });
    } else {
      forceReport({});
    }
  }, [props.estateId, forceReport]);

  useEffect(() => {
    if (
      estate.latitude === null ||
      estate.latitude === undefined ||
      estate.longitude === null ||
      estate.longitude === undefined
    ) {
      setLocation(null);
      setReport.latitude(null);
      setReport.longitude(null);
    } else {
      setLocation({
        lat: estate.latitude,
        lng: estate.longitude
      });
      setReport.latitude(estate.latitude);
      setReport.longitude(estate.longitude);
    }
  }, [setLocation, estate.latitude, estate.longitude, setReport]);

  useEffect(() => {
    if (estate.fullAddress) {
      setAddress(estate.fullAddress);
    }
  }, [estate.fullAddress]);

  useEffect(() => {
    if (map) {
      const service = new google.maps.places.PlacesService(map);
      setPlacesService(service);
    }
  }, [map, setPlacesService]);

  useEffect(() => {
    setBusy(busyForEstate || busyForReport);
  }, [busyForEstate, busyForReport]);

  useEffect(() => {
    setForceDisable(disable);
  }, [disable]);

  return {
    theme,

    estate,
    report,

    specifyMessage,

    reportKinds,

    map,
    location,
    center,
    zoom,
    places,
    address,

    errorMessage,

    busy,
    forceDisable,
    confirm,
    thanks,

    onReportKindChange,
    onDescriptionChange,

    onAddressChange,
    onAddressToMapButtonClick,

    onMapChange,
    onMapReady,

    onLocationChange,

    onOkButtonClick,
    onClose,
    onConfirmClick,
    onConfirmCloseClick,
    onConfirmClose,
    onThanksCloseClick,
    onThanksClose
  } as const;
};

export type ReportInputDialogHooksType = ReturnType<
  typeof useReportInputDialogHooks
>;
