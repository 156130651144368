import { AutocompleteValue } from "@mui/material";
import { Company } from "API";

export const constructOptionString = (option: Company) => {
  if (option.name) {
    const idStr =
      option.branchId === 0
        ? `${option.recNo}`
        : `${option.recNo} - ${option.branchId}`;

    return `${option.name || ""} ${option.branchName || ""} (${idStr})`;
  }

  return "";
};

export const invokeConvertValue = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  multiple: boolean | undefined,
  companies: Company[],
  value:
    | AutocompleteValue<Company, Multiple, DisableClearable, FreeSolo>
    | string
    | string[]
    | undefined
) => {
  if (value) {
    if (typeof value === "string") {
      return (companies.find((x) => constructOptionString(x) === value) ||
        null) as AutocompleteValue<
        Company,
        Multiple,
        DisableClearable,
        FreeSolo
      >;
    }

    if (value instanceof Array) {
      return (value
        .map((x) => {
          if (typeof x === "string") {
            return companies.find(
              (master) => constructOptionString(master) === x
            );
          }

          return x;
        })
        .filter((x) => x) || []) as AutocompleteValue<
        Company,
        Multiple,
        DisableClearable,
        FreeSolo
      >;
    }
  }

  return (value || (multiple ? [] : null)) as AutocompleteValue<
    Company,
    Multiple,
    DisableClearable,
    FreeSolo
  >;
};
