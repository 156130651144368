import { Autocomplete, Box, Typography } from "@mui/material";
import React from "react";
import { AutocompleteRenderInputTextBox } from "../AutocompleteRenderInputTextBox";
import { useCompanyWithRecNoAutocompleteHooks } from "./hooks";
import { CompanyWithRecNoAutocompleteProps } from "./interfaces";

const CompanyWithRecNoAutocompleteImplements = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: CompanyWithRecNoAutocompleteProps<Multiple, DisableClearable, FreeSolo>
) => {
  const {
    companies,
    busy,
    referenceStyles,
    getOptionLabel,
    getOptionSelected,
    convertValue
  } = useCompanyWithRecNoAutocompleteHooks<
    Multiple,
    DisableClearable,
    FreeSolo
  >(props.recNo, props.multiple, props.setFatal, props.onOptionsLoad);

  const {
    label: _label,
    helperText: _helperText,
    errorMessage: _errorMessage,
    referenceText: _referenceText,
    required: _required,
    InputProps: _InputProps,
    onOptionsLoad: _onOptionsLoad,
    recNo: _recNo,
    ...autocompleteProps
  } = props;

  delete autocompleteProps.setFatal;

  return (
    <>
      <Autocomplete
        {...autocompleteProps}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <AutocompleteRenderInputTextBox
            params={params}
            label={props.label || "業者を選択"}
            helperText={props.helperText}
            errorMessage={props.errorMessage}
            referenceText={props.referenceText}
            required={props.required}
            InputProps={props.InputProps}
          />
        )}
        options={companies}
        loading={busy}
        value={convertValue(props.value)}
        isOptionEqualToValue={getOptionSelected}
        disabled={companies.length === 0 || props.disabled}
        noOptionsText={<i>候補が見つかりません</i>}
      ></Autocomplete>
      <Box sx={referenceStyles}>
        <Typography variant="caption">{props.referenceText}</Typography>
      </Box>
    </>
  );
};

export const CompanyWithRecNoAutocomplete = React.memo(
  CompanyWithRecNoAutocompleteImplements
) as typeof CompanyWithRecNoAutocompleteImplements;
