import { Box, Button, Fade } from "@mui/material";
import { memo } from "react";
import styles from "./ContentMain.module.scss";
import { useContentMainHooks } from "./hooks";

export const ContentMain = memo(
  (props: { children: JSX.Element | JSX.Element[] }) => {
    const { mainContext, appearReloadMessage } = useContentMainHooks();

    const reloadMessage = (
      <>
        <Fade in={!appearReloadMessage} timeout={1500}>
          <Box className={styles.message}>
            <Box className={styles.messageContent}>お待ちください。</Box>
          </Box>
        </Fade>
        <Fade in={appearReloadMessage} timeout={1500}>
          <Box className={styles.message}>
            <Box className={styles.messageContent}>
              画面が切り替わらない場合は
              <Button
                variant="text"
                onClick={() => {
                  window.location.reload();
                }}
              >
                リロード
              </Button>
              してください。
            </Box>
          </Box>
        </Fade>
      </>
    );

    return (
      <Box component="main" className={styles.contentMain}>
        {mainContext.isAuthenticated &&
        mainContext.isAgreed &&
        !mainContext.isReadyToUse &&
        appearReloadMessage
          ? reloadMessage
          : props.children}
      </Box>
    );
  }
);
