import { Box } from "@mui/material";
import ic_apart from "assets/icons/map_menu/ic_apart.svg";
import ic_block from "assets/icons/map_menu/ic_block.svg";
import ic_business from "assets/icons/map_menu/ic_menu_business.svg";
import ic_land from "assets/icons/map_menu/ic_land.svg";
import ic_new from "assets/icons/map_menu/ic_new.svg";
import ic_used from "assets/icons/map_menu/ic_used.svg";
import { memo } from "react";
import styles from "../SearchMenu.module.scss";
import { SearchMenuButton } from "./SearchMenuButton";

export const EstateTypeMenu = memo(
  (props: {
    landStatus: boolean;
    blockStatus: boolean;
    newStatus: boolean;
    usedStatus: boolean;
    apartStatus: boolean;
    businessStatus: boolean;
    onLandStatusChange: (on: boolean) => void;
    onBlockStatusChange: (on: boolean) => void;
    onNewStatusChange: (on: boolean) => void;
    onUsedStatusChange: (on: boolean) => void;
    onApartStatusChange: (on: boolean) => void;
    onBusinessStatusChange: (on: boolean) => void;
  }) => {
    return (
      <Box component="div" className={styles.cat}>
        <Box component="div" className={styles.tit}>
          <span>物件種別</span>
        </Box>
        <Box component="div" className={styles.btn}>
          <Box component="ul">
            <SearchMenuButton
              titles={["土地"]}
              imgSrc={ic_land}
              status={props.landStatus}
              onStatusChange={props.onLandStatusChange}
            />
            <SearchMenuButton
              titles={["分譲地"]}
              imgSrc={ic_block}
              status={props.blockStatus}
              onStatusChange={props.onBlockStatusChange}
            />
            <SearchMenuButton
              titles={["新築建売"]}
              imgSrc={ic_new}
              status={props.newStatus}
              onStatusChange={props.onNewStatusChange}
            />
            <SearchMenuButton
              titles={["中古戸建"]}
              imgSrc={ic_used}
              status={props.usedStatus}
              onStatusChange={props.onUsedStatusChange}
            />
            <SearchMenuButton
              titles={["マンション"]}
              imgSrc={ic_apart}
              status={props.apartStatus}
              onStatusChange={props.onApartStatusChange}
            />
            <SearchMenuButton
              titles={["事業用"]}
              imgSrc={ic_business}
              status={props.businessStatus}
              onStatusChange={props.onBusinessStatusChange}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);
