import { ArchivePage } from "components/Parts/Organisms/ArchivePage";
import { ArchiveProps } from "components/Parts/Organisms/ArchivePage/interfaces";
import { Auth } from "components/Parts/Organisms/Auth";
import React from "react";

export const Archive = React.memo((props: ArchiveProps) => (
  <Auth>
    <ArchivePage {...props} />
  </Auth>
));
