import { MenuTabValueEnum } from "common/enums/MenuTabValueEnum";
import React, { useRef } from "react";

export const useCommonPageHandlers = (
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setTabValue: React.Dispatch<React.SetStateAction<MenuTabValueEnum>>
) => {
  const menuButtonClicked = useRef(false);

  // メニューボタンクリック時
  const onMenuButtonClick = () => {
    setMenuOpen((x) => !x);
    menuButtonClicked.current = true;
    setTimeout(() => {
      menuButtonClicked.current = false;
    }, 100);
  };

  // メニュー非表示
  const invokeMenuClose = () => {
    if (!menuButtonClicked.current) {
      setMenuOpen(false);
    }

    menuButtonClicked.current = false;
  };

  // メニュータブ変更時
  const onTabValueChange = (
    event: React.SyntheticEvent<Element, Event>,
    tabValue: MenuTabValueEnum
  ) => {
    setTabValue(tabValue);
  };

  // メニューアイコンクリック時
  const onMenuIconClick = () => {
    invokeMenuClose();
  };

  // 初期タブ値指定時
  const onNoticeInitialTabValue = (initialTabValue: MenuTabValueEnum) => {
    setTabValue(initialTabValue);
  };

  return {
    onMenuButtonClick,
    invokeMenuClose,
    onTabValueChange,
    onMenuIconClick,
    onNoticeInitialTabValue
  } as const;
};
