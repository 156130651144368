import { Box } from "@mui/material";
import { CopyLinkButton } from "components/Parts/Atoms/CopyLinkButton";
import { DownloadButton } from "components/Parts/Atoms/DownloadButton";
import React from "react";
import styles from "./DetailPage.module.scss";
import { useDetailHooks } from "./hooks";
import { IndividualPage } from "./IndividualPage";
import { DetailProps } from "./interfaces";

export const DetailPage = React.memo((props: DetailProps) => {
  const {
    publicEstateIds,
    waiting,
    isDownloadingPdf,
    isPdfDownloaded,
    completed,

    onPublicEstateLoaded,
    onDownloadPdfClick
  } = useDetailHooks(props);

  const Styler = () => (
    <style>{`
      @page {
        size: A4 landscape;
        margin: 0mm;
      }

      @media print {
        body {
          min-height: 1px;
        }
      }
  `}</style>
  );

  if (waiting) {
    return (
      <Box className={styles.waiting}>作成中です。しばらくお待ちください。</Box>
    );
  }

  return (
    <>
      <Box className={styles.detail}>
        <Styler />
        <Box className={styles.section} component="section">
          {publicEstateIds.map((publicEstateId, index) => (
            <IndividualPage
              key={index}
              forAnonymous={props.forAnonymous}
              publicEstateId={publicEstateId}
              onPublicEstateLoaded={onPublicEstateLoaded}
            />
          ))}
        </Box>
      </Box>
      {completed ? (
        <Box className={styles.completed} id="completed">
          読み込み完了
        </Box>
      ) : null}
      {props.forAnonymous ? null : (
        <Box className={styles.printButton}>
          <CopyLinkButton
            url={window.location.href
              .replace(/\?ci=[0-9]+/g, "")
              .replace(/\/detail/, "/pub")}
            toolTipText={
              <>
                このページのリンクをコピーしました。
                <br />
                コピーリンク閲覧期限：本日より14日間
              </>
            }
          />
          <DownloadButton
            busy={isDownloadingPdf}
            toolTipText="PDFをダウンロードしました"
            completed={isPdfDownloaded}
            onClick={() =>
              onDownloadPdfClick(
                window.location.href
                  .replace(/\?ci=[0-9]+/g, "")
                  .replace(/\/detail/, "/pub")
              )
            }
          />
        </Box>
      )}
    </>
  );
});
