import { Report } from "API";
import { ReportInputMode } from "components/Parts/Organisms/ReportInputDialog/enums";
import { useCallback } from "react";
import { SearchDialogStatesType } from "../states/dialogs";

export const useSearchReportInputDialogHandlers = (
  dialogStates: SearchDialogStatesType
) => {
  const onClose = useCallback(() => {
    dialogStates.setReportInputDialogMode(undefined);
    dialogStates.setReportInputDialogEstateId(undefined);
  }, [dialogStates]);

  const onOkButtonClick = useCallback(
    (mode: ReportInputMode, inputValues: Report | null) => {
      onClose();
    },
    [onClose]
  );

  return {
    onClose,
    onOkButtonClick
  } as const;
};

export type SearchReportInputDialogHandlersType = ReturnType<
  typeof useSearchReportInputDialogHandlers
>;
