import { StickyNote } from "API";
import { StickyNoteInputMode } from "components/Parts/Organisms/StickyNoteInputDialog/enums";
import { useCallback } from "react";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchStickyNoteStatesType } from "../states/stickyNotes";

export const useSearchStickyNoteInputDialogHandlers = (
  dialogStates: SearchDialogStatesType,
  stickyNoteStates: SearchStickyNoteStatesType
) => {
  const onStickyNoteInputDialogClose = useCallback(() => {
    dialogStates.setStickyNoteInputDialogStickyNote(undefined);
    dialogStates.setStickyNoteInputDialogMode(undefined);
    dialogStates.setStickyNoteInputDialogInitialLatitude(undefined);
    dialogStates.setStickyNoteInputDialogInitialLongitude(undefined);
  }, [dialogStates]);

  const onStickyNoteInputDialogOkButtonClick = useCallback(
    (mode: StickyNoteInputMode, inputValues: StickyNote | null) => {
      const currentStickyNotes = stickyNoteStates.stickyNotes || [];

      if (inputValues) {
        switch (mode) {
          case StickyNoteInputMode.Create:
            currentStickyNotes.push(inputValues);
            break;
          case StickyNoteInputMode.Update:
            const updatedIndex = currentStickyNotes.findIndex(
              (x) => x.id === inputValues.id
            );

            if (updatedIndex > -1) {
              currentStickyNotes.splice(updatedIndex, 1);
              currentStickyNotes.push(inputValues);
            }

            break;
          case StickyNoteInputMode.Delete:
            const deletedIndex = currentStickyNotes.findIndex(
              (x) => x.id === inputValues.id
            );

            if (deletedIndex > -1) {
              currentStickyNotes.splice(deletedIndex, 1);
            }
            break;
        }

        stickyNoteStates.setStickyNotes(currentStickyNotes);
        stickyNoteStates.setStickyNotesCount(currentStickyNotes.length);
        stickyNoteStates.setStickyNotesChangeDispatcher((x) => !x);

        onStickyNoteInputDialogClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialogStates, stickyNoteStates]
  );

  return {
    onStickyNoteInputDialogClose,
    onStickyNoteInputDialogOkButtonClick
  } as const;
};

export type SearchStickyNoteInputDialogHandlersType = ReturnType<
  typeof useSearchStickyNoteInputDialogHandlers
>;
