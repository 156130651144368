import { QuestionMark } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import React from "react";
import styles from "./Confirm.module.scss";
import { useConfirmHooks } from "./hooks";
import { ConfirmProps } from "./interfaces";

export const Confirm = React.memo((props: ConfirmProps) => {
  const { busy, onOkClick } = useConfirmHooks(props);

  return (
    <>
      <Dialog
        style={props.style}
        className={`${props.className} ${styles.dialog}}`}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="confirm-title"
        aria-describedby="confirm-description"
        fullWidth
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{ backdrop: { className: styles.backdrop } }}
      >
        <DialogHeader id="confirm-title" icon={<QuestionMark />} />
        <DialogContent id="confirm-description">{props.content}</DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            variant="contained"
            className={styles.cancelButton}
            disabled={busy}
            onClick={props.onCloseClick}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            className={styles.okButton}
            disabled={busy}
            onClick={onOkClick}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
