import { MainContext } from "common/interfaces/MainContext";
import { useCallback } from "react";
import { SearchMapStatesType } from "../states/maps";

export const useSearchBehaviorMethods = (
  mainContext: MainContext,
  mapStates: SearchMapStatesType
) => {
  // 地図中心のデフォルト設定(会社マスタから)
  const setDefaultCenter = useCallback(() => {
    if (
      mapStates.map &&
      mainContext.company?.latitude &&
      mainContext.company?.longitude
    ) {
      const center = {
        lat: mainContext.company.latitude,
        lng: mainContext.company.longitude
      };

      mapStates.setCenter(center);
      mapStates.map.setCenter(center);

      return true;
    }

    return false;
  }, [
    mainContext.company?.latitude,
    mainContext.company?.longitude,
    mapStates
  ]);

  return {
    setDefaultCenter
  } as const;
};
