import { Box, FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { OpenLevel } from "common/interfaces/OpenLevel";
import { JsonMasterSwitchButtons } from "components/Parts/Atoms/JsonMasterSwitchButtons";
import { LocationMap } from "components/Parts/Molecules/LocationMap";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import styles from "./StickyNoteInputDialog.module.scss";
import { StickyNoteInputMode } from "./enums";
import { StickyNoteInputDialogHooksType } from "./hooks";
import { StickyNoteInputDialogProps } from "./interfaces";
import { ColorSwitchButtons } from "./subComponents/ColorSwitchButtons";
import { IconSwitchButtons } from "./subComponents/IconSwitchButtons";

export const useInputFields = (
  props: StickyNoteInputDialogProps,
  {
    stickyNote,
    openLevels,

    stickyNoteErrorMessages,
    setStickyNoteErrorMessages,
    map,
    location,
    center,
    zoom,
    places,

    errorMessage,
    busy,
    confirm,
    forceDisable,

    onMemoKindChange,
    onOpenLevelChange,
    onDescriptionChange,
    onIconChange,
    onColorChange,

    onMapChange,
    onMapReady,
    onLocationChange,

    onOkButtonClick,
    onConfirmDeleteClick,
    onConfirmDeleteCloseClick,
    onConfirmDeleteClose,
    onClose,

    nodeRef
  }: StickyNoteInputDialogHooksType
) => {
  return useCallback(() => {
    const updatedAt = stickyNote?.updatedAt
      ? new Date(stickyNote.updatedAt)
          .toISOString()
          .slice(0, 10)
          .replaceAll("-", ".")
          .split(".")
          .map((x) => x.replace(/^0/g, ""))
          .join(".")
      : undefined;

    const inputFields = [
      {
        title: "公開範囲",
        value: (
          <JsonMasterSwitchButtons<OpenLevel>
            id="open-level-selector"
            label="公開範囲"
            master={openLevels}
            value={stickyNote.openLevel}
            disabled={
              forceDisable || busy || props.mode === StickyNoteInputMode.Delete
            }
            onChange={onOpenLevelChange}
          />
        )
      },
      {
        title: "本文",
        value: (
          <FormControl fullWidth>
            <FormLabel className={styles.formLabel} id="location-label">
              本文 <Box component="small">（最終更新：{updatedAt}）</Box>
            </FormLabel>
            <Box className={styles.fieldWrapper}>
              <Box className={styles.field}>
                <TextField
                  label=" "
                  multiline
                  rows={4}
                  fullWidth
                  error={
                    !isEmpty(stickyNoteErrorMessages.descriptionErrorMessage)
                  }
                  disabled={
                    forceDisable ||
                    busy ||
                    props.mode === StickyNoteInputMode.Delete
                  }
                  value={stickyNote.description || ""}
                  onChange={onDescriptionChange}
                  helperText={stickyNoteErrorMessages.descriptionErrorMessage}
                />
              </Box>
            </Box>
          </FormControl>
        )
      },
      {
        title: "アイコンの絵",
        value: (
          <IconSwitchButtons
            id="icon-selector"
            label="アイコンの絵"
            value={stickyNote.icon}
            disabled={
              forceDisable || busy || props.mode === StickyNoteInputMode.Delete
            }
            onChange={onIconChange}
          />
        )
      },
      {
        title: "アイコンの色",
        value: (
          <ColorSwitchButtons
            id="color-selector"
            label="アイコンの色"
            value={stickyNote.color}
            disabled={
              forceDisable || busy || props.mode === StickyNoteInputMode.Delete
            }
            onChange={onColorChange}
          />
        )
      },
      {
        title: "MAP",
        value: (
          <FormControl fullWidth>
            <FormLabel className={styles.formLabel} id="location-label">
              位置調整
            </FormLabel>
            <Box className={styles.fieldWrapper}>
              <Grid container>
                <Grid item xs={12}>
                  <LocationMap
                    mapDivId="location-map"
                    location={location}
                    center={center}
                    zoom={zoom}
                    places={places}
                    draggable={
                      !busy && props.mode !== StickyNoteInputMode.Delete
                    }
                    onChange={onMapChange}
                    onMapReady={onMapReady}
                    onLocationChange={onLocationChange}
                    className={styles.map}
                  />
                </Grid>
                <Grid item xs={12}>
                  <small>
                    緯度: {stickyNote.latitude} / 経度: {stickyNote.longitude}
                  </small>
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        )
      }
    ];

    return inputFields;
  }, [
    stickyNote.updatedAt,
    stickyNote.openLevel,
    stickyNote.description,
    stickyNote.icon,
    stickyNote.color,
    stickyNote.latitude,
    stickyNote.longitude,
    openLevels,
    forceDisable,
    busy,
    props.mode,
    onOpenLevelChange,
    stickyNoteErrorMessages.descriptionErrorMessage,
    onDescriptionChange,
    onIconChange,
    onColorChange,
    location,
    center,
    zoom,
    places,
    onMapChange,
    onMapReady,
    onLocationChange
  ]);
};
