import { BldConditionTypeEnum } from "common/enums/BldConditionTypeEnum";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { GroupEnum } from "common/enums/GroupEnum";
import { ParkingEnum } from "common/enums/ParkingEnum";
import { PetEnum } from "common/enums/PetEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { calcOld } from "common/functions/estates";
import { formatMonth } from "common/functions/utilities";
import { MainContextContainer } from "components/Main";
import { useEstateAtomicCounterHooks } from "hooks/atomicCounterHooks";
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { MinimalEstateWithInfo } from "../interfaces";

export const useHooks = (
  block: MinimalEstateWithInfo | null | undefined,
  onResize: ((height: number) => void) | undefined
) => {
  const infoRef = useRef<HTMLDivElement>(null);
  const mainContext = useContext(MainContextContainer);
  const [height, setHeight] = useState(0);
  const [expandRoomPrice, setExpandRoomPrice] = useState(true);
  const [openContact, setOpenContact] = useState(false);
  const [openCompleteCopy, setOpenCompleteCopy] = useState(false);
  const [openPriceHistory, setOpenPriceHistory] = useState(false);

  const { clickedCount } = useEstateAtomicCounterHooks(
    block?.id,
    mainContext.setFatal
  );

  const id = useMemo(
    () =>
      block?.id ?? `${block?.isCondominiumMaster === true ? "C" : "R"}-?????`,
    [block?.id, block?.isCondominiumMaster]
  );

  const groundType = useMemo(
    () =>
      (block?.estateType === EstateTypeNameEnum.土地 ||
        block?.estateType === EstateTypeNameEnum.分譲地) &&
      (block?.groundType || block?.groundTypeFree)
        ? `${block?.groundType?.join(" / ") || ""} ${
            block?.groundTypeFree || ""
          }`
        : null,
    [block?.estateType, block?.groundType, block?.groundTypeFree]
  );

  const parking = useMemo(
    () =>
      block?.parking === ParkingEnum.あり
        ? "P有"
        : block?.parking === ParkingEnum.なし
        ? "P無"
        : null,
    [block?.parking]
  );

  const pet = useMemo(
    () =>
      block?.isCondominiumMaster === true
        ? block?.pet === PetEnum.OK
          ? "ペットOK"
          : block?.pet === PetEnum.NG
          ? "ペットNG"
          : null
        : null,
    [block?.isCondominiumMaster, block?.pet]
  );

  const externalUrl = useMemo(
    () =>
      (block?.status === StatusNameEnum.公開 ||
        block?.status === StatusNameEnum.下書き) &&
      block?.url
        ? block?.url || null
        : null,
    [block?.status, block?.url]
  );

  const myself = useMemo(
    () =>
      String(mainContext.company?.recNo) ===
        block?.companyId?.split("-")?.at(0) ||
      String(mainContext.company?.recNo) ===
        block?.subCompanyId?.split("-")?.at(0) ||
      String(mainContext.company?.recNo) ===
        block?.subCompany2Id?.split("-")?.at(0),
    [
      block?.companyId,
      block?.subCompany2Id,
      block?.subCompanyId,
      mainContext.company?.recNo
    ]
  );

  const isAdministrator = useMemo(
    () => mainContext.groups?.includes(GroupEnum.Administrator),
    [mainContext.groups]
  );

  const isEditable = useMemo(
    () => isAdministrator || myself,
    [isAdministrator, myself]
  );

  const sold = useMemo(
    () => block?.status === StatusNameEnum.成約済み,
    [block?.status]
  );

  const bldConditionType = useMemo(
    () =>
      block?.bldConditionType === BldConditionTypeEnum.建築条件あり
        ? "条件（あり）"
        : block?.bldConditionType === BldConditionTypeEnum.建築条件なし
        ? "条件（なし）"
        : null,
    [block?.bldConditionType]
  );

  const layout = useMemo(() => block?.layout ?? "", [block?.layout]);

  const purposeB = useMemo(() => block?.purposeB ?? "", [block?.purposeB]);

  const completionMonth = useMemo(() => {
    const completionMonth =
      block?.completionMonth ?? block?.completionSchMonth ?? 0;

    if (completionMonth === 0) {
      return "-";
    }

    return formatMonth(completionMonth, "yyyy.MM");
  }, [block?.completionMonth, block?.completionSchMonth]);

  const old = useMemo(() => (block ? calcOld(block) ?? "-" : "-"), [block]);

  const structure = useMemo(() => {
    if (block?.structure) {
      return block.structure.replace(/（.+）$/, "");
    }

    return null;
  }, [block?.structure]);

  const companies = useMemo(() => {
    const companies: { name: string; tel: string | null | undefined }[] = [];

    if (block?.companyName) {
      companies.push({ name: block?.companyName, tel: block?.companyTel });
    }

    if (block?.subCompanyName) {
      companies.push({
        name: block?.subCompanyName,
        tel: block?.subCompanyTel
      });
    }

    if (block?.subCompany2Name) {
      companies.push({
        name: block?.subCompany2Name,
        tel: block?.subCompany2Tel
      });
    }

    // companiesの要素をランダムに並び替える
    for (let i = companies.length - 1; i > 0; i--) {
      const r = Math.floor(Math.random() * (i + 1));
      const tmp = companies[i];
      companies[i] = companies[r];
      companies[r] = tmp;
    }

    return companies.length === 0
      ? [{ name: "（不明）", tel: undefined }]
      : companies;
  }, [
    block?.companyName,
    block?.companyTel,
    block?.subCompany2Name,
    block?.subCompany2Tel,
    block?.subCompanyName,
    block?.subCompanyTel
  ]);

  const isCondominiumMaster = useMemo(
    () => block?.isCondominiumMaster ?? false,
    [block?.isCondominiumMaster]
  );

  const isCondominiumRoom = useMemo(
    () =>
      block?.estateType === EstateTypeNameEnum.マンション &&
      !isCondominiumMaster,
    [block?.estateType, isCondominiumMaster]
  );

  const floorBlocks = useMemo(() => {
    const floors = new Set(block?.blocks?.map((x) => x.floor) ?? []);

    return Array.from(floors).map((floor) => {
      const floorBlocks = block?.blocks?.filter((x) => x.floor === floor) ?? [];

      return {
        floor,
        floorBlocks
      };
    });
  }, [block?.blocks]);

  const priceHistory = useMemo(
    () =>
      block?.priceHistory?.sort(
        (a, b) => (a?.pricedAt ?? "Z").localeCompare(b?.pricedAt ?? "Z") * -1
      ),
    [block?.priceHistory]
  );

  const onHistoryClick = useCallback(() => {
    if (block?.id) {
      window.open(
        `/archive?mid=${block.id}&c=1`,
        "_blank",
        "noopener noreferrer"
      );
    }
  }, [block?.id]);

  const onExpandRoomPriceClick = useCallback(() => {
    setExpandRoomPrice((prev) => !prev);
  }, []);

  const onLinkClick = useCallback(() => {
    if (externalUrl) {
      window.open(externalUrl, "_blank", "noopener noreferrer");
    }
  }, [externalUrl]);

  const onGMapClick = useCallback(() => {
    if (
      block?.status !== StatusNameEnum.成約済み &&
      block?.longitude &&
      block?.latitude
    ) {
      const mapUrl = `https://www.google.com/maps?q=${block?.latitude},${block?.longitude}`;
      navigator.clipboard.writeText(mapUrl);
      setOpenCompleteCopy(true);
      setTimeout(() => {
        setOpenCompleteCopy(false);
      }, 1400);
    }
  }, [block?.latitude, block?.longitude, block?.status]);

  const onNaviClick = useCallback(() => {
    if (
      block?.status !== StatusNameEnum.成約済み &&
      block?.longitude &&
      block?.latitude
    ) {
      const url = `https://www.google.com/maps/dir/?api=1&hl=ja&travelmode=driving&destination=${block.latitude},${block.longitude}`;
      window.open(url, "_blank", "noopener noreferrer");
    }
  }, [block?.latitude, block?.longitude, block?.status]);

  const onContactClick = useCallback(() => {
    setOpenContact(true);
  }, []);

  const onContactCloseClick = useCallback(() => {
    setOpenContact(false);
  }, []);

  const onPriceHistoryClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      setOpenPriceHistory(true);
    },
    []
  );

  const onPriceHistoryCloseClick = useCallback(() => {
    setOpenPriceHistory(false);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setHeight(entry.target.clientHeight + 8);
      });
    });

    if (infoRef.current) {
      resizeObserver.observe(infoRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    onResize?.(height);
  }, [onResize, height]);

  return {
    infoRef,
    mainContext,

    expandRoomPrice,
    clickedCount,
    openContact,
    openCompleteCopy,
    openPriceHistory,

    id,
    groundType,
    externalUrl,
    parking,
    pet,
    isAdministrator,
    isEditable,
    sold,
    bldConditionType,
    layout,
    purposeB,
    completionMonth,
    old,
    structure,
    companies,
    isCondominiumMaster,
    isCondominiumRoom,
    floorBlocks,
    priceHistory,

    onHistoryClick,
    onExpandRoomPriceClick,
    onLinkClick,
    onGMapClick,
    onNaviClick,
    onContactClick,
    onContactCloseClick,
    onPriceHistoryClick,
    onPriceHistoryCloseClick
  } as const;
};
