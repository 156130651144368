import {
  AutocompleteFreeSoloValueMapping,
  AutocompleteValue
} from "@mui/material";
import { Company } from "API";
import { useCompanyWithRecNoHooks } from "hooks/companyHooks";
import { useReferenceStyles } from "hooks/referenceStyles";
import { useCallback, useEffect } from "react";
import {
  constructOptionString,
  invokeConvertValue
} from "../CompanyAutocomplete/utils";

export const useCompanyWithRecNoAutocompleteHooks = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  recNo: number | null | undefined,
  multiple: boolean | undefined,
  setFatal: ((error?: string | null | undefined) => void) | undefined,
  onOptionsLoad: ((options: Company[]) => void) | undefined
) => {
  const { companies, busy } = useCompanyWithRecNoHooks(recNo, setFatal);

  const referenceStyles = useReferenceStyles();

  const groupBy = useCallback((option: Company) => option.name, []);
  const getOptionLabel = useCallback(
    (option: Company | AutocompleteFreeSoloValueMapping<FreeSolo>) => {
      if (typeof option === "string") {
        return option as string;
      }

      return constructOptionString(option);
    },
    []
  );

  const getOptionSelected = useCallback(
    (option: Company, value: Company): boolean => {
      return option.id === value.id;
    },
    []
  );

  const convertValue = useCallback(
    (
      value:
        | AutocompleteValue<Company, Multiple, DisableClearable, FreeSolo>
        | string
        | string[]
        | undefined
    ) => {
      return invokeConvertValue(multiple, companies, value);
    },
    [companies, multiple]
  );

  useEffect(() => {
    if (onOptionsLoad) {
      onOptionsLoad(companies);
    }
  }, [companies, onOptionsLoad]);

  return {
    companies,
    busy,
    referenceStyles,
    groupBy,
    getOptionLabel,
    getOptionSelected,
    convertValue
  } as const;
};
