import { Box } from "@mui/material";
import ArrowLR2 from "assets/icons/filter/arrow_lr2.svg";
import SliderFkdsHorn from "assets/icons/filter/slider_fkds_horn.svg";
import React, { useEffect } from "react";
import styles from "../EstateFilterDialog.module.scss";
import { isMobileOnly } from "react-device-detect";

export const SliderCaption = React.memo(
  (props: {
    captionRef: React.RefObject<HTMLDivElement>;
    arrowRef: React.RefObject<HTMLDivElement>;
    captionLeft: number | undefined;
    captionRight: number | undefined;
    arrowLeft: number | undefined;
    arrowRight: number | undefined;
    fromCaption: string[];
    toCaption: string[];
    chaseThumb: () => void;
  }) => {
    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          entry.target === props.captionRef.current && props.chaseThumb();
          entry.target === props.arrowRef.current && props.chaseThumb();
        }
      });

      resizeObserver.observe(props.captionRef.current!);
      resizeObserver.observe(props.arrowRef.current!);

      return () => {
        resizeObserver.disconnect();
      };
    }, [props]);

    return (
      <>
        <Box className={styles.caption_box_wrapper}>
          <Box
            ref={props.captionRef}
            className={`${styles.caption_box1} ${
              isMobileOnly ? styles.mobileOnly : ""
            }`}
            sx={{
              left: props.captionLeft,
              right: props.captionRight
            }}
          >
            <Box className={styles.fkds_slider}>
              <span className={`${styles.value} ${styles.min}`}>
                {props.fromCaption.at(0) ?? ""}
              </span>
              {props.fromCaption.at(1) && (
                <small>{props.fromCaption.at(1)}</small>
              )}
              <img src={ArrowLR2} alt="←→" className={styles.arrow} />
              <span className={`${styles.value} ${styles.max}`}>
                {props.toCaption.at(0) ?? ""}
              </span>
              {props.toCaption.at(1) && <small>{props.toCaption.at(1)}</small>}
            </Box>
          </Box>
          <Box
            ref={props.arrowRef}
            className={styles.caption_box2}
            sx={{
              left: props.arrowLeft,
              right: props.arrowRight
            }}
          >
            <Box className={styles.fkds_slider_horn}>
              <img src={SliderFkdsHorn} alt="▼" />
            </Box>
          </Box>
        </Box>
      </>
    );
  }
);
