export enum StatusEnum {
  "公開" = 1,
  "商談中" = 2,
  "成約済み" = 3,
  "非公開" = 4,
  "下書き" = 5,
  "マンションM" = 6
}

export enum StatusNameEnum {
  "公開" = "公開",
  "商談中" = "商談中",
  "成約済み" = "成約済み",
  "非公開" = "非公開",
  "下書き" = "下書き",
  "マンションM" = "マンションM",

  "位置不明" = "位置不明"
}
