import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Company, Estate } from "API";
import logoYoko4c from "assets/icons/logo_yoko_4c.svg";
import React, { useCallback, useMemo } from "react";
import styles from "./DetailPage.subComponents.module.scss";

export const ContentCompany = React.memo(
  (props: {
    company: Company | null | undefined;
    estate: Estate | null | undefined;
    companyIndex: string | null | undefined;
    forAnonymous: boolean | null | undefined;
  }) => {
    interface CompanyContent {
      id?: string | null;
      name?: string | null;
      address?: string | null;
      tel?: string | null;
      fax?: string | null;
    }

    const [companyIndex, setCompanyIndex] = React.useState(
      Number(props.companyIndex || 0)
    );
    const [showCompany, setShowCompany] = React.useState(true);

    const companies: CompanyContent[] = useMemo(() => {
      const companies: CompanyContent[] = [];

      if (props.company) {
        companies.push({
          id: props.company?.id,
          name: `${props.company?.name || ""} ${
            props.company?.branchName || ""
          }`,
          address: props.company?.branchAddress || props.company?.address,
          tel: props.company?.branchTel || props.company?.tel,
          fax: props.company?.branchFax || props.company?.fax
        });
      }

      if (props.estate?.companyId) {
        companies.push({
          id: props.estate?.companyId,
          name: props.estate?.companyName,
          address: props.estate?.companyAddress,
          tel: props.estate?.companyTel,
          fax: props.estate?.companyFax
        });
      }

      if (props.estate?.subCompanyId) {
        companies.push({
          id: props.estate?.subCompanyId,
          name: props.estate?.subCompanyName,
          address: props.estate?.subCompanyAddress,
          tel: props.estate?.subCompanyTel,
          fax: props.estate?.subCompanyFax
        });
      }

      if (props.estate?.subCompany2Id) {
        companies.push({
          id: props.estate?.subCompany2Id,
          name: props.estate?.subCompany2Name,
          address: props.estate?.subCompany2Address,
          tel: props.estate?.subCompany2Tel,
          fax: props.estate?.subCompany2Fax
        });
      }

      companies.push({
        id: null,
        name: null,
        address: null,
        tel: null,
        fax: null
      });

      const ids = new Set(companies.map((c) => c.id));

      return Array.from(ids).map((id) => companies.find((c) => c.id === id)!);
    }, [
      props.company,
      props.estate?.companyAddress,
      props.estate?.companyFax,
      props.estate?.companyId,
      props.estate?.companyName,
      props.estate?.companyTel,
      props.estate?.subCompany2Address,
      props.estate?.subCompany2Fax,
      props.estate?.subCompany2Id,
      props.estate?.subCompany2Name,
      props.estate?.subCompany2Tel,
      props.estate?.subCompanyAddress,
      props.estate?.subCompanyFax,
      props.estate?.subCompanyId,
      props.estate?.subCompanyName,
      props.estate?.subCompanyTel
    ]);

    const company = useMemo(
      () => companies.at(companyIndex) ?? {},
      [companies, companyIndex]
    );

    const onUpClick = useCallback(() => {
      setShowCompany(false);

      setTimeout(() => {
        setCompanyIndex((prev) => (prev > 0 ? prev - 1 : prev));
        setShowCompany(true);
      }, 200);
    }, []);

    const onDownClick = useCallback(() => {
      setShowCompany(false);

      setTimeout(() => {
        setCompanyIndex((prev) =>
          prev < companies.length - 1 ? prev + 1 : prev
        );
        setShowCompany(true);
      }, 200);
    }, [companies.length]);

    return (
      <Box className={styles.companyContent}>
        <Box
          className={`${styles.name} ${
            showCompany ? styles.visible : styles.hidden
          }`}
        >
          <Typography variant="h6">{company.name}</Typography>
        </Box>
        <Box
          className={`${styles.address} ${
            showCompany ? styles.visible : styles.hidden
          }`}
        >
          <Typography variant="inherit">{company.address}</Typography>
          <Typography variant="inherit">
            {company.tel ? <span>TEL: {company.tel}</span> : null}
            {company.fax ? (
              <span className={styles.fax}>FAX: {company.fax}</span>
            ) : null}
          </Typography>
        </Box>
        <Box className={styles.logo}>
          {!props.forAnonymous && (
            <Box className={styles.switch}>
              <Box
                className={`${styles.up} ${
                  companyIndex > 0 ? "" : styles.disabled
                }`}
                onClick={onUpClick}
              >
                <KeyboardArrowUp />
              </Box>
              <Box
                className={`${styles.down} ${
                  companyIndex < companies.length - 1 ? "" : styles.disabled
                }`}
                onClick={onDownClick}
              >
                <KeyboardArrowDown />
              </Box>
            </Box>
          )}
          <img src={logoYoko4c} alt="sumora" />
        </Box>
      </Box>
    );
  }
);
