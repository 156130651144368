import { incrementEstateAtomicCounter } from "common/functions/api/atomicCounters";
import { MinimalEstate } from "common/queries/minimalEstates";
import { handleError } from "../utilities";

export class EstateAtomicCounterGetter {
  private setFatal?: (error?: string | null | undefined) => void = undefined;

  constructor(setFatal: (error?: string | null | undefined) => void) {
    this.setFatal = setFatal;
  }

  getIndividualEstateAtomicCounter = async (
    estateId: string | null | undefined
  ) => {
    const counter = estateId
      ? await incrementEstateAtomicCounter(estateId).catch((error) => {
          handleError(error, this.setFatal);
          return undefined;
        })
      : null;

    return counter?.data?.incrementEstateAtomicCounterC?.sequence || null;
  };

  getEstateAtomicCounter = async (
    estate: MinimalEstate
  ): Promise<MinimalEstate> => {
    const blocks = [...(estate.blocks || [])];

    if (blocks.length === 0 && estate.id) {
      const counter = await incrementEstateAtomicCounter(estate.id).catch(
        (error) => {
          handleError(error, this.setFatal);
          return undefined;
        }
      );

      const clickedEstate: MinimalEstate = {
        ...estate,
        clickedCount:
          counter?.data?.incrementEstateAtomicCounterC?.sequence || 0
      };

      return clickedEstate;
    } else {
      const clickedEstate: MinimalEstate = {
        ...estate,
        blocks: [
          ...(await Promise.all(
            blocks.map(
              async (x) =>
                (await this.getEstateAtomicCounter(x)) as MinimalEstate
            )
          ).catch((error) => {
            handleError(error, this.setFatal);
            return blocks;
          }))
        ]
      };

      return clickedEstate;
    }
  };
}
