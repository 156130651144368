import { Box } from "@mui/material";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { Loading } from "components/Parts/Atoms/Loading";
import { HeaderBar } from "components/Parts/Molecules/HeaderBar";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import React, { Suspense, useMemo } from "react";
import styles from "./ArchivePage.module.scss";
import { EstateList } from "./components";
import { useArchiveHooks } from "./hooks";
import { ArchiveProps } from "./interfaces";

const menuButtonId = "menu-button";

export const ArchivePage = React.memo((props: ArchiveProps) => {
  const { mainContext, estates, condominiumName, isLoading } =
    useArchiveHooks(props);

  const content = useMemo(() => {
    if (mainContext.user && mainContext.company && mainContext.isAgreed) {
      if (isLoading) {
        return <Box className={styles.noResult}>読み込み中です...</Box>;
      }

      if (estates.length === 0) {
        return (
          <Box className={styles.noResult}>
            過去事例が見つかりませんでした。
          </Box>
        );
      }

      const typedEstates = estates.map((x) => ({
        ...x,
        estateType:
          x.estateType === EstateTypeNameEnum.分譲地
            ? EstateTypeNameEnum.土地
            : x.estateType,
        isLot: x.estateType === EstateTypeNameEnum.分譲地 ? 1 : undefined
      }));

      const estateTypeOrder = (estateType: string | null | undefined) =>
        estateType === EstateTypeNameEnum.土地
          ? 0
          : estateType === EstateTypeNameEnum.中古戸建
          ? 1
          : estateType === EstateTypeNameEnum.新築建売
          ? 2
          : estateType === EstateTypeNameEnum.マンション
          ? 3
          : estateType === EstateTypeNameEnum.事業用
          ? 4
          : 9;

      const estateTypes = [...new Set(typedEstates.map((x) => x.estateType))]
        .filter((x) => x)
        .sort((a, b) => estateTypeOrder(a) - estateTypeOrder(b));

      const estateTypeList = estateTypes.map((estateType) => (
        <div key={`estate-type-${estateType}`}>
          <h2>{condominiumName ?? estateType}</h2>
          <EstateList
            estates={typedEstates}
            estateType={estateType}
            condominiumName={condominiumName}
          />
        </div>
      ));

      return <Box className={styles.estateTypeList}>{estateTypeList}</Box>;
    }
  }, [
    condominiumName,
    estates,
    isLoading,
    mainContext.company,
    mainContext.isAgreed,
    mainContext.user
  ]);

  return (
    <ContentMain>
      <HeaderBar
        authStatus={mainContext.authStatus}
        authUser={mainContext.authUser}
        user={mainContext.user}
        company={mainContext.company}
        userInfo={mainContext.userInfo}
        groups={mainContext.groups}
        menuButtonId={menuButtonId}
      />
      <Suspense fallback={Loading}>{content}</Suspense>
    </ContentMain>
  );
});
