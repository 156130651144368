import React, { useCallback, useEffect, useState } from "react";
import { CopyLinkButtonProps } from "./interfaces";

export const useCopyLinkButtonHooks = (props: CopyLinkButtonProps) => {
  const [open, setOpen] = useState(false);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (props.url) {
        navigator.clipboard.writeText(props.url);
        setOpen(true);
      }
    },
    [props.url]
  );

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open]);

  return { open, onClick, handleTooltipClose } as const;
};
