import { Auth } from "components/Parts/Organisms/Auth";
import { LicensePage } from "components/Parts/Organisms/LicensePage";
import { LicenseProps } from "components/Parts/Organisms/LicensePage/interfaces";
import React from "react";

export const License = React.memo((props: LicenseProps) => (
  <Auth>
    <LicensePage {...props} />
  </Auth>
));
