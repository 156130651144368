import { Grid, Hidden } from "@mui/material";
import { TokyoCoords } from "common/consts/googleMaps";
import { GroupEnum } from "common/enums/GroupEnum";
import { MainContextContainer } from "components/Main";
import { MainWrapper } from "components/Parts/Atoms/MainWrapper";
import { BackgroundMap } from "components/Parts/Molecules/BackgroundMap";
import { HeaderBar } from "components/Parts/Molecules/HeaderBar";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HomePage.module.scss";
import { HomeProps } from "./interfaces";
import {
  MenuButtonAbout,
  MenuButtonCheckEstatesForAdmin,
  MenuButtonCheckEstates,
  MenuButtonCondominium,
  MenuButtonDraft,
  MenuButtonLicense,
  MenuButtonReload,
  MenuButtonSearch,
  MenuButtonSearchOwn,
  MenuButtonSignOut
} from "./menuButtons";

export const HomePage = React.memo((props: HomeProps) => {
  const mainContext = useContext(MainContextContainer);
  const navigate = useNavigate();

  const { items } = useMemo(() => {
    const items: JSX.Element[] = [];

    if (
      mainContext.groups &&
      mainContext.groups?.includes(GroupEnum.Administrator)
    ) {
      // 管理者メニュー
      items.push(
        <MenuButtonSearch mainContext={mainContext} key="search" />,
        <MenuButtonCheckEstates
          mainContext={mainContext}
          navigate={navigate}
          key="check_estates_own"
        />,
        <MenuButtonCheckEstatesForAdmin
          mainContext={mainContext}
          key="check_estates"
        />,

        <Hidden lgDown key="spacer1">
          <Grid lg={3} item></Grid>
        </Hidden>,

        <MenuButtonAbout
          mainContext={mainContext}
          navigate={navigate}
          key="about"
        />,
        <MenuButtonLicense
          mainContext={mainContext}
          navigate={navigate}
          key="license"
        />,
        <MenuButtonReload mainContext={mainContext} key="reload" />,
        <MenuButtonSignOut mainContext={mainContext} key="signout" />,

        <MenuButtonCondominium
          mainContext={mainContext}
          navigate={navigate}
          key="condominium"
        />,
        <MenuButtonDraft
          mainContext={mainContext}
          navigate={navigate}
          key="drafts"
        />
      );
    }

    if (
      mainContext.groups &&
      (mainContext.groups?.includes(GroupEnum.Staff) ||
        mainContext.groups?.includes(GroupEnum.HouseMaker) ||
        mainContext.groups?.includes(GroupEnum.RealEstateAgency) ||
        mainContext.groups?.includes(GroupEnum.RealEstateAgencyFree))
    ) {
      // 通常メニュー
      items.push(
        <MenuButtonSearch mainContext={mainContext} key="search" />,
        <MenuButtonCheckEstates
          mainContext={mainContext}
          navigate={navigate}
          key="check_estates_own"
        />,
        <MenuButtonSearchOwn mainContext={mainContext} key="search_own" />,

        <Hidden lgDown key="spacer1">
          <Grid lg={3} item></Grid>
        </Hidden>,

        <MenuButtonAbout
          mainContext={mainContext}
          navigate={navigate}
          key="about"
        />,
        <MenuButtonLicense
          mainContext={mainContext}
          navigate={navigate}
          key="license"
        />,
        <MenuButtonReload mainContext={mainContext} key="reload" />,
        <MenuButtonSignOut mainContext={mainContext} key="signout" />,

        <Hidden lgDown key="spacer2">
          <Grid lg={6} item></Grid>
        </Hidden>
      );
    }

    return { items };
  }, [mainContext, navigate]);

  return (
    <ContentMain>
      <HeaderBar
        authStatus={mainContext.authStatus}
        authUser={mainContext.authUser}
        user={mainContext.user}
        company={mainContext.company}
        userInfo={mainContext.userInfo}
        groups={mainContext.groups}
      />
      <MainWrapper>
        {mainContext.company?.latitude && mainContext.company?.longitude && (
          <BackgroundMap
            center={TokyoCoords}
            mapDivId="background-map"
            location={{
              lat: mainContext.company?.latitude,
              lng: mainContext.company?.longitude
            }}
            zoom={5}
            onMapReady={() => {}}
            className={styles.backgroundMap}
          />
        )}
        <Grid container spacing={2}>
          {items}
        </Grid>
      </MainWrapper>
    </ContentMain>
  );
});
