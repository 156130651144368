import { Fab } from "@mui/material";
import resetPositionAll from "assets/icons/estate_info/btn_bknk_restoreposition_all.svg";
import React from "react";
import styles from "./ResetPositionOfEstateInfoButton.module.scss";

export const ResetPositionOfEstateInfoButton = React.memo(
  (props: {
    selectingEstates: boolean | undefined;
    onResetPositionOfEstateInfoClick: () => void;
  }) => (
    <Fab
      onClick={props.onResetPositionOfEstateInfoClick}
      className={styles.resetPositionOfEstateInfoButton}
    >
      <img src={resetPositionAll} alt="全ての物件情報の位置をリセットする" />
    </Fab>
  )
);
