import { getCurrentPosition } from "common/functions/utilities";
import { MouseEventHandler, useCallback } from "react";
import { SearchMapStatesType } from "../states/maps";

export const useSearchMoveHandlers = (
  mapStates: SearchMapStatesType,
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const onMoveToCurrentLocationButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        const map = mapStates.map;

        if (map) {
          const center = map.getCenter();

          const lat = center?.lat();
          const lng = center?.lng();

          if (lat !== undefined && lng !== undefined) {
            mapStates.setBusyForMove(true);

            getCurrentPosition({ lat: lat, lng: lng })
              .then((currentPos) => {
                if (currentPos.success) {
                  const pos = {
                    lat: currentPos.position.lat,
                    lng: currentPos.position.lng
                  };

                  mapStates.setCurrentPosition(pos);
                }
              })
              .finally(() => {
                mapStates.setBusyForMove(false);
              });
          }
        }
      },
      [mapStates]
    );

  const onPlacesChange: (places: google.maps.places.PlaceResult) => void =
    useCallback(
      (places) => {
        mapStates.setPlaces(places);
        setMenuOpen(false);
      },
      [setMenuOpen, mapStates]
    );

  const onSearchLocationButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setShowSearchId(false);
        mapStates.setShowSearchLocation((x) => !x);
      },
      [mapStates]
    );

  const onSearchIdButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setShowSearchLocation(false);
        mapStates.setShowSearchId((x) => !x);
      },
      [mapStates]
    );

  return {
    onMoveToCurrentLocationButtonClick,
    onPlacesChange,
    onSearchLocationButtonClick,
    onSearchIdButtonClick
  } as const;
};
