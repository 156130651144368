import { getUrl } from "aws-amplify/storage";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { CitiesOnTile } from "common/interfaces/CitiesOfTile";
import { City } from "common/interfaces/City";
import { boundsToTiles } from "../mapUtilities";

export const getCitiesOfTiles = async (
  bounds: google.maps.LatLngBounds,
  zoom: number
): Promise<City[]> => {
  const citiesOfTiles: City[] = [];

  const useTileOnZoom12 = zoom < 14;
  const useTileOnZoom14 = zoom >= 14 && zoom < 16;
  const tileZoom = useTileOnZoom12 ? 12 : useTileOnZoom14 ? 14 : 16;

  const tiles = boundsToTiles(bounds, tileZoom);

  for (const tile of tiles) {
    const [x, y] = tile.split(",");

    const cityJsonUrl = await getUrl({
      key: `public/json/cities/tiles/${tileZoom}/${x}/${y}.json`,
      options: {
        accessLevel: "guest"
      }
    })
      .then((value) => value.url.href)
      .catch((err) => undefined);

    if (cityJsonUrl) {
      const axios = setupCache(Axios.create());
      const cityJsonResponse = await axios
        .get<CitiesOnTile>(cityJsonUrl, {
          responseType: "json"
        })
        .catch((err) => undefined);

      if (cityJsonResponse?.data) {
        const cities: CitiesOnTile = cityJsonResponse.data;

        cities.includes.forEach((x) => {
          if (!citiesOfTiles.find((city) => city.city_code === x.city_code)) {
            citiesOfTiles.push(x);
          }
        });
      }
    }
  }

  return citiesOfTiles;
};
