import { stickyNoteSearchableZoom } from "common/consts/stickyNotes";
import { searchStickyNote as invokeSearchStickyNote } from "common/functions/api/stickyNotes";
import { boundsToTiles } from "common/functions/mapUtilities";
import { MainContext } from "common/interfaces/MainContext";
import { StickyNoteSearchConditions } from "common/interfaces/StickyNoteSearchConditions";
import { mapKeys } from "lodash";
import { useCallback } from "react";
import { SearchBehaviorStatesType } from "../states/behaviors";
import { SearchMapStatesType } from "../states/maps";
import { SearchStickyNoteSearchConditionStatesType } from "../states/stickyNoteSearchConditions";
import { SearchStickyNoteStatesType } from "../states/stickyNotes";

export const useSearchStickyNoteMethods = (
  mainContext: MainContext,
  behaviorStates: SearchBehaviorStatesType,
  mapStates: SearchMapStatesType,
  stickyNoteStates: SearchStickyNoteStatesType,
  stickyNoteSearchConditionStates: SearchStickyNoteSearchConditionStatesType
) => {
  const searchStickyNote = useCallback(async () => {
    const bounds = mapStates.map?.getBounds();
    const zoom = mapStates.zoom;

    if (
      !behaviorStates.stickyNoteMode ||
      zoom < stickyNoteSearchableZoom ||
      mainContext.company === undefined
    ) {
      // メモ表示オフ、範囲検索不可能なズーム値では処理しない
      stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom12();
      stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom14();
      stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom16();

      stickyNoteStates.setStickyNotes([]);
      stickyNoteStates.setStickyNotesCount(0);
      return;
    }

    const conditions: StickyNoteSearchConditions = {
      ...stickyNoteSearchConditionStates,
      companies: [mainContext.company],
      tilesOnZoom12: [],
      tilesOnZoom14: [],
      tilesOnZoom16: []
    };

    if (bounds) {
      // 現在のタイルを退避
      const currentTilesOnZoom12 = [
        ...stickyNoteSearchConditionStates.stickyNoteSearchConditions
          .tilesOnZoom12
      ];
      const currentTilesOnZoom14 = [
        ...stickyNoteSearchConditionStates.stickyNoteSearchConditions
          .tilesOnZoom14
      ];
      const currentTilesOnZoom16 = [
        ...stickyNoteSearchConditionStates.stickyNoteSearchConditions
          .tilesOnZoom16
      ];

      const useTileOnZoom12 = zoom < 14;
      const useTileOnZoom14 = zoom >= 14 && zoom < 16;

      const tiles = boundsToTiles(
        bounds,
        useTileOnZoom12 ? 12 : useTileOnZoom14 ? 14 : 16
      );

      if (useTileOnZoom12) {
        conditions.tilesOnZoom12 = tiles.filter(
          (x) => !currentTilesOnZoom12.includes(x)
        );
        stickyNoteSearchConditionStates.setStickyNoteSearchConditions.tilesOnZoom12(
          tiles
        );
        stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom14();
        stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom16();
      } else if (useTileOnZoom14) {
        conditions.tilesOnZoom14 = tiles.filter(
          (x) => !currentTilesOnZoom14.includes(x)
        );
        stickyNoteSearchConditionStates.setStickyNoteSearchConditions.tilesOnZoom14(
          tiles
        );
        stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom12();
        stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom16();
      } else {
        conditions.tilesOnZoom16 = tiles.filter(
          (x) => !currentTilesOnZoom16.includes(x)
        );
        stickyNoteSearchConditionStates.setStickyNoteSearchConditions.tilesOnZoom16(
          tiles
        );
        stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom12();
        stickyNoteSearchConditionStates.clearStickyNoteSearchConditions.tilesOnZoom14();
      }

      // 検索済みメモ退避
      const currentStickyNotes = [
        ...(stickyNoteStates.stickyNotes || [])
      ].filter((x) =>
        tiles.includes(
          (useTileOnZoom12
            ? x.tileOnZoom12
            : useTileOnZoom14
            ? x.tileOnZoom14
            : x.tileOnZoom16) || ""
        )
      );

      const stickyNotes = await invokeSearchStickyNote(conditions).catch(
        (error) => {
          mainContext.setFatal?.(error);
          return undefined;
        }
      );

      if (stickyNotes) {
        // 未検索のメモステータス・タイルによる検索結果を既存のメモリストに追加
        const newStickyNotes = [...stickyNotes, ...currentStickyNotes];
        const newUniqueStickyNotes =
          Object.values(mapKeys(newStickyNotes, (x) => x.id)) ?? [];

        stickyNoteStates.setStickyNotes(newUniqueStickyNotes);
        stickyNoteStates.setStickyNotesCount(newUniqueStickyNotes.length);
        stickyNoteStates.setStickyNotesChangeDispatcher((x) => !x);
      }
    }
  }, [
    behaviorStates.stickyNoteMode,
    mainContext,
    mapStates.map,
    mapStates.zoom,
    stickyNoteSearchConditionStates,
    stickyNoteStates
  ]);

  return {
    searchStickyNote
  } as const;
};
