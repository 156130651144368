import { LibraryOptions } from "@aws-amplify/core";
import { Authenticator } from "@aws-amplify/ui-react";
import { App } from "App";
import { Amplify } from "aws-amplify";
import awsExports from "aws-exports";
import { createRoot } from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistration().then((value) => {
    value?.unregister();
  });
}

const libraryOptions: LibraryOptions = {
  Storage: {
    S3: {
      prefixResolver: async ({ accessLevel, targetIdentityId }) => {
        if (accessLevel === "guest") {
          return "";
        } else if (accessLevel === "protected") {
          return `protected/${targetIdentityId}/`;
        } else {
          return `private/${targetIdentityId}/`;
        }
      }
    }
  }
};

if (process.env.REACT_APP_MOCK === "true") {
  const config = {
    ...awsExports,
    aws_appsync_graphqlEndpoint: "http://localhost:20002/graphql",
    aws_user_files_s3_dangerously_connect_to_http_endpoint_for_testing: true,
    aws_appsync_dangerously_connect_to_http_endpoint_for_testing: true
  };

  Amplify.configure(config, libraryOptions);
} else {
  Amplify.configure(awsExports, libraryOptions);
}

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
