import { HazardMapType } from "common/functions/classes/HazardMapType";
import { DrawingId } from "common/types/DrawingId";
import { useCallback, useContext, useEffect, useState } from "react";
import { SearchMapContext } from "../../context";

const id: DrawingId = "doshasaigai";

/*

現象種別コード 〈ファイル名称：CodeOfPhenomenon〉

コード	内容
1	急傾斜地の崩壊
2	土石流
3	地滑り

H27区域コード 〈ファイル名称：CodeOfZone〉

コード	内容
1	土砂災害警戒区域(指定済)
2	土砂災害特別警戒区域(指定済)
3	土砂災害警戒区域(指定前)
4	土砂災害特別警戒区域(指定前)

*/

export const useHooks = (on: boolean) => {
  const { map } = useContext(SearchMapContext);
  const [doshasaigaiMapType, setDoshasaigaiMapType] = useState<
    HazardMapType | undefined
  >(undefined);

  // マップタイプ構築
  const constructDoshasaigaiMapType = useCallback(() => {
    if (map) {
      const mapType = new HazardMapType(
        `${id}MapType`,
        map,
        [
          `https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png`,
          `https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png`,
          `https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png`
        ],
        17
      );

      setDoshasaigaiMapType(mapType);
    }
  }, [map]);

  // 描画
  const drawDoshasaigai = useCallback(async () => {
    if (map && doshasaigaiMapType) {
      for (let i = 0; i < map.overlayMapTypes.getLength(); i++) {
        const mapType = map.overlayMapTypes.getAt(
          i
        ) as unknown as HazardMapType;

        if (mapType.id === `${id}MapType`) {
          map.overlayMapTypes.removeAt(i);
          break;
        }
      }

      if (on) {
        map.overlayMapTypes.insertAt(
          0,
          doshasaigaiMapType as unknown as google.maps.MapType
        );
      }
    }
  }, [doshasaigaiMapType, map, on]);

  useEffect(() => {
    constructDoshasaigaiMapType();
  }, [constructDoshasaigaiMapType]);

  useEffect(() => {
    drawDoshasaigai();
  }, [drawDoshasaigai]);

  return { map } as const;
};
