import React from "react";
import { useHooks } from "./hooks";

export const Tile16Plugin = React.memo(({ on }: { on: boolean }) => {
  const { map } = useHooks(on);

  if (map) {
    return <></>;
  }

  return null;
});
