export const TileSize = 256;

export const MapTypeControlStyle = {
  DEFAULT: 0.0 as google.maps.MapTypeControlStyle,
  DROPDOWN_MENU: 1.0 as google.maps.MapTypeControlStyle,
  HORIZONTAL_BAR: 2.0 as google.maps.MapTypeControlStyle
};

export const ControlPosition = {
  BOTTOM_CENTER: 0.0 as google.maps.ControlPosition,
  BOTTOM_LEFT: 1.0 as google.maps.ControlPosition,
  BOTTOM_RIGHT: 2.0 as google.maps.ControlPosition,
  LEFT_BOTTOM: 3.0 as google.maps.ControlPosition,
  LEFT_CENTER: 4.0 as google.maps.ControlPosition,
  LEFT_TOP: 5.0 as google.maps.ControlPosition,
  RIGHT_BOTTOM: 6.0 as google.maps.ControlPosition,
  RIGHT_CENTER: 7.0 as google.maps.ControlPosition,
  RIGHT_TOP: 8.0 as google.maps.ControlPosition,
  TOP_CENTER: 9.0 as google.maps.ControlPosition,
  TOP_LEFT: 10.0 as google.maps.ControlPosition,
  TOP_RIGHT: 11.0 as google.maps.ControlPosition
};

export const TokyoCoords = {
  lat: 35.681236,
  lng: 139.767125
};
