import { MainContextContainer } from "components/Main";
import { useCallback, useContext, useEffect, useState } from "react";

export const useContentMainHooks = () => {
  const mainContext = useContext(MainContextContainer);
  const [appearReloadMessage, setAppearReloadMessage] = useState(false);

  const noticeReload = useCallback(() => {
    setAppearReloadMessage(true);
  }, []);

  useEffect(() => {
    setTimeout(noticeReload, 5000);
  }, [noticeReload]);

  return { mainContext, appearReloadMessage } as const;
};
