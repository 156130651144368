import { marks, selectionItems } from "common/functions/utilities";
import { add, differenceInMonths, format, subMonths } from "date-fns";
import { range } from "lodash";

// 新着の判定日数
export const daysOfNew = 7;

// 範囲検索可能ZOOM値
export const boundSearchableZoom = 12;

// メッシュ表示ZOOM値
export const tileBaseZoom = 16;

// メッシュ表示可能ZOOM値
export const meshDisplayableZoom = 15;

// 土地(坪)
export const tsuboAreaMin = 30;
export const tsuboAreaSteps = [40, 50, 60, 70, 80, 90, 100, 150, 200];
export const tsuboAreaMax = 300;
const tsuboAreaStep = 10;
export const tsuboAreaMarks = [
  {
    value: tsuboAreaMin,
    label: String(tsuboAreaMin),
    displayAlways: false
  },
  ...tsuboAreaSteps.map((step, index) => ({
    value: step,
    label: String(step),
    displayAlways: index % 2 === 1
  })),
  {
    value: tsuboAreaMax,
    label: String(tsuboAreaMax),
    displayAlways: false
  }
];
export const tsuboAreaValueFromItems = [
  {
    value: tsuboAreaMin,
    caption: "下限なし"
  },
  ...tsuboAreaSteps.map((step) => ({
    value: step,
    caption: `${step}坪以上`
  })),
  {
    value: tsuboAreaMax,
    caption: `${tsuboAreaMax}坪以上`
  }
];
export const tsuboAreaValueToItems = [
  {
    value: tsuboAreaMin + tsuboAreaStep,
    caption: `${tsuboAreaMin}坪未満`
  },
  ...tsuboAreaSteps.map((step) => ({
    value: step + tsuboAreaStep,
    caption: `${step}坪未満`
  })),
  {
    value: tsuboAreaMax,
    caption: "上限なし"
  }
];

// 物件価格(万円)
export const estatePriceMin = 0;
export const estatePriceMax = 5000;
const estatePriceStep = 500;
export const estatePriceMarks = marks(
  estatePriceMin,
  estatePriceMax,
  estatePriceStep
);
export const estatePriceValueFromItems = selectionItems(
  estatePriceMin,
  estatePriceMax,
  estatePriceStep,
  "下限なし",
  " 万円以上",
  " 万円以上",
  {
    forceMinCaption: true,
    forceMaxCaption: false
  }
);
export const estatePriceValueToItems = selectionItems(
  estatePriceMin + estatePriceStep,
  estatePriceMax,
  estatePriceStep,
  " 万円未満",
  " 万円未満",
  "上限なし",
  {
    forceMinCaption: false,
    forceMaxCaption: true
  }
);

export const dateMin = add(new Date(), { years: -50 }).getTime();
export const dateMax = new Date().getTime();

// 建築年
export const completionYMMin = new Date(`1980-${format(new Date(), "MM")}-01`);
export const completionYMMax = new Date(`${format(new Date(), "yyyy-MM")}-01`);
const completionYMBorder1 = new Date("1981-06-01");
const completionYMBorder2 = new Date("2000-06-01");

const completionYMBorder1Gap = differenceInMonths(
  completionYMMax,
  completionYMBorder1
);
const completionYMBorder2Gap = differenceInMonths(
  completionYMMax,
  completionYMBorder2
);

const completionYMMinGap = differenceInMonths(completionYMMax, completionYMMin);

const completionYMGaps = [
  ...new Set([
    0,
    3 * 12,
    5 * 12,
    ...range(10 * 12, completionYMBorder1Gap, 10 * 12),
    completionYMMinGap,
    completionYMBorder1Gap
    // completionYMBorder2Gap
  ])
].sort((a, b) => a - b);

export const completionYMs = completionYMGaps.map((gap) =>
  Number(format(subMonths(completionYMMax, gap), "yyyyMM"))
);

export const completionYMMarks: {
  value: number;
  label: string;
  displayAlways: boolean;
}[] = completionYMGaps.map((gap, index) => ({
  value: index,
  label:
    index === 0
      ? "築年指定なし"
      : index === completionYMGaps.length - 1
      ? "新築"
      : gap === completionYMBorder1Gap
      ? "1981年06月"
      : gap === completionYMBorder2Gap
      ? "2000年06月"
      : `築${gap / 12}年`,
  displayAlways:
    index > 1 && index < completionYMGaps.length - 2 && index % 2 === 0
}));

// 部屋数
export const roomsMin = 1;
export const roomsSteps = [2, 3, 4, 5, 6, 7, 8];
export const roomsMax = 9;
const roomsStep = 10;
export const roomsMarks = [
  {
    value: roomsMin,
    label: String(roomsMin),
    displayAlways: false
  },
  ...roomsSteps.map((step, index) => ({
    value: step,
    label: String(step),
    displayAlways: index % 2 === 1
  })),
  {
    value: roomsMax,
    label: String(roomsMax),
    displayAlways: false
  }
];
export const roomsValueFromItems = [
  {
    value: roomsMin,
    caption: "下限なし"
  },
  ...roomsSteps.map((step) => ({
    value: step,
    caption: `${step}部屋以上`
  })),
  {
    value: roomsMax,
    caption: `${roomsMax}部屋以上`
  }
];
export const roomsValueToItems = [
  {
    value: roomsMin + roomsStep,
    caption: `${roomsMin}部屋未満`
  },
  ...roomsSteps.map((step) => ({
    value: step + roomsStep,
    caption: `${step}部屋未満`
  })),
  {
    value: roomsMax,
    caption: "上限なし"
  }
];
