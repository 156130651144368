import { Box, Divider, TextField } from "@mui/material";
import React, { useCallback } from "react";
import { CheckEstatesCondition } from "../../../../../../common/interfaces/CheckEstatesCondition";
import styles from "./ConditionSelector.module.scss";

export const ConditionSelector = React.memo(
  (
    props: CheckEstatesCondition & {
      onModeClick: () => void;
      onStatusesClick: () => void;
      onEstateTypesClick: () => void;
      onCitiesClick: () => void;
      onAreaChange: (area: string | null | undefined) => void;
      onSearchClick: () => void;
    }
  ) => {
    const onAreaChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onAreaChange(e.target.value);
      },
      [props]
    );

    return (
      <Box id="conditionSelector" className={styles.condition}>
        <Box className={styles.paragraph}>
          <Box className={styles.button} onClick={props.onModeClick}>
            {props.mode}
          </Box>{" "}
          の{" "}
          <Box className={styles.button} onClick={props.onStatusesClick}>
            {props.statuses.join(" / ")}
          </Box>{" "}
          となっている{" "}
          <Box className={styles.button} onClick={props.onEstateTypesClick}>
            {props.estateTypes.join(" / ")}
          </Box>
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.paragraph}>
          <Box className={styles.button} onClick={props.onCitiesClick}>
            {props.cities.length > 3
              ? `${props.cities
                  .map((x) => x.city_name)
                  .slice(0, 3)
                  .join(" / ")} 他`
              : props.cities.map((x) => x.city_name).join(" / ")}
          </Box>{" "}
          の{" "}
          <Box className={styles.area}>
            <TextField
              value={props.area ?? ""}
              onChange={onAreaChange}
              placeholder="町名"
              className={styles.areaInput}
            />
          </Box>{" "}
          が対象
          <Box className={styles.spacer} />
          <Box
            className={`${styles.button} ${styles.searchButton}`}
            onClick={props.onSearchClick}
          >
            検索
          </Box>
        </Box>
      </Box>
    );
  }
);
