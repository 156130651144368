import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { useCallback, useEffect, useState } from "react";
import { ModeSelectDialogProps } from "./interfaces";

export const useModeSelectDialogHooks = (props: ModeSelectDialogProps) => {
  const [mode, setMode] = useState<ModeSelectDialogProps["mode"]>(props.mode);
  const [busy, setBusy] = useState(false);

  const onModeClick = useCallback((mode: ModeSelectDialogProps["mode"]) => {
    setMode((prev) =>
      prev === SearchModeEnum.all
        ? SearchModeEnum.domain
        : prev === SearchModeEnum.domain
        ? SearchModeEnum.all
        : prev
    );
  }, []);

  const onOkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // OKボタンクリック時
      setBusy(true);
    },
    []
  );

  useEffect(() => {
    setMode(props.mode);
  }, [props.mode]);

  useEffect(() => {
    if (busy) {
      props.onOkClick(mode);
      setBusy(false);
    }
  }, [busy, mode, props]);

  return { mode, busy, onModeClick, onOkClick } as const;
};
