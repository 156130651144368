import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import styles from "./ProgressBackdrop.module.scss";
import { ProgressBackdropProps } from "./interfaces";

export const ProgressBackdrop = React.memo((props: ProgressBackdropProps) => {
  return (
    <>
      <Backdrop
        style={props.style}
        className={`${props.className} ${styles.progressBackdrop}`}
        open={props.open}
      >
        <Box className={styles.container}>
          {(props.showSpinner ?? true) && (
            <Box className={styles.spinner}>
              <CircularProgress />
            </Box>
          )}
          <Box className={styles.message}>{props.message}</Box>
          {props.buttonCaption ? (
            <Box className={styles.buttonWrapper}>
              <Button
                className={styles.button}
                variant="contained"
                onClick={props.onButtonClick}
              >
                {props.buttonCaption}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Backdrop>
    </>
  );
});
