import { useAuthenticator } from "@aws-amplify/ui-react";
import { CognitoUser } from "API";
import { getUrl } from "aws-amplify/storage";
import axios from "axios";
import { useUserHooks } from "hooks/userHooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AgreementDialogProps } from "./interfaces";

export const useAgreementDialogHooks = (props: AgreementDialogProps) => {
  const nodeRef = useRef(null);

  const { signOut } = useAuthenticator((context) => [context.signOut]);

  const [doc, setDoc] = useState("");
  const [agree, setAgree] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const { busy: busyOfUser, agree: agreeOfUser } = useUserHooks(
    props.mainContext.userInfo?.username,
    props.mainContext.setFatal
  );

  const onAgreeChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void = useCallback((event, value) => {
    setAgree(value);
  }, []);

  const onOkButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      let error = false;

      if (!error) {
        agreeOfUser().then((value) => {
          if (value?.item) {
            props.onOkButtonClick(value.item as CognitoUser);
          }
        });
      }
    },
    [agreeOfUser, props]
  );

  const onLogoutButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      signOut();
    },
    [signOut]
  );

  const loadDoc = useCallback(
    () =>
      getUrl({
        key: "public/docs/agreement.md",
        options: {
          accessLevel: "guest"
        }
      })
        .then((url) => {
          if (url) {
            axios
              .get<string>(url.url.href)
              .then((response) => {
                setDoc(response.data);
              })
              .catch((err) => undefined);
          }
        })
        .catch((err) => undefined),
    []
  );

  useEffect(() => {}, []);

  useEffect(() => setBusy(busyOfUser), [busyOfUser]);

  return {
    doc,
    setDoc,
    agree,
    setAgree,
    errorMessage,
    setErrorMessage,
    busy,
    setBusy,
    confirm,
    setConfirm,

    onAgreeChange,
    onOkButtonClick,
    onLogoutButtonClick,

    loadDoc,
    nodeRef
  } as const;
};
