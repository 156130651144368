import { meshDisplayableZoom, tileBaseZoom } from "common/consts/estates";
import { TileSize } from "common/consts/googleMaps";

export class Tile16MapType {
  id = "";
  infowindow = new google.maps.InfoWindow();
  map: google.maps.Map | null = null;
  tileSize = new google.maps.Size(TileSize, TileSize);

  constructor(id: string, map: google.maps.Map) {
    this.id = id;
    this.map = map;
  }

  getTile = (coord: google.maps.Point, zoom: number, ownerDocument: any) => {
    const div = ownerDocument.createElement("div") as HTMLElement;

    div.classList.add("tile16");

    if (zoom >= meshDisplayableZoom) {
      const multiply = Math.pow(2, tileBaseZoom - zoom);
      const multiplyReverse = Math.pow(2, zoom - tileBaseZoom);

      for (let x = 0; x < multiply; x++) {
        for (let y = 0; y < multiply; y++) {
          const innerDiv = ownerDocument.createElement("div") as HTMLElement;

          if (coord.x % multiplyReverse === 0) {
            innerDiv.classList.add("b_left");
          }

          if (coord.x % multiplyReverse === multiplyReverse) {
            innerDiv.classList.add("b_right");
          }

          if (coord.y % multiplyReverse === 0) {
            innerDiv.classList.add("b_top");
          }

          if (coord.y % multiplyReverse === multiplyReverse) {
            innerDiv.classList.add("b_bottom");
          }

          const width = this.tileSize.width * multiplyReverse;
          const height = this.tileSize.height * multiplyReverse;

          innerDiv.style.width = `${width}px`;
          innerDiv.style.height = `${height}px`;

          innerDiv.style.left = `${x * width}px`;
          innerDiv.style.top = `${y * height}px`;

          div.appendChild(innerDiv);
        }
      }
    }

    // div.innerHTML = `${calcTileInfo.x}, ${calcTileInfo.y}`;
    div.style.width = `${this.tileSize.width}px`;
    div.style.height = `${this.tileSize.height}px`;

    return div;
  };

  releaseTile = (tile: any) => {
    if (tile.parentElement) {
      tile.parentElement.removeChild(tile);
    }
  };
}
