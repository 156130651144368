import { Fab } from "@mui/material";
import estatesOff from "assets/icons/estate_info/btn_bknk_close_all.svg";
import React from "react";
import styles from "./SelectingEstatesButton.module.scss";

export const SelectingEstatesButton = React.memo(
  (props: {
    selectingEstates: boolean | undefined;
    onClearSelectedEstatesClick: () => void;
  }) => (
    <Fab
      onClick={props.onClearSelectedEstatesClick}
      className={styles.selectingEstatesButton}
    >
      <img src={estatesOff} alt="全ての物件情報を閉じる" />
    </Fab>
  )
);
