import { useCallback, useState } from "react";

export const useHooks = () => {
  const [openBound, setOpenBound] = useState(true);
  const [openSold, setOpenSold] = useState(true);
  const [openStickyNote, setOpenStickyNote] = useState(true);

  const onCloseBound = useCallback(() => {
    setOpenBound(false);
  }, []);

  const onCloseSold = useCallback(() => {
    setOpenSold(false);
  }, []);

  const onCloseStickyNote = useCallback(() => {
    setOpenStickyNote(false);
  }, []);

  return {
    openBound,
    openSold,
    openStickyNote,
    onCloseBound,
    onCloseSold,
    onCloseStickyNote
  } as const;
};
