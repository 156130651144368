import {
  EstateNotifyResponse,
  OnNotifyEstateCSubscription,
  StickyNote
} from "API";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { createPublicEstate } from "common/functions/api/publicEstates";
import { MainContext } from "common/interfaces/MainContext";
import { MinimalEstate } from "common/queries/minimalEstates";
import { RouteLocations } from "components/Parts/Organisms/SearchMap/interfaces";
import { useCallback } from "react";
import { SearchBehaviorStatesType } from "../states/behaviors";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchEstateStatesType } from "../states/estates";
import { SearchMapStatesType } from "../states/maps";

export const useSearchMapHandlers = (
  mainContext: MainContext,
  behaviorStates: SearchBehaviorStatesType,
  dialogStates: SearchDialogStatesType,
  mapStates: SearchMapStatesType,
  estateStates: SearchEstateStatesType
) => {
  const onMapReady = useCallback(
    (value: google.maps.Map) => {
      mapStates.setMap(value);
    },
    [mapStates]
  );

  const onSoldMarkerClick = useCallback((estate: MinimalEstate) => {
    if (estate?.tileOnZoom16) {
      const estateTypes = [
        ...new Set(estate.blocks?.map((x) => x.estateType))
      ].map((x) =>
        x === EstateTypeNameEnum.土地
          ? "g=1"
          : x === EstateTypeNameEnum.分譲地
          ? "l=1"
          : x === EstateTypeNameEnum.新築建売
          ? "h=1"
          : x === EstateTypeNameEnum.中古戸建
          ? "u=1"
          : x === EstateTypeNameEnum.事業用
          ? "b=1"
          : null
      );

      window.open(
        `/archive?tile=${estate.tileOnZoom16}&${estateTypes.join("&")}`,
        "_blank"
      );
    }
  }, []);

  const onStickyNoteMarkerClick = useCallback((stickyNote: StickyNote) => {},
  []);

  const onBoundChange = useCallback(
    (zoom: number, center: google.maps.LatLngLiteral) => {
      mapStates.setZoom(zoom);
      mapStates.setCenter(center);
    },
    [mapStates]
  );

  const onRouteLocationsChange = useCallback(
    (routeLocations: RouteLocations) => {
      mapStates.setRouteLocations(routeLocations);

      if (
        routeLocations.from !== null ||
        routeLocations.to !== null ||
        routeLocations.waypoints.length !== 0
      ) {
        mapStates.setSearchRouteMode(true);
      }
    },
    [mapStates]
  );

  const onTravelModeClear = useCallback(() => {
    mapStates.setTravelMode(null);
  }, [mapStates]);

  const onToggleSearchTrigger = useCallback(() => {
    mapStates.setSearchTrigger((x) => !x);
  }, [mapStates]);

  const onRouteLocationsResultChange = useCallback(
    (routeLocationsResult: google.maps.DirectionsResult | null) => {
      mapStates.setRouteLocationsResult(routeLocationsResult);
    },
    [mapStates]
  );

  const onQuitMeasureClick = useCallback(() => {
    mapStates.setMeasureLengthMode(false);
    mapStates.setMeasureAreaMode(false);
  }, [mapStates]);

  const onUpdateEstate = useCallback(
    (estate: MinimalEstate) => {
      dialogStates.setEstateLocationInputDialogEstateId(estate.id || undefined);
      dialogStates.setEstateLocationInputDialogMode("update");
    },
    [dialogStates]
  );

  const onReportEstate = useCallback(
    (estate: MinimalEstate) => {
      dialogStates.setReportInputDialogEstateId(estate.id || undefined);
      dialogStates.setReportInputDialogMode("create");
    },
    [dialogStates]
  );

  const onDeleteEstate = useCallback(
    (estate: MinimalEstate) => {
      dialogStates.setEstateLocationInputDialogEstateId(estate.id || undefined);
      dialogStates.setEstateLocationInputDialogMode("delete");
    },
    [dialogStates]
  );

  const onDisplayEstate = useCallback(
    (estate: MinimalEstate) => {
      behaviorStates.setCreatingPublicEstate(true);

      if (estate.id && mainContext.company?.id) {
        const w = window.open("/detail/waiting", "_blank");

        createPublicEstate(estate.id, mainContext.company.id)
          .then((res) => {
            if (w && res?.id) {
              const url = `/detail/${res.id}`;
              w.location.href = url;

              behaviorStates.setCreatingPublicEstate(false);
            }
          })
          .catch((err) => {
            w?.close();
            window.console.error(err);
            behaviorStates.setCreatingPublicEstate(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mainContext.company?.id]
  );

  const onSubscribeEstate = useCallback(
    (data: OnNotifyEstateCSubscription) => {
      const modify = (
        estates: MinimalEstate[] | undefined,
        estate: EstateNotifyResponse
      ) => {
        if (estates) {
          const index = estates?.findIndex((x) => x.id === estate.id);

          if (index !== undefined && index !== -1) {
            const oldEstate = estates[index];
            const newEstate = {
              ...oldEstate,
              ...estate,
              __typename: "Estate"
            } as MinimalEstate;

            estates?.splice(index, 1, newEstate);

            return [...estates];
          }
        }

        return estates;
      };

      if (data.onNotifyEstateC?.id) {
        window.console.log(`Notified! ${data.onNotifyEstateC?.id}`);

        const estate = data.onNotifyEstateC;

        estateStates.setEstates((estates) => modify(estates, estate));
        estateStates.setSelectedEstateOnAutoComplete((estate) => {
          if (
            estate &&
            data.onNotifyEstateC &&
            estate?.id === data.onNotifyEstateC?.id
          ) {
            return {
              ...estate,
              ...data.onNotifyEstateC,
              __typename: "Estate"
            } as MinimalEstate;
          }

          return estate;
        });

        estateStates.setNotifiedEstate({
          ...estate,
          ...data.onNotifyEstateC,
          __typename: "Estate"
        } as MinimalEstate);
      }
    },
    [estateStates]
  );

  const onCreateStickyNote = useCallback(
    (lat: number | undefined, lng: number | undefined) => {
      behaviorStates.setStickyNoteMode(true);
      dialogStates.setStickyNoteInputDialogInitialLatitude(lat);
      dialogStates.setStickyNoteInputDialogInitialLongitude(lng);
      dialogStates.setStickyNoteInputDialogMode("create");
    },
    [behaviorStates, dialogStates]
  );

  const onUpdateStickyNote = useCallback(
    (stickyNote: StickyNote) => {
      dialogStates.setStickyNoteInputDialogStickyNote(stickyNote);
      dialogStates.setStickyNoteInputDialogMode("update");
    },
    [dialogStates]
  );

  const onDeleteStickyNote = useCallback(
    (stickyNote: StickyNote) => {
      dialogStates.setStickyNoteInputDialogStickyNote(stickyNote);
      dialogStates.setStickyNoteInputDialogMode("delete");
    },
    [dialogStates]
  );

  return {
    onMapReady,
    onSoldMarkerClick,
    onStickyNoteMarkerClick,
    onBoundChange,
    onRouteLocationsChange,
    onTravelModeClear,
    onToggleSearchTrigger,
    onRouteLocationsResultChange,
    onQuitMeasureClick,
    onUpdateEstate,
    onDeleteEstate,
    onReportEstate,
    onDisplayEstate,
    onSubscribeEstate,
    onCreateStickyNote,
    onUpdateStickyNote,
    onDeleteStickyNote
  } as const;
};

export type SearchMapHandlersType = ReturnType<typeof useSearchMapHandlers>;
