import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { MinimalEstate } from "common/queries/minimalEstates";
import { cloneDeep, debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { DraggableData, DraggableEvent } from "react-draggable";
import type { Swiper as SwiperCore } from "swiper";
import { EstateInfoProps, MinimalEstateWithInfo } from "./interfaces";

export const useHooks = (props: EstateInfoProps) => {
  const { map, projection, estate } = props;

  const openRef = useRef(true);
  const mainRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);

  const [markerPixelPosition, setMarkerPixelPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [page, setPage] = useState<number>(1);

  const [bounds, setBounds] = useState<{
    northEast: google.maps.LatLngLiteral;
    southWest: google.maps.LatLngLiteral;
  } | null>(null);

  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0
  });
  const [dragging, setDragging] = useState<boolean>(false);

  const isStreetView = useMemo(
    () => map?.getStreetView()?.getVisible() ?? false,
    [map]
  );

  const getPreviousPrice = useCallback(
    (block: MinimalEstate | null | undefined) => {
      const priceHistory = cloneDeep(block?.priceHistory ?? []);

      priceHistory.sort((a, b) =>
        (a?.pricedAt ?? "").localeCompare(b?.pricedAt ?? "")
      );

      const currentPrice = priceHistory?.at(-1) ?? null;
      const previousPrice = priceHistory?.at(-2) ?? null;

      if (previousPrice) {
        if ((block?.estatePrice ?? null) === previousPrice.price) {
          return null;
        }

        previousPrice.pricedAt = currentPrice?.pricedAt ?? null;

        return previousPrice;
      }

      return null;
    },
    []
  );

  const getPriceBehavior = useCallback(
    (block: MinimalEstate | null | undefined) => {
      const previousPrice = getPreviousPrice(block);

      if (block?.estatePrice && previousPrice?.price) {
        if (block.estatePrice > previousPrice.price) {
          return "up";
        }

        if (block.estatePrice < previousPrice.price) {
          return "down";
        }

        return "none";
      }
    },
    [getPreviousPrice]
  );

  const condominiumBlocks = useMemo(() => {
    const condominiumMasters = estate?.blocks?.every(
      (x) => x.estateType === EstateTypeNameEnum.マンション
    )
      ? estate?.estateType === EstateTypeNameEnum.マンション
        ? [estate]
        : []
      : estate?.blocks?.filter(
          (x) => x.estateType === EstateTypeNameEnum.マンション
        ) ?? [];

    if (condominiumMasters?.length === 0) {
      return [];
    }

    const condominiumBlocks: MinimalEstateWithInfo[] = [];

    condominiumMasters.forEach((master) => {
      const roomBlocks =
        master.blocks.filter(
          (x) =>
            x.status === StatusNameEnum.公開 ||
            x.status === StatusNameEnum.下書き
        ) ?? [];

      roomBlocks.sort(
        (a, b) =>
          (b.floor ?? 0) - (a.floor ?? 0) ||
          (b.estatePrice ?? 0) - (a.estatePrice ?? 0) ||
          (a.recNo ?? 0) - (b.recNo ?? 0)
      );

      const rooms = roomBlocks.map(
        (x) =>
          ({
            ...x,
            previousPrice: getPreviousPrice(x),
            priceBehavior: getPriceBehavior(x),
            isCondominiumMaster: false
          } as MinimalEstateWithInfo)
      );

      condominiumBlocks.push({
        ...master,
        blocks: rooms,
        previousPrice: null,
        priceBehavior: undefined,
        isCondominiumMaster: true
      });

      condominiumBlocks.push(...rooms);
    });

    return condominiumBlocks;
  }, [estate, getPreviousPrice, getPriceBehavior]);

  const blocks = useMemo(() => {
    const blocks = (
      (estate?.blocks?.length ?? 0) <= 1 ? [estate] : estate?.blocks ?? [estate]
    )
      .map(
        (x) =>
          ({
            ...x,
            previousPrice: getPreviousPrice(x),
            priceBehavior: getPriceBehavior(x),
            isCondominiumMaster: false
          } as MinimalEstateWithInfo)
      )
      .filter((x) => x.estateType !== EstateTypeNameEnum.マンション);

    if (condominiumBlocks.length > 0) {
      blocks.push(...condominiumBlocks);
    }

    return blocks;
  }, [condominiumBlocks, estate, getPreviousPrice, getPriceBehavior]);

  const currentBlock = useMemo(
    () => blocks.at(page - 1) ?? estate ?? null,
    [blocks, estate, page]
  );

  const sold = useMemo(
    () => currentBlock?.status === StatusNameEnum.成約済み,
    [currentBlock?.status]
  );

  const paginationEnabled = useMemo(
    () => (blocks.length ?? 0) > 1,
    [blocks.length]
  );

  const onSwiperInit = useCallback((swiper: SwiperCore) => {
    setSwiper(swiper);
  }, []);

  const onSlideChange = useCallback(() => {
    setPage((swiper?.realIndex ?? 0) + 1);
  }, [swiper?.realIndex]);

  const onCloseClick = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      props.onCloseClick();
    }, 250);
  }, [props]);

  const onPrevClick = useCallback(() => {
    if (swiper) {
      swiper.slidePrev();
    }
  }, [swiper]);

  const onNextClick = useCallback(() => {
    if (swiper) {
      swiper?.slideNext();
    }
  }, [swiper]);

  const onDragStart = useCallback(() => {
    setDragging(true);
    props.onDragStart();
  }, [props]);

  const onDragStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setPosition({ x: data.x, y: data.y });
      props.onDragStop();
      setDragging(false);
    },
    [props]
  );

  const onClick = useCallback(() => {
    props.onDragStart();
    props.onDragStop();
  }, [props]);

  const pan = useCallback(() => {
    if (
      props.map &&
      open &&
      markerPixelPosition &&
      mainRef.current &&
      !isMobileOnly
    ) {
      const hiddenVLength =
        markerPixelPosition.y - mainRef.current.clientHeight - 100;

      const hiddenHLengthLeft =
        markerPixelPosition.x - mainRef.current.clientWidth / 2 - 10;

      const hiddenHLengthRight =
        markerPixelPosition.x +
        mainRef.current.clientWidth / 2 -
        props.map.getDiv().clientWidth +
        60;

      const panLengthH =
        hiddenHLengthLeft < 0
          ? hiddenHLengthLeft
          : hiddenHLengthRight > 0
          ? hiddenHLengthRight
          : 0;

      if (panLengthH !== 0 || hiddenVLength < 0) {
        props.map.panBy(panLengthH, Math.min(0, hiddenVLength));
      }

      return true;
    }

    return false;
  }, [markerPixelPosition, open, props.map]);

  const onBlockInfoResize = useCallback(() => {
    swiper?.updateAutoHeight(200);
  }, [swiper]);

  const onRoomPriceClick = useCallback(
    (estate: MinimalEstate) => {
      const index = blocks.findIndex((x) => x.id === estate.id);
      swiper?.slideToLoop(index);
    },
    [blocks, swiper]
  );

  useEffect(() => {
    if (map) {
      const boundsChange = debounce(
        () => {
          const mapBounds = map.getBounds() ?? null;

          const bounds = {
            northEast: {
              lat: mapBounds?.getNorthEast().lat() ?? 0,
              lng: mapBounds?.getNorthEast().lng() ?? 0
            },
            southWest: {
              lat: mapBounds?.getSouthWest().lat() ?? 0,
              lng: mapBounds?.getSouthWest().lng() ?? 0
            }
          };

          setBounds(bounds);
        },
        isMobile ? 100 : 1,
        {
          leading: true,
          trailing: true
        }
      );

      const idleListener = map.addListener("idle", boundsChange);

      const boundsChangedListener = map.addListener(
        "bounds_changed",
        boundsChange
      );

      return () => {
        if (idleListener) {
          google.maps.event.removeListener(idleListener);
        }

        if (boundsChangedListener) {
          google.maps.event.removeListener(boundsChangedListener);
        }
      };
    }
  }, [map]);

  useEffect(() => {
    if (map && estate?.latitude && estate?.longitude) {
      const pixelPosition = projection?.fromLatLngToContainerPixel(
        new google.maps.LatLng(estate.latitude, estate.longitude)
      );

      if (pixelPosition) {
        setMarkerPixelPosition({ x: pixelPosition.x, y: pixelPosition.y });

        setTimeout(() => {
          setOpen(true);
        }, 0.1);

        return;
      }
    }

    setMarkerPixelPosition(null);
  }, [map, projection, estate, bounds, onBlockInfoResize]);

  useEffect(() => {
    setPosition({ x: 0, y: 0 });
  }, [props.triggerResetPosition]);

  useEffect(() => {
    if (openRef.current) {
      openRef.current = !pan();
    }
  }, [pan]);

  return {
    mainRef,
    open,
    markerPixelPosition,
    page,
    swiper,
    position,
    dragging,

    isStreetView,

    blocks,
    currentBlock,
    sold,
    paginationEnabled,

    onSwiperInit,
    onSlideChange,
    onCloseClick,
    onPrevClick,
    onNextClick,
    onDragStart,
    onDragStop,
    onClick,
    onBlockInfoResize,
    onRoomPriceClick
  } as const;
};

export type UseHooksType = ReturnType<typeof useHooks>;
