import { useCallback, useEffect, useRef, useState } from "react";

export const useSliderHooks = (
  props: {
    onChange: (
      event: Event,
      value: number | number[],
      activeThumb: number
    ) => void;
  },
  thumbClassName: string
) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const captionRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const [captionLeft, setCaptionLeft] = useState<number | undefined>(undefined);
  const [captionRight, setCaptionRight] = useState<number | undefined>(
    undefined
  );

  const [arrowLeft, setArrowLeft] = useState<number | undefined>(undefined);
  const [arrowRight, setArrowRight] = useState<number | undefined>(undefined);

  const chaseThumb = useCallback(() => {
    setTimeout(() => {
      const thumbs = document.querySelectorAll(`.${thumbClassName}`);

      if (
        thumbs.length === 2 &&
        sliderRef.current &&
        captionRef.current &&
        arrowRef.current
      ) {
        const sliderLeft = sliderRef.current.getBoundingClientRect().x;
        const sliderWidth = sliderRef.current.getBoundingClientRect().width;
        const sliderRight = sliderWidth;

        const rangeWidth = captionRef.current.getBoundingClientRect().width;
        const arrowWidth = arrowRef.current?.getBoundingClientRect().width;

        const thumbsLeft = [
          thumbs[0].getBoundingClientRect().x - sliderLeft,
          thumbs[1].getBoundingClientRect().x - sliderLeft
        ];
        const thumbsRight = [
          thumbsLeft[0] + thumbs[0].getBoundingClientRect().width,
          thumbsLeft[1] + thumbs[1].getBoundingClientRect().width
        ];

        const newCaptionLeft =
          thumbsLeft[0] + (thumbsRight[1] - thumbsLeft[0]) / 2 - rangeWidth / 2;

        setCaptionLeft(
          newCaptionLeft < 0
            ? 0
            : newCaptionLeft + rangeWidth > sliderRight
            ? undefined
            : newCaptionLeft
        );

        setCaptionRight(
          newCaptionLeft + rangeWidth > sliderRight ? 0 : undefined
        );

        const newArrowLeft =
          thumbsLeft[0] + (thumbsRight[1] - thumbsLeft[0]) / 2 - arrowWidth / 2;

        setArrowLeft(
          newArrowLeft < 0
            ? 0
            : newArrowLeft + arrowWidth > sliderRight
            ? undefined
            : newArrowLeft
        );

        setArrowRight(newArrowLeft + arrowWidth > sliderRight ? 0 : undefined);
      }
    }, 100);
  }, [thumbClassName]);

  const onChange = useCallback(
    (event: Event, value: number | number[], activeThumb: number) => {
      props.onChange(event, value, activeThumb);
      chaseThumb();
    },
    [chaseThumb, props]
  );

  useEffect(() => {
    chaseThumb();
  }, [chaseThumb]);

  return {
    sliderRef,
    captionRef,
    arrowRef,
    captionLeft,
    captionRight,
    arrowLeft,
    arrowRight,

    chaseThumb,
    onChange
  } as const;
};
