import { EstateAtomicCounterGetter } from "common/functions/classes/EstateAtomicCounterGetter";
import useSWR from "swr";

export const useEstateAtomicCounterHooks = (
  estateId: string | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const { data, error } = useSWR(
    ["estateAtomicCounter", estateId],
    () => getEstateAtomicCounter(estateId, setFatal),
    {
      dedupingInterval: 300,
      revalidateOnFocus: false
    }
  );

  return { clickedCount: data, error } as const;
};

const getEstateAtomicCounter = async (
  estateId: string | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  if (estateId) {
    const getter = new EstateAtomicCounterGetter(setFatal);
    return await getter.getIndividualEstateAtomicCounter(estateId);
  }
};
