import { useCallback } from "react";

export const useMapStyleMethods = (mapState: google.maps.Map | null) => {
  // マップのスタイル定義
  const applyMapStyle = useCallback(() => {
    if (mapState) {
      mapState?.setOptions({
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DEFAULT,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
          mapTypeIds: ["roadmap", "hybrid"]
        },
        maxZoom: 20,
        clickableIcons: false
      });
    }
  }, [mapState]);

  return {
    applyMapStyle
  } as const;
};
