import Menu from "@mui/material/Menu";
import * as React from "react";
import { ContextMenuProps } from "./interfaces";

export const ContextMenu = React.memo((props: ContextMenuProps) => {
  return (
    <Menu
      open={props.contextMenuPosition !== null}
      onClose={props.onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        props.contextMenuPosition !== null
          ? {
              top: props.contextMenuPosition.mouseY,
              left: props.contextMenuPosition.mouseX
            }
          : undefined
      }
    >
      {props.children}
    </Menu>
  );
});
