import { Box, Grid, Typography } from "@mui/material";
import styles from "./MenuElement.module.scss";

export const MenuElement = (props: {
  title: string;
  description: string | JSX.Element;
  icon: JSX.Element;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <Box onClick={props.onClick} className={styles.menuElement}>
      <Grid container>
        <Grid item xs={3} className={styles.icon}>
          {props.icon}
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" className={styles.title}>
            {props.title}
          </Typography>
          <Typography variant="caption">{props.description}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
