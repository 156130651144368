import { Box } from "@mui/material";
import { SumoraMap } from "components/Parts/Atoms/SumoraMap";
import React from "react";
import styles from "./BackgroundMap.module.scss";
import { useBackgroundMapHooks } from "./hooks";
import { BackgroundMapProps } from "./interfaces";

export const BackgroundMap = React.memo((props: BackgroundMapProps) => {
  const { onGoogleApiLoaded } = useBackgroundMapHooks(props);

  return (
    <>
      <Box style={props.style} className={props.className} id={props.mapDivId}>
        <SumoraMap
          center={props.center}
          zoom={props.zoom}
          streetViewControl={false}
          mapTypeControl={false}
          scaleControl={false}
          fullscreenControl={false}
          scrollwheel={false}
          zoomControl={false}
          dspPoi={true}
          onGoogleApiLoaded={onGoogleApiLoaded}
        />
        <Box className={styles.overlay} />
      </Box>
    </>
  );
});
