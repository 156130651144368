import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  CreateReportMutation,
  CreateReportMutationVariables,
  DeleteReportMutation,
  DeleteReportMutationVariables,
  GetReportQuery,
  GetReportQueryVariables,
  ListReportsQuery,
  ListReportsQueryVariables,
  Report,
  UpdateReportMutation,
  UpdateReportMutationVariables
} from "API";
import { generateClient } from "aws-amplify/api";
import {
  createReport as createReportMutation,
  deleteReport as deleteReportMutation,
  updateReport as updateReportMutation
} from "graphql/mutations";
import { getReport as getReportQuery, listReports } from "graphql/queries";
import { getSumoraAuthToken } from "../credentials";

// 検索を停止させるフラグ
let cancelSearch = false;

// 報告検索
export const searchReport = async () => {
  const reports: Report[] = [];

  const variablesList: ListReportsQueryVariables[] = [{}];

  const responses = variablesList.map(async (variables) => {
    let nextToken: string | null = "start";
    const client = generateClient();

    while (nextToken && !cancelSearch) {
      if (nextToken !== "start") {
        variables.nextToken = nextToken;
      }

      let items: (Report | null)[] = [];

      const res = (await client.graphql({
        ...graphqlOperation(listReports, variables),
        authMode: "lambda",
        authToken: await getSumoraAuthToken()
      })) as GraphQLResult<ListReportsQuery>;

      if (res.data?.listReports?.items) {
        items = res.data?.listReports?.items;
      }

      nextToken = res.data?.listReports?.nextToken || null;

      // 返却値のセット
      items.forEach((x) => {
        if (x) {
          reports.push(x);
        }
      });
    }
  });

  await Promise.all(responses);

  return reports;
};

// 報告取得
export const getReport = async (id: string, infoName: string) => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(getReportQuery, {
      id: id,
      infoName: infoName
    } as GetReportQueryVariables),
    authMode: "userPool"
  })) as GraphQLResult<GetReportQuery>;

  return res.data?.getReport;
};

export const createReport = async (report: Report) => {
  const { __typename: _, ...input } = { ...report };
  const variables: CreateReportMutationVariables = { input: input };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(createReportMutation, variables),
    authMode: "userPool"
  })) as GraphQLResult<CreateReportMutation>;

  if (res.errors) {
    throw res.errors;
  }

  return res.data?.createReport;
};

export const updateReport = async (report: Report) => {
  const { __typename: _, ...input } = { ...report };
  const variables: UpdateReportMutationVariables = { input: input };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(updateReportMutation, variables),
    authMode: "userPool"
  })) as GraphQLResult<UpdateReportMutation>;

  if (res.errors) {
    throw res.errors;
  }

  return res.data?.updateReport;
};

export const deleteReport = async (report: Report) => {
  const variables: DeleteReportMutationVariables = {
    input: {
      id: report.id
    }
  };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(deleteReportMutation, variables),
    authMode: "userPool"
  })) as GraphQLResult<DeleteReportMutation>;

  if (res.errors) {
    throw res.errors;
  }

  return res.data?.deleteReport;
};
