import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Coord, nearestPoint } from "@turf/turf";
import { TileSize } from "common/consts/googleMaps";
import { RouteLocations } from "components/Parts/Organisms/SearchMap/interfaces";
import { FeatureCollection, GeoJsonProperties, Point } from "geojson";
import { range } from "lodash";

// マーカーの破棄
export const clearMarker = (
  marker: google.maps.marker.AdvancedMarkerElement | google.maps.Marker | null
) => {
  if (marker) {
    try {
      google.maps.event.clearInstanceListeners(marker);

      if (marker instanceof google.maps.Marker) {
        marker.setMap(null);
      } else {
        marker.map = null;
      }

      marker = null;
    } catch (e) {
      window.console.info(e);
    }
  }
};

// Featureの破棄
export const clearFeature = (
  map: google.maps.Map | null,
  feature: google.maps.Data.Feature | null
) => {
  if (feature) {
    map?.data.remove(feature);
    google.maps.event.clearInstanceListeners(feature);
    feature = null;
  }
};

// Polygonの破棄
export const clearPolygon = (polygon: google.maps.Polygon | null) => {
  if (polygon) {
    google.maps.event.clearInstanceListeners(polygon);
    polygon.setMap(null);
    polygon = null;
  }
};

// MarkerClustererの破棄
export const clearMarkerClusterer = (
  markerClusterer: MarkerClusterer | null
) => {
  if (markerClusterer) {
    try {
      google.maps.event.clearInstanceListeners(markerClusterer);
      markerClusterer.setMap(null);
    } catch (e) {
      window.console.info(e);
    }

    markerClusterer = null;
  }
};

//#region ルート地点操作系
// 近傍点特定
export const specifyNearest = (
  locations: (google.maps.LatLng | null)[],
  location: google.maps.LatLng
) => {
  let nearestLocation: google.maps.LatLng = location;

  if (locations.length > 0) {
    const feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [location.lng(), location.lat()]
      },
      properties: {}
    } as Coord;

    const featureCollection = {
      type: "FeatureCollection",
      features: [
        ...locations
          .filter((x) => x !== null)
          .map((x) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [x?.lng(), x?.lat()]
            },
            properties: {}
          }))
      ]
    } as FeatureCollection<Point, GeoJsonProperties>;

    const nearestFeature = nearestPoint(feature, featureCollection);

    nearestLocation = new google.maps.LatLng(
      nearestFeature.geometry.coordinates[1],
      nearestFeature.geometry.coordinates[0]
    );

    return nearestLocation;
  }
  return null;
};

// 路線ポイントコピー
export const copyRouteLocations = (routeLocations: RouteLocations) => ({
  ...routeLocations,
  waypoints: [...routeLocations.waypoints]
});

// ルート出発点削除
export const deleteFrom = (
  routeLocations: RouteLocations,
  payload: google.maps.LatLng
) => {
  if (routeLocations.from) {
    if (
      routeLocations.from?.lat() === payload.lat() &&
      routeLocations.from?.lng() === payload.lng()
    ) {
      routeLocations.from = null;

      return true;
    }
  }

  return false;
};

// ルート到着点削除
export const deleteTo = (
  routeLocations: RouteLocations,
  payload: google.maps.LatLng
) => {
  if (routeLocations.to) {
    if (
      routeLocations.to?.lat() === payload.lat() &&
      routeLocations.to?.lng() === payload.lng()
    ) {
      routeLocations.to = null;

      return true;
    }
  }

  return false;
};

// ルート経由地削除
export const deleteWaypoint = (
  routeLocations: RouteLocations,
  payload: google.maps.LatLng
) => {
  const index = routeLocations.waypoints.findIndex(
    (x) => x.lat() === payload.lat() && x.lng() === payload.lng()
  );

  if (index > -1) {
    routeLocations.waypoints.splice(index, 1);

    return true;
  }

  return false;
};

// 路線検索
export const callRoute = async (
  service: google.maps.DirectionsService,
  origin:
    | string
    | google.maps.LatLng
    | google.maps.Place
    | google.maps.LatLngLiteral,
  destination:
    | string
    | google.maps.LatLng
    | google.maps.Place
    | google.maps.LatLngLiteral,
  waypoints: google.maps.DirectionsWaypoint[] | undefined,
  travelMode: google.maps.TravelMode,
  optimizeWaypoints: boolean
): Promise<google.maps.DirectionsResult | null> => {
  return new Promise<google.maps.DirectionsResult | null>((resolve) =>
    service.route(
      {
        region: "JP",
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: travelMode,
        optimizeWaypoints: optimizeWaypoints
      },
      (
        result: google.maps.DirectionsResult | null,
        status: google.maps.DirectionsStatus
      ) => {
        if (status === google.maps.DirectionsStatus.OK) {
          resolve(result);
        }

        resolve(null);
      }
    )
  );
};
//#endregion

// 緯度経度から各種座標に変換
export const latLngToCoordinatesPrimitive = (
  latitude: number,
  longitude: number,
  zoom: number
) => {
  const scale = 2 ** zoom;

  const siny = Math.min(
    Math.max(Math.sin((latitude * Math.PI) / 180), -0.9999),
    0.9999
  );

  const worldCoordinate = {
    x: TileSize * (0.5 + longitude / 360),
    y: TileSize * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
  };

  const pixelCoordinate = {
    x: Math.floor(worldCoordinate.x * scale),
    y: Math.floor(worldCoordinate.y * scale)
  };

  const tileCoordinate = {
    x: Math.floor((worldCoordinate.x * scale) / TileSize),
    y: Math.floor((worldCoordinate.y * scale) / TileSize)
  };

  const pixelCoordinateOnTile = {
    x: pixelCoordinate.x - tileCoordinate.x * TileSize,
    y: pixelCoordinate.y - tileCoordinate.y * TileSize
  };

  return {
    worldCoordinate,
    pixelCoordinate,
    tileCoordinate,
    pixelCoordinateOnTile
  };
};

// 緯度経度から各種座標に変換(google.maps形式)
export const latLngToCoordinates = (
  latLng: google.maps.LatLng,
  zoom: number
) => {
  const latitude = latLng.lat();
  const longitude = latLng.lng();

  const coordinates = latLngToCoordinatesPrimitive(latitude, longitude, zoom);

  return {
    worldCoordinate: new google.maps.Point(
      coordinates.worldCoordinate.x,
      coordinates.worldCoordinate.y
    ),
    pixelCoordinate: new google.maps.Point(
      coordinates.pixelCoordinate.x,
      coordinates.pixelCoordinate.y
    ),
    tileCoordinate: new google.maps.Point(
      coordinates.tileCoordinate.x,
      coordinates.tileCoordinate.y
    ),
    pixelCoordinateOnTile: new google.maps.Point(
      coordinates.pixelCoordinateOnTile.x,
      coordinates.pixelCoordinateOnTile.y
    )
  };
};

// タイルYアドレスから左上の緯度を取得
const tileToLat = (tileY: number, zoom: number) => {
  const n = Math.PI - (2 * Math.PI * tileY) / Math.pow(2, zoom);
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
};

// タイルXアドレスから左上の経度を取得
const tileToLng = (tileX: number, zoom: number) => {
  return (tileX / Math.pow(2, zoom)) * 360 - 180;
};

// タイルアドレスからその左上の緯度・経度を取得
export const tileToNWLatLng = (tile: string, zoom: number) => {
  const addresses = tile.split(",");

  if (addresses.length < 2) {
    return {
      latitude: null,
      longitude: null
    };
  }

  const lngOfNW = tileToLng(Number(addresses[0]), zoom);
  const latOfNW = tileToLat(Number(addresses[1]), zoom);

  return {
    latitude: latOfNW,
    longitude: lngOfNW
  };
};

// 緯度経度範囲からタイルアドレス取得
const latLngToTiles = (
  latitudeFrom: number | undefined,
  latitudeTo: number | undefined,
  longitudeFrom: number | undefined,
  longitudeTo: number | undefined,
  zoom: number
) => {
  const { tileCoordinate: swTile } = latLngToCoordinates(
    new google.maps.LatLng(latitudeFrom || 0, longitudeFrom || 0),
    zoom
  );

  const { tileCoordinate: neTile } = latLngToCoordinates(
    new google.maps.LatLng(latitudeTo || 0, longitudeTo || 0),
    zoom
  );

  const tiles: string[] = [];

  for (const x of range(
    Math.min(swTile.x, neTile.x),
    Math.max(swTile.x, neTile.x) + 1
  )) {
    for (const y of range(
      Math.min(swTile.y, neTile.y),
      Math.max(swTile.y, neTile.y) + 1
    )) {
      tiles.push(`${x},${y}`);
    }
  }

  return tiles;
};

// マップ範囲からタイルアドレス取得
export const boundsToTiles = (
  bounds: google.maps.LatLngBounds,
  zoom: number
) => {
  const latitudeFrom = bounds.getSouthWest().lat();
  const latitudeTo = bounds.getNorthEast().lat();
  const longitudeFrom = bounds.getSouthWest().lng();
  const longitudeTo = bounds.getNorthEast().lng();

  return latLngToTiles(
    latitudeFrom,
    latitudeTo,
    longitudeFrom,
    longitudeTo,
    zoom
  );
};
