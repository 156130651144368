import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { GeoJSONFeature } from "common/interfaces/GeoJSON";
import { MojProperty } from "common/interfaces/Moj";
import { PickingInfo } from "deck.gl";
import { useCallback, useContext, useEffect, useRef } from "react";
import { SearchMapContext } from "../../context";
import { MojMVTPointLayer } from "./MojMVTPointLayer";
import { MojMVTPolygonLayer } from "./MojMVTPolygonLayer";

export const useHooks = (on: boolean) => {
  const { map, measureMode } = useContext(SearchMapContext);
  const googleMapsOverlay = useRef<GoogleMapsOverlay | undefined>(undefined);
  const cashedTiles = useRef<{ [url: string]: { buf: ArrayBuffer } }>({});
  const infoWindows = useRef<{ [uid: string]: google.maps.InfoWindow }>({});

  const bringFront = useCallback(
    (targetInfoWindow: google.maps.InfoWindow | null) => {
      if (targetInfoWindow) {
        const maxInfoWindowZIndex = Math.max(
          ...(Object.values(infoWindows.current) ?? []).map(
            (infoWindow) => infoWindow.getZIndex() ?? 0
          )
        );

        if (!isNaN(maxInfoWindowZIndex)) {
          targetInfoWindow.setZIndex(maxInfoWindowZIndex + 1);
        }
      }
    },
    []
  );

  const constructInfoWindow = useCallback(
    (info: PickingInfo) => {
      const { coordinate, object } = info;
      const [lng, lat] = coordinate as [number, number];
      const { properties } = object as GeoJSONFeature<MojProperty>;

      // window.console.log(info);

      const featureArea = properties?.AREA
        ? Math.round(properties.AREA * 100) / 100
        : undefined;

      const mojInfoDiv = document.createElement("div");

      mojInfoDiv.className = "moj-info";
      mojInfoDiv.innerHTML = `
      ${
        properties?.KIND === "city"
          ? `
            <div>${properties?.市区町村名 ?? ""}</div>
            `
          : properties?.KIND === "area"
          ? `
            <div>${properties?.市区町村名 ?? ""}${properties?.大字名 ?? ""}${
              properties?.丁目名 ?? ""
            }</div>
            `
          : `
            <div>地番：${properties?.地番}</div>
            <div>面積：${featureArea ?? "？"}㎡ <small>(※概算)</small></div>
            <div class="map-name">
              <div>${properties?.市区町村名 ?? ""}${properties?.大字名 ?? ""}${
              properties?.丁目名 ?? ""
            }</div>
            </div>
            `
      }
    `;

      const existingInfoWindow = infoWindows.current[properties?.UID ?? "xxx"];

      if (existingInfoWindow) {
        return null;
      }

      const newInfoWindow = new google.maps.InfoWindow({
        content: mojInfoDiv,
        position: { lat, lng }
      });

      mojInfoDiv.addEventListener("click", () => {
        bringFront(newInfoWindow);
      });

      newInfoWindow.addListener("closeclick", () => {
        delete infoWindows.current[properties?.UID ?? "xxx"];
      });

      infoWindows.current[properties?.UID ?? "xxx"] = newInfoWindow;

      return newInfoWindow;
    },
    [bringFront]
  );

  const constructTileLayer = useCallback(() => {
    const params = {
      data: "json/moj/tiles/{z}/{x}/{y}.pbf",
      tileSize: 256,
      useDevicePixels: false,
      pickable: true,
      uniqueIdProperty: "UID"
    };

    const mvtPolygonLayer = new MojMVTPolygonLayer({
      ...params,
      id: "mojPolygonTilesLayer",
      getFillColor: [245, 160, 220, 80],
      getLineColor: [255, 255, 255, 255],
      lineWidthMinPixels: 2,
      lineWidthMaxPixels: 2,
      autoHighlight: true,
      highlightColor: [245, 100, 150, 100],
      pointType: "icon",
      getIcon: () => null,
      getIconSize: 0
    });

    mvtPolygonLayer.cachedTiles = cashedTiles;
    mvtPolygonLayer.mockGML = process.env.REACT_APP_MOCK_GML === "true";

    const mvtPointLayer = new MojMVTPointLayer({
      ...params,
      id: "mojPointTilesLayer",
      filled: false,
      pickable: false,
      lineWidthMinPixels: 0,
      lineWidthMaxPixels: 0,
      pointType: "text",
      getText: (data: { properties: MojProperty }) => data?.properties?.地番,
      getTextAnchor: "middle",
      getTextAlignmentBaseline: "center",
      getTextSize: 12,
      getTextColor: [255, 255, 255, 127],
      textFontSettings: {
        sdf: true
      },
      textFontFamily: "din-2014, fot-tsukuardgothic-std, sans-serif",
      textFontWeight: 600,
      textCharacterSet: "auto",
      textOutlineColor: [0, 0, 0, 127],
      textOutlineWidth: 8
    });

    mvtPointLayer.delay = 200;
    mvtPointLayer.cachedTiles = cashedTiles;
    mvtPointLayer.mockGML = process.env.REACT_APP_MOCK_GML === "true";

    return new GoogleMapsOverlay({
      layers: [mvtPolygonLayer, mvtPointLayer]
    });
  }, []);

  useEffect(() => {
    if (on && map) {
      const tileLayer = constructTileLayer();
      tileLayer.setMap(map);
      googleMapsOverlay.current = tileLayer;
      map.panBy(1, 1);
      map.panBy(-1, -1);
    } else if (!on) {
      googleMapsOverlay.current?.setMap(null);
    }
  }, [constructTileLayer, map, on]);

  useEffect(() => {
    if (measureMode) {
      googleMapsOverlay.current?.setProps({
        onClick: undefined
      });
    } else if (on && map) {
      googleMapsOverlay.current?.setProps({
        onClick: (info) => {
          if (info?.object && info?.coordinate) {
            const infoWindow = constructInfoWindow(info);

            infoWindow?.open(map);
            bringFront(infoWindow);
          }
        }
      });
    }
  }, [bringFront, constructInfoWindow, map, measureMode, on]);

  return { map } as const;
};
