import {
  GraphQLResult,
  GraphqlSubscriptionMessage,
  GraphqlSubscriptionResult,
  graphqlOperation
} from "@aws-amplify/api-graphql";
import {
  DeleteEstateFMutation,
  DeleteEstateFMutationVariables,
  Estate,
  GetEstateQuery,
  GetEstateQueryVariables,
  ListEstatesBySearchInfoQuery,
  ListEstatesBySearchInfoQueryVariables,
  OnNotifyEstateCSubscription,
  UpdateEstateFMutation,
  UpdateEstateFMutationVariables
} from "API";
import { generateClient } from "aws-amplify/api";
import { InfoNameEnum } from "common/enums/InfoNameEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { StatusEnum, StatusNameEnum } from "common/enums/StatusEnum";
import { CheckEstatesCondition } from "common/interfaces/CheckEstatesCondition";
import { EstateSearchConditions } from "common/interfaces/EstateSearchConditions";
import { MinimalEstate } from "common/queries/minimalEstates";
import {
  deleteEstateF as deleteEstateFMutation,
  updateEstateF as updateEstateFMutation
} from "graphql/mutations";
import {
  getEstate as getEstateQuery,
  listEstatesBySearchInfo
} from "graphql/queries";
import { onNotifyEstateC } from "graphql/subscriptions";
import { isEmpty } from "lodash";
import { getSumoraAuthToken } from "../credentials";

// 検索を停止させるフラグ
let cancelSearch = false;

export const setCancelSearch = (newCancelSearch: boolean) => {
  cancelSearch = newCancelSearch;
};

// 物件検索(一覧用)
export const listEstates = async (
  condition: CheckEstatesCondition,
  isAdministrator: boolean
) => {
  const estates: MinimalEstate[] = [];
  const client = generateClient();

  const variablesList: ListEstatesBySearchInfoQueryVariables[] = [];

  variablesList.push(
    ...condition.statuses.flatMap((status) =>
      condition.cities.map(
        (city) =>
          ({
            infoName: InfoNameEnum.statusAndPrefectureAndCityAndPricedAt,
            searchInfo: {
              beginsWith: `${status}#${city.pref_name}#${city.city_name}#`
            },
            filter: {
              and: [
                condition.mode === SearchModeEnum.domain ||
                (!isAdministrator &&
                  (status === StatusNameEnum.成約済み ||
                    status === StatusNameEnum.非公開))
                  ? {
                      or: [
                        {
                          companyId: {
                            beginsWith: `${condition.company?.recNo ?? "?"}-`
                          }
                        },
                        {
                          subCompanyId: {
                            beginsWith: `${condition.company?.recNo ?? "?"}-`
                          }
                        },
                        {
                          subCompany2Id: {
                            beginsWith: `${condition.company?.recNo ?? "?"}-`
                          }
                        }
                      ]
                    }
                  : null,

                condition.estateTypes.length > 0
                  ? {
                      or: condition.estateTypes.map((x) => ({
                        estateType: { eq: x }
                      }))
                    }
                  : null
              ].filter((x) => x)
            },
            limit: 500
          } as ListEstatesBySearchInfoQueryVariables)
      )
    )
  );

  // console.log(variablesList);

  const responses = variablesList.map(async (variables) => {
    let nextToken: string | null = "start";

    while (nextToken && !cancelSearch) {
      if (nextToken !== "start") {
        variables.nextToken = nextToken;
      }

      let items: (Estate | null)[] = [];

      const res = (await client.graphql({
        ...graphqlOperation(listEstatesBySearchInfo, variables),
        authMode: "lambda",
        authToken: await getSumoraAuthToken()
      })) as GraphQLResult<ListEstatesBySearchInfoQuery>;

      if (res.data?.listEstatesBySearchInfo?.items) {
        items = res.data?.listEstatesBySearchInfo?.items;
      }

      nextToken = res.data?.listEstatesBySearchInfo?.nextToken || null;

      // 返却値のセット
      items.forEach((x) => {
        if (x) {
          estates.push({
            ...x,
            blocks: [],
            clickedCount: 0,
            __typename: "Estate"
          });
        }
      });
    }
  });

  await Promise.all(responses);

  return (condition.area ?? "").trim() === ""
    ? estates
    : estates.filter((x) => x.fullAddress?.includes(condition.area ?? ""));
};

// 物件検索
export const searchEstate = async (
  searchMode: SearchModeEnum,
  conditions: EstateSearchConditions,
  estatesCountSetter?: (value: number) => void
) => {
  const estates: MinimalEstate[] = [];

  const variablesList = constructVariablesForEstate(searchMode, conditions);
  const client = generateClient();

  const responses = variablesList.map(async (variables) => {
    let nextToken: string | null = "start";

    while (nextToken && !cancelSearch) {
      if (nextToken !== "start") {
        variables.nextToken = nextToken;
      }

      let items: (Estate | null)[] = [];

      const res = (await client.graphql({
        ...graphqlOperation(listEstatesBySearchInfo, variables),
        authMode: "lambda",
        authToken: await getSumoraAuthToken()
      })) as GraphQLResult<ListEstatesBySearchInfoQuery>;

      if (res.data?.listEstatesBySearchInfo?.items) {
        items = res.data?.listEstatesBySearchInfo?.items;
      }

      nextToken = res.data?.listEstatesBySearchInfo?.nextToken || null;

      // 返却値のセット
      items.forEach((x) => {
        if (x) {
          estates.push({
            ...x,
            blocks: [],
            clickedCount: 0,
            __typename: "Estate"
          });
        }
      });

      if (estatesCountSetter) {
        estatesCountSetter(estates.length);
      }
    }
  });

  await Promise.all(responses);

  if (searchMode === SearchModeEnum.unknownLocation) {
    return estates.filter(
      (x) => x.status === StatusNameEnum.下書き || isEmpty(x.tileOnZoom12)
    );
  } else if (
    searchMode === SearchModeEnum.duplicateLocation ||
    searchMode === SearchModeEnum.deleteDuplicateLocation
  ) {
    return estates.filter((x) => !isEmpty(x.tileOnZoom12));
  }

  return estates;
};

// パラメータ構築
const constructVariablesForEstate = (
  searchMode: SearchModeEnum,
  conditions: EstateSearchConditions
): ListEstatesBySearchInfoQueryVariables[] => {
  const innerConditions = { ...conditions };

  switch (searchMode) {
    case SearchModeEnum.condominium:
    case SearchModeEnum.deleted:
      // ステータスを限定
      innerConditions.statuses = [{ id: -1, name: searchMode }];
      break;

    case SearchModeEnum.draft:
      // ステータスを限定
      innerConditions.statuses = [
        { id: StatusEnum.下書き, name: StatusNameEnum.下書き }
      ];
      break;
  }

  const variablesList: ListEstatesBySearchInfoQueryVariables[] = [];

  const prefExists = innerConditions.prefs.length > 0;
  const cityExists = innerConditions.cities.length > 0;
  const tileOnZoom12Exists = innerConditions.tilesOnZoom12.length > 0;
  const tileOnZoom14Exists = innerConditions.tilesOnZoom14.length > 0;
  const tileOnZoom16Exists = innerConditions.tilesOnZoom16.length > 0;

  // 業者フィルタ構築
  const companyFilter =
    innerConditions.companies.length === 0
      ? {}
      : {
          filter: {
            or: [
              ...innerConditions.companies.map((x) => ({
                companyId: { beginsWith: `${x.recNo}-` }
              })),
              ...innerConditions.companies.map((x) => ({
                subCompanyId: { beginsWith: `${x.recNo}-` }
              })),
              ...innerConditions.companies.map((x) => ({
                subCompany2Id: { beginsWith: `${x.recNo}-` }
              }))
            ]
          }
        };

  // Queryパラメータ構築
  for (const estateStatus of innerConditions.statuses) {
    if (searchMode === SearchModeEnum.company) {
      // 特定業者管理物件検索
      const variables: ListEstatesBySearchInfoQueryVariables[] =
        innerConditions.companies.map((x) => ({
          infoName: InfoNameEnum.statusAndCompanyId,
          searchInfo: {
            eq: `${estateStatus.name}#${x.id}`
          },
          limit: 500
        }));

      variablesList.push(...variables);
    } else if (searchMode === SearchModeEnum.unknownPrefecture) {
      // 都道府県不明物件検索
      const variables: ListEstatesBySearchInfoQueryVariables = {
        infoName: InfoNameEnum.statusAndPrefectureAndCityAndPricedAt,
        searchInfo: { beginsWith: `${estateStatus.name}#None#None#` },
        limit: 500,
        ...companyFilter
      };

      variablesList.push(variables);
    } else if (searchMode === SearchModeEnum.unknownCity) {
      // 市区町村不明物件検索
      const variables: ListEstatesBySearchInfoQueryVariables[] =
        innerConditions.prefs.map((x) => ({
          infoName: InfoNameEnum.statusAndPrefectureAndCityAndPricedAt,
          searchInfo: {
            beginsWith: `${estateStatus.name}#${x.pref_name}#None#`
          },
          limit: 500,
          ...companyFilter
        }));

      variablesList.push(...variables);
    } else if (
      searchMode === SearchModeEnum.unknownLocation ||
      searchMode === SearchModeEnum.duplicateLocation ||
      searchMode === SearchModeEnum.deleteDuplicateLocation
    ) {
      // 位置不明物件検索
      // 位置重複チェック用検索
      const variables: ListEstatesBySearchInfoQueryVariables[] =
        innerConditions.cities.map((x) => ({
          infoName: InfoNameEnum.statusAndPrefectureAndCityAndPricedAt,
          searchInfo: {
            beginsWith: `${estateStatus.name}#${x.pref_name}#${x.city_name}#`
          },
          limit: 500,
          ...companyFilter
        }));

      variablesList.push(...variables);
    } else if (searchMode === SearchModeEnum.deleted) {
      // 削除済み検索
      const variables: ListEstatesBySearchInfoQueryVariables[] = [
        {
          infoName: InfoNameEnum.deleted,
          searchInfo: {
            eq: "-"
          },
          limit: 500
        }
      ];

      variablesList.push(...variables);
    } else if (innerConditions.bound) {
      // 位置範囲検索
      for (const tile of tileOnZoom12Exists
        ? innerConditions.tilesOnZoom12
        : tileOnZoom14Exists
        ? innerConditions.tilesOnZoom14
        : tileOnZoom16Exists
        ? innerConditions.tilesOnZoom16
        : []) {
        const variables: ListEstatesBySearchInfoQueryVariables = {
          infoName: `statusAndTileOnZoom${
            tileOnZoom12Exists
              ? "12"
              : tileOnZoom14Exists
              ? "14"
              : tileOnZoom16Exists
              ? "16"
              : ""
          }`,
          searchInfo: { eq: `${estateStatus.name}#${tile}` },
          limit: 500,
          ...companyFilter
        };

        variablesList.push(variables);
      }
    } else if (cityExists) {
      // 市区町村が指定されている場合
      for (const city of innerConditions.cities) {
        const variables: ListEstatesBySearchInfoQueryVariables = {
          infoName: InfoNameEnum.statusAndPrefectureAndCityAndPricedAt,
          limit: 500,
          ...companyFilter
        };

        variables.searchInfo = {
          beginsWith: `${estateStatus.name}#${city.pref_name}#${city.city_name}#`
        };

        variablesList.push(variables);
      }
    } else if (prefExists) {
      // 都道府県のみが指定されている場合
      for (const pref of innerConditions.prefs) {
        const variables: ListEstatesBySearchInfoQueryVariables = {
          infoName: InfoNameEnum.statusAndPrefectureAndCityAndPricedAt,
          limit: 500,
          ...companyFilter
        };

        variables.searchInfo = {
          beginsWith: `${estateStatus.name}#${pref.pref_name}#`
        };

        variablesList.push(variables);
      }
    }
  }

  return variablesList;
};

// 物件取得
export const getEstate = async (id: string, infoName: string) => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(getEstateQuery, {
      id: id,
      infoName: infoName
    } as GetEstateQueryVariables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<GetEstateQuery>;

  return res.data?.getEstate;
};

// 特定マンションのアーカイブレコードリスト取得
export const getCondominiumArchives = async (condominiumId: string) => {
  const items: Estate[] = [];

  const variables: ListEstatesBySearchInfoQueryVariables = {
    infoName: InfoNameEnum.condominiumId,
    searchInfo: { eq: condominiumId }
  };

  let nextToken: string | null = "start";
  const client = generateClient();

  while (nextToken) {
    if (nextToken !== "start") {
      variables.nextToken = nextToken;
    }

    const res = (await client.graphql({
      ...graphqlOperation(listEstatesBySearchInfo, variables),
      authMode: "lambda",
      authToken: await getSumoraAuthToken()
    })) as GraphQLResult<ListEstatesBySearchInfoQuery>;

    if (res.data?.listEstatesBySearchInfo) {
      items.push(
        ...res.data?.listEstatesBySearchInfo.items
          .filter((x) => x?.status === StatusNameEnum.成約済み)
          .map(
            (x) =>
              ({
                ...x,
                __typename: "Estate"
              } as Estate)
          )
      );
    }

    nextToken = res.data?.listEstatesBySearchInfo?.nextToken || null;
  }

  return items;
};

// アーカイブレコードリスト取得
export const getArchives = async (tileOnZoom16: string) => {
  const items: Estate[] = [];

  const variables: ListEstatesBySearchInfoQueryVariables = {
    infoName: InfoNameEnum.statusAndTileOnZoom16,
    searchInfo: { eq: `${StatusNameEnum.成約済み}#${tileOnZoom16}` }
  };

  let nextToken: string | null = "start";
  const client = generateClient();

  while (nextToken) {
    if (nextToken !== "start") {
      variables.nextToken = nextToken;
    }

    const res = (await client.graphql({
      ...graphqlOperation(listEstatesBySearchInfo, variables),
      authMode: "lambda",
      authToken: await getSumoraAuthToken()
    })) as GraphQLResult<ListEstatesBySearchInfoQuery>;

    if (res.data?.listEstatesBySearchInfo) {
      items.push(
        ...res.data?.listEstatesBySearchInfo.items.map(
          (x) =>
            ({
              ...x,
              __typename: "Estate"
            } as Estate)
        )
      );
    }

    nextToken = res.data?.listEstatesBySearchInfo?.nextToken || null;
  }

  return items;
};

export const updateEstate = async (estate: Estate) => {
  const { __typename: _, ...input } = {
    ...estate,
    sold: undefined,
    priceHistory: undefined
  };
  const variables: UpdateEstateFMutationVariables = { input: input };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(updateEstateFMutation, variables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<UpdateEstateFMutation>;

  return res.data?.updateEstateF;
};

export const deleteEstate = async (estate: Estate) => {
  const variables: DeleteEstateFMutationVariables = {
    input: {
      id: estate.id,
      infoName: estate.infoName,
      companyId: estate.companyId
    }
  };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(deleteEstateFMutation, variables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<DeleteEstateFMutation>;

  return res.data?.deleteEstateF;
};

export const subscribeEstates = async (
  callback: (data: OnNotifyEstateCSubscription) => void
) => {
  const client = generateClient();
  const res = client.graphql({
    ...graphqlOperation(onNotifyEstateC),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  }) as GraphqlSubscriptionResult<OnNotifyEstateCSubscription>;

  const subscriber = res.subscribe({
    next: (
      eventData: GraphqlSubscriptionMessage<OnNotifyEstateCSubscription>
    ) => {
      const data = eventData.data;

      if (data?.onNotifyEstateC) {
        callback(data);
      }
    },
    error: (error) => {
      window.console.error(error);
    }
  });

  return subscriber;
};
