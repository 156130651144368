import { useAutoReducer } from "common/functions/reducers";
import {
  EstateFilterConditions,
  initialEstateFilterConditions
} from "common/interfaces/EstateFilterConditions";

export const useSearchFilterConditionStates = () => {
  const [
    filterConditions,
    initializeFilterConditions,
    forceFilterConditions,
    setFilterConditions,
    clearFilterConditions
  ] = useAutoReducer<EstateFilterConditions>(initialEstateFilterConditions);

  return {
    filterConditions,
    initializeFilterConditions,
    forceFilterConditions,
    setFilterConditions,
    clearFilterConditions
  } as const;
};

export type SearchFilterConditionStatesType = ReturnType<
  typeof useSearchFilterConditionStates
>;
