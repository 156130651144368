import { getUrl } from "aws-amplify/storage";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import {
  UseDistrictGeoJSON,
  UseDistrictProperty
} from "common/interfaces/UseDistrictGeoJSON";
import { invokeGetPresignedUrlForAnonymous } from "../api/s3";
import { pbfToGeoJson } from "./pbfToGeoJson";

export const getUseDistrictsGeoJSON = async (
  tileOnZoom20: string,
  forAnonymous: boolean
): Promise<UseDistrictGeoJSON | undefined> => {
  const coords = tileOnZoom20.split(",");
  const zoom = 20;

  const jsonKey = `public/json/use_districts/tiles/${zoom}/${coords.join(
    "/"
  )}.pbf`;
  const jsonUrl = await (forAnonymous
    ? invokeGetPresignedUrlForAnonymous(jsonKey, "unauthenticated")
    : getUrl({
        key: jsonKey,
        options: { accessLevel: "guest" }
      }).then((url) => url.url.href)
  ).catch((err) => undefined);

  if (jsonUrl) {
    const axios = setupCache(Axios.create());
    const response = await axios
      .get<ArrayBuffer>(jsonUrl, {
        responseType: "arraybuffer"
      })
      .catch((err) => undefined);

    if (response) {
      const geoJson = pbfToGeoJson<UseDistrictProperty>(
        response.data,
        Number(coords[0]),
        Number(coords[1]),
        zoom
      );

      return { ...geoJson } as UseDistrictGeoJSON;
    }
  }

  return undefined;
};
