import { StickyNote } from "API";
import { useState } from "react";

export const useSearchStickyNoteStates = () => {
  // メモデータ
  const [stickyNotes, setStickyNotes] = useState<StickyNote[] | undefined>(
    undefined
  );
  const [stickyNotesChangeDispatcher, setStickyNotesChangeDispatcher] =
    useState(false);
  const [stickyNotesCount, setStickyNotesCount] = useState(0);

  return {
    stickyNotes,
    setStickyNotes,
    stickyNotesChangeDispatcher,
    setStickyNotesChangeDispatcher,
    stickyNotesCount,
    setStickyNotesCount
  } as const;
};

export type SearchStickyNoteStatesType = ReturnType<
  typeof useSearchStickyNoteStates
>;
