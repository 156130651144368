import { Estate, Report } from "API";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { GroupEnum } from "common/enums/GroupEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { listEstates } from "common/functions/api/estates";
import { getDefaultCities } from "common/functions/jsonMasters/cities";
import {
  getDefaultPrefectures,
  getPrefectures
} from "common/functions/jsonMasters/prefectures";
import { City } from "common/interfaces/City";
import { Prefecture } from "common/interfaces/Prefecture";
import { MinimalEstate } from "common/queries/minimalEstates";
import { MainContextContainer } from "components/Main";
import { EstateLocationInputMode } from "components/Parts/Organisms/EstateLocationInputDialog/enums";
import { useCityHooks } from "hooks/cityHooks";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CheckEstatesCondition } from "../../../../common/interfaces/CheckEstatesCondition";
import { ReportInputMode } from "../ReportInputDialog/enums";
import { estateTypeNumber, statusNumber } from "./functions";

export const useCheckEstatesHooks = () => {
  const initSearchRef = useRef(true);

  const mainContext = useContext(MainContextContainer);

  const [condition, setCondition] = useState<CheckEstatesCondition>({
    mode: SearchModeEnum.all,
    statuses: [StatusNameEnum.位置不明],
    estateTypes: [
      EstateTypeNameEnum.土地,
      EstateTypeNameEnum.分譲地,
      EstateTypeNameEnum.新築建売,
      EstateTypeNameEnum.中古戸建,
      EstateTypeNameEnum.マンション,
      EstateTypeNameEnum.事業用
    ],
    prefs: [],
    cities: [],
    area: null,
    company: mainContext.company
  });

  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const { cities } = useCityHooks(condition.prefs, mainContext.setFatal);

  const [openModeSelectDialog, setOpenModeSelectDialog] = useState(false);
  const [openStatusSelectDialog, setOpenStatusSelectDialog] = useState(false);
  const [openEstateTypeSelectDialog, setOpenEstateTypeSelectDialog] =
    useState(false);
  const [openSwitchAreaDialog, setOpenSwitchAreaDialog] = useState(false);

  const [estates, setEstates] = useState<MinimalEstate[]>([]);

  const [clickedEstateForLocation, setClickedEstateForLocation] = useState<
    MinimalEstate | undefined
  >(undefined);
  const [inputModeForLocation, setInputModeForLocation] = useState<
    "update" | "delete" | undefined
  >(undefined);

  const [clickedEstateForReport, setClickedEstateForReport] = useState<
    MinimalEstate | undefined
  >(undefined);
  const [inputModeForReport, setInputModeForReport] = useState<
    "report" | undefined
  >(undefined);

  const [busy, setBusy] = useState(false);

  const setDefaultPrefectures = useCallback(() => {
    const defaultPrefectures = getDefaultPrefectures(
      prefectures,
      mainContext.company?.areas
    );

    if (defaultPrefectures) {
      setCondition((condition) => ({
        ...condition,
        prefs: defaultPrefectures
      }));
    }
  }, [mainContext.company?.areas, prefectures]);

  const setDefaultCities = useCallback(() => {
    const defaultCities = getDefaultCities(cities, mainContext.company?.areas);

    if (defaultCities) {
      setCondition((condition) => ({
        ...condition,
        cities: defaultCities
      }));

      initSearchRef.current = false;
    }
  }, [cities, mainContext.company?.areas]);

  const onModeClick = useCallback(() => {
    setOpenModeSelectDialog(true);
  }, []);

  const onModeSelectDialogClose = useCallback(() => {
    setOpenModeSelectDialog(false);
  }, []);

  const onModeSelectDialogOkClick = useCallback(
    (mode: SearchModeEnum.all | SearchModeEnum.domain) => {
      setCondition((condition) => ({
        ...condition,
        mode
      }));
      setOpenModeSelectDialog(false);
    },
    []
  );

  const onStatusesClick = useCallback(() => {
    setOpenStatusSelectDialog(true);
  }, []);

  const onStatusSelectDialogClose = useCallback(() => {
    setOpenStatusSelectDialog(false);
  }, []);

  const onStatusSelectDialogOkClick = useCallback(
    (statuses: StatusNameEnum[]) => {
      setCondition((condition) => ({ ...condition, statuses }));
      setOpenStatusSelectDialog(false);
    },
    []
  );

  const onEstateTypesClick = useCallback(() => {
    setOpenEstateTypeSelectDialog(true);
  }, []);

  const onEstateTypeSelectDialogClose = useCallback(() => {
    setOpenEstateTypeSelectDialog(false);
  }, []);

  const onEstateTypeSelectDialogOkClick = useCallback(
    (estateTypes: EstateTypeNameEnum[]) => {
      setCondition((condition) => ({ ...condition, estateTypes }));
      setOpenEstateTypeSelectDialog(false);
    },
    []
  );

  const onCitiesClick = useCallback(() => {
    setOpenSwitchAreaDialog(true);
  }, []);

  const onSwitchAreaDialogClose = useCallback(() => {
    setOpenSwitchAreaDialog(false);
  }, []);

  const onSwitchAreaDialogOkClick = useCallback(
    (selectedPrefectures: Prefecture[], selectedCities: City[]) => {
      setCondition((condition) => ({
        ...condition,
        prefs: selectedPrefectures,
        cities: selectedCities
      }));
      setOpenSwitchAreaDialog(false);
    },
    []
  );

  const onAreaChange = useCallback((area: string | null | undefined) => {
    setCondition((condition) => ({ ...condition, area }));
  }, []);

  const onSearchClick = useCallback(async () => {
    try {
      setBusy(true);

      const validatedCondition: CheckEstatesCondition = {
        ...condition,
        statuses: condition.statuses.includes(StatusNameEnum.位置不明)
          ? [
              ...condition.statuses.filter(
                (x) => x !== StatusNameEnum.位置不明
              ),
              StatusNameEnum.下書き
            ]
          : condition.statuses,
        company: mainContext.company
      };

      const getEstates = await listEstates(
        validatedCondition,
        mainContext.groups?.includes(GroupEnum.Administrator) ?? false
      );

      setEstates(
        getEstates.sort(
          (a, b) =>
            estateTypeNumber(a) - estateTypeNumber(b) ||
            statusNumber(a) - statusNumber(b) ||
            Number((a.id ?? "").replace("R-", "")) -
              Number((b.id ?? "").replace("R-", ""))
        )
      );
    } finally {
      setBusy(false);
    }
  }, [condition, mainContext.company, mainContext.groups]);

  const onEstateClick = useCallback(
    (estate: MinimalEstate, mode: "update" | "delete" | "report") => {
      if (mode === "report") {
        setClickedEstateForReport(estate);
        setInputModeForReport(mode);
      } else {
        setClickedEstateForLocation(estate);
        setInputModeForLocation(mode);
      }
    },
    [setClickedEstateForLocation, setInputModeForLocation]
  );

  const onEstatesChange = useCallback(
    (
      changedEstates: {
        estate: Estate;
        mode: "create" | "update" | "delete" | null;
      }[]
    ) => {
      changedEstates.forEach((changedEstate) => {
        const currentEstates = [...estates];
        const operatedEstateIndex = currentEstates.findIndex(
          (x) => x.id === changedEstate.estate.id
        );
        switch (changedEstate.mode) {
          case "delete":
            setEstates((estates) =>
              estates.filter((x) => x.id !== changedEstate.estate.id).slice()
            );
            break;
          case "update":
            setEstates((estates) => {
              const newEstates = estates.slice();

              newEstates[operatedEstateIndex] = {
                ...newEstates[operatedEstateIndex],
                ...changedEstate.estate,
                clickedCount: 0,
                blocks: []
              };

              return newEstates;
            });
            break;
        }
      });
    },
    [estates]
  );

  const onLocationInputDialogOkButtonClick = useCallback(
    (mode: EstateLocationInputMode, inputValues: Estate | null) => {
      if (inputValues) {
        onEstatesChange([
          {
            estate: inputValues,
            mode:
              mode === EstateLocationInputMode.Update
                ? "update"
                : mode === EstateLocationInputMode.Delete
                ? "delete"
                : null
          }
        ]);
      }
    },
    [onEstatesChange]
  );

  const onReportInputDialogOkButtonClick = useCallback(
    (mode: ReportInputMode, inputValues: Report | null) => {},
    []
  );

  const onInputClose = useCallback(() => {
    setClickedEstateForLocation(undefined);
    setInputModeForLocation(undefined);
    setClickedEstateForReport(undefined);
    setInputModeForReport(undefined);
  }, []);

  useEffect(() => {
    getPrefectures(false).then((prefectures) => {
      if (prefectures) {
        setPrefectures(prefectures);
      }
    });
  }, []);

  useEffect(() => {
    if (
      initSearchRef.current &&
      mainContext.company &&
      prefectures.length > 0
    ) {
      setDefaultPrefectures();
    }
  }, [mainContext.company, prefectures, setDefaultPrefectures]);

  useEffect(() => {
    if (initSearchRef.current && mainContext.company && cities.length > 0) {
      setDefaultCities();
    }
  }, [cities, mainContext.company, setDefaultCities]);

  return {
    mainContext,

    condition,
    openModeSelectDialog,
    openStatusSelectDialog,
    openEstateTypeSelectDialog,
    openSwitchAreaDialog,

    estates,

    clickedEstateForLocation,
    inputModeForLocation,

    clickedEstateForReport,
    inputModeForReport,

    busy,

    onModeClick,
    onModeSelectDialogClose,
    onModeSelectDialogOkClick,

    onStatusesClick,
    onStatusSelectDialogClose,
    onStatusSelectDialogOkClick,

    onEstateTypesClick,
    onEstateTypeSelectDialogClose,
    onEstateTypeSelectDialogOkClick,

    onCitiesClick,
    onSwitchAreaDialogClose,
    onSwitchAreaDialogOkClick,

    onAreaChange,

    onSearchClick,

    onEstateClick,
    onLocationInputDialogOkButtonClick,
    onReportInputDialogOkButtonClick,
    onInputClose
  } as const;
};
