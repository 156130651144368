import { useCallback, useEffect, useState } from "react";
import { ConfirmProps } from "./interfaces";

export const useConfirmHooks = (props: ConfirmProps) => {
  const [busy, setBusy] = useState(false);

  const onOkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // OKボタンクリック時
      setBusy(true);
    },
    []
  );

  useEffect(() => {
    if (busy) {
      props.onOkClick({} as React.MouseEvent<HTMLButtonElement, MouseEvent>);
      setBusy(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busy]);

  return { busy, onOkClick } as const;
};
