import { agreeCognitoUser } from "common/functions/api/users";
import { handleError } from "common/functions/utilities";
import { useCallback, useState } from "react";

export const useUserHooks = (
  username: string | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const [busy, setBusy] = useState(false);

  const agree = useCallback(async () => {
    setBusy(true);

    const value = await agreeCognitoUser(username || "", true).catch(
      (reason) => {
        handleError(reason, setFatal);
      }
    );

    setBusy(false);

    return value;
  }, [setFatal, username]);

  return {
    busy,
    agree
  } as const;
};
