import { Box } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import styles from "../SearchMenu.module.scss";

export const OftenMenuButton = memo(
  (props: {
    title: string;
    imgSrc: string;
    additionalClassName: string;
    status: boolean;
    input: JSX.Element;
    onStatusChange: (on: boolean) => void;
    onClick: () => void;
  }) => {
    const [switchClassName, setSwitchClassName] = useState<string>("");

    const onClick = useCallback(() => {
      if (props.onStatusChange) {
        props.onStatusChange(!props.status);
      }

      if (props.onClick) {
        props.onClick();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onStatusChange, props.onClick, props.status]);

    useEffect(
      () =>
        setSwitchClassName((prev) =>
          prev === "" && !props.status
            ? prev
            : props.status
            ? styles.on
            : styles.off
        ),
      [props.status]
    );

    return (
      <Box
        component="div"
        className={`${styles.btn} ${props.additionalClassName} ${switchClassName}`}
      >
        <Box component="figure" className={switchClassName} onClick={onClick}>
          <Box component="img" src={props.imgSrc} alt={props.title} />
        </Box>
        <Box component="div" className={styles.txbox}>
          <Box component="div">{props.input}</Box>
        </Box>
      </Box>
    );
  }
);
