import { PriorityHigh } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import React from "react";
import styles from "./NoticeDialog.module.scss";
import { NoticeDialogProps } from "./interfaces";

export const NoticeDialog = React.memo((props: NoticeDialogProps) => {
  return (
    <>
      <Dialog
        style={props.style}
        className={props.className}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="notice-title"
        aria-describedby="notice-description"
        fullWidth
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{ backdrop: { className: styles.backdrop } }}
        >
        <DialogHeader id="notice-title" icon={<PriorityHigh />} />
        <DialogContent id="notice-description">{props.content}</DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            variant="contained"
            className={styles.okButton}
            color="warning"
            onClick={props.onCloseClick}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
