import { Auth } from "components/Parts/Organisms/Auth";
import { HomePage } from "components/Parts/Organisms/HomePage";
import { HomeProps } from "components/Parts/Organisms/HomePage/interfaces";
import React from "react";

export const Home = React.memo((props: HomeProps) => {
  return (
    <Auth>
      <HomePage {...props} />
    </Auth>
  );
});
