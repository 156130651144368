import { dateMax, dateMin } from "common/consts/estates";
import { TokyoCoords } from "common/consts/googleMaps";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { fromDt, toDt } from "common/functions/estates";
import {
  getCurrentPosition,
  incrementWithCycle
} from "common/functions/utilities";
import { EstateFilterConditions } from "common/interfaces/EstateFilterConditions";
import { EstateSearchConditions } from "common/interfaces/EstateSearchConditions";
import { MainContext } from "common/interfaces/MainContext";
import { MinimalEstate } from "common/queries/minimalEstates";
import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { SearchEstateMethodsType } from "./hooks/methods/estates";
import { SearchBehaviorStatesType } from "./hooks/states/behaviors";
import { SearchDialogStatesType } from "./hooks/states/dialogs";
import { SearchEstateSearchConditionStatesType } from "./hooks/states/estateSearchConditions";
import { SearchEstateStatesType } from "./hooks/states/estates";
import { SearchFilterConditionStatesType } from "./hooks/states/filterConditions";
import { SearchMapStatesType } from "./hooks/states/maps";
import { SearchMasterStatesType } from "./hooks/states/masters";

export const useMenuHooks = (
  searchMode: SearchModeEnum,
  mainContext: MainContext,
  masterStates: SearchMasterStatesType,
  estateSearchConditionStates: SearchEstateSearchConditionStatesType,
  filterConditionStates: SearchFilterConditionStatesType,
  mapStates: SearchMapStatesType,
  behaviorStates: SearchBehaviorStatesType,
  dialogStates: SearchDialogStatesType,
  estateStates: SearchEstateStatesType,
  defaultFilterConditions: EstateFilterConditions,
  searchEstateMethods: SearchEstateMethodsType
) => {
  const switchStatus = useMemo(() => {
    const cities = [
      ...estateSearchConditionStates.estateSearchConditions.cities.map(
        (x) => x.city_code
      )
    ];
    const defaultCities = [...(mainContext.company?.areas || [])];

    cities.sort();
    defaultCities.sort();

    return !isEqual(cities, defaultCities);
  }, [
    estateSearchConditionStates.estateSearchConditions.cities,
    mainContext.company?.areas
  ]);

  const [
    landStatus,
    blockStatus,
    newStatus,
    usedStatus,
    apartStatus,
    businessStatus
  ] = useMemo(() => {
    return Object.entries(EstateTypeNameEnum).map(([_, value]) =>
      filterConditionStates.filterConditions.estateTypes.some(
        (x) => x.name === value
      )
    );
  }, [filterConditionStates.filterConditions.estateTypes]);

  const newEstateStatus = useMemo(
    () =>
      filterConditionStates.filterConditions.createdAtFrom !== dateMin ||
      filterConditionStates.filterConditions.createdAtTo !== dateMax,
    [
      filterConditionStates.filterConditions.createdAtFrom,
      filterConditionStates.filterConditions.createdAtTo
    ]
  );

  const updatePriceStatus = useMemo(
    () =>
      filterConditionStates.filterConditions.pricedAtFrom !== dateMin ||
      filterConditionStates.filterConditions.pricedAtTo !== dateMax,
    [
      filterConditionStates.filterConditions.pricedAtFrom,
      filterConditionStates.filterConditions.pricedAtTo
    ]
  );

  const filterStatus = useMemo(() => {
    const sanitizedFilterConditions = {
      ...filterConditionStates.filterConditions,
      estateTypes: [],
      createdAtFrom: dateMin,
      createdAtTo: dateMax,
      pricedAtFrom: dateMin,
      pricedAtTo: dateMax
    } as EstateFilterConditions;

    const sanitizedDefaultFilterConditions = {
      ...defaultFilterConditions,
      estateTypes: [],
      createdAtFrom: dateMin,
      createdAtTo: dateMax,
      pricedAtFrom: dateMin,
      pricedAtTo: dateMax
    } as EstateFilterConditions;

    sanitizedFilterConditions.bldConditionSelections.sort(
      (a, b) => (a.id || 0) - (b.id || 0)
    );

    sanitizedDefaultFilterConditions.bldConditionSelections.sort(
      (a, b) => (a.id || 0) - (b.id || 0)
    );

    sanitizedFilterConditions.purposeSummaryBs.sort(
      (a, b) => (a.id || 0) - (b.id || 0)
    );

    sanitizedDefaultFilterConditions.purposeSummaryBs.sort(
      (a, b) => (a.id || 0) - (b.id || 0)
    );

    return !isEqual(
      sanitizedFilterConditions,
      sanitizedDefaultFilterConditions
    );
  }, [defaultFilterConditions, filterConditionStates.filterConditions]);

  const onOpenStatusChange = useCallback(
    (on: boolean) => behaviorStates.setOpenMenu(on),
    [behaviorStates]
  );

  const onPlacesChange = useCallback(
    (places: google.maps.places.PlaceResult) => mapStates.setPlaces(places),
    [mapStates]
  );

  const onEstateChange = useCallback(
    (estate: MinimalEstate | null | undefined) => {
      if (estate) {
        mapStates.setZoom(18);
        mapStates.setCenter({
          lat:
            estate.latitude || mainContext.company?.latitude || TokyoCoords.lat,
          lng:
            estate.longitude ||
            mainContext.company?.longitude ||
            TokyoCoords.lng
        });
      }

      estateStates.setSelectedEstateOnAutoComplete(estate ?? undefined);
    },
    [
      estateStates,
      mainContext.company?.latitude,
      mainContext.company?.longitude,
      mapStates
    ]
  );

  const onSwitchClick = useCallback(() => {
    estateSearchConditionStates.clearEstateSearchConditions.bound();
    estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom12();
    estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom14();
    estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom16();

    dialogStates.setOpenSwitchAreaDialog(true);
    behaviorStates.setOpenMenu(false);
  }, [
    behaviorStates,
    dialogStates,
    estateSearchConditionStates.clearEstateSearchConditions
  ]);

  const onNewEstateStatusChange = useCallback(() => {
    if (newEstateStatus) {
      filterConditionStates.clearFilterConditions.createdAtFrom();
      filterConditionStates.clearFilterConditions.createdAtTo();
    } else {
      filterConditionStates.setFilterConditions.createdAtFrom(
        new Date(fromDt()).getTime()
      );
      filterConditionStates.setFilterConditions.createdAtTo(
        new Date(toDt()).getTime()
      );
    }
  }, [
    filterConditionStates.clearFilterConditions,
    filterConditionStates.setFilterConditions,
    newEstateStatus
  ]);

  const onUpdatePriceStatusChange = useCallback(() => {
    if (updatePriceStatus) {
      filterConditionStates.clearFilterConditions.pricedAtFrom();
      filterConditionStates.clearFilterConditions.pricedAtTo();
    } else {
      filterConditionStates.setFilterConditions.pricedAtFrom(
        new Date(fromDt()).getTime()
      );
      filterConditionStates.setFilterConditions.pricedAtTo(
        new Date(toDt()).getTime()
      );
    }
  }, [
    filterConditionStates.clearFilterConditions,
    filterConditionStates.setFilterConditions,
    updatePriceStatus
  ]);

  const onDisplayStatusChange = useCallback(() => {
    const newBound = !estateSearchConditionStates.estateSearchConditions.bound;

    estateSearchConditionStates.setEstateSearchConditions.bound(newBound);

    if (!newBound) {
      estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom12();
      estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom14();
      estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom16();

      const conditions: EstateSearchConditions = {
        ...estateSearchConditionStates.estateSearchConditions,
        tilesOnZoom12: [],
        tilesOnZoom14: [],
        tilesOnZoom16: []
      };

      searchEstateMethods.searchEstate(
        searchMode,
        conditions,
        false,
        false,
        false
      );
    }
  }, [
    estateSearchConditionStates.clearEstateSearchConditions,
    estateSearchConditionStates.estateSearchConditions,
    estateSearchConditionStates.setEstateSearchConditions,
    searchEstateMethods,
    searchMode
  ]);

  const onEstateTypeStatusChange = useCallback(
    (on: boolean, estateType: EstateTypeNameEnum) => {
      const estateTypes = [
        ...filterConditionStates.filterConditions.estateTypes
      ];

      if (on) {
        const newEstateType = masterStates.estateTypes.find(
          (x) => x.name === estateType
        );

        if (newEstateType && !estateTypes.some((x) => x.name === estateType)) {
          estateTypes.push(newEstateType);
        }
      } else {
        const deleteIndex = estateTypes.findIndex((x) => x.name === estateType);

        if (deleteIndex !== -1) {
          estateTypes.splice(deleteIndex, 1);
        }
      }

      filterConditionStates.setFilterConditions.estateTypes(estateTypes);
    },
    [
      filterConditionStates.filterConditions.estateTypes,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onPriceStatusChange = useCallback(
    () => mapStates.setDspPrice((value) => !value),
    [mapStates]
  );

  const onSizeStatusChange = useCallback(
    () => mapStates.setDspTsuboArea((value) => !value),
    [mapStates]
  );

  const onFilterClick = useCallback(() => {
    if (!dialogStates.showEstateFilterDialog) {
      // setOpenMenu(false);
      dialogStates.setEstateFilterDialogSign(incrementWithCycle);
    }

    dialogStates.setShowEstateFilterDialog((value) => !value);
  }, [dialogStates]);

  const onHistoryStatusChange = useCallback(() => {
    behaviorStates.setClosedMode((x) => !x);
    behaviorStates.setSoldMode((x) => !x);
  }, [behaviorStates]);

  const onEyeStatusChange = useCallback(
    () => behaviorStates.setSaleMode((x) => !x),
    [behaviorStates]
  );

  const onSyoStatusChange = useCallback(
    () => mapStates.setDspESchools((value) => !value),
    [mapStates]
  );

  const onChuStatusChange = useCallback(
    () => mapStates.setDspJSchools((value) => !value),
    [mapStates]
  );

  const onMountainStatusChange = useCallback(
    () => mapStates.setDspDoshasaigai((value) => !value),
    [mapStates]
  );

  const onFloodStatusChange = useCallback(
    () => mapStates.setDspKouzui((value) => !value),
    [mapStates]
  );

  const onWaveStatusChange = useCallback(
    () => mapStates.setDspTsunami((value) => !value),
    [mapStates]
  );

  const onHighTideStatusChange = useCallback(
    () => mapStates.setDspTakashio((value) => !value),
    [mapStates]
  );

  const onUsageStatusChange = useCallback(
    () => mapStates.setDspYouto((value) => !value),
    [mapStates]
  );

  const onKozuStatusChange = useCallback(
    () => mapStates.setDspMoj((value) => !value),
    [mapStates]
  );

  const onMeasureStatusChange = useCallback(() => {
    if (!mapStates.measureLengthMode) {
      behaviorStates.setOpenMenu(false);
    }

    mapStates.setMeasureLengthMode((value) => !value);
  }, [behaviorStates, mapStates]);

  const onSquareStatusChange = useCallback(() => {
    if (!mapStates.measureAreaMode) {
      behaviorStates.setOpenMenu(false);
    }

    mapStates.setMeasureAreaMode((value) => !value);
  }, [behaviorStates, mapStates]);

  const onNaviStatusChange = useCallback(() => {
    if (!mapStates.searchRouteMode) {
      behaviorStates.setOpenMenu(false);
      behaviorStates.setEditMode(false);
    }

    mapStates.setSearchRouteMode((value) => !value);
  }, [behaviorStates, mapStates]);

  const onShopStatusChange = useCallback(
    () => mapStates.setDspPoi((value) => !value),
    [mapStates]
  );

  const onMemoStatusChange = useCallback(() => {
    if (!behaviorStates.stickyNoteMode) {
      behaviorStates.setOpenMenu(false);
    }

    behaviorStates.setStickyNoteMode((value) => !value);
  }, [behaviorStates]);

  const onGpsClick = useCallback(() => {
    const map = mapStates.map;

    if (map) {
      const center = map.getCenter();

      const lat = center?.lat();
      const lng = center?.lng();

      if (lat !== undefined && lng !== undefined) {
        mapStates.setBusyForMove(true);

        getCurrentPosition({ lat: lat, lng: lng })
          .then((currentPos) => {
            if (currentPos.success) {
              const pos = {
                lat: currentPos.position.lat,
                lng: currentPos.position.lng
              };

              mapStates.setCurrentPosition(pos);
            }
          })
          .finally(() => {
            mapStates.setBusyForMove(false);
          });
      }
    }
  }, [mapStates]);

  return {
    switchStatus,

    landStatus,
    blockStatus,
    newStatus,
    usedStatus,
    apartStatus,
    businessStatus,

    newEstateStatus,
    updatePriceStatus,

    filterStatus,

    onOpenStatusChange,

    onPlacesChange,
    onEstateChange,

    onSwitchClick,
    onNewEstateStatusChange,
    onUpdatePriceStatusChange,
    onDisplayStatusChange,

    onEstateTypeStatusChange,

    onPriceStatusChange,
    onSizeStatusChange,
    onFilterClick,
    onHistoryStatusChange,
    onEyeStatusChange,

    onSyoStatusChange,
    onChuStatusChange,
    onMountainStatusChange,
    onFloodStatusChange,
    onWaveStatusChange,
    onHighTideStatusChange,
    onUsageStatusChange,
    onKozuStatusChange,

    onMeasureStatusChange,
    onSquareStatusChange,
    onNaviStatusChange,
    onShopStatusChange,
    onMemoStatusChange,
    onGpsClick
  } as const;
};
