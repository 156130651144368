import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  AgreeCognitoUserFMutation,
  AgreeCognitoUserFMutationVariables,
  GetUserQuery
} from "API";
import { generateClient } from "aws-amplify/api";
import { InfoNameEnum } from "common/enums/InfoNameEnum";
import { agreeCognitoUserF as agreeCognitoUserFMutation } from "graphql/mutations";
import { getUser as getUserQuery } from "graphql/queries";
import { getSumoraAuthToken } from "../credentials";

export const getUser = async (
  username: string | undefined
): Promise<GetUserQuery | undefined> => {
  if (username) {
    const client = generateClient();
    const userData = (await client.graphql({
      ...graphqlOperation(getUserQuery, {
        username: username,
        infoName: InfoNameEnum.master
      }),
      authMode: "lambda",
      authToken: await getSumoraAuthToken()
    })) as GraphQLResult<GetUserQuery>;

    return userData.data;
  }
};

export const agreeCognitoUser = async (
  username: string,
  agreement: boolean
) => {
  const variables: AgreeCognitoUserFMutationVariables = {
    username: username || "",
    agreement: agreement || false
  };

  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(agreeCognitoUserFMutation, variables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<AgreeCognitoUserFMutation>;

  if (res.data?.agreeCognitoUserF?.errorMessage) {
    throw res.data?.agreeCognitoUserF.errorMessage;
  }

  if (
    res?.data?.agreeCognitoUserF?.errorMessage &&
    res?.data?.agreeCognitoUserF?.errorMessage !== ""
  ) {
    throw res.data?.agreeCognitoUserF?.errorMessage;
  }

  return res.data?.agreeCognitoUserF;
};
