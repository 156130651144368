import { VectorTile } from "@mapbox/vector-tile";
import { GeoJSON, GeoJSONFeature } from "common/interfaces/GeoJSON";
import { range } from "lodash";
import Pbf from "pbf";

export const pbfToGeoJson = <T>(
  data: ArrayBuffer,
  x: number,
  y: number,
  zoom: number
) => {
  const pbf = new Pbf(data);

  const tile = new VectorTile(pbf);

  const geoJson = {
    type: "FeatureCollection",
    features: []
  } as GeoJSON<T>;

  for (const layer of Object.values(tile.layers) ?? []) {
    for (const index of range(0, layer.length)) {
      const feature = layer.feature(index);

      const featureGeoJson = feature.toGeoJSON(x, y, zoom);

      geoJson.features.push(featureGeoJson as GeoJSONFeature<T>);
    }
  }

  return geoJson;
};
