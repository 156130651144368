import { getSchoolPoints } from "common/functions/geoJsons/schoolPoints";
import { handleError } from "common/functions/utilities";
import { SchoolArea } from "common/interfaces/SchoolArea";
import { SchoolPoint } from "common/interfaces/SchoolPoint";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useSchoolPointHooks = (
  schoolAreas: SchoolArea[],
  setFatal: (error?: string | null | undefined) => void
) => {
  const [schoolPoints, setSchoolPoints] = useState<SchoolPoint[]>([]);

  const schoolPointParameterKeys = [
    ...new Set(
      schoolAreas.flatMap((x) =>
        x.features.map(
          (feature) =>
            `${feature.properties.pref_code}/${feature.properties.city_code}/${feature.properties.school_kind}`
        )
      )
    )
  ];

  const schoolPointParameters = schoolPointParameterKeys.map((x) => {
    const parameters = x.split("/");

    return {
      prefCode: parameters[0],
      cityCode: parameters[1],
      schoolKind: Number(parameters[2])
    };
  });

  const { data, error } = useSWR(
    `school of ${schoolPointParameterKeys.join(", ")}`,
    () => getSchoolPoints(schoolPointParameters)
  );

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setSchoolPoints(data);
    }
  }, [data]);

  return { schoolPoints, setSchoolPoints } as const;
};
