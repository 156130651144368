import { Company } from "API";
import { getCompany as invokeGetCompany } from "common/functions/api/companies";
import { useAutoReducer } from "common/functions/reducers";
import { handleError } from "common/functions/utilities";
import { useEffect } from "react";
import useSWR from "swr";

export const useCompanyQueryHooks = (
  companyId: string | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const [company, initializeCompany, forceCompany, setCompany] =
    useAutoReducer<Company>({} as Company);

  const { data, error } = useSWR(`company of ${companyId}`, () =>
    // 業者取得
    invokeGetCompany(companyId).then((value) => {
      if (value?.getCompany) {
        return {
          ...value.getCompany,
          __typename: "Company"
        } as Company;
      }

      return null;
    })
  );

  useEffect(() => {
    if (error) {
      handleError(
        error,
        setFatal,
        `業者(${companyId})を取得できませんでした。`
      );
    }
  }, [companyId, error, setFatal]);

  useEffect(() => {
    if (data) {
      forceCompany(data);
    }
  }, [data, forceCompany]);

  return {
    company,
    initializeCompany,
    forceCompany,
    setCompany
  } as const;
};
