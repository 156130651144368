import { MinimalEstate } from "common/queries/minimalEstates";
import { useRef, useState } from "react";
import { Subscription } from "zen-observable-ts";

export const useSearchEstateStates = () => {
  // 物件データ
  const [estates, setEstates] = useState<MinimalEstate[] | undefined>(
    undefined
  );
  const [estatesCount, setEstatesCount] = useState(0);
  const [estatesTotalCount, setEstatesTotalCount] = useState(0);

  const [filteredPublicEstates, setFilteredPublicEstates] = useState<
    MinimalEstate[] | undefined
  >(undefined);
  const [filteredPublicEstatesCount, setFilteredPublicEstatesCount] =
    useState(0);

  const [filteredClosedEstates, setFilteredClosedEstates] = useState<
    MinimalEstate[] | undefined
  >(undefined);
  const [filteredClosedEstatesCount, setFilteredClosedEstatesCount] =
    useState(0);

  const [soldEstates, setSoldEstates] = useState<MinimalEstate[] | undefined>(
    undefined
  );
  const [soldEstatesCount, setSoldEstatesCount] = useState(0);

  const [selectedEstateOnAutoComplete, setSelectedEstateOnAutoComplete] =
    useState<MinimalEstate | undefined>(undefined);

  const estateSubscriber = useRef<Subscription | undefined>(undefined);

  const [notifiedEstate, setNotifiedEstate] = useState<MinimalEstate | null>(
    null
  );

  return {
    estates,
    setEstates,
    estatesCount,
    setEstatesCount,
    estatesTotalCount,
    setEstatesTotalCount,

    filteredPublicEstates,
    setFilteredPublicEstates,
    filteredPublicEstatesCount,
    setFilteredPublicEstatesCount,

    filteredClosedEstates,
    setFilteredClosedEstates,
    filteredClosedEstatesCount,
    setFilteredClosedEstatesCount,

    soldEstates,
    setSoldEstates,
    soldEstatesCount,
    setSoldEstatesCount,

    selectedEstateOnAutoComplete,
    setSelectedEstateOnAutoComplete,

    estateSubscriber,

    notifiedEstate,
    setNotifiedEstate
  } as const;
};

export type SearchEstateStatesType = ReturnType<typeof useSearchEstateStates>;
