import {
  AutocompleteFreeSoloValueMapping,
  AutocompleteValue
} from "@mui/material";
import { Prefecture } from "common/interfaces/Prefecture";
import { usePrefectureHooks } from "hooks/prefectureHooks";
import { useReferenceStyles } from "hooks/referenceStyles";
import { useCallback, useEffect } from "react";
import { invokeConvertValue } from "../JsonMasterAutocomplete/utils";

export const usePrefectureAutocompleteHooks = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  multiple: boolean | undefined,
  setFatal: ((error?: string | null | undefined) => void) | undefined,
  onOptionsLoad: ((options: Prefecture[]) => void) | undefined
) => {
  const { prefectures, setPrefectures } = usePrefectureHooks(setFatal);

  const referenceStyles = useReferenceStyles();

  const getOptionLabel = useCallback(
    (option: Prefecture | AutocompleteFreeSoloValueMapping<FreeSolo>) => {
      if (typeof option === "string") {
        return option as string;
      }

      return (option as Prefecture).pref_name || "？";
    },
    []
  );

  const getOptionSelected = useCallback(
    (option: Prefecture, value: Prefecture): boolean => {
      return option.pref_code === value.pref_code;
    },
    []
  );

  const convertValue = useCallback(
    (
      value:
        | AutocompleteValue<Prefecture, Multiple, DisableClearable, FreeSolo>
        | string
        | string[]
        | undefined
    ) => {
      return invokeConvertValue(multiple, prefectures, value);
    },
    [prefectures, multiple]
  );

  useEffect(() => {
    if (onOptionsLoad) {
      onOptionsLoad(prefectures);
    }
  }, [prefectures, onOptionsLoad]);

  return {
    prefectures,
    setPrefectures,
    referenceStyles,
    getOptionLabel,
    getOptionSelected,
    convertValue
  } as const;
};
