import { useCallback, useEffect, useRef, useState } from "react";
import { BackgroundMapProps } from "./interfaces";

export const useBackgroundMapHooks = (props: BackgroundMapProps) => {
  const initRef = useRef(true);
  const backgroundMoveTimer = useRef<NodeJS.Timeout | null>(null);
  const count = useRef(0);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  // APIロード完了時
  const onGoogleApiLoaded = useCallback(
    (maps: {
      map: google.maps.Map | null | undefined;
      maps: any;
      ref: Element | null;
    }) => {
      if (maps.map) {
        setMap(maps.map);

        props.onMapReady(maps.map);
      }

      window.onunload = () => {
        if (backgroundMoveTimer.current) {
          clearInterval(backgroundMoveTimer.current);
        }

        count.current = 0;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Map破棄
  const onDestruct = useCallback(() => {
    if (map) {
      google.maps.event.clearInstanceListeners(map);
    }

    if (backgroundMoveTimer.current) {
      clearInterval(backgroundMoveTimer.current);
    }
  }, [map]);

  // 位置設定時
  const onLocationChange = useCallback(
    (location: google.maps.LatLngLiteral | null) => {
      if (map) {
        if (location) {
          const lat = location?.lat;
          const lng = location?.lng;

          if (map && lat !== undefined && lng !== undefined) {
            const pos = {
              lat: lat,
              lng: lng
            };

            map.setCenter(pos);

            map.setZoom(12);

            if (backgroundMoveTimer.current) {
              clearInterval(backgroundMoveTimer.current);
            }

            count.current = 0;

            const ratio = props.moveSpeedRatio || 1;
            const move = ratio;
            const moveHalf = 0.5 * ratio;

            backgroundMoveTimer.current = setInterval(() => {
              if (count.current < 300) {
                map.panBy(move, -moveHalf);
              } else if (count.current === 300) {
                map.setZoom(13);
              } else if (count.current > 300 && count.current < 600) {
                map.panBy((moveHalf * 13) / 12, (move * 13) / 12);
              } else if (count.current === 600) {
                map.setZoom(14);
              } else if (count.current > 600 && count.current < 900) {
                map.panBy((-move * 14) / 12, (moveHalf * 14) / 12);
              } else if (count.current === 900) {
                map.setZoom(13);
              } else if (count.current > 900 && count.current < 1200) {
                map.panBy((-moveHalf * 13) / 12, (-move * 13) / 12);
              } else if (count.current === 1200) {
                map.setZoom(12);
                count.current = 0;
              }

              count.current++;
            }, 100);
          }
        }
      }
    },
    [map, props.moveSpeedRatio]
  );

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }

    return onDestruct;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onLocationChange(props.location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location, map]);

  return { onGoogleApiLoaded } as const;
};
