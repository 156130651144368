import { DrawingColor } from "common/interfaces/DrawingColor";

// 洪水浸水想定区域カラーチャート
export const kouzuiColors: DrawingColor[] = [
  {
    code: 1,
    name: "0.3m未満",
    strokeColor: "#cccccc",
    fillColor: "#ffffbc"
  },
  {
    code: 2,
    name: "0.5m未満",
    strokeColor: "#cccccc",
    fillColor: "#f7f5b2"
  },
  {
    code: 3,
    name: "0.5m以上 〜 1.0m未満",
    strokeColor: "#cccccc",
    fillColor: "#f4e2ad"
  },
  {
    code: 4,
    name: "0.5m以上 〜 3.0m未満",
    strokeColor: "#cccccc",
    fillColor: "#f9d9c3"
  },
  {
    code: 5,
    name: "3.0m以上 〜 5.0m未満",
    strokeColor: "#cccccc",
    fillColor: "#f4bab9"
  },
  {
    code: 6,
    name: "5.0m以上 〜 10.0m未満",
    strokeColor: "#cccccc",
    fillColor: "#f09694"
  },
  {
    code: 7,
    name: "10.0m以上 〜 20.0m未満",
    strokeColor: "#cccccc",
    fillColor: "#e38bc6"
  },
  {
    code: 8,
    name: "20.0m以上",
    strokeColor: "#cccccc",
    fillColor: "#cf7fb9"
  }
];
