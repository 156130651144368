import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { MinimalEstate } from "common/queries/minimalEstates";
import { createContext } from "react";

interface SearchMapContextType {
  map: google.maps.Map | null;

  myPositionMarker: google.maps.marker.AdvancedMarkerElement | null;
  placeMarker: google.maps.marker.AdvancedMarkerElement | null;
  estateMarkers: {
    [id: string]: {
      marker: google.maps.marker.AdvancedMarkerElement | google.maps.Marker;
      data: MinimalEstate;
    };
  };
  soldEstateMarkers: {
    [id: string]: {
      marker: google.maps.marker.AdvancedMarkerElement;
      data: MinimalEstate;
    };
  };
  markerClusterer: MarkerClusterer | null;

  fillingSchoolPolygons: boolean | undefined;
  searchRouteMode: boolean;
  measureMode: boolean;

  constructMarkerClustererOfEstate:
    | ((
        map: google.maps.Map,
        measureLengthMode: boolean,
        measureAreaMode: boolean,
        markers: (
          | google.maps.marker.AdvancedMarkerElement
          | google.maps.Marker
        )[]
      ) => MarkerClusterer)
    | undefined;

  onReconstructMarkerClusterer:
    | ((newMarkerClusterer: MarkerClusterer) => void)
    | undefined;
  onMapContextMenu:
    | ((event: Event, latLng: google.maps.LatLng) => void)
    | undefined;
  onSchoolContextMenu:
    | ((event: Event, latLng: google.maps.LatLng) => void)
    | undefined;
  onFillingSchoolPolygonsChange: ((filling: boolean) => void) | undefined;
}

export const SearchMapContext = createContext<SearchMapContextType>({
  map: null,

  myPositionMarker: null,
  placeMarker: null,
  estateMarkers: {},
  soldEstateMarkers: {},
  markerClusterer: null,

  fillingSchoolPolygons: undefined,
  searchRouteMode: false,
  measureMode: false,

  constructMarkerClustererOfEstate: undefined,

  onReconstructMarkerClusterer: undefined,
  onMapContextMenu: undefined,
  onSchoolContextMenu: undefined,
  onFillingSchoolPolygonsChange: undefined
});
