import { Tile16MapType } from "common/functions/classes/Tile16MapType";
import { DrawingId } from "common/types/DrawingId";
import { useCallback, useContext, useEffect, useState } from "react";
import { SearchMapContext } from "../../context";

const id: DrawingId = "tile16";

export const useHooks = (on: boolean) => {
  const { map } = useContext(SearchMapContext);
  const [tile16MapType, setTile16MapType] = useState<Tile16MapType | undefined>(
    undefined
  );

  // マップタイプ構築
  const constructTile16MapType = useCallback(() => {
    if (map) {
      const mapType = new Tile16MapType(`${id}MapType`, map);
      setTile16MapType(mapType);
    }
  }, [map]);

  // 描画
  const drawTile16 = useCallback(async () => {
    if (map && tile16MapType) {
      for (let i = 0; i < map.overlayMapTypes.getLength(); i++) {
        const mapType = map.overlayMapTypes.getAt(
          i
        ) as unknown as Tile16MapType;

        if (mapType.id === `${id}MapType`) {
          map.overlayMapTypes.removeAt(i);
          break;
        }
      }

      if (on) {
        map.overlayMapTypes.push(
          tile16MapType as unknown as google.maps.MapType
        );
      }
    }
  }, [tile16MapType, map, on]);

  useEffect(() => {
    constructTile16MapType();
  }, [constructTile16MapType]);

  useEffect(() => {
    drawTile16();
  }, [drawTile16]);

  return { map } as const;
};
