import { MenuTabValueEnum } from "common/enums/MenuTabValueEnum";
import { useState } from "react";

export const useCommonPageStates = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [tabValue, setTabValue] = useState<MenuTabValueEnum>(
    MenuTabValueEnum.Menu
  );

  return {
    menuOpen,
    setMenuOpen,
    tabValue,
    setTabValue
  } as const;
};
