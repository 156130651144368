import { useState } from "react";

export const useSearchEstateSearchStates = () => {
  // 検索中止シグナル
  const [cancelSearch, setCancelSearch] = useState(false);

  // 検索エラーメッセージ
  const [searchErrorMessage, setSearchErrorMessage] = useState<string | null>(
    null
  );

  return {
    cancelSearch,
    setCancelSearch,
    searchErrorMessage,
    setSearchErrorMessage
  } as const;
};

export type SearchEstateSearchStatesType = ReturnType<
  typeof useSearchEstateSearchStates
>;
