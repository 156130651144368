import { useAutoReducer } from "common/functions/reducers";
import {
  defaultStickyNoteSearchConditions,
  StickyNoteSearchConditions
} from "common/interfaces/StickyNoteSearchConditions";

export const useSearchStickyNoteSearchConditionStates = () => {
  const [
    stickyNoteSearchConditions,
    initializeStickyNoteSearchConditions,
    forceStickyNoteSearchConditions,
    setStickyNoteSearchConditions,
    clearStickyNoteSearchConditions
  ] = useAutoReducer<StickyNoteSearchConditions>(
    defaultStickyNoteSearchConditions
  );

  return {
    stickyNoteSearchConditions,
    initializeStickyNoteSearchConditions,
    forceStickyNoteSearchConditions,
    setStickyNoteSearchConditions,
    clearStickyNoteSearchConditions
  } as const;
};

export type SearchStickyNoteSearchConditionStatesType = ReturnType<
  typeof useSearchStickyNoteSearchConditionStates
>;
