import { useState } from "react";

export const useSearchBehaviorStates = () => {
  // ウィンドウ
  const [mapDivSize, setMapDivSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  // メニュー
  const [openMenu, setOpenMenu] = useState(false);

  // ビジー
  const [busy, setBusy] = useState(false);

  // 初回検索可能
  const [readyToInitialSearch, setReadyToInitialSearch] = useState(false);

  // 初回検索
  const [initialSearch, setInitialSearch] = useState(false);

  // 編集モード
  const [editMode, setEditMode] = useState(false);

  // 公開物件表示
  const [saleMode, setSaleMode] = useState(true);

  // 成約済み物件表示
  const [closedMode, setClosedMode] = useState(false);

  // 成約済みメッシュ表示
  const [soldMode, setSoldMode] = useState(false);

  // メモ表示
  const [stickyNoteMode, setStickyNoteMode] = useState(false);

  // 一時公開物件作成中
  const [creatingPublicEstate, setCreatingPublicEstate] = useState(false);

  return {
    mapDivSize,
    setMapDivSize,

    openMenu,
    setOpenMenu,

    busy,
    setBusy,

    readyToInitialSearch,
    setReadyToInitialSearch,

    initialSearch,
    setInitialSearch,

    editMode,
    setEditMode,

    saleMode,
    setSaleMode,

    closedMode,
    setClosedMode,

    soldMode,
    setSoldMode,

    stickyNoteMode,
    setStickyNoteMode,

    creatingPublicEstate,
    setCreatingPublicEstate
  } as const;
};

export type SearchBehaviorStatesType = ReturnType<
  typeof useSearchBehaviorStates
>;
