import { Box } from "@mui/material";
import * as React from "react";
import styles from "./DialogHeader.module.scss";
import { DialogHeaderProps } from "./interfaces";

export const DialogHeader = React.memo((props: DialogHeaderProps) => {
  return (
    <Box
      id={props.id}
      style={props.style}
      className={`${props.className ?? ""} ${styles.dialogHeader}`}
    >
      <Box component="span" className={styles.icon}>
        {props.icon}
      </Box>
    </Box>
  );
});
