import { Box, Button, CircularProgress } from "@mui/material";
import styles from "./Loading.module.scss";

export const Loading = (
  <div>
    <Box className={styles.wrapper}>
      <Box className={styles.loading}>
        <CircularProgress />
        <Box>
          しばらく待って画面が切り替わらない場合は、
          <Button
            variant="text"
            onClick={() => {
              window.location.reload();
            }}
          >
            リロード
          </Button>
          してください。
        </Box>
      </Box>
    </Box>
  </div>
);
