import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import React from "react";

export const GettingCurrentLocationProgressBackdrop = React.memo(
  (props: { busyForMove: boolean }) => {
    return (
      <ProgressBackdrop
        open={props.busyForMove}
        message="現在地を取得しています..."
      />
    );
  }
);
