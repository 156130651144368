import { Box } from "@mui/material";
import ApartmentIcon from "assets/icons/ic_map_type_apartment.png";
import BlockIcon from "assets/icons/ic_map_type_block.png";
import BusinessIcon from "assets/icons/ic_map_type_business.png";
import GroundIcon from "assets/icons/ic_map_type_ground.png";
import HatenaIcon from "assets/icons/ic_map_type_hatena.png";
import NewIcon from "assets/icons/ic_map_type_new.png";
import UsedIcon from "assets/icons/ic_map_type_used.png";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { MinimalEstate } from "common/queries/minimalEstates";
import React from "react";
import styles from "./EstateItemIcon.module.scss";

export const EstateItemIcon = React.memo((props: { estate: MinimalEstate }) => {
  const icon =
    props.estate.estateType === EstateTypeNameEnum.土地
      ? GroundIcon
      : props.estate.estateType === EstateTypeNameEnum.分譲地
      ? BlockIcon
      : props.estate.estateType === EstateTypeNameEnum.新築建売
      ? NewIcon
      : props.estate.estateType === EstateTypeNameEnum.中古戸建
      ? UsedIcon
      : props.estate.estateType === EstateTypeNameEnum.マンション
      ? ApartmentIcon
      : props.estate.estateType === EstateTypeNameEnum.事業用
      ? BusinessIcon
      : HatenaIcon;

  return (
    <Box
      component="img"
      src={icon}
      alt={props.estate.estateType || ""}
      className={styles.icon}
    />
  );
});
