import { MinimalEstate } from "common/queries/minimalEstates";
import { useMemo, useState } from "react";
import { RouteLocationStatus } from "../types";

export const useSearchMapStates = () => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [previousMap, setPreviousMap] = useState<google.maps.Map | null>(null);
  const [directionService, setDirectionService] = useState<
    google.maps.DirectionsService | undefined
  >(undefined);

  // 物件コンテキストメニュー
  const [estateContextMenuPosition, setEstateContextMenuPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [estateContextMenuEstate, setEstateContextMenuEstate] = useState<
    MinimalEstate | null | undefined
  >(undefined);

  // マップコンテキストメニュー
  const [mapContextMenuPosition, setMapContextMenuPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  // ルート地点コンテキストメニュー
  const [routeLocationContextMenuInfo, setRouteLocationContextMenuInfo] =
    useState<{
      routeLocationStatus: RouteLocationStatus;
      position: {
        mouseX: number;
        mouseY: number;
      } | null;
    }>({
      routeLocationStatus: null,
      position: null
    });

  // 学校コンテキストメニュー
  const [schoolContextMenuPosition, setSchoolContextMenuPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  // 右クリック地点の緯度・経度
  const [rightClickedLocation, setRightClickedLocation] =
    useState<google.maps.LatLng | null>(null);

  // 検索エリア
  const [citiesOfSearchConditions, setCitiesOfSearchConditions] =
    useState<string>("");

  // 選択中物件
  const [dspEstates, setDspEstates] = useState<MinimalEstate[]>([]);
  const selectingEstates = useMemo(() => dspEstates.length > 0, [dspEstates]);

  const [triggerResetPosition, setTriggerResetPosition] =
    useState<boolean>(false);

  // ストリートビュー切り替えトリガ
  const [triggerSwitchStreetView, setTriggerSwitchStreetView] =
    useState<boolean>(false);

  const [domEvent, setDomEvent] = useState<any>(null);

  return {
    map,
    setMap,
    previousMap,
    setPreviousMap,

    directionService,
    setDirectionService,

    estateContextMenuPosition,
    setEstateContextMenuPosition,
    estateContextMenuEstate,
    setEstateContextMenuEstate,
    mapContextMenuPosition,
    setMapContextMenuPosition,
    routeLocationContextMenuInfo,
    setRouteLocationContextMenuInfo,
    schoolContextMenuPosition,
    setSchoolContextMenuPosition,
    rightClickedLocation,
    setRightClickedLocation,

    citiesOfSearchConditions,
    setCitiesOfSearchConditions,

    dspEstates,
    setDspEstates,

    selectingEstates,

    triggerResetPosition,
    setTriggerResetPosition,

    triggerSwitchStreetView,
    setTriggerSwitchStreetView,

    domEvent,
    setDomEvent
  } as const;
};
