import React, { useCallback } from "react";
import { SearchEstateSearchStatesType } from "../states/searches";

export const useSearchModalHandlers = (
  searchStates: SearchEstateSearchStatesType
) => {
  const onModalCancelClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      searchStates.setCancelSearch(true);
    },
    [searchStates]
  );

  return {
    onModalCancelClick
  } as const;
};

export type SearchModalHandlersType = ReturnType<typeof useSearchModalHandlers>;
