import { bbox, points } from "@turf/turf";
import {
  clearMarker,
  clearMarkerClusterer
} from "common/functions/mapUtilities";
import { squareToTsubo } from "common/functions/utilities";
import { useCallback, useContext, useEffect, useRef } from "react";
import { SearchMapContext } from "../../context";
import { LengthView } from "./LengthView";
import { MeasurePluginProps, ResultInfos } from "./interfaces";
import { isMobile } from "react-device-detect";

export const useHooks = (props: MeasurePluginProps) => {
  const { measureLengthMode, measureAreaMode } = props;

  const {
    map,
    myPositionMarker,
    placeMarker,
    estateMarkers,
    soldEstateMarkers,
    markerClusterer,
    constructMarkerClustererOfEstate,
    onReconstructMarkerClusterer
  } = useContext(SearchMapContext);

  const zIndex = 10000;

  const measureMarkersRef = useRef<google.maps.marker.AdvancedMarkerElement[]>(
    []
  );
  const measureMarkerDraggingRef = useRef<boolean>(false);
  const measurePolylineRef = useRef<google.maps.Polyline | null>(null);
  const measurePolygonRef = useRef<google.maps.Polygon | null>(null);
  const measureDraggingPolylineRef = useRef<google.maps.Polyline | null>(null);
  const measureDraggingPolygonRef = useRef<google.maps.Polygon | null>(null);
  const measureInfoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const measureLengthsRef = useRef<LengthView[]>([]);

  const clickListenerRef = useRef<google.maps.MapsEventListener | null>(null);

  // 計測モード時のマーカー群のクリック挙動制御
  const setClickableToMarkers = useCallback(() => {
    for (const marker of [
      ...(Object.values(estateMarkers) ?? []).map((x) => x.marker),
      ...(Object.values(soldEstateMarkers) ?? []).map((x) => x.marker),
      placeMarker,
      myPositionMarker
    ]) {
      if (marker) {
        if (marker instanceof google.maps.Marker) {
          marker.setClickable(!(measureLengthMode || measureAreaMode));
        } else {
          marker.ariaDisabled =
            measureLengthMode || measureAreaMode ? "true" : "false";
        }
      }
    }

    if (markerClusterer) {
      if (map && constructMarkerClustererOfEstate) {
        clearMarkerClusterer(markerClusterer);
        const markers = (Object.values(estateMarkers) ?? []).map(
          (x) => x.marker
        );

        const newMarkerClusterer = constructMarkerClustererOfEstate(
          map,
          measureLengthMode,
          measureAreaMode,
          markers
        );

        // const bounds = map.getBounds();
        // const markers = (Object.values(estateMarkers) ?? [])
        //   .map((x) => x.marker)
        //   .filter(
        //     (marker) =>
        //       bounds &&
        //       marker &&
        //       bounds.contains(marker.position || { lat: 0, lng: 0 })
        //   );

        // newMarkerClusterer.addMarkers(markers);

        if (onReconstructMarkerClusterer) {
          onReconstructMarkerClusterer(newMarkerClusterer);
        }
      }
    }
  }, [
    constructMarkerClustererOfEstate,
    estateMarkers,
    map,
    markerClusterer,
    measureAreaMode,
    measureLengthMode,
    myPositionMarker,
    onReconstructMarkerClusterer,
    placeMarker,
    soldEstateMarkers
  ]);

  // ポリゴン、ポリラインパス取得
  const getPath = useCallback(
    (
      forMeasureArea: boolean,
      measureMarkers: google.maps.marker.AdvancedMarkerElement[]
    ) => {
      const path = measureMarkers.map((x) => x.position).filter((x) => x) as (
        | google.maps.LatLng
        | google.maps.LatLngLiteral
      )[];

      if (forMeasureArea && path.length > 2) {
        path.push(path[0]);
      }

      const hull = path.map((x) => [
        typeof x.lat === "number" ? x.lat : x.lat(),
        typeof x.lng === "number" ? x.lng : x.lng()
      ]) as number[][];

      if (forMeasureArea) {
        return hull.map((x) => new google.maps.LatLng(x[0], x[1]));
      }

      return path;
    },
    []
  );

  // Lengthの破棄
  const clearLength = useCallback((length: LengthView | null) => {
    if (length) {
      google.maps.event.clearInstanceListeners(length);
      length.setMap(null);
      length = null;
    }
  }, []);

  // 計測オブジェクト初期化
  const clearMeasures = useCallback(() => {
    measureDraggingPolylineRef.current?.setMap(null);
    measureDraggingPolygonRef.current?.setMap(null);
    measurePolylineRef.current?.setMap(null);
    measurePolygonRef.current?.setMap(null);
    measureInfoWindowRef.current?.close();

    for (const measureLength of measureLengthsRef.current) {
      clearLength(measureLength);
    }

    measureLengthsRef.current = [];

    measureDraggingPolylineRef.current = null;
    measureDraggingPolygonRef.current = null;
    measurePolylineRef.current = null;
    measurePolygonRef.current = null;
    measureInfoWindowRef.current = null;
  }, [clearLength]);

  // 計測オブジェクト初期化
  const clearAllMeasures = useCallback(() => {
    for (const measureMarker of measureMarkersRef.current) {
      clearMarker(measureMarker);
    }

    measureMarkersRef.current = [];

    clearMeasures();
  }, [clearMeasures]);

  const removeListener = useCallback(() => {
    if (clickListenerRef.current) {
      google.maps.event.removeListener(clickListenerRef.current);
    }
  }, []);

  // 計測結果InfoWindow構築
  const constructInfoWindow = useCallback(
    (infos: ResultInfos) => {
      const measureResults: string[] = [];

      if (infos.length) {
        measureResults.push("長さ");
        measureResults.push(`${infos.length}ｍ`);
      }

      if (infos.area || infos.tsuboArea) {
        measureResults.push("面積");
      }

      if (infos.area) {
        measureResults.push(`${infos.area}㎡`);
      }

      if (infos.tsuboArea) {
        measureResults.push(`${infos.tsuboArea}坪`);
      }

      const measureInfoWindow = new google.maps.InfoWindow({
        content: `<div class="measure_info_window">${measureResults
          .map((x) => `<div>${x}</div>`)
          .join("")}</div>`,
        position: infos.position,
        pixelOffset: new google.maps.Size(10, -10),
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + zIndex
      });

      measureInfoWindow.addListener("closeclick", () => {
        clearAllMeasures();

        return false;
      });

      return measureInfoWindow;
    },
    [clearAllMeasures]
  );

  // 計測オブジェクト描画
  const drawMeasureObject = useCallback(() => {
    clearMeasures();

    if (map) {
      const LengthView = require("./LengthView").LengthView;

      if (measureMarkersRef.current.length > 1) {
        const infos: ResultInfos = {
          position: null,
          length: 0,
          area: 0,
          tsuboArea: 0
        };

        const path = getPath(measureAreaMode, measureMarkersRef.current);

        if (measureAreaMode && google.maps.geometry) {
          measurePolygonRef.current = new google.maps.Polygon({
            paths: path,
            fillColor: "#FFFFFF",
            fillOpacity: 0.5,
            strokeColor: "#FF0000",
            strokeWeight: 1.0,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + zIndex,
            clickable: false
          });

          infos.area =
            Math.floor(
              google.maps.geometry.spherical.computeArea(
                measurePolygonRef.current.getPath()
              ) * 100
            ) / 100;

          infos.tsuboArea = squareToTsubo(infos.area);

          measurePolygonRef.current.setMap(map);
        } else if (measureLengthMode) {
          measurePolylineRef.current = new google.maps.Polyline({
            path: path,
            strokeColor: "#FF0000",
            strokeWeight: 1.0,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + zIndex,
            clickable: false
          });

          infos.length =
            Math.floor(
              google.maps.geometry.spherical.computeLength(path) * 100
            ) / 100;

          measurePolylineRef.current.setMap(map);
        }

        for (
          let i = 0;
          i <
          path.length -
            (measureAreaMode && measureMarkersRef.current.length < 3 ? 2 : 1);
          i++
        ) {
          const length = new LengthView(path[i], path[i + 1]);

          length.setMap(map);

          measureLengthsRef.current.push(length);
        }

        const builtBbox = bbox(
          points(
            path.map((x) => [
              typeof x.lat === "number" ? x.lat : x.lat(),
              typeof x.lng === "number" ? x.lng : x.lng()
            ])
          )
        );

        infos.position = new google.maps.LatLng(builtBbox[2], builtBbox[1]);

        if (infos.length || infos.area || infos.tsuboArea) {
          measureInfoWindowRef.current = constructInfoWindow(infos);
          measureInfoWindowRef.current.open(map);
        }
      }
    }
  }, [
    clearMeasures,
    constructInfoWindow,
    getPath,
    map,
    measureAreaMode,
    measureLengthMode
  ]);

  // ドラッグ中の計測オブジェクト描画
  const drawMeasureDraggingObject = useCallback(() => {
    measureDraggingPolylineRef.current?.setMap(null);
    measureDraggingPolygonRef.current?.setMap(null);

    if (map && measureMarkersRef.current.length > 1) {
      const path = getPath(measureAreaMode, measureMarkersRef.current);

      if (measureAreaMode) {
        measureDraggingPolygonRef.current = new google.maps.Polygon({
          paths: path,
          fillColor: "#FFFFFF",
          fillOpacity: 0.0,
          strokeColor: "#FF00FF",
          strokeWeight: 0.8
        });

        measureDraggingPolygonRef.current.setMap(map);
      } else if (measureLengthMode) {
        measureDraggingPolylineRef.current = new google.maps.Polyline({
          path: path,
          strokeColor: "#FF00FF",
          strokeWeight: 0.8
        });

        measureDraggingPolylineRef.current.setMap(map);
      }
    }
  }, [getPath, map, measureAreaMode, measureLengthMode]);

  const constructMeasurePointMarker = useCallback(
    (position: google.maps.LatLng) => {
      const content = document.createElement("div");

      content.innerHTML = `
        <div class="measure-point-marker"></div>
      `;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: position,
        gmpDraggable: true,
        content: content,
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + zIndex
      });

      const onClick = () => {
        const index = measureMarkersRef.current.findIndex((x) => {
          const markerPosition = marker.position;

          return x.position && markerPosition
            ? x.position instanceof google.maps.LatLng &&
              markerPosition instanceof google.maps.LatLng
              ? x.position.equals(markerPosition)
              : x.position.lat === markerPosition.lat &&
                x.position.lng === markerPosition.lng
            : false;
        });

        // 点の削除
        clearMarker(marker);
        measureMarkersRef.current.splice(index, 1);

        drawMeasureObject();
      };

      if (isMobile) {
        marker.element?.addEventListener("touchend", () => {
          if (!measureMarkerDraggingRef.current) {
            onClick();
          }

          return false;
        });
      } else {
        marker.addListener("click", () => {
          onClick();

          return false;
        });
      }

      marker.addListener("drag", () => {
        measureMarkerDraggingRef.current = true;
        drawMeasureDraggingObject();

        return false;
      });

      marker.addListener("dragend", () => {
        drawMeasureObject();
        measureMarkerDraggingRef.current = false;

        return false;
      });

      return marker;
    },
    [drawMeasureDraggingObject, drawMeasureObject]
  );

  const invokeMapClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (map && (measureLengthMode || measureAreaMode) && event.latLng) {
        const marker = constructMeasurePointMarker(event.latLng);

        marker.map = map;
        measureMarkersRef.current.push(marker);

        drawMeasureObject();
      }
    },
    [
      constructMeasurePointMarker,
      drawMeasureObject,
      map,
      measureAreaMode,
      measureLengthMode
    ]
  );

  useEffect(() => {
    return () => {
      removeListener();
      clearAllMeasures();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      removeListener();
      clickListenerRef.current = map.addListener(
        "click",
        (event: google.maps.MapMouseEvent) => {
          invokeMapClick(event);
          return false;
        }
      );
    }
  }, [invokeMapClick, map, removeListener]);

  useEffect(() => {
    if (map) {
      map.setOptions({
        draggableCursor:
          measureLengthMode || measureAreaMode ? "default" : "grab"
      });

      if (measureLengthMode && measureAreaMode) {
        setClickableToMarkers();
      }
    }
  }, [map, measureAreaMode, measureLengthMode, setClickableToMarkers]);

  useEffect(() => {
    clearAllMeasures();
  }, [clearAllMeasures, measureAreaMode, measureLengthMode]);

  return { map } as const;
};
