import { booleanPointInPolygon, polygon } from "@turf/turf";
import { GeoJSONFeature } from "common/interfaces/GeoJSON";
import { Feature, GeoJsonProperties, Point } from "geojson";

export const isInnerOfFeature = <T>(
  point: Feature<Point, GeoJsonProperties>,
  feature: GeoJSONFeature<T>
) => {
  try {
    const featurePolygon = polygon(feature.geometry.coordinates);

    if (featurePolygon) {
      for (const coordinates of featurePolygon.geometry.coordinates) {
        try {
          const individualPolygon = polygon([coordinates]);

          if (
            individualPolygon &&
            booleanPointInPolygon(point, individualPolygon)
          ) {
            return true;
          }
        } catch {}
      }
    }
  } catch {}

  return false;
};
