import { SxProps, Theme } from "@mui/material";

export const getReferenceStyles = (theme: Theme) => {
  const referenceStyles: SxProps<Theme> = {
    marginLeft: "11px",
    marginBottom: "5px",
    "& .MuiTypography-root": {
      color: theme.palette.info.main
    }
  };

  return referenceStyles;
};
