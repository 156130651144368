import React, { useCallback, useMemo, useReducer } from "react";

const init = <T>(initialState: T) => {
  return initialState;
};

type PayloadTypes<T> = {
  [P in keyof T]: T[P];
};

export type DefaultActions<T> = {
  type: "set" | "clear" | "increment" | "toggle" | "init" | "reset";
  name?: keyof T;
  payload?: PayloadTypes<T> | T;
};

type DefaultSetDispatcherFunctions<T> = {
  [P in keyof T]-?: (value: T[P] | null) => void;
};

type DefaultClearDispatcherFunctions<T> = {
  [P in keyof T]-?: () => void;
};

type DefaultIncrementDispatcherFunctions<T> =
  DefaultClearDispatcherFunctions<T>;

type DefaultInitDispatcherFunctions = { all: () => void };

type DefaultResetDispatcherFunctions<T> = {
  all: (value: T) => void;
};

// ステートの型を自動判断するReducer
export const defaultReducer = <T>(
  state: T,
  action: DefaultActions<T>,
  initialState: T,
  additionalAction?: () => void
) => {
  let newState = state;

  switch (action.type) {
    case "set": {
      if (action.name) {
        newState =
          action.payload !== undefined
            ? {
                ...state,
                [action.name]: action.payload
              }
            : state;
      }

      break;
    }

    case "clear": {
      if (action.name) {
        newState = {
          ...state,
          [action.name]: initialState[action.name]
        };
      }

      break;
    }

    case "increment": {
      if (action.name && typeof (state as T)[action.name] === "number") {
        const current = (state as T)[action.name] as unknown as number;

        newState = {
          ...state,
          [action.name]: (current === Number.MAX_VALUE ? 0 : current) + 1
        };
      }

      break;
    }

    case "toggle": {
      if (action.name && typeof (state as T)[action.name] === "boolean") {
        const current = (state as T)[action.name] as unknown as boolean;

        newState = {
          ...state,
          [action.name]: !current
        };
      }

      break;
    }

    case "init": {
      return init({ ...initialState });
    }

    case "reset": {
      return init({ ...(action.payload as T) });
    }
  }

  if (additionalAction) {
    additionalAction();
  }

  return newState;
};

// 自動で型付きDispatcherを作成する
export const useDefaultDispatcher = <T>(
  dispatch: React.Dispatch<DefaultActions<T>>
) => {
  const dispatchers = {
    set: React.useMemo(
      (): DefaultSetDispatcherFunctions<T> =>
        new Proxy({} as DefaultSetDispatcherFunctions<T>, {
          get:
            (_target, name: string | symbol, _receiver) =>
            (value: PayloadTypes<T>) => {
              (dispatch as React.Dispatch<DefaultActions<T>>)({
                type: "set",
                name: name as keyof T,
                payload: value
              });
            }
        }),
      [dispatch]
    ),

    clear: React.useMemo(
      (): DefaultClearDispatcherFunctions<T> =>
        new Proxy({} as DefaultClearDispatcherFunctions<T>, {
          get: (_target, name: string | symbol, _receiver) => () => {
            (dispatch as React.Dispatch<DefaultActions<T>>)({
              type: "clear",
              name: name as keyof T
            });
          }
        }),
      [dispatch]
    ),

    increment: React.useMemo(
      (): DefaultIncrementDispatcherFunctions<T> =>
        new Proxy({} as DefaultIncrementDispatcherFunctions<T>, {
          get: (_target, name: string | symbol, _receiver) => () => {
            (dispatch as React.Dispatch<DefaultActions<T>>)({
              type: "increment",
              name: name as keyof T
            });
          }
        }),
      [dispatch]
    ),

    toggle: React.useMemo(
      (): DefaultIncrementDispatcherFunctions<T> =>
        new Proxy({} as DefaultIncrementDispatcherFunctions<T>, {
          get: (_target, name: string | symbol, _receiver) => () => {
            (dispatch as React.Dispatch<DefaultActions<T>>)({
              type: "toggle",
              name: name as keyof T
            });
          }
        }),
      [dispatch]
    ),

    init: React.useMemo(
      (): DefaultInitDispatcherFunctions =>
        new Proxy({} as DefaultInitDispatcherFunctions, {
          get: (_target, name: string | symbol, _receiver) => () => {
            (dispatch as React.Dispatch<DefaultActions<T>>)({
              type: "init"
            });
          }
        }),
      [dispatch]
    ),

    reset: React.useMemo(
      (): DefaultResetDispatcherFunctions<T> =>
        new Proxy({} as DefaultResetDispatcherFunctions<T>, {
          get: (_target, name: string | symbol, _receiver) => (value: T) => {
            (dispatch as React.Dispatch<DefaultActions<T>>)({
              type: "reset",
              payload: value
            });
          }
        }),
      [dispatch]
    )
  };

  return dispatchers;
};

// == new Reducer! ========================================================

export const useAutoReducer = <T>(initialValue: T) => {
  type Action =
    | { type: "initialize" }
    | { type: "force"; payload: T }
    | {
        type: "set";
        field: keyof T;
        payload: T[keyof T];
      }
    | {
        type: "clear";
        field: keyof T;
      };

  const reducer = (state: T, action: Action) => {
    switch (action.type) {
      case "initialize":
        return initialValue;
      case "force":
        return action.payload;
      case "set":
        return { ...state, [action.field]: action.payload };
      case "clear":
        return { ...state, [action.field]: initialValue[action.field] };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialValue);

  const initialize = useCallback(
    () => dispatch({ type: "initialize" }),
    [dispatch]
  );
  const force = useCallback(
    (initial: T) => dispatch({ type: "force", payload: initial }),
    [dispatch]
  );

  type SetDispatchType = {
    [P in keyof T]-?: (value: T[P] | null) => void;
  };

  const set = useMemo(
    () =>
      new Proxy({} as SetDispatchType, {
        get:
          (_target, name: string | symbol, _receiver) => (value: T[keyof T]) =>
            dispatch({
              type: "set",
              field: name as keyof T,
              payload: value
            })
      }),
    [dispatch]
  );

  type ClearDispatchType = {
    [P in keyof T]-?: () => void;
  };

  const clear = useMemo(
    () =>
      new Proxy({} as ClearDispatchType, {
        get: (_target, name: string | symbol, _receiver) => () =>
          dispatch({
            type: "clear",
            field: name as keyof T
          })
      }),
    [dispatch]
  );

  return [state, initialize, force, set, clear, dispatch] as const;
};

export const useIndexedReducer = <T>(initialValue: T[]) => {
  type Action =
    | { type: "initialize" }
    | { type: "force"; payload: T[] }
    | { type: "forceItem"; index: number; payload: T }
    | { type: "pushItem"; payload: T }
    | { type: "deleteItem"; index: number }
    | {
        type: "setItem";
        index: number;
        field: keyof T;
        payload: T[keyof T];
      };

  const reducer = (state: T[], action: Action) => {
    switch (action.type) {
      case "initialize":
        return initialValue;
      case "force":
        return action.payload;
      case "forceItem": {
        const item = state.at(action.index);

        if (item) {
          const newState = [...state];
          newState[action.index] = action.payload;

          return newState;
        }

        return state;
      }
      case "pushItem": {
        const newState = [...state];
        newState.push(action.payload);

        return newState;
      }
      case "deleteItem": {
        const item = state.at(action.index);

        if (item) {
          const newState = [...state];
          newState.splice(action.index, 1);

          return newState;
        }

        return state;
      }
      case "setItem": {
        const item = state.at(action.index);

        if (item) {
          const newState = [...state];
          newState[action.index] = { ...item, [action.field]: action.payload };

          return newState;
        }

        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialValue);

  const initialize = useCallback(
    () => dispatch({ type: "initialize" }),
    [dispatch]
  );

  const force = useCallback(
    (initial: T[]) => dispatch({ type: "force", payload: initial }),
    [dispatch]
  );

  const forceItem = useCallback(
    (index: number, initial: T) =>
      dispatch({ type: "forceItem", index, payload: initial }),
    [dispatch]
  );

  const pushItem = useCallback(
    (payload: T) => dispatch({ type: "pushItem", payload }),
    [dispatch]
  );

  const deleteItem = useCallback(
    (index: number) => dispatch({ type: "deleteItem", index }),
    [dispatch]
  );

  type SetDispatchType = {
    [P in keyof T]-?: (index: number, value: T[P] | null) => void;
  };

  const setItem = useMemo(
    () =>
      new Proxy({} as SetDispatchType, {
        get:
          (_target, name: string | symbol, _receiver) =>
          (index: number, value: T[keyof T]) =>
            dispatch({
              type: "setItem",
              index,
              field: name as keyof T,
              payload: value
            })
      }),
    [dispatch]
  );

  return [
    state,
    initialize,
    force,
    forceItem,
    pushItem,
    deleteItem,
    setItem,
    dispatch
  ] as const;
};
