import {
  AutocompleteFreeSoloValueMapping,
  AutocompleteValue
} from "@mui/material";
import { Company } from "API";
import { useCompanyWithSearchStringHooks } from "hooks/companyHooks";
import { useReferenceStyles } from "hooks/referenceStyles";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { constructOptionString, invokeConvertValue } from "./utils";

export const useCompanyAutocompleteHooks = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>(
  multiple: boolean | undefined,
  setFatal: ((error?: string | null | undefined) => void) | undefined,
  onOptionsLoad: ((options: Company[]) => void) | undefined
) => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const { companies, busy } = useCompanyWithSearchStringHooks(
    searchString,
    setFatal
  );

  const referenceStyles = useReferenceStyles();

  const groupBy = useCallback((option: Company) => option.name, []);
  const getOptionLabel = useCallback(
    (option: Company | AutocompleteFreeSoloValueMapping<true>) => {
      if (typeof option === "string") {
        return option as string;
      }

      return constructOptionString(option);
    },
    []
  );

  const getOptionSelected = useCallback(
    (option: Company, value: Company): boolean => {
      return option.id === value.id;
    },
    []
  );

  const convertValue = useCallback(
    (
      value:
        | AutocompleteValue<Company, Multiple, DisableClearable, true>
        | string
        | string[]
        | undefined
    ) => {
      return invokeConvertValue(multiple, companies, value);
    },
    [companies, multiple]
  );

  const onInputChange = debounce(
    useCallback(
      (event: React.SyntheticEvent<Element, Event>, value: string) => {
        if (value !== "") {
          setSearchString(value);
        }
      },
      []
    ),
    500
  );

  useEffect(() => {
    if (onOptionsLoad) {
      onOptionsLoad(companies);
    }
  }, [companies, onOptionsLoad]);

  return {
    companies,
    busy,
    referenceStyles,
    groupBy,
    getOptionLabel,
    getOptionSelected,
    convertValue,
    onInputChange
  } as const;
};
