import { OpenLevelNameEnum } from "common/enums/OpenLevelEnum";
import { getOpenLevels } from "common/functions/jsonMasters/openLevels";
import { handleError } from "common/functions/utilities";
import { OpenLevel } from "common/interfaces/OpenLevel";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useOpenLevelsHooks = (
  setFatal: (error?: string | null | undefined) => void
) => {
  const [openLevels, setOpenLevels] = useState<OpenLevel[]>([]);

  const { data, error } = useSWR("open_levels", () =>
    getOpenLevels().then((value) => {
      if (value) {
        return value.filter((x) =>
          [
            OpenLevelNameEnum.社内 as string,
            OpenLevelNameEnum.支店内 as string
          ].includes(x.name || "")
        );
      }
    })
  );

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setOpenLevels(data);
    }
  }, [data]);

  return { openLevels, setOpenLevels } as const;
};
