import { PanToolAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import React from "react";
import styles from "./StatusSelectDialog.module.scss";
import { useStatusSelectDialogHooks } from "./hooks";
import { StatusSelectDialogProps } from "./interfaces";

export const StatusSelectDialog = React.memo(
  (props: StatusSelectDialogProps) => {
    const { statuses, busy, onModeClick, onOkClick } =
      useStatusSelectDialogHooks(props);

    return (
      <>
        <Dialog
          style={props.style}
          className={`${props.className} ${styles.dialog}}`}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="statuses-select-title"
          aria-describedby="statuses-select-description"
          fullWidth
          PaperProps={{ className: styles.dialogPaper }}
          slotProps={{ backdrop: { className: styles.backdrop } }}
        >
          <DialogHeader id="statuses-select-title" icon={<PanToolAlt />} />
          <DialogContent id="statuses-select-description">
            <Box className={styles.paragraph}>
              <Box
                className={`${styles.button} ${
                  statuses.includes(StatusNameEnum.公開)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(StatusNameEnum.公開)}
              >
                {StatusNameEnum.公開}
              </Box>
              <Box
                className={`${styles.button} ${
                  statuses.includes(StatusNameEnum.位置不明)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(StatusNameEnum.位置不明)}
              >
                {StatusNameEnum.位置不明}
              </Box>
              <Box
                className={`${styles.button} ${
                  statuses.includes(StatusNameEnum.成約済み)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(StatusNameEnum.成約済み)}
              >
                {StatusNameEnum.成約済み}
              </Box>
              <Box
                className={`${styles.button} ${
                  statuses.includes(StatusNameEnum.非公開)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(StatusNameEnum.非公開)}
              >
                {StatusNameEnum.非公開}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              variant="contained"
              className={styles.cancelButton}
              disabled={busy}
              onClick={props.onCloseClick}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              className={styles.okButton}
              disabled={busy || statuses.length === 0}
              onClick={onOkClick}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
