import { Estate } from "API";
import { InfoNameEnum } from "common/enums/InfoNameEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { StatusEnum, StatusNameEnum } from "common/enums/StatusEnum";
import { EstateLocationInputMode } from "components/Parts/Organisms/EstateLocationInputDialog/enums";
import { useCallback } from "react";
import { SearchProps } from "../../interfaces";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchEstateSearchConditionStatesType } from "../states/estateSearchConditions";
import { SearchEstateStatesType } from "../states/estates";

export const useSearchEstateLocationInputDialogHandlers = (
  props: SearchProps,
  dialogStates: SearchDialogStatesType,
  estateStates: SearchEstateStatesType,
  searchConditionStates: SearchEstateSearchConditionStatesType
) => {
  const onClose = useCallback(() => {
    dialogStates.setEstateLocationInputDialogMode(undefined);
    dialogStates.setEstateLocationInputDialogEstateId(undefined);
  }, [dialogStates]);

  const onOkButtonClick = useCallback(
    (mode: EstateLocationInputMode, inputValues: Estate | null) => {
      let currentEstates = estateStates.estates || [];

      const searchConditions = {
        ...searchConditionStates.estateSearchConditions
      };

      if (props.searchMode === SearchModeEnum.condominium) {
        searchConditions.statuses = [
          { id: StatusEnum.マンションM, name: StatusNameEnum.マンションM }
        ];
      }

      if (props.searchMode === SearchModeEnum.draft) {
        searchConditions.statuses = [
          { id: StatusEnum.下書き, name: StatusNameEnum.下書き }
        ];
        searchConditions.cities.push(
          ...[{ pref_code: "", pref_name: "", city_code: "", city_name: "" }]
        );
      }

      if (inputValues) {
        if (
          inputValues.status === StatusNameEnum.マンションM &&
          props.searchMode !== SearchModeEnum.condominium
        ) {
          return;
        }

        if (inputValues.infoName === InfoNameEnum.received) {
          return;
        }

        const newMinimalEstate = {
          ...inputValues,
          blocks: [],
          clickedCount: 0
        };

        let innerMode = mode;

        if (
          !searchConditions.statuses
            .map((x) => x.name)
            .includes(inputValues.status || undefined) ||
          !searchConditions.cities
            .map((x) => x.city_name)
            .includes(inputValues.city || "")
        ) {
          innerMode = EstateLocationInputMode.Delete;
        }

        switch (innerMode) {
          case EstateLocationInputMode.Update:
            currentEstates = [
              ...currentEstates.filter((x) => x.id !== inputValues.id),
              newMinimalEstate
            ];
            break;
          case EstateLocationInputMode.Delete:
            currentEstates = [
              ...currentEstates.filter((x) => x.id !== inputValues.id)
            ];
            break;
        }

        estateStates.setEstates(currentEstates);
        estateStates.setEstatesCount(currentEstates.length);

        onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dialogStates,
      estateStates,
      props.searchMode,
      searchConditionStates.estateSearchConditions
    ]
  );

  return {
    onClose,
    onOkButtonClick
  } as const;
};

export type SearchEstateLocationInputDialogHandlersType = ReturnType<
  typeof useSearchEstateLocationInputDialogHandlers
>;
