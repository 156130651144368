import { PanToolAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import React from "react";
import styles from "./EstateTypeSelectDialog.module.scss";
import { useEstateTypeSelectDialogHooks } from "./hooks";
import { EstateTypeSelectDialogProps } from "./interfaces";

export const EstateTypeSelectDialog = React.memo(
  (props: EstateTypeSelectDialogProps) => {
    const { estateTypes, busy, onModeClick, onOkClick } =
      useEstateTypeSelectDialogHooks(props);

    return (
      <>
        <Dialog
          style={props.style}
          className={`${props.className} ${styles.dialog}}`}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="estate-types-select-title"
          aria-describedby="estate-types-select-description"
          fullWidth
          PaperProps={{ className: styles.dialogPaper }}
          slotProps={{ backdrop: { className: styles.backdrop } }}
        >
          <DialogHeader id="estate-types-select-title" icon={<PanToolAlt />} />
          <DialogContent id="estate-types-select-description">
            <Box className={styles.paragraph}>
              <Box
                className={`${styles.button} ${
                  estateTypes.includes(EstateTypeNameEnum.土地)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(EstateTypeNameEnum.土地)}
              >
                {EstateTypeNameEnum.土地}
              </Box>
              <Box
                className={`${styles.button} ${
                  estateTypes.includes(EstateTypeNameEnum.分譲地)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(EstateTypeNameEnum.分譲地)}
              >
                {EstateTypeNameEnum.分譲地}
              </Box>
              <Box
                className={`${styles.button} ${
                  estateTypes.includes(EstateTypeNameEnum.新築建売)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(EstateTypeNameEnum.新築建売)}
              >
                {EstateTypeNameEnum.新築建売}
              </Box>
              <Box
                className={`${styles.button} ${
                  estateTypes.includes(EstateTypeNameEnum.中古戸建)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(EstateTypeNameEnum.中古戸建)}
              >
                {EstateTypeNameEnum.中古戸建}
              </Box>
              <Box
                className={`${styles.button} ${
                  estateTypes.includes(EstateTypeNameEnum.マンション)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(EstateTypeNameEnum.マンション)}
              >
                {EstateTypeNameEnum.マンション}
              </Box>
              <Box
                className={`${styles.button} ${
                  estateTypes.includes(EstateTypeNameEnum.事業用)
                    ? styles.on
                    : styles.off
                }`}
                onClick={() => onModeClick(EstateTypeNameEnum.事業用)}
              >
                {EstateTypeNameEnum.事業用}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              variant="contained"
              className={styles.cancelButton}
              disabled={busy}
              onClick={props.onCloseClick}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              className={styles.okButton}
              disabled={busy || estateTypes.length === 0}
              onClick={onOkClick}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
