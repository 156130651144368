import { Box, FormControl, FormLabel } from "@mui/material";
import { JsonMaster } from "common/interfaces/JsonMaster";
import React from "react";
import styles from "./JsonMasterSwitchButtons.module.scss";
import { JsonMasterSwitchButtonsProps } from "./interfaces";

const PrimitiveJsonMasterSwitchButtons = <T extends JsonMaster>(
  props: JsonMasterSwitchButtonsProps<T>
) => (
  <FormControl>
    <FormLabel className={styles.label} id={`${props.id}-label`}>
      {props.label}
    </FormLabel>
    <Box className={styles.wrapper}>
      {props.master.map(
        (x) =>
          x && (
            <Box
              key={x.id}
              className={`${styles.btn_switch01} ${styles.click_act01} ${
                props.value === x.name ? styles.on : styles.off
              }`}
              onClick={() => props.onChange(x)}
            >
              {x.name}
            </Box>
          )
      )}
    </Box>
  </FormControl>
);

export const JsonMasterSwitchButtons = React.memo(
  PrimitiveJsonMasterSwitchButtons
) as typeof PrimitiveJsonMasterSwitchButtons;
