import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  GetAtomicCounterQuery,
  GetAtomicCounterQueryVariables,
  IncrementEstateAtomicCounterCMutation,
  IncrementEstateAtomicCounterCMutationVariables,
  IncrementSearchAtomicCounterCMutation
} from "API";
import { generateClient } from "aws-amplify/api";
import {
  incrementEstateAtomicCounterC as incrementEstateAtomicCounterCMutation,
  incrementSearchAtomicCounterC as incrementSearchAtomicCounterCMutation
} from "graphql/mutations";
import { getAtomicCounter } from "graphql/queries";

export const getEstateAtomicCounter = async (id: string) => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(getAtomicCounter, {
      counterName: `Get#${id}`
    } as GetAtomicCounterQueryVariables),
    authMode: "userPool"
  })) as GraphQLResult<GetAtomicCounterQuery>;

  return res.data?.getAtomicCounter;
};

export const incrementEstateAtomicCounter = async (id: string) => {
  // 物件表示カウンタ増分
  const client = generateClient();
  return client.graphql({
    ...graphqlOperation(incrementEstateAtomicCounterCMutation, {
      id: id
    } as IncrementEstateAtomicCounterCMutationVariables),
    authMode: "userPool"
  }) as Promise<GraphQLResult<IncrementEstateAtomicCounterCMutation>>;
};

export const incrementSearchAtomicCounter = async () => {
  // 検索カウンタ増分
  const client = generateClient();
  return client.graphql({
    ...graphqlOperation(incrementSearchAtomicCounterCMutation),
    authMode: "userPool"
  }) as Promise<GraphQLResult<IncrementSearchAtomicCounterCMutation>>;
};
