import { Box } from "@mui/material";
import ic_chu from "assets/icons/map_menu/ic_chu.svg";
import ic_flood from "assets/icons/map_menu/ic_flood.svg";
import ic_high_tide from "assets/icons/map_menu/ic_high_tide.svg";
import ic_kozu from "assets/icons/map_menu/ic_kozu.svg";
import ic_mountain from "assets/icons/map_menu/ic_mountain.svg";
import ic_shop from "assets/icons/map_menu/ic_shop.svg";
import ic_syo from "assets/icons/map_menu/ic_syo.svg";
import ic_usage from "assets/icons/map_menu/ic_usage.svg";
import ic_wave from "assets/icons/map_menu/ic_wave.svg";
import { memo } from "react";
import styles from "../SearchMenu.module.scss";
import { SearchMenuButton } from "./SearchMenuButton";

export const DrawingMenu = memo(
  (props: {
    shopStatus: boolean;
    syoStatus: boolean;
    chuStatus: boolean;
    mountainStatus: boolean;
    floodStatus: boolean;
    waveStatus: boolean;
    highTideStatus: boolean;
    usageStatus: boolean;
    kozuStatus: boolean;
    onShopStatusChange: (on: boolean) => void;
    onSyoStatusChange: (on: boolean) => void;
    onChuStatusChange: (on: boolean) => void;
    onMountainStatusChange: (on: boolean) => void;
    onFloodStatusChange: (on: boolean) => void;
    onWaveStatusChange: (on: boolean) => void;
    onHighTideStatusChange: (on: boolean) => void;
    onUsageStatusChange: (on: boolean) => void;
    onKozuStatusChange: (on: boolean) => void;
  }) => {
    return (
      <Box component="div" className={styles.cat}>
        <Box component="div" className={styles.tit}>
          <span>地図に重ねる</span>
        </Box>
        <Box component="div" className={styles.btn}>
          <Box component="ul">
            <SearchMenuButton
              titles={["お店･施設"]}
              imgSrc={ic_shop}
              status={props.shopStatus}
              onStatusChange={props.onShopStatusChange}
            />
            <SearchMenuButton
              titles={["小学校"]}
              imgSrc={ic_syo}
              status={props.syoStatus}
              onStatusChange={props.onSyoStatusChange}
            />
            <SearchMenuButton
              titles={["中学校"]}
              imgSrc={ic_chu}
              status={props.chuStatus}
              onStatusChange={props.onChuStatusChange}
            />
            <SearchMenuButton
              titles={["土砂"]}
              imgSrc={ic_mountain}
              status={props.mountainStatus}
              onStatusChange={props.onMountainStatusChange}
            />
            <SearchMenuButton
              titles={["洪水"]}
              imgSrc={ic_flood}
              status={props.floodStatus}
              onStatusChange={props.onFloodStatusChange}
            />
            <SearchMenuButton
              titles={["高潮"]}
              imgSrc={ic_high_tide}
              status={props.highTideStatus}
              onStatusChange={props.onHighTideStatusChange}
            />
            <SearchMenuButton
              titles={["津波"]}
              imgSrc={ic_wave}
              status={props.waveStatus}
              onStatusChange={props.onWaveStatusChange}
            />
            <SearchMenuButton
              titles={["用途地域"]}
              imgSrc={ic_usage}
              status={props.usageStatus}
              onStatusChange={props.onUsageStatusChange}
            />
            <SearchMenuButton
              titles={["公図"]}
              imgSrc={ic_kozu}
              status={props.kozuStatus}
              onStatusChange={props.onKozuStatusChange}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);
