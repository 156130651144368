import { StickyNote } from "API";
import { InfoNameEnum } from "common/enums/InfoNameEnum";
import { OpenLevelNameEnum } from "common/enums/OpenLevelEnum";
import {
  createStickyNote as invokeCreateStickyNote,
  deleteStickyNote as invokeDeleteStickyNote,
  getStickyNote as invokeGetStickyNote,
  updateStickyNote as invokeUpdateStickyNote
} from "common/functions/api/stickyNotes";
import { latLngToCoordinates } from "common/functions/mapUtilities";
import { useAutoReducer } from "common/functions/reducers";
import { handleError } from "common/functions/utilities";
import { useCallback, useEffect, useState } from "react";

export const useStickyNoteHooks = (
  stickyNoteId: string | null | undefined,
  username: string | null | undefined,
  companyId: string | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const [stickyNote, initializeStickyNote, forceStickyNote, setStickyNote] =
    useAutoReducer<StickyNote>({
      memoKind: "メモ",
      openLevel: OpenLevelNameEnum.社内,
      icon: "normal",
      color: "white"
    } as StickyNote);

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  const loadStickyNote = useCallback(() => {
    if (stickyNoteId) {
      setBusy(true);
      setDisable(false);
      setError(false);

      invokeGetStickyNote(stickyNoteId, InfoNameEnum.master)
        .then((value) => {
          if (value) {
            forceStickyNote({
              ...value,
              __typename: "StickyNote"
            });
          }
        })
        .catch((reason) => {
          setDisable(true);
          setError(true);
          handleError(reason, setFatal);
        })
        .finally(() => setBusy(false));
    }
  }, [forceStickyNote, setFatal, stickyNoteId]);

  const constructStickyNoteFromInputValue = useCallback(() => {
    for (const zoom of [12, 14, 16]) {
      const { tileCoordinate } = latLngToCoordinates(
        new google.maps.LatLng({
          lat: stickyNote.latitude,
          lng: stickyNote.longitude
        }),
        zoom
      );

      if (tileCoordinate) {
        switch (zoom) {
          case 12:
            stickyNote.tileOnZoom12 = `${tileCoordinate.x},${tileCoordinate.y}`;
            break;
          case 14:
            stickyNote.tileOnZoom14 = `${tileCoordinate.x},${tileCoordinate.y}`;
            break;
          case 16:
            stickyNote.tileOnZoom16 = `${tileCoordinate.x},${tileCoordinate.y}`;
            break;
        }
      }
    }

    stickyNote.infoName = InfoNameEnum.master;
    stickyNote.companyId = companyId || "";
    stickyNote.searchInfo = "-";
    stickyNote.owner = username || "";

    return stickyNote;
  }, [stickyNote, companyId, username]);

  const createStickyNote = useCallback(async () => {
    setBusy(true);
    setError(false);

    const stickyNote = constructStickyNoteFromInputValue();

    const value = await invokeCreateStickyNote(stickyNote)
      .catch((reason) => {
        setError(true);
        handleError(reason, setFatal);
      })
      .finally(() => setBusy(false));

    if (value?.item) {
      forceStickyNote(value.item);
    }

    return value;
  }, [constructStickyNoteFromInputValue, forceStickyNote, setFatal]);

  const updateStickyNote = useCallback(async () => {
    setBusy(true);
    setError(false);

    const stickyNote = constructStickyNoteFromInputValue();

    const value = await invokeUpdateStickyNote(stickyNote)
      .catch((reason) => {
        setError(true);
        handleError(reason, setFatal);
      })
      .finally(() => setBusy(false));

    if (value?.item) {
      forceStickyNote(value.item);
    }

    return value;
  }, [constructStickyNoteFromInputValue, forceStickyNote, setFatal]);

  const deleteStickyNote = useCallback(async () => {
    setBusy(true);
    setError(false);

    const stickyNote = constructStickyNoteFromInputValue();

    const value = await invokeDeleteStickyNote(stickyNote)
      .catch((reason) => {
        setError(true);
        handleError(reason, setFatal);
      })
      .finally(() => setBusy(false));

    if (value?.item) {
      forceStickyNote(value.item);
    }

    return value;
  }, [constructStickyNoteFromInputValue, forceStickyNote, setFatal]);

  useEffect(() => {
    loadStickyNote();
  }, [loadStickyNote, stickyNoteId]);

  return {
    stickyNote,
    initializeStickyNote,
    forceStickyNote,
    setStickyNote,
    busy,
    error,
    disable,

    createStickyNote,
    updateStickyNote,
    deleteStickyNote
  } as const;
};
