import { GroupEnum } from "common/enums/GroupEnum";
import { Loading } from "components/Parts/Atoms/Loading";
import { MainWrapper } from "components/Parts/Atoms/MainWrapper";
import { HeaderBar } from "components/Parts/Molecules/HeaderBar";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import React, { Suspense } from "react";
import { SwitchAreaDialog } from "../SwitchAreaDialog";
import { useCheckEstatesHooks } from "./hooks";
import { ConditionSelector } from "./subComponents/ConditionSelector";
import { EstatesList } from "./subComponents/EstateList";
import { EstateTypeSelectDialog } from "./subComponents/EstateTypeSelectDialog";
import { LocationInputDialog } from "./subComponents/LocationInputDialog";
import { ModeSelectDialog } from "./subComponents/ModeSelectDialog";
import { ReportInputDialog } from "./subComponents/ReportInputDialog";
import { StatusSelectDialog } from "./subComponents/StatusSelectDialog";

export const CheckEstatesPage = React.memo(() => {
  const {
    mainContext,

    condition,
    openModeSelectDialog,
    openStatusSelectDialog,
    openEstateTypeSelectDialog,
    openSwitchAreaDialog,

    estates,

    clickedEstateForLocation,
    inputModeForLocation,

    clickedEstateForReport,
    inputModeForReport,

    busy,

    onModeClick,
    onModeSelectDialogClose,
    onModeSelectDialogOkClick,

    onStatusesClick,
    onStatusSelectDialogClose,
    onStatusSelectDialogOkClick,

    onEstateTypesClick,
    onEstateTypeSelectDialogClose,
    onEstateTypeSelectDialogOkClick,

    onCitiesClick,
    onSwitchAreaDialogClose,
    onSwitchAreaDialogOkClick,

    onAreaChange,

    onSearchClick,

    onEstateClick,
    onLocationInputDialogOkButtonClick,
    onReportInputDialogOkButtonClick,
    onInputClose
  } = useCheckEstatesHooks();

  return (
    <ContentMain>
      <HeaderBar
        authStatus={mainContext.authStatus}
        authUser={mainContext.authUser}
        user={mainContext.user}
        company={mainContext.company}
        userInfo={mainContext.userInfo}
        groups={mainContext.groups}
      />
      <Suspense fallback={Loading}>
        <MainWrapper>
          <ConditionSelector
            mode={condition.mode}
            statuses={condition.statuses}
            estateTypes={condition.estateTypes}
            prefs={condition.prefs}
            cities={condition.cities}
            area={condition.area}
            company={condition.company}
            onModeClick={onModeClick}
            onStatusesClick={onStatusesClick}
            onEstateTypesClick={onEstateTypesClick}
            onCitiesClick={onCitiesClick}
            onAreaChange={onAreaChange}
            onSearchClick={onSearchClick}
          />
          <EstatesList
            estates={estates}
            busy={busy}
            disabled={busy}
            onEstateClick={onEstateClick}
          />
        </MainWrapper>
        <ModeSelectDialog
          open={openModeSelectDialog}
          mode={condition.mode}
          onClose={onModeSelectDialogClose}
          onCloseClick={onModeSelectDialogClose}
          onOkClick={onModeSelectDialogOkClick}
        />
        <StatusSelectDialog
          open={openStatusSelectDialog}
          statuses={condition.statuses}
          onClose={onStatusSelectDialogClose}
          onCloseClick={onStatusSelectDialogClose}
          onOkClick={onStatusSelectDialogOkClick}
        />
        <EstateTypeSelectDialog
          open={openEstateTypeSelectDialog}
          estateTypes={condition.estateTypes}
          onClose={onEstateTypeSelectDialogClose}
          onCloseClick={onEstateTypeSelectDialogClose}
          onOkClick={onEstateTypeSelectDialogOkClick}
        />
        <SwitchAreaDialog
          open={openSwitchAreaDialog}
          isAdministrator={
            mainContext.groups?.includes(GroupEnum.Administrator) ?? false
          }
          forEstateList={true}
          setFatal={mainContext.setFatal}
          selectedPrefectures={condition.prefs}
          selectedCities={condition.cities}
          onClose={onSwitchAreaDialogClose}
          onOkButtonClick={onSwitchAreaDialogOkClick}
        />
        <LocationInputDialog
          setFatal={mainContext.setFatal}
          estate={clickedEstateForLocation}
          mode={inputModeForLocation}
          onClose={onInputClose}
          onOkButtonClick={onLocationInputDialogOkButtonClick}
        />
        <ReportInputDialog
          setFatal={mainContext.setFatal}
          estate={clickedEstateForReport}
          mode={inputModeForReport}
          onClose={onInputClose}
          onOkButtonClick={onReportInputDialogOkButtonClick}
        />
      </Suspense>
    </ContentMain>
  );
});
