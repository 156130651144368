import { Box } from "@mui/material";
import ic_menu_walk from "assets/icons/ic_menu_walk.svg";
import ic_measure from "assets/icons/map_menu/ic_measure.svg";
import ic_memo from "assets/icons/map_menu/ic_memo.svg";
import ic_square from "assets/icons/map_menu/ic_square.svg";
import { memo } from "react";
import styles from "../SearchMenu.module.scss";
import { SearchMenuButton } from "./SearchMenuButton";

export const ToolMenu = memo(
  (props: {
    measureStatus: boolean;
    squareStatus: boolean;
    naviStatus: boolean;
    memoStatus: boolean;
    onMeasureStatusChange: (on: boolean) => void;
    onSquareStatusChange: (on: boolean) => void;
    onNaviStatusChange: (on: boolean) => void;
    onMemoStatusChange: (on: boolean) => void;
  }) => {
    return (
      <Box component="div" className={styles.cat}>
        <Box component="div" className={styles.tit}>
          <span>ツール</span>
        </Box>
        <Box component="div" className={styles.btn}>
          <Box component="ul">
            <SearchMenuButton
              titles={["長さを", "測る"]}
              imgSrc={ic_measure}
              status={props.measureStatus}
              onStatusChange={props.onMeasureStatusChange}
            />
            <SearchMenuButton
              titles={["面積を", "測る"]}
              imgSrc={ic_square}
              status={props.squareStatus}
              onStatusChange={props.onSquareStatusChange}
            />
            <SearchMenuButton
              titles={["距離を", "測る"]}
              imgSrc={ic_menu_walk}
              status={props.naviStatus}
              onStatusChange={props.onNaviStatusChange}
            />
            <SearchMenuButton
              titles={["共有メモ"]}
              imgSrc={ic_memo}
              status={props.memoStatus}
              onStatusChange={props.onMemoStatusChange}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);
