import { StatusEnum, StatusNameEnum } from "common/enums/StatusEnum";
import { getStatuses } from "common/functions/jsonMasters/statuses";
import { handleError } from "common/functions/utilities";
import { Status } from "common/interfaces/Status";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useStatusLimitedHooks = (
  limitStatusName: string | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const [statuses, setStatuses] = useState<Status[]>([]);

  const { data, error } = useSWR(`statuses of ${limitStatusName}`, () =>
    getStatuses(true, true, true, true).then((value) => {
      if (value) {
        if (limitStatusName) {
          return value.filter(
            limitStatusName === StatusNameEnum.マンションM
              ? (x) => x.id === StatusEnum.マンションM
              : (x) =>
                  x.id === StatusEnum.下書き ||
                  x.id === StatusEnum.公開 ||
                  x.id === StatusEnum.成約済み ||
                  x.id === StatusEnum.非公開
          );
        }
      }

      return [];
    })
  );

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setStatuses(data);
    }
  }, [data]);

  return { statuses, setStatuses } as const;
};
