/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listCognitoUsersF = /* GraphQL */ `query ListCognitoUsersF(
  $groups: [String]
  $username: String
  $name: String
  $nameRuby: String
  $companyIds: [String]
  $email: AWSEmail
  $trial: Boolean
  $nextToken: String
) {
  listCognitoUsersF(
    groups: $groups
    username: $username
    name: $name
    nameRuby: $nameRuby
    companyIds: $companyIds
    email: $email
    trial: $trial
    nextToken: $nextToken
  ) {
    items {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        companyId
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCognitoUsersFQueryVariables,
  APITypes.ListCognitoUsersFQuery
>;
export const getCognitoUserF = /* GraphQL */ `query GetCognitoUserF($username: String) {
  getCognitoUserF(username: $username) {
    item {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        companyId
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCognitoUserFQueryVariables,
  APITypes.GetCognitoUserFQuery
>;
export const getPresignedUrlForGetF = /* GraphQL */ `query GetPresignedUrlForGetF(
  $key: String
  $companyId: String
  $estateId: String
) {
  getPresignedUrlForGetF(
    key: $key
    companyId: $companyId
    estateId: $estateId
  ) {
    url
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPresignedUrlForGetFQueryVariables,
  APITypes.GetPresignedUrlForGetFQuery
>;
export const getPresignedUrlForPutF = /* GraphQL */ `query GetPresignedUrlForPutF(
  $key: String
  $companyId: String
  $estateId: String
) {
  getPresignedUrlForPutF(
    key: $key
    companyId: $companyId
    estateId: $estateId
  ) {
    url
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPresignedUrlForPutFQueryVariables,
  APITypes.GetPresignedUrlForPutFQuery
>;
export const getPresignedUrlForDeleteF = /* GraphQL */ `query GetPresignedUrlForDeleteF(
  $key: String
  $companyId: String
  $estateId: String
) {
  getPresignedUrlForDeleteF(
    key: $key
    companyId: $companyId
    estateId: $estateId
  ) {
    url
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPresignedUrlForDeleteFQueryVariables,
  APITypes.GetPresignedUrlForDeleteFQuery
>;
export const getAtomicCounter = /* GraphQL */ `query GetAtomicCounter($counterName: String!) {
  getAtomicCounter(counterName: $counterName) {
    counterName
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAtomicCounterQueryVariables,
  APITypes.GetAtomicCounterQuery
>;
export const listBatchJobs = /* GraphQL */ `query ListBatchJobs(
  $id: ID
  $filter: ModelBatchJobFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBatchJobs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      jobName
      remarks
      status
      createdAt
      updatedAt
      expiredAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBatchJobsQueryVariables,
  APITypes.ListBatchJobsQuery
>;
export const listBatchJobsByJobName = /* GraphQL */ `query ListBatchJobsByJobName(
  $jobName: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBatchJobFilterInput
  $limit: Int
  $nextToken: String
) {
  listBatchJobsByJobName(
    jobName: $jobName
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      jobName
      remarks
      status
      createdAt
      updatedAt
      expiredAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBatchJobsByJobNameQueryVariables,
  APITypes.ListBatchJobsByJobNameQuery
>;
export const getBatchJobConf = /* GraphQL */ `query GetBatchJobConf($jobName: String!) {
  getBatchJobConf(jobName: $jobName) {
    jobName
    remarks
    allowedGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBatchJobConfQueryVariables,
  APITypes.GetBatchJobConfQuery
>;
export const listBatchJobConfs = /* GraphQL */ `query ListBatchJobConfs(
  $jobName: String
  $filter: ModelBatchJobConfFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBatchJobConfs(
    jobName: $jobName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      jobName
      remarks
      allowedGroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBatchJobConfsQueryVariables,
  APITypes.ListBatchJobConfsQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: String!, $infoName: String!) {
  getCompany(id: $id, infoName: $infoName) {
    id
    infoName
    startMark
    recNo
    name
    branchId
    branchName
    branchTel
    branchFax
    branchUrl
    branchAddress
    address
    tel
    fax
    url
    remarks
    license
    licenser
    licenseCount
    licenseNo
    guaranter
    job
    email
    logoImg
    latitude
    longitude
    areas
    areaNames
    eAreas
    eAreaNames
    estateTypes
    remarks2
    searchInfo
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $id: String
  $infoName: ModelStringKeyConditionInput
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanies(
    id: $id
    infoName: $infoName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      infoName
      startMark
      recNo
      name
      branchId
      branchName
      branchTel
      branchFax
      branchUrl
      branchAddress
      address
      tel
      fax
      url
      remarks
      license
      licenser
      licenseCount
      licenseNo
      guaranter
      job
      email
      logoImg
      latitude
      longitude
      areas
      areaNames
      eAreas
      eAreaNames
      estateTypes
      remarks2
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const listCompaniesBySearchInfo = /* GraphQL */ `query ListCompaniesBySearchInfo(
  $infoName: String!
  $searchInfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompaniesBySearchInfo(
    infoName: $infoName
    searchInfo: $searchInfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      infoName
      startMark
      recNo
      name
      branchId
      branchName
      branchTel
      branchFax
      branchUrl
      branchAddress
      address
      tel
      fax
      url
      remarks
      license
      licenser
      licenseCount
      licenseNo
      guaranter
      job
      email
      logoImg
      latitude
      longitude
      areas
      areaNames
      eAreas
      eAreaNames
      estateTypes
      remarks2
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesBySearchInfoQueryVariables,
  APITypes.ListCompaniesBySearchInfoQuery
>;
export const getEstate = /* GraphQL */ `query GetEstate($id: String!, $infoName: String!) {
  getEstate(id: $id, infoName: $infoName) {
    id
    infoName
    condominiumId
    confirmed
    status
    estateType
    name
    fullAddress
    prefecture
    city
    area
    address
    mediationKind
    currentStatus
    currentStatusFree
    traffic
    eSchool
    jSchool
    remarks
    landAreaType
    squareArea
    tsuboArea
    estatePrice
    unitPrice
    pricedAt
    priceHistory {
      price
      pricedAt
      __typename
    }
    completionSchMonth
    completionMonth
    clearingType
    cleaningMonth
    bldConditionType
    landRights
    cityPlanningType
    useDistrict
    floorAreaRatio
    bcr
    roadType
    drivewayPay
    roadDirection
    roadWidth
    water
    sewer
    gas
    groundType
    groundTypeFree
    layout
    totalArea
    structure
    structureFree
    buildingFloors
    balconyArea
    managementType
    pet
    floor
    room
    managementFee
    repairCost
    otherCost
    parking
    carsInParkingN
    parkingTakeOver
    parkingTakeOverFree
    parkingMechOrPlane
    parkingHeight
    parkingFee
    purposeB
    purposeSummaryB
    companyId
    companyName
    companyAddress
    companyTel
    companyFax
    companyUrl
    companyLicense
    companyGuaranter
    subCompanyId
    subCompanyName
    subCompanyAddress
    subCompanyTel
    subCompanyFax
    subCompanyUrl
    subCompanyLicense
    subCompanyGuaranter
    subCompany2Id
    subCompany2Name
    subCompany2Address
    subCompany2Tel
    subCompany2Fax
    subCompany2Url
    subCompany2License
    subCompany2Guaranter
    latitude
    longitude
    tileOnZoom12
    tileOnZoom14
    tileOnZoom16
    layoutImgs
    appearanceImgs
    mapImg
    waterPdf
    sewerPdf
    gasPdf
    cadastralMapPdf
    groundPdf
    url
    license
    recNo
    media
    location
    collectedAt
    floorStructure
    buildingCompany
    notFoundAt
    exactLocation
    nearlyLocation
    unsettledLocation
    isLot
    currentStatusB
    currentStatusG
    companyRecNo
    companyBranchId
    subCompanyRecNo
    completionMonthS
    condominiumName
    condominiumRecNo
    totalHouses
    manager
    seller
    estateUrl
    searchInfo
    owner
    createdAt
    updatedAt
    sold
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEstateQueryVariables, APITypes.GetEstateQuery>;
export const listEstates = /* GraphQL */ `query ListEstates(
  $id: String
  $infoName: ModelStringKeyConditionInput
  $filter: ModelEstateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEstates(
    id: $id
    infoName: $infoName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEstatesQueryVariables,
  APITypes.ListEstatesQuery
>;
export const listEstatesBySearchInfo = /* GraphQL */ `query ListEstatesBySearchInfo(
  $infoName: String!
  $searchInfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEstateFilterInput
  $limit: Int
  $nextToken: String
) {
  listEstatesBySearchInfo(
    infoName: $infoName
    searchInfo: $searchInfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEstatesBySearchInfoQueryVariables,
  APITypes.ListEstatesBySearchInfoQuery
>;
export const getPublicEstate = /* GraphQL */ `query GetPublicEstate($id: String!) {
  getPublicEstate(id: $id) {
    id
    distributer {
      id
      infoName
      startMark
      recNo
      name
      branchId
      branchName
      branchTel
      branchFax
      branchUrl
      branchAddress
      address
      tel
      fax
      url
      remarks
      license
      licenser
      licenseCount
      licenseNo
      guaranter
      job
      email
      logoImg
      latitude
      longitude
      areas
      areaNames
      eAreas
      eAreaNames
      estateTypes
      remarks2
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    estateId
    estate {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPublicEstateQueryVariables,
  APITypes.GetPublicEstateQuery
>;
export const getReport = /* GraphQL */ `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    estateId
    estateName
    reportKind
    description
    latitude
    longitude
    companyId
    companyName
    userName
    owner
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const listReports = /* GraphQL */ `query ListReports(
  $id: ID
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReports(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      estateId
      estateName
      reportKind
      description
      latitude
      longitude
      companyId
      companyName
      userName
      owner
      createdAt
      updatedAt
      expiredAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const listScrapings = /* GraphQL */ `query ListScrapings(
  $baseUrl: String
  $status: ModelStringKeyConditionInput
  $filter: ModelScrapingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listScrapings(
    baseUrl: $baseUrl
    status: $status
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      baseUrl
      status
      operation
      input
      startedAt
      totalCount
      updating {
        newRecord
        updatePrice
        expired
        return
        returnAndUpdatePrice
        scrapingFailed
        requiresAuth
        blocked
        updateFailed
        __typename
      }
      updated {
        newRecord
        updatePrice
        expired
        return
        returnAndUpdatePrice
        scrapingFailed
        requiresAuth
        blocked
        updateFailed
        __typename
      }
      resultKey
      endedAt
      output
      remarks
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScrapingsQueryVariables,
  APITypes.ListScrapingsQuery
>;
export const getStickyNote = /* GraphQL */ `query GetStickyNote($id: ID!, $infoName: String!) {
  getStickyNote(id: $id, infoName: $infoName) {
    id
    infoName
    memoKind
    openLevel
    icon
    color
    description
    companyId
    latitude
    longitude
    tileOnZoom12
    tileOnZoom14
    tileOnZoom16
    searchInfo
    owner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStickyNoteQueryVariables,
  APITypes.GetStickyNoteQuery
>;
export const listStickyNotes = /* GraphQL */ `query ListStickyNotes(
  $id: ID
  $infoName: ModelStringKeyConditionInput
  $filter: ModelStickyNoteFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStickyNotes(
    id: $id
    infoName: $infoName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      infoName
      memoKind
      openLevel
      icon
      color
      description
      companyId
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStickyNotesQueryVariables,
  APITypes.ListStickyNotesQuery
>;
export const listStickyNotesBySearchInfo = /* GraphQL */ `query ListStickyNotesBySearchInfo(
  $infoName: String!
  $searchInfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStickyNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listStickyNotesBySearchInfo(
    infoName: $infoName
    searchInfo: $searchInfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      infoName
      memoKind
      openLevel
      icon
      color
      description
      companyId
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStickyNotesBySearchInfoQueryVariables,
  APITypes.ListStickyNotesBySearchInfoQuery
>;
export const getUser = /* GraphQL */ `query GetUser($username: String!, $infoName: String!) {
  getUser(username: $username, infoName: $infoName) {
    username
    infoName
    name
    nameRuby
    tel
    role
    agreement
    trial
    companyId
    group
    email
    searchInfo
    owner
    createdAt
    updatedAt
    authenticatedAt
    companyName
    companyBranchName
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $username: String
  $infoName: ModelStringKeyConditionInput
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    username: $username
    infoName: $infoName
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      username
      infoName
      name
      nameRuby
      tel
      role
      agreement
      trial
      companyId
      group
      email
      searchInfo
      owner
      createdAt
      updatedAt
      authenticatedAt
      companyName
      companyBranchName
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const listUsersBySearchInfo = /* GraphQL */ `query ListUsersBySearchInfo(
  $infoName: String!
  $searchInfo: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsersBySearchInfo(
    infoName: $infoName
    searchInfo: $searchInfo
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      username
      infoName
      name
      nameRuby
      tel
      role
      agreement
      trial
      companyId
      group
      email
      searchInfo
      owner
      createdAt
      updatedAt
      authenticatedAt
      companyName
      companyBranchName
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsersBySearchInfoQueryVariables,
  APITypes.ListUsersBySearchInfoQuery
>;
export const getPresignedUrlForAnonymousF = /* GraphQL */ `query GetPresignedUrlForAnonymousF($key: String) {
  getPresignedUrlForAnonymousF(key: $key) {
    url
    errorMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPresignedUrlForAnonymousFQueryVariables,
  APITypes.GetPresignedUrlForAnonymousFQuery
>;
