import { Box, Slider, Theme, ThemeProvider } from "@mui/material";
import IconFilterPrice from "assets/icons/filter/ic_filter_price.svg";
import {
  estatePriceMarks,
  estatePriceMax,
  estatePriceMin
} from "common/consts/estates";
import React, { useCallback, useMemo } from "react";
import styles from "../EstateFilterDialog.module.scss";
import { SliderCaption } from "./SliderCaption";
import { useSliderHooks } from "./hooks";

const thumbClassName = "estate-price-thumb";

export const EstatePriceSlider = React.memo(
  (props: {
    sliderTheme: Theme;
    id: string;
    valueFrom: number;
    valueTo: number;
    formattedEstatePriceFrom: string;
    formattedEstatePriceTo: string;
    onChange: (
      event: Event,
      value: number | number[],
      activeThumb: number
    ) => void;
  }) => {
    const hooks = useSliderHooks({ onChange: props.onChange }, thumbClassName);

    const marks = useMemo(
      () =>
        estatePriceMarks.map((x) => ({
          value: x.value,
          label: x.displayAlways ? Number(x.label) : ""
        })),
      []
    );

    const valueText = useCallback((value: number) => {
      return value === estatePriceMin
        ? "下限なし"
        : value === estatePriceMax
        ? "上限なし"
        : `${value.toLocaleString()} 万円`;
    }, []);

    const valueLabelFormat = useCallback(
      (value: number) => {
        return valueText(value);
      },
      [valueText]
    );

    return (
      <ThemeProvider theme={props.sliderTheme}>
        <Box className={styles.box}>
          <Box className={styles.ic_tit}>
            <img src={IconFilterPrice} alt="価格" />
          </Box>
          <Box className={styles.setting}>
            <SliderCaption
              captionRef={hooks.captionRef}
              arrowRef={hooks.arrowRef}
              captionLeft={hooks.captionLeft}
              captionRight={hooks.captionRight}
              arrowLeft={hooks.arrowLeft}
              arrowRight={hooks.arrowRight}
              fromCaption={[props.formattedEstatePriceFrom, "万円"]}
              toCaption={[props.formattedEstatePriceTo, "万円"]}
              chaseThumb={hooks.chaseThumb}
            />
            <Slider
              ref={hooks.sliderRef}
              value={[props.valueFrom, props.valueTo]}
              onChange={hooks.onChange}
              valueLabelFormat={valueLabelFormat}
              aria-labelledby={props.id}
              getAriaValueText={valueText}
              valueLabelDisplay="off"
              marks={marks}
              min={estatePriceMin}
              max={estatePriceMax}
              step={null}
              slotProps={{
                thumb: {
                  className: thumbClassName
                }
              }}
            />
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
);
