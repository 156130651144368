import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import { AuthStatus } from "@aws-amplify/ui";
import {
  GetPresignedUrlForAnonymousFQuery,
  GetPresignedUrlForAnonymousFQueryVariables,
  GetPresignedUrlForDeleteFQuery,
  GetPresignedUrlForGetFQuery,
  GetPresignedUrlForPutFQuery
} from "API";
import { generateClient } from "aws-amplify/api";
import {
  getPresignedUrlForAnonymousF,
  getPresignedUrlForDeleteF,
  getPresignedUrlForGetF,
  getPresignedUrlForPutF
} from "graphql/queries";
import { getSumoraAuthToken } from "../credentials";

export const invokeGetPresignedUrlForGet = async (
  key: string,
  companyId: string,
  estateId: string | undefined
): Promise<string> => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(
      getPresignedUrlForGetF,
      estateId
        ? {
            key: key,
            companyId: companyId,
            estateId: estateId
          }
        : { key: key, companyId: companyId }
    ),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<GetPresignedUrlForGetFQuery>;

  if (res.data?.getPresignedUrlForGetF?.errorMessage) {
    throw res.data?.getPresignedUrlForGetF.errorMessage;
  }

  if (res.data?.getPresignedUrlForGetF?.url) {
    return res.data.getPresignedUrlForGetF.url;
  }

  throw new Error("ダウンロード用のURLを取得できませんでした。");
};

export const invokeGetPresignedUrlForPut = async (
  key: string,
  companyId: string,
  estateId: string | undefined
): Promise<string> => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(
      getPresignedUrlForPutF,
      estateId
        ? {
            key: key,
            companyId: companyId,
            estateId: estateId
          }
        : { key: key, companyId: companyId }
    ),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<GetPresignedUrlForPutFQuery>;

  if (res.data?.getPresignedUrlForPutF?.errorMessage) {
    throw res.data?.getPresignedUrlForPutF.errorMessage;
  }

  if (res.data?.getPresignedUrlForPutF?.url) {
    return res.data.getPresignedUrlForPutF.url;
  }

  throw new Error("アップロード用のURLを取得できませんでした。");
};

export const invokeGetPresignedUrlForDelete = async (
  key: string,
  companyId: string,
  estateId: string | undefined
): Promise<string> => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(
      getPresignedUrlForDeleteF,
      estateId
        ? {
            key: key,
            companyId: companyId,
            estateId: estateId
          }
        : { key: key, companyId: companyId }
    ),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<GetPresignedUrlForDeleteFQuery>;

  if (res.data?.getPresignedUrlForDeleteF?.errorMessage) {
    throw res.data?.getPresignedUrlForDeleteF.errorMessage;
  }

  if (res.data?.getPresignedUrlForDeleteF?.url) {
    return res.data.getPresignedUrlForDeleteF.url;
  }

  throw new Error("削除用のURLを取得できませんでした。");
};

export const invokeGetPresignedUrlForAnonymous = async (
  key: string,
  authStatus: AuthStatus
): Promise<string> => {
  const authMode = authStatus === "authenticated" ? "userPool" : "iam";

  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(getPresignedUrlForAnonymousF, {
      key: key
    } as GetPresignedUrlForAnonymousFQueryVariables),
    authMode
  })) as GraphQLResult<GetPresignedUrlForAnonymousFQuery>;

  if (res.data?.getPresignedUrlForAnonymousF?.errorMessage) {
    throw res.data?.getPresignedUrlForAnonymousF.errorMessage;
  }

  if (res.data?.getPresignedUrlForAnonymousF?.url) {
    return res.data.getPresignedUrlForAnonymousF.url;
  }

  throw new Error("ダウンロード用のURLを取得できませんでした。");
};
