import { Box, LinearProgress } from "@mui/material";
import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import React from "react";
import styles from "./SearchingProgressBackdrop.module.scss";

export const SearchingProgressBackdrop = React.memo(
  (props: {
    busy: boolean;
    news: boolean;
    own: boolean;
    estatesCount: number;
    estatesTotalCount: number;
    onModalCancelClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => {
    const message = (
      <>
        <Box>
          {props.news ? "新着" : ""}物件を取得中...
          <br />
          <small>{props.estatesCount} 件 取得しました</small>
        </Box>
        <Box className={styles.progress_wrapper}>
          <LinearProgress
            variant="determinate"
            value={Math.min(
              (props.estatesCount / (props.estatesTotalCount || 1)) * 100,
              100
            )}
            className={styles.progress}
            sx={{
              "& .MuiLinearProgress-bar": {
                backgroundColor: "rgba(255, 240, 0, 1)"
              }
            }}
          />
        </Box>
        {props.own ? (
          <Box className={styles.message}>
            ※ 取得件数には自社物件以外も含まれます
          </Box>
        ) : (
          ""
        )}
      </>
    );

    return (
      <ProgressBackdrop
        open={props.busy}
        message={message}
        showSpinner={false}
        buttonCaption="キャンセル"
        onButtonClick={props.onModalCancelClick}
      />
    );
  }
);
