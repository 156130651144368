import { SchoolArea } from "common/interfaces/SchoolArea";
import { SchoolPoint } from "common/interfaces/SchoolPoint";
import React from "react";
import { useHooks } from "./hooks";

export const SchoolPlugin = React.memo(
  ({
    schoolAreas,
    schoolPoints
  }: {
    schoolAreas: SchoolArea[];
    schoolPoints: SchoolPoint[];
  }) => {
    const { map } = useHooks(schoolAreas, schoolPoints);

    if (map) {
      return <></>;
    }

    return null;
  }
);
