import { Box } from "@mui/material";
import { Estate } from "API";
import { formatMonth } from "common/functions/utilities";
import { UseDistrictProperty } from "common/interfaces/UseDistrictGeoJSON";
import React, { useMemo } from "react";
import { ContentBasic } from "./ContentBasic";
import { ContentTail } from "./ContentTail";
import { ContentWrapper } from "./ContentWrapper";
import styles from "./DetailPage.subComponents.module.scss";

export const HouseContent = React.memo(
  (props: {
    estate?: Estate;
    eSchools?: string[];
    jSchools?: string[];
    useDistricts?: Partial<UseDistrictProperty>[];
    roadInfos: string[];
  }) => {
    const road = useMemo(() => {
      const roadStr = props.roadInfos.filter((x) => x !== "").join(" / ");

      return roadStr === "" ? null : roadStr;
    }, [props.roadInfos]);

    const currentStatus = useMemo(() => {
      const currentStatusStr = [
        props.estate?.currentStatus,
        props.estate?.currentStatusFree
      ]
        .filter((x) => x)
        .join(", ");

      return currentStatusStr === "" ? null : currentStatusStr;
    }, [props.estate?.currentStatus, props.estate?.currentStatusFree]);

    const clearingType = useMemo(() => {
      const clearingTypeStr = [
        props.estate?.clearingType,
        props.estate?.clearingType === "期日指定" && props.estate?.cleaningMonth
          ? `(${formatMonth(props.estate?.cleaningMonth)})`
          : null
      ]
        .filter((x) => x)
        .join(" ");

      return clearingTypeStr === "" ? null : clearingTypeStr;
    }, [props.estate?.cleaningMonth, props.estate?.clearingType]);

    const structure = useMemo(() => {
      const structureStr = [
        props.estate?.structure,
        props.estate?.structureFree
      ]
        .filter((x) => x)
        .join(" ");

      return structureStr === "" ? null : structureStr;
    }, [props.estate?.structure, props.estate?.structureFree]);

    return (
      <ContentWrapper>
        <Box className={styles.name}>
          <strong>{props.estate?.estateType ?? "−"}</strong>
        </Box>

        <Box className={styles.price}>
          <Box>価格</Box>
          <Box>{props.estate?.estatePrice ?? "−"} 万円</Box>
        </Box>

        <ContentBasic estate={props.estate} />

        <Box className={styles.area}>
          <Box>土地面積</Box>
          <Box>{props.estate?.tsuboArea ?? "−"} 坪</Box>
        </Box>

        <Box className={styles.road}>
          <Box>道路</Box>
          <Box>{road ?? "−"}</Box>
        </Box>

        <Box className={`${styles.conditions} ${styles.house}`}>
          <Box>現況</Box>
          <Box>{currentStatus ?? "−"}</Box>

          <Box>明渡</Box>
          <Box>{clearingType ?? "−"}</Box>
        </Box>

        <Box className={styles.totalArea}>
          <Box>建物面積</Box>
          <Box>{props.estate?.totalArea ?? "−"} ㎡</Box>

          <Box>建物階数</Box>
          <Box>{props.estate?.buildingFloors ?? "−"}</Box>
        </Box>

        <Box className={styles.structure}>
          <Box>構造</Box>
          <Box>{structure ?? "−"}</Box>
        </Box>

        <Box className={styles.layoutCompletion}>
          <Box>間取り</Box>
          <Box>{props.estate?.layout ?? "−"}</Box>

          <Box>完成</Box>
          <Box>
            {props.estate?.completionMonth
              ? formatMonth(props.estate?.completionMonth)
              : props.estate?.completionSchMonth
              ? `${formatMonth(props.estate?.completionSchMonth)} 予定`
              : "−"}
          </Box>
        </Box>

        <ContentTail
          estate={props.estate}
          eSchools={props.eSchools}
          jSchools={props.jSchools}
          useDistricts={props.useDistricts}
        />

        <Box className={`${styles.memo} ${styles.house}`}>
          <Box>メモ</Box>
          <Box></Box>
        </Box>
      </ContentWrapper>
    );
  }
);
