import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ThemeProvider
} from "@mui/material";
import Edit from "assets/icons/estate_location/ic_menu_edit.svg";
import { GroupEnum } from "common/enums/GroupEnum";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import { Confirm } from "components/Parts/Molecules/Confirm";
import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import styles from "./EstateLocationInputDialog.module.scss";
import { useInputFields } from "./fields";
import { useEstateLocationInputDialogHooks } from "./hooks";
import { EstateLocationInputDialogProps } from "./interfaces";

const idPrefix = "company-input-dialog";

export const EstateLocationInputDialog = React.memo(
  (props: EstateLocationInputDialogProps) => {
    const hooks = useEstateLocationInputDialogHooks(props);

    const {
      mainContext,
      theme,

      companyErrorMessage,
      errorMessage,
      specifyMessage,

      busy,
      confirm,
      forceDisable,

      onOkButtonClick,
      onConfirmDeleteClick,
      onConfirmDeleteCloseClick,
      onConfirmDeleteClose,
      onAdminEditButtonClick,
      onClose
    } = hooks;

    const listItems: { title: string; value: JSX.Element }[] = useInputFields(
      props,
      hooks
    )();

    const fields = useMemo(
      () =>
        listItems.map((inputField) => (
          <Grid xs={12} item key={inputField.title}>
            {inputField.value}
          </Grid>
        )),
      [listItems]
    );

    if (props.open) {
      return (
        <Dialog
          open={props.open}
          onClose={onClose}
          aria-labelledby={`${idPrefix}-title`}
          aria-describedby={`${idPrefix}-description`}
          maxWidth={false}
          disableEscapeKeyDown
          PaperProps={{ className: styles.dialogPaper }}
          slotProps={{
            backdrop: { className: styles.backdrop }
          }}
          componentsProps={{
            root: { className: styles.root }
          }}
        >
          <DialogHeader
            id={`${idPrefix}-title`}
            icon={
              <Box className={styles.icon}>
                <Box component="img" src={Edit} />
              </Box>
            }
          />
          <DialogContent
            id={`${idPrefix}-description`}
            className={styles.dialogContent}
          >
            <Collapse in={props.errorMessage !== ""}>
              <Alert severity="error" className={styles.alert}>
                <AlertTitle>エラーが発生しました！</AlertTitle>
                {props.errorMessage}
              </Alert>
            </Collapse>
            <Collapse
              in={!isEmpty(companyErrorMessage) || !isEmpty(errorMessage)}
            >
              <Alert severity="error" className={styles.alert}>
                <AlertTitle>入力エラーがあります。</AlertTitle>
                {companyErrorMessage}
                {errorMessage}
              </Alert>
            </Collapse>
            <ThemeProvider theme={theme}>{fields}</ThemeProvider>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              variant="contained"
              className={styles.cancelButton}
              disabled={busy || forceDisable}
              onClick={props.onClose}
            >
              キャンセル
            </Button>
            {mainContext.groups?.includes(GroupEnum.Administrator) && (
              <Button
                fullWidth
                className={styles.adminEditButton}
                variant="contained"
                disabled={busy || forceDisable}
                onClick={onAdminEditButtonClick}
              >
                管理者用編集へ
              </Button>
            )}
            <Button
              fullWidth
              className={styles.okButton}
              variant="contained"
              disabled={busy || forceDisable}
              onClick={onOkButtonClick}
            >
              {props.okButtonCaption}
            </Button>
          </DialogActions>
          <Box className={styles.bottomError}>{specifyMessage}</Box>
          <ProgressBackdrop open={busy || props.busy || false} />
          <Confirm
            open={confirm}
            content="物件を削除します。"
            onClose={onConfirmDeleteClose}
            onCloseClick={onConfirmDeleteCloseClick}
            onOkClick={onConfirmDeleteClick}
          />
        </Dialog>
      );
    } else {
      return null;
    }
  }
);
