import { Box } from "@mui/material";
import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import React from "react";

export const CreatingPublicEstateProgressBackdrop = React.memo(
  (props: { creatingPublicEstate: boolean }) => {
    return (
      <ProgressBackdrop
        open={props.creatingPublicEstate}
        message={
          <Box>
            印刷用ページを作成しています。
            <br />
            しばらくお待ちください。
          </Box>
        }
      />
    );
  }
);
