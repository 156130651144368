import { Company } from "API";

export interface StickyNoteSearchConditions {
  companies: Company[];
  tilesOnZoom12: string[];
  tilesOnZoom14: string[];
  tilesOnZoom16: string[];
}

export const defaultStickyNoteSearchConditions = {
  companies: [],
  tilesOnZoom12: [],
  tilesOnZoom14: [],
  tilesOnZoom16: []
} as StickyNoteSearchConditions;
