import { StatusNameEnum } from "common/enums/StatusEnum";
import { useCallback, useEffect, useState } from "react";
import { StatusSelectDialogProps } from "./interfaces";

export const useStatusSelectDialogHooks = (props: StatusSelectDialogProps) => {
  const [statuses, setStatuses] = useState<StatusSelectDialogProps["statuses"]>(
    props.statuses
  );
  const [busy, setBusy] = useState(false);

  const onModeClick = useCallback((status: StatusNameEnum) => {
    setStatuses((prev) => {
      if (prev.includes(status)) {
        return prev.filter((v) => v !== status).slice();
      } else {
        return [...prev, status];
      }
    });
  }, []);

  const onOkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // OKボタンクリック時
      setBusy(true);
    },
    []
  );

  useEffect(() => {
    setStatuses(props.statuses);
  }, [props.statuses]);

  useEffect(() => {
    if (busy) {
      props.onOkClick(statuses);
      setBusy(false);
    }
  }, [busy, statuses, props]);

  return { statuses, busy, onModeClick, onOkClick } as const;
};
