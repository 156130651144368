import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import {
  getArchives,
  getCondominiumArchives
} from "common/functions/api/estates";
import { constructSoldEstate } from "common/functions/estates";
import { useQuery } from "common/functions/utilities";
import { MinimalEstate } from "common/queries/minimalEstates";
import { MainContextContainer } from "components/Main";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ArchiveProps } from "./interfaces";

export const useArchiveHooks = (props: ArchiveProps) => {
  const initRef = useRef(true);

  const queryParams = useQuery();

  const tileParam = queryParams.get("tile");

  const groundParam = queryParams.get("g") === "1";
  const lotParam = queryParams.get("l") === "1";
  const houseParam = queryParams.get("h") === "1";
  const usedParam = queryParams.get("u") === "1";
  const condominiumParam = queryParams.get("c") === "1";
  const businessParam = queryParams.get("b") === "1";

  const midParam = queryParams.get("mid");

  const mainContext = useContext(MainContextContainer);

  const [estates, setEstates] = useState<MinimalEstate[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const condominiumName = useMemo(
    () => (midParam === null ? null : estates.at(0)?.condominiumName),
    [estates, midParam]
  );

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (tileParam || midParam) {
      (tileParam
        ? getArchives(tileParam)
        : midParam
        ? getCondominiumArchives(midParam)
        : Promise.resolve([])
      )
        .then((value) => {
          setEstates(
            value
              .filter(
                (x) =>
                  (groundParam && x.estateType === EstateTypeNameEnum.土地) ||
                  ((lotParam || groundParam) &&
                    x.estateType === EstateTypeNameEnum.分譲地) ||
                  (houseParam &&
                    x.estateType === EstateTypeNameEnum.新築建売) ||
                  (usedParam && x.estateType === EstateTypeNameEnum.中古戸建) ||
                  (condominiumParam &&
                    x.estateType === EstateTypeNameEnum.マンション) ||
                  (businessParam && x.estateType === EstateTypeNameEnum.事業用)
              )
              .map((x) => constructSoldEstate(x as MinimalEstate, true, false))
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    businessParam,
    condominiumParam,
    groundParam,
    houseParam,
    lotParam,
    midParam,
    tileParam,
    usedParam
  ]);

  return {
    mainContext,
    estates,
    condominiumName,
    isLoading
  };
};
