import { Download } from "@mui/icons-material";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import styles from "./DownloadButton.module.scss";
import { useDownloadButtonHooks } from "./hooks";
import { DownloadButtonProps } from "./interfaces";

export const DownloadButton = React.memo((props: DownloadButtonProps) => {
  const { open, handleTooltipClose } = useDownloadButtonHooks(props);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        className: styles.downloadButtonToolTipPopper,
        sx: {
          ".MuiTooltip-tooltip": {
            color: "black",
            backgroundColor: "white",
            border: "1px solid rgba(235, 220, 0, 1)",
            fontSize: "90%"
          }
        }
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={props.toolTipText ?? "コピーしました"}
    >
      <IconButton
        aria-label="ダウンロード"
        style={props.style}
        className={`${styles.downloadButton} ${props.className ?? ""}`}
        disabled={props.busy}
        onClick={props.onClick}
      >
        {props.busy ? (
          <CircularProgress size={14} className={styles.busy} />
        ) : (
          <Icon className={styles.icon}>
            <Download />
          </Icon>
        )}
      </IconButton>
    </Tooltip>
  );
});
