import {
  copyRouteLocations,
  deleteFrom,
  deleteTo,
  deleteWaypoint,
  specifyNearest
} from "common/functions/mapUtilities";
import { useCallback } from "react";
import { RouteLocations, SearchMapHandlerProperties } from "../../interfaces";
import { RouteLocationStatus } from "../../types";

export const useRouteMethods = () => {
  // ルート開始地点設定
  const setRouteFrom = useCallback(
    (
      location: google.maps.LatLng | null,
      routeLocations: RouteLocations,
      allContextMenuClose: () => void,
      onRouteLocationsChange: (routeLocations: RouteLocations) => void,
      onToggleSearchTrigger: () => void
    ) => {
      const position = location;

      if (position) {
        const copiedRouteLocations = copyRouteLocations(routeLocations);

        deleteFrom(copiedRouteLocations, position);
        deleteTo(copiedRouteLocations, position);
        deleteWaypoint(copiedRouteLocations, position);

        if (copiedRouteLocations.from) {
          copiedRouteLocations.waypoints.unshift(
            new google.maps.LatLng({
              lat: copiedRouteLocations.from.lat(),
              lng: copiedRouteLocations.from.lng()
            })
          );
        }

        copiedRouteLocations.from = new google.maps.LatLng({
          lat: position.lat(),
          lng: position.lng()
        });

        onRouteLocationsChange(copiedRouteLocations);
        onToggleSearchTrigger();
      }

      allContextMenuClose();
    },
    []
  );

  // 経由を追加
  const addRouteWaypoint = useCallback(
    (
      location: google.maps.LatLng | null,
      routeLocations: RouteLocations,
      allContextMenuClose: () => void,
      onRouteLocationsChange: (routeLocations: RouteLocations) => void,
      onToggleSearchTrigger: () => void
    ) => {
      if (location) {
        const copiedRouteLocations = copyRouteLocations(routeLocations);

        deleteFrom(copiedRouteLocations, location);
        deleteTo(copiedRouteLocations, location);
        deleteWaypoint(copiedRouteLocations, location);

        copiedRouteLocations.waypoints.push(
          new google.maps.LatLng({
            lat: location.lat(),
            lng: location.lng()
          })
        );

        onRouteLocationsChange(copiedRouteLocations);
        onToggleSearchTrigger();
      }

      allContextMenuClose();
    },
    []
  );

  // 経由地に設定
  const setRouteWaypoint = useCallback(
    (
      location: google.maps.LatLng | null,
      routeLocations: RouteLocations,
      allContextMenuClose: () => void,
      onRouteLocationsChange: (routeLocations: RouteLocations) => void,
      onToggleSearchTrigger: () => void
    ) => {
      if (location) {
        const copiedRouteLocations = copyRouteLocations(routeLocations);

        const fromExists = deleteFrom(copiedRouteLocations, location);
        const toExists = deleteTo(copiedRouteLocations, location);
        deleteWaypoint(copiedRouteLocations, location);

        if (fromExists) {
          copiedRouteLocations.from = null;

          if (copiedRouteLocations.waypoints.length > 0) {
            const firstWaypoint = copiedRouteLocations.waypoints.splice(0, 1);

            copiedRouteLocations.from = firstWaypoint.at(0) || null;
          }

          copiedRouteLocations.waypoints.unshift(
            new google.maps.LatLng({
              lat: location.lat(),
              lng: location.lng()
            })
          );
        } else if (toExists) {
          copiedRouteLocations.to = null;

          if (copiedRouteLocations.waypoints.length > 0) {
            const lastWaypoint = copiedRouteLocations.waypoints.pop();

            copiedRouteLocations.to = lastWaypoint || null;
          }

          copiedRouteLocations.waypoints.push(
            new google.maps.LatLng({
              lat: location.lat(),
              lng: location.lng()
            })
          );
        }

        onRouteLocationsChange(copiedRouteLocations);
        onToggleSearchTrigger();
      }

      allContextMenuClose();
    },
    []
  );

  // ここまでのルート
  const setRouteTo = useCallback(
    (
      location: google.maps.LatLng | null,
      routeLocations: RouteLocations,
      allContextMenuClose: () => void,
      onRouteLocationsChange: (routeLocations: RouteLocations) => void,
      onToggleSearchTrigger: () => void
    ) => {
      if (location) {
        const copiedRouteLocations = copyRouteLocations(routeLocations);

        deleteFrom(copiedRouteLocations, location);
        deleteTo(copiedRouteLocations, location);
        deleteWaypoint(copiedRouteLocations, location);

        if (copiedRouteLocations.to) {
          copiedRouteLocations.waypoints.push(
            new google.maps.LatLng({
              lat: copiedRouteLocations.to.lat(),
              lng: copiedRouteLocations.to.lng()
            })
          );
        }

        copiedRouteLocations.to = new google.maps.LatLng({
          lat: location.lat(),
          lng: location.lng()
        });

        onRouteLocationsChange(copiedRouteLocations);
        onToggleSearchTrigger();
      }

      allContextMenuClose();
    },
    []
  );

  // ポイント削除
  const deleteRouteLocationWithLocation = useCallback(
    (
      location: google.maps.LatLng | null | undefined,
      rightClickedLocation: google.maps.LatLng | null,
      routeLocations: RouteLocations,
      allContextMenuClose: () => void,
      onTravelModeClear: () => void,
      onRouteLocationsChange: (routeLocations: RouteLocations) => void,
      onToggleSearchTrigger: () => void
    ) => {
      const copiedRouteLocations = copyRouteLocations(routeLocations);
      let position: google.maps.LatLng | null = null;

      if (location === undefined) {
        position = rightClickedLocation;
      } else if (location) {
        const nearest = specifyNearest(
          [
            ...copiedRouteLocations.waypoints,
            copiedRouteLocations.from,
            copiedRouteLocations.to
          ],
          location
        );

        position = nearest;
      }

      if (position) {
        deleteFrom(copiedRouteLocations, position);
        deleteTo(copiedRouteLocations, position);
        deleteWaypoint(copiedRouteLocations, position);

        if (
          copiedRouteLocations.from === null &&
          copiedRouteLocations.to === null &&
          copiedRouteLocations.waypoints.length === 0
        ) {
          onTravelModeClear();
        }

        onRouteLocationsChange(copiedRouteLocations);
        onToggleSearchTrigger();
      }

      allContextMenuClose();
    },
    []
  );

  // ルート地点マーカー構築
  const constructRoutePointMarker = useCallback(
    (
      map: google.maps.Map | null,
      location: google.maps.LatLng,
      status: RouteLocationStatus,
      label: string,
      locations: RouteLocations,
      onRouteLocationContextMenu: (
        event: Event,
        routeLocationLatLng: google.maps.LatLng,
        routeLocationStatus: RouteLocationStatus
      ) => void,
      properties: SearchMapHandlerProperties
    ) => {
      const content = document.createElement("div");

      content.innerHTML = `
        <div class="route-point-marker">
          <div>${label}</div>
        </div>
      `;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: location.lat(),
          lng: location.lng()
        },
        content: content,

        zIndex: 300000
      });

      properties.routeLocationMarkers.push(marker);

      const nearest = specifyNearest(
        [...locations.waypoints, locations.from, locations.to],
        location
      );

      marker.addListener("click", (event: google.maps.MapMouseEvent) => {
        if (properties.searchRouteMode) {
          onRouteLocationContextMenu(
            event.domEvent,
            nearest || location,
            status
          );
        }
      });

      marker.element?.addEventListener("contextmenu", (event) => {
        onRouteLocationContextMenu(event, nearest || location, status);
        event.stopPropagation();
        return false;
      });

      if (map) {
        marker.map = map;
      }

      return marker;
    },
    []
  );

  return {
    setRouteFrom,
    addRouteWaypoint,
    setRouteWaypoint,
    setRouteTo,
    deleteRouteLocationWithLocation,
    constructRoutePointMarker
  } as const;
};
