import { Stack } from "@mui/material";
import { ResetPositionOfEstateInfoButton } from "components/Parts/Atoms/ResetPositionOfEstateInfoButton";
import { SelectingEstatesButton } from "components/Parts/Atoms/SelectingEstatesButton";
import React from "react";
import { FillingSchoolPolygonsButton } from "../../Atoms/FillingSchoolPolygonsButton";
import { MeasureModeButton } from "../../Atoms/MeasureModeButton";
import styles from "./ClearButtons.module.scss";
import { useHooks } from "./hooks";
import { ClearButtonsProps } from "./interfaces";

export const ClearButtons = React.memo((props: ClearButtonsProps) => {
  const { stack } = useHooks(props);

  return (
    <Stack gap={2} className={styles.clearButtons}>
      {stack.map((x) => (
        <React.Fragment key={x}>
          {x === "measure" ? (
            <MeasureModeButton
              measureLengthMode={props.measureLengthMode}
              measureAreaMode={props.measureAreaMode}
              onQuitMeasureClick={props.onQuitMeasureClick}
            />
          ) : x === "fillingSchoolPolygons" ? (
            <FillingSchoolPolygonsButton
              fillingSchoolPolygons={props.fillingSchoolPolygons}
              onClearFillingSchoolPolygonsClick={
                props.onClearFillingSchoolPolygonsClick
              }
            />
          ) : x === "selectingEstates" && !props.isStreetView ? (
            <SelectingEstatesButton
              selectingEstates={props.selectingEstates}
              onClearSelectedEstatesClick={props.onClearSelectedEstatesClick}
            />
          ) : x === "resetPositionOfEstateInfo" && !props.isStreetView ? (
            <ResetPositionOfEstateInfoButton
              selectingEstates={props.selectingEstates}
              onResetPositionOfEstateInfoClick={
                props.onResetPositionOfEstateInfoClick
              }
            />
          ) : null}
        </React.Fragment>
      ))}
    </Stack>
  );
});
