import { Box } from "@mui/material";
import ic_eye from "assets/icons/map_menu/ic_eye.svg";
import ic_filter from "assets/icons/map_menu/ic_filter.svg";
import ic_history from "assets/icons/map_menu/ic_history.svg";
import ic_new_price from "assets/icons/map_menu/ic_new_price.svg";
import ic_price from "assets/icons/map_menu/ic_price.svg";
import ic_size from "assets/icons/map_menu/ic_size.svg";
import ic_switch from "assets/icons/map_menu/ic_switch.svg";
import ic_update from "assets/icons/map_menu/ic_update.svg";
import { memo } from "react";
import styles from "../SearchMenu.module.scss";
import { SearchMenuButton } from "./SearchMenuButton";

export const SearchConfigMenu = memo(
  (props: {
    switchStatus: boolean;
    filterStatus: boolean;
    newEstateStatus: boolean;
    updatePriceStatus: boolean;
    historyStatus: boolean;
    priceStatus: boolean;
    sizeStatus: boolean;
    eyeStatus: boolean;
    onSwitchClick: () => void;
    onFilterClick: () => void;
    onNewEstateStatusChange: (on: boolean) => void;
    onUpdatePriceStatusChange: (on: boolean) => void;
    onHistoryStatusChange: (on: boolean) => void;
    onPriceStatusChange: (on: boolean) => void;
    onSizeStatusChange: (on: boolean) => void;
    onEyeStatusChange: (on: boolean) => void;
  }) => {
    return (
      <Box component="div" className={styles.cat}>
        <Box component="div" className={styles.tit}>
          <span>探し方</span>
        </Box>
        <Box component="div" className={styles.btn}>
          <Box component="ul">
            <SearchMenuButton
              titles={["エリア", "切り替え"]}
              imgSrc={ic_switch}
              status={props.switchStatus}
              onClick={props.onSwitchClick}
            />
            <SearchMenuButton
              titles={["条件設定"]}
              imgSrc={ic_filter}
              status={props.filterStatus}
              onClick={props.onFilterClick}
            />
            <SearchMenuButton
              titles={["新着"]}
              imgSrc={ic_update}
              status={props.newEstateStatus}
              onStatusChange={props.onNewEstateStatusChange}
            />
            <SearchMenuButton
              titles={["価格変更"]}
              imgSrc={ic_new_price}
              status={props.updatePriceStatus}
              onStatusChange={props.onUpdatePriceStatusChange}
            />
            <SearchMenuButton
              titles={["過去事例"]}
              imgSrc={ic_history}
              status={props.historyStatus}
              onStatusChange={props.onHistoryStatusChange}
            />
            <SearchMenuButton
              titles={["価格ラベル"]}
              imgSrc={ic_price}
              status={props.priceStatus}
              onStatusChange={props.onPriceStatusChange}
            />
            <SearchMenuButton
              titles={["坪ラベル"]}
              imgSrc={ic_size}
              status={props.sizeStatus}
              onStatusChange={props.onSizeStatusChange}
            />
            <SearchMenuButton
              titles={["公開物件"]}
              imgSrc={ic_eye}
              status={props.eyeStatus}
              onStatusChange={props.onEyeStatusChange}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);
