import { Autocomplete, Box, Typography } from "@mui/material";
import React from "react";
import { AutocompleteRenderInputTextBox } from "../AutocompleteRenderInputTextBox";
import { usePrefectureAutocompleteHooks } from "./hooks";
import { PrefectureAutocompleteProps } from "./interfaces";

const PrefectureAutocompleteImplements = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: PrefectureAutocompleteProps<Multiple, DisableClearable, FreeSolo>
) => {
  const {
    prefectures,
    referenceStyles,
    getOptionLabel,
    getOptionSelected,
    convertValue
  } = usePrefectureAutocompleteHooks<Multiple, DisableClearable, FreeSolo>(
    props.multiple,
    props.setFatal,
    props.onOptionsLoad
  );

  const {
    label: _label,
    helperText: _helperText,
    errorMessage: _errorMessage,
    referenceText: _referenceText,
    required: _required,
    InputProps: _InputProps,
    onOptionsLoad: _onOptionsLoad,
    ...autocompleteProps
  } = props;

  delete autocompleteProps.setFatal;

  return (
    <>
      <Autocomplete
        {...autocompleteProps}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <AutocompleteRenderInputTextBox
            params={params}
            helperText={props.helperText}
            errorMessage={props.errorMessage}
            referenceText={props.referenceText}
            required={props.required}
            InputProps={props.InputProps}
            label={props.label || "都道府県を選択"}
          />
        )}
        options={prefectures}
        value={convertValue(props.value)}
        isOptionEqualToValue={getOptionSelected}
        disabled={prefectures.length === 0 || props.disabled}
        noOptionsText={<i>候補が見つかりません</i>}
      ></Autocomplete>
      <Box sx={referenceStyles}>
        <Typography variant="caption">{props.referenceText}</Typography>
      </Box>
    </>
  );
};

export const PrefectureAutocomplete = React.memo(
  PrefectureAutocompleteImplements
) as typeof PrefectureAutocompleteImplements;
