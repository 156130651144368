import { ReportInputDialog as ImplementedReportInputDialog } from "components/Parts/Organisms/ReportInputDialog";
import { ReportInputMode } from "components/Parts/Organisms/ReportInputDialog/enums";
import { isEmpty } from "lodash";
import React from "react";
import { ReportInputDialogProps } from "./interface";

export const ReportInputDialog = React.memo((props: ReportInputDialogProps) => {
  return (
    <ImplementedReportInputDialog
      open={!isEmpty(props.mode)}
      okButtonCaption="報告"
      busy={false}
      mode={props.mode === "report" ? ReportInputMode.Create : null}
      estateId={props.estate?.id || null}
      errorMessage=""
      onClose={props.onClose}
      onOkButtonClick={props.onOkButtonClick}
    />
  );
});
