import { getCitiesFromPrefs } from "common/functions/jsonMasters/cities";
import { handleError } from "common/functions/utilities";
import { City } from "common/interfaces/City";
import { Prefecture } from "common/interfaces/Prefecture";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { usePrefectureHooks } from "./prefectureHooks";

export const useCityHooks = (
  prefectures: (Prefecture | string | null) | (Prefecture | string)[],
  setFatal: ((error?: string | null | undefined) => void) | undefined
) => {
  const { prefectures: prefecturesMaster } = usePrefectureHooks(setFatal);
  const [cities, setCities] = useState<City[]>([]);
  const prefecturesArray =
    prefectures === null
      ? []
      : typeof prefectures === "string" || "pref_name" in prefectures
      ? [prefectures]
      : prefectures;

  const { data, error } = useSWR(
    `cities of ${prefecturesArray
      .map((x) => (typeof x === "string" ? x : x.pref_name))
      .join(", ")}`,
    () => {
      if (prefecturesArray.length > 0) {
        const prefs = prefecturesMaster.filter((x) =>
          prefecturesArray
            .map((x) => (typeof x === "string" ? x : x.pref_name))
            .includes(x.pref_name)
        );

        if (prefs) {
          return getCitiesFromPrefs(prefs, false);
        }
      }

      return [];
    }
  );

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setCities(data);
    }
  }, [data]);

  return { cities, setCities } as const;
};

export const usePrefectureAndCityFromCityCodesHooks = (
  cityCodes: (string | null)[] | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const { prefectures } = usePrefectureHooks(setFatal);
  const selectedPrefectureCodes = [
    ...new Set(
      cityCodes?.map((x) => ((x?.length || 0) >= 2 ? x?.substring(0, 2) : ""))
    ).values()
  ].filter((x) => x);

  const selectedPrefectures = prefectures.filter((x) =>
    selectedPrefectureCodes.includes(x.pref_code)
  );

  const { cities } = useCityHooks(selectedPrefectures, setFatal);

  const selectedCities = cities.filter((x) => cityCodes?.includes(x.city_code));

  return { selectedPrefectures, selectedCities } as const;
};
