import { StickyNote } from "API";
import { meshDisplayableZoom } from "common/consts/estates";
import { stickyNoteSearchableZoom } from "common/consts/stickyNotes";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { addEstateToList } from "common/functions/estates";
import {
  callRoute,
  clearMarker,
  clearMarkerClusterer
} from "common/functions/mapUtilities";
import { MinimalEstate } from "common/queries/minimalEstates";
import { mapKeys, throttle } from "lodash";
import "long-press-event";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  RouteLocations,
  SearchMapHandlerProperties,
  SearchMapProps
} from "../interfaces";
import { RouteLocationStatus } from "../types";
import { useEstateMethods } from "./methods/estates";
import { useMapStyleMethods } from "./methods/mapStyles";
import { useMarkerMethods } from "./methods/markers";
import { useRouteMethods } from "./methods/routes";
import { useStickyNoteMethods } from "./methods/stickyNotes";
import { useUtilityMethods } from "./methods/utilities";
import { useSearchMapStates } from "./states";

export const useSearchMapHooks = (props: SearchMapProps) => {
  const initRef = useRef(true);
  const initialDrawEstatesRef = useRef(true);

  const idleListenerOfShowEstateMarkersInBoundRef =
    useRef<google.maps.MapsEventListener | null>(null);
  const idleListenerOfShowSoldMarkersInBoundRef =
    useRef<google.maps.MapsEventListener | null>(null);
  const idleListenerOfShowStickyNoteMarkersInBoundRef =
    useRef<google.maps.MapsEventListener | null>(null);

  const properties = useRef<SearchMapHandlerProperties>({
    myPositionMarker: null,
    placeMarker: null,
    estateMarkers: {},
    soldEstateMarkers: {},
    markerClusterer: null,
    circleIds: [],
    routeLocationMarkers: [],
    directionRenderer: null,

    stickyNoteMarkers: {},
    stickyNoteInfoWindows: {},

    searchRouteMode: false,
    measureLengthMode: false,
    measureAreaMode: false,
    editMode: false
  });

  const [fillingSchoolPolygons, setFillingSchoolPolygons] = useState(false);

  const {
    map,
    setMap,
    previousMap,
    setPreviousMap,

    directionService,
    setDirectionService,

    estateContextMenuPosition,
    setEstateContextMenuPosition,
    estateContextMenuEstate,
    setEstateContextMenuEstate,
    mapContextMenuPosition,
    setMapContextMenuPosition,
    routeLocationContextMenuInfo,
    setRouteLocationContextMenuInfo,
    schoolContextMenuPosition,
    setSchoolContextMenuPosition,
    rightClickedLocation,
    setRightClickedLocation,

    citiesOfSearchConditions,
    setCitiesOfSearchConditions,

    dspEstates,
    setDspEstates,

    selectingEstates,
    triggerResetPosition,
    setTriggerResetPosition,

    triggerSwitchStreetView,
    setTriggerSwitchStreetView,

    domEvent,
    setDomEvent
  } = useSearchMapStates();

  const { applyMapStyle } = useMapStyleMethods(map);

  const {
    constructMyPositionMarker,
    constructPlaceMarker,
    showMarkersInBound
  } = useMarkerMethods();

  const {
    clearAllEstateMarkers,
    clearAllRouteLocationMarkers,
    clearAllSoldEstateMarkers,
    clearAllStickyNoteMarkers,
    clearAllStickyNoteInfoWindows
  } = useUtilityMethods();

  const {
    showCaptionsOnEstatesChangeAndMarkerDspColumnsChange,
    showSelectedEffectOfEstate,
    constructEstateMarker,
    constructEstateMarkerForStreetView,
    constructMarkerClustererOfEstate,
    constructSoldEstateMarker,
    fitBoundFromEstates
  } = useEstateMethods(props.company?.recNo ?? null);

  const {
    setRouteFrom,
    addRouteWaypoint,
    setRouteWaypoint,
    setRouteTo,
    deleteRouteLocationWithLocation,
    constructRoutePointMarker
  } = useRouteMethods();

  const {
    constructStickyNoteMarker,
    constructStickyNoteInfoWindow,
    fitBoundFromStickyNotes
  } = useStickyNoteMethods();

  // APIロード完了時
  const onGoogleApiLoaded = useCallback(
    (maps: {
      map: google.maps.Map | null | undefined;
      maps: any;
      ref: Element | null;
    }) => {
      if (maps.map) {
        setMap((previousMap) => {
          if (previousMap !== maps.map) {
            setPreviousMap(previousMap);
          }

          return maps.map ?? null;
        });

        props.onMapReady(maps.map);

        const panorama = maps.map.getStreetView();
        panorama.setOptions({
          addressControl: false,
          addressControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM
          }
        });
        panorama.addListener("visible_changed", () => {
          setTriggerSwitchStreetView((trigger) => !trigger);
        });

        const overlay = new google.maps.OverlayView();
        overlay.setMap(maps.map);

        if (isMobile) {
          maps.map.getDiv().setAttribute("data-long-press-delay", "300");
        }

        maps.map.addListener(
          "contextmenu",
          (event: google.maps.MapMouseEvent) => {
            if (event.latLng) {
              onMapContextMenu(event.domEvent, event.latLng);
            }
            return false;
          }
        );

        if (isMobile) {
          maps.map.getDiv().addEventListener("long-press", (event) => {
            if (
              properties.current.measureLengthMode ||
              properties.current.measureAreaMode
            ) {
            } else {
              setTimeout(() => {
                const customEvent = event as CustomEvent;
                if (
                  maps.map &&
                  customEvent.detail?.clientX &&
                  customEvent.detail?.clientY
                ) {
                  const rect = maps.map.getDiv().getBoundingClientRect();
                  const latLng = overlay
                    .getProjection()
                    .fromContainerPixelToLatLng(
                      new google.maps.Point(
                        customEvent.detail.clientX - rect.left,
                        customEvent.detail.clientY - rect.top
                      )
                    );
                  if (latLng) {
                    onMapContextMenu(event, latLng);
                  }
                }

                return false;
              }, 300);
            }
          });
        }

        maps.map.addListener("idle", () => {
          const zoom = maps.map?.getZoom();
          const center = maps.map?.getCenter();

          if (zoom && center) {
            props.onBoundChange(zoom, { lat: center.lat(), lng: center.lng() });
          }
        });

        properties.current.directionRenderer =
          new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            preserveViewport: true,
            draggable: true,
            polylineOptions: {
              strokeColor: "#D93751",
              strokeOpacity: 0.8,
              strokeWeight: 5
            }
          });

        const directionService = new google.maps.DirectionsService();
        setDirectionService(directionService);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Map破棄
  const onDestruct = useCallback(() => {
    clearMarker(properties.current.myPositionMarker);
    clearMarker(properties.current.placeMarker);
    clearAllEstateMarkers(properties.current);
    clearAllStickyNoteMarkers(properties.current);
    clearMarkerClusterer(properties.current.markerClusterer);
    clearAllRouteLocationMarkers(properties.current);

    if (map) {
      google.maps.event.clearInstanceListeners(map);
    }
  }, [
    clearAllEstateMarkers,
    clearAllRouteLocationMarkers,
    clearAllStickyNoteMarkers,
    map
  ]);

  // 自店舗の位置取得完了時
  const onMyPositionConfirmed = useCallback(() => {
    if (map) {
      if (props.myPosition) {
        const previousMap = properties.current.myPositionMarker?.map;
        const position = properties.current.myPositionMarker?.position;

        if (
          previousMap !== map ||
          position === null ||
          position === undefined ||
          (typeof position.lat === "number" ? position.lat : position.lat()) !==
            props.myPosition.lat ||
          (typeof position.lng === "number" ? position.lng : position.lng()) !==
            props.myPosition.lng
        ) {
          clearMarker(properties.current.myPositionMarker);

          properties.current.myPositionMarker = constructMyPositionMarker(
            props.myPosition,
            onMapContextMenu,
            properties.current
          );
          properties.current.myPositionMarker.map = map;
        }
      } else {
        clearMarker(properties.current.myPositionMarker);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.myPosition, map]);

  // 場所マーカー共通処理
  const putPlaceMarker = useCallback(
    (
      placePosition: google.maps.LatLngLiteral | undefined,
      forceZoom: boolean
    ) => {
      if (map) {
        if (placePosition) {
          const previousPosition = properties.current.placeMarker?.position;

          clearMarker(properties.current.placeMarker);

          const position: google.maps.LatLngLiteral = {
            lat: placePosition.lat,
            lng: placePosition.lng
          };

          if (
            previousPosition === null ||
            previousPosition === undefined ||
            (typeof previousPosition.lat === "number"
              ? previousPosition.lat
              : previousPosition.lat()) !== placePosition.lat ||
            (typeof previousPosition.lng === "number"
              ? previousPosition.lng
              : previousPosition.lng()) !== placePosition.lng
          ) {
            map.setCenter(position);

            if (forceZoom && props.zoom < props.boundSearchableZoom) {
              map.setZoom(props.boundSearchableZoom);
            }
          }

          properties.current.placeMarker = constructPlaceMarker(
            position,
            onMapContextMenu,
            properties.current
          );
          properties.current.placeMarker.map = map;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.boundSearchableZoom, props.zoom, map]
  );

  // 現在の位置変化時
  const onCurrentPositionChange = useCallback(
    (
      currentPosition: google.maps.LatLngLiteral | undefined,
      forceZoom: boolean
    ) => putPlaceMarker(currentPosition, forceZoom),
    [putPlaceMarker]
  );

  // 場所検索結果変化時
  const onPlacesChange = useCallback(
    (places?: google.maps.places.PlaceResult) => {
      if (places) {
        const lat = places.geometry?.location?.lat();
        const lng = places.geometry?.location?.lng();

        const position =
          lat && lng
            ? {
                lat: lat,
                lng: lng
              }
            : undefined;

        putPlaceMarker(position, true);
      }
    },
    [putPlaceMarker]
  );

  // ズーム変化時
  const onZoomChange = useCallback(() => {}, []);

  // 物件リスト変化時
  const onEstatesChange = useCallback(
    (
      estates: (MinimalEstate | null)[] | undefined,
      closedEstates: (MinimalEstate | null)[] | undefined,
      fitBounds: boolean
    ) => {
      if (map) {
        const isStreetView = map.getStreetView().getVisible();

        if (
          (isStreetView &&
            (Object.values(properties.current.estateMarkers) ?? []).some(
              (x) =>
                x.marker instanceof google.maps.marker.AdvancedMarkerElement
            )) ||
          (!isStreetView &&
            (Object.values(properties.current.estateMarkers) ?? []).some(
              (x) => x.marker instanceof google.maps.Marker
            ))
        ) {
          clearAllEstateMarkers(properties.current);
        }

        if (estates) {
          const allEstateDict = mapKeys(
            [...estates, ...(closedEstates || [])],
            (x) => x?.id || ""
          );

          const allEstates = Object.values(allEstateDict) ?? [];

          properties.current.estateMarkers = Object.assign(
            {},
            ...allEstates
              .filter(
                (estate) => estate && estate?.latitude && estate?.longitude
              )
              .map((estate) => {
                const marker = isStreetView
                  ? constructEstateMarkerForStreetView(
                      estate,
                      setDspEstates,
                      properties.current
                    )
                  : constructEstateMarker(
                      estate,
                      onEstateContextMenu,
                      setDspEstates,
                      properties.current
                    );

                return {
                  [estate?.id || ""]: { data: estate, marker }
                };
              })
          );

          const markers = (
            Object.values(properties.current.estateMarkers) ?? []
          ).map((x) => x.marker);

          if (fitBounds) {
            fitBoundFromEstates(map, allEstates);
          }

          const showEstateMarkersInBound = () => {
            const visibleMarkers = showMarkersInBound(map, markers);

            if (properties.current.markerClusterer) {
              clearMarkerClusterer(properties.current.markerClusterer);
            }

            properties.current.markerClusterer =
              constructMarkerClustererOfEstate(
                map,
                props.measureLengthMode,
                props.measureAreaMode,
                visibleMarkers
              );
          };

          if (idleListenerOfShowEstateMarkersInBoundRef.current) {
            google.maps.event.removeListener(
              idleListenerOfShowEstateMarkersInBoundRef.current
            );
          }

          idleListenerOfShowEstateMarkersInBoundRef.current = map.addListener(
            "idle",
            showEstateMarkersInBound
          );

          showEstateMarkersInBound();
        } else {
          clearAllEstateMarkers(properties.current);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, props.measureLengthMode, props.measureAreaMode]
  );

  // 成約済み物件リスト、ズーム値変化時
  const onSoldEstatesChange = useCallback(
    (
      zoom: number,
      soldEstates: (MinimalEstate | null)[] | undefined,
      fitBounds: boolean
    ) => {
      if (map) {
        const previousMap = (
          Object.values(properties.current.soldEstateMarkers) ?? []
        ).at(-1)?.marker?.map;

        if (
          previousMap?.get("mono") !== map.get("mono") ||
          previousMap?.get("dspPoi") !== map.get("dspPoi")
        ) {
          clearAllSoldEstateMarkers(properties.current);
        }

        if (zoom >= meshDisplayableZoom && soldEstates) {
          const allSoldEstateDict = mapKeys(soldEstates, (x) => x?.id || "");

          const allSoldEstates = Object.values(allSoldEstateDict) ?? [];

          properties.current.soldEstateMarkers = Object.assign(
            {},
            ...allSoldEstates
              .filter(
                (estate) => estate && estate?.latitude && estate?.longitude
              )
              .map((estate) => {
                const marker = constructSoldEstateMarker(
                  estate,
                  props.onSoldMarkerClick,
                  properties.current
                );

                return {
                  [estate?.id || ""]: { data: estate, marker }
                };
              })
          );

          (Object.values(properties.current.soldEstateMarkers) ?? []).forEach(
            ({ marker }) => marker && (marker.map = map)
          );

          if (fitBounds) {
            fitBoundFromEstates(map, soldEstates);
          }

          const showSoldMarkersInBound = () =>
            showMarkersInBound(
              map,
              (Object.values(properties.current.soldEstateMarkers) ?? []).map(
                ({ marker }) => marker
              )
            );

          if (idleListenerOfShowSoldMarkersInBoundRef.current) {
            google.maps.event.removeListener(
              idleListenerOfShowSoldMarkersInBoundRef.current
            );
          }

          idleListenerOfShowSoldMarkersInBoundRef.current = map.addListener(
            "idle",
            showSoldMarkersInBound
          );

          showSoldMarkersInBound();
        } else {
          clearAllSoldEstateMarkers(properties.current);
        }
      }
    },
    [
      map,
      constructSoldEstateMarker,
      props.onSoldMarkerClick,
      fitBoundFromEstates,
      showMarkersInBound,
      clearAllSoldEstateMarkers
    ]
  );

  // メモリスト変化時
  const onStickyNotesChange = useCallback(
    (
      zoom: number,
      stickyNotes: (StickyNote | null)[] | undefined,
      fitBounds: boolean
    ) => {
      if (map) {
        const previousMap = (
          Object.values(properties.current.stickyNoteMarkers) ?? []
        ).at(-1)?.marker?.map;

        if (
          previousMap?.get("mono") !== map.get("mono") ||
          previousMap?.get("dspPoi") !== map.get("dspPoi")
        ) {
          clearAllStickyNoteMarkers(properties.current);
        }

        if (zoom >= stickyNoteSearchableZoom && stickyNotes) {
          const allStickyNoteDict = mapKeys(stickyNotes, (x) => x?.id || "");

          const allStickyNotes = Object.values(allStickyNoteDict) ?? [];

          const setAnimation = (
            marker: google.maps.marker.AdvancedMarkerElement,
            on: boolean
          ) => {
            if (marker.content?.hasChildNodes()) {
              const img = Array.from(marker.content.childNodes)
                .filter((x) => x.nodeType === Node.ELEMENT_NODE)
                .find(
                  (x) =>
                    x instanceof HTMLElement &&
                    x.classList.contains("sticky-note-marker")
                );

              if (img && img instanceof HTMLElement) {
                if (on) {
                  if (!img.classList.contains("clicked")) {
                    img.classList.add("clicked");
                  }
                } else {
                  if (img.classList.contains("clicked")) {
                    img.classList.remove("clicked");
                  }
                }
              }
            }
          };

          (Object.values(properties.current.stickyNoteMarkers) ?? []).forEach(
            ({ data, marker }) => {
              if (
                !allStickyNoteDict[data?.id || ""] ||
                !data ||
                !data?.latitude ||
                !data?.longitude
              ) {
                marker.map = null;
                delete properties.current.stickyNoteMarkers[data?.id || ""];
              }
            }
          );

          (
            Object.entries(properties.current.stickyNoteInfoWindows) ?? []
          ).forEach(([id, { infoWindow }]) => {
            if (!allStickyNoteDict[id || ""]) {
              infoWindow.close();
              delete properties.current.stickyNoteInfoWindows[id || ""];
            }
          });

          const newStickyNodeMarkers = Object.assign(
            {},
            ...allStickyNotes
              .filter((stickyNote) => {
                const oldStickyNoteMarker =
                  properties.current.stickyNoteMarkers[stickyNote?.id || ""] ??
                  undefined;

                return (
                  stickyNote &&
                  stickyNote?.latitude &&
                  stickyNote?.longitude &&
                  (oldStickyNoteMarker === undefined ||
                    oldStickyNoteMarker.data?.description !==
                      stickyNote?.description ||
                    oldStickyNoteMarker.data?.icon !== stickyNote?.icon ||
                    oldStickyNoteMarker.data?.color !== stickyNote?.color ||
                    oldStickyNoteMarker.data?.latitude !==
                      stickyNote?.latitude ||
                    oldStickyNoteMarker.data?.longitude !==
                      stickyNote?.longitude)
                );
              })
              .map((stickyNote) => {
                const marker = constructStickyNoteMarker(stickyNote);
                const infoWindow = constructStickyNoteInfoWindow(
                  props.username || null,
                  props.company?.id || null,
                  stickyNote,
                  props.onUpdateStickyNote,
                  props.onDeleteStickyNote
                );

                marker.addListener("click", () => {
                  if (stickyNote) {
                    infoWindow.open({
                      anchor: marker,
                      shouldFocus: false
                    });

                    setAnimation(marker, true);

                    if (
                      properties.current.stickyNoteInfoWindows[
                        stickyNote.id
                      ] === undefined
                    ) {
                      properties.current.stickyNoteInfoWindows[stickyNote.id] =
                        { infoWindow };
                    }
                  }
                });

                infoWindow.addListener("closeclick", () => {
                  setAnimation(marker, false);

                  if (
                    properties.current.stickyNoteInfoWindows[
                      stickyNote?.id || ""
                    ]
                  ) {
                    delete properties.current.stickyNoteInfoWindows[
                      stickyNote?.id || ""
                    ];
                  }
                });

                return {
                  [stickyNote?.id || ""]: { data: stickyNote, marker }
                };
              })
          );

          Object.entries(properties.current.stickyNoteMarkers)
            .filter(
              ([id, { marker }]) =>
                id in newStickyNodeMarkers &&
                marker instanceof google.maps.marker.AdvancedMarkerElement
            )
            .forEach(([id, { marker }]) => (marker.map = null));

          properties.current.stickyNoteMarkers = {
            ...properties.current.stickyNoteMarkers,
            ...newStickyNodeMarkers
          };

          if (fitBounds) {
            fitBoundFromStickyNotes(map, stickyNotes);
          }

          const showStickyNoteMarkersInBound = () => {
            showMarkersInBound(
              map,
              (Object.values(properties.current.stickyNoteMarkers) ?? []).map(
                ({ marker }) => marker
              )
            );

            Object.keys(properties.current.stickyNoteMarkers).forEach((id) => {
              const marker = properties.current.stickyNoteMarkers[id]?.marker;
              const infoWindow =
                properties.current.stickyNoteInfoWindows[id]?.infoWindow;

              if (marker && marker.map && infoWindow) {
                infoWindow.addListener("closeclick", () => {
                  setAnimation(marker, false);
                });

                infoWindow.open({
                  anchor: marker,
                  shouldFocus: false
                });

                setAnimation(marker, true);
              }
            });
          };

          if (idleListenerOfShowStickyNoteMarkersInBoundRef.current) {
            google.maps.event.removeListener(
              idleListenerOfShowStickyNoteMarkersInBoundRef.current
            );
          }

          idleListenerOfShowStickyNoteMarkersInBoundRef.current =
            map.addListener("idle", showStickyNoteMarkersInBound);

          showStickyNoteMarkersInBound();
        } else {
          clearAllStickyNoteInfoWindows(properties.current);
          clearAllStickyNoteMarkers(properties.current);
        }
      }
    },
    [
      map,
      clearAllStickyNoteMarkers,
      constructStickyNoteMarker,
      constructStickyNoteInfoWindow,
      props.username,
      props.company?.id,
      props.onUpdateStickyNote,
      props.onDeleteStickyNote,
      fitBoundFromStickyNotes,
      showMarkersInBound,
      clearAllStickyNoteInfoWindows
    ]
  );

  // ダイアログ表示物件変化時
  const onDspEstateChange = throttle(
    useCallback(() => {
      showSelectedEffectOfEstate(
        map,
        dspEstates,
        properties.current,
        previousMap !== map
      );
    }, [showSelectedEffectOfEstate, map, dspEstates, previousMap]),
    500
  );

  // マーカー表示文字列指定変化時
  const onMarkerDspColumnsChange = useCallback(
    (
      estates: (MinimalEstate | null)[] | undefined,
      closedEstates: (MinimalEstate | null)[] | undefined,
      dspPrice: boolean,
      dspTsuboArea: boolean
    ) => {
      showCaptionsOnEstatesChangeAndMarkerDspColumnsChange(
        map,
        estates,
        closedEstates,
        dspPrice,
        dspTsuboArea,
        properties.current
      );
    },
    [showCaptionsOnEstatesChangeAndMarkerDspColumnsChange, map]
  );

  const onEstateInfoCloseClick = useCallback(
    (estate: MinimalEstate) => {
      setDspEstates((estates) => {
        const foundIndex = estates.findIndex((x) => x.id === estate.id);

        if (foundIndex >= 0) {
          const newEstates = [...estates];

          newEstates.splice(foundIndex, 1);

          return newEstates;
        }

        return estates;
      });
    },
    [setDspEstates]
  );

  const setAnimationToEstateMarker = useCallback(
    (estateId: string, on: boolean) => {
      const marker = properties.current.estateMarkers[estateId]?.marker;
      const content =
        marker instanceof google.maps.Marker ? null : marker?.content;

      if (content) {
        if (content && content instanceof HTMLElement) {
          if (on) {
            if (!content.classList.contains("info_clicked")) {
              content.classList.add("info_clicked");
            }
          } else {
            if (content.classList.contains("info_clicked")) {
              content.classList.remove("info_clicked");
            }
          }
        }
      }
    },
    []
  );

  const onEstateInfoDragStart = useCallback(
    (estate: MinimalEstate) => {
      setDspEstates((estates) => {
        const foundIndex = estates.findIndex((x) => x.id === estate.id);

        if (foundIndex >= 0) {
          const newEstates = [...estates];
          const estate = newEstates.splice(foundIndex, 1);

          if (estate.length > 0) {
            newEstates.push(estate[0]);
          }

          return newEstates;
        }

        return estates;
      });

      if (estate?.id) {
        setAnimationToEstateMarker(estate.id, true);
      }
    },
    [setAnimationToEstateMarker, setDspEstates]
  );

  const onEstateInfoDragStop = useCallback(
    (estate: MinimalEstate) => {
      if (estate?.id) {
        setAnimationToEstateMarker(estate.id, false);
      }
    },
    [setAnimationToEstateMarker]
  );

  // 物件コンテキストメニュー表示
  const onEstateContextMenu = useCallback(
    (
      event: Event,
      estate: MinimalEstate | null | undefined,
      estateLatLng: google.maps.LatLng
    ) => {
      event.preventDefault();
      setDomEvent(event);
      setRightClickedLocation(estateLatLng);

      if ((event as unknown as MouseEvent).clientX) {
        const domEvent = event as unknown as MouseEvent;

        setEstateContextMenuEstate(estate);
        setEstateContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.clientX - 2,
                mouseY: domEvent.clientY - 4
              }
            : null
        );
      } else if ((event as unknown as CustomEvent).detail?.clientX) {
        const domEvent = event as unknown as CustomEvent;

        setEstateContextMenuEstate(estate);
        setEstateContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.detail?.clientX - 2,
                mouseY: domEvent.detail?.clientY - 4
              }
            : null
        );
      } else if (
        (event as unknown as TouchEvent).changedTouches &&
        (event as unknown as TouchEvent).changedTouches.length > 0
      ) {
        const domEvent = event as unknown as TouchEvent;

        setEstateContextMenuEstate(estate);
        setEstateContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.changedTouches[0].clientX - 2,
                mouseY: domEvent.changedTouches[0].clientY - 4
              }
            : null
        );
      }
    },
    [
      estateContextMenuPosition,
      setDomEvent,
      setEstateContextMenuEstate,
      setEstateContextMenuPosition,
      setRightClickedLocation
    ]
  );

  // マップコンテキストメニュー表示
  const onMapContextMenu = useCallback(
    (event: Event, markerLatLng: google.maps.LatLng) => {
      event.preventDefault();
      setDomEvent(event);
      setRightClickedLocation(markerLatLng);

      if ((event as unknown as MouseEvent).clientX) {
        const domEvent = event as unknown as MouseEvent;
        setMapContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.clientX - 2,
                mouseY: domEvent.clientY - 4
              }
            : null
        );
      } else if ((event as unknown as CustomEvent).detail?.clientX) {
        const domEvent = event as unknown as CustomEvent;
        setMapContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.detail?.clientX - 2,
                mouseY: domEvent.detail?.clientY - 4
              }
            : null
        );
      } else if (
        (event as unknown as TouchEvent).changedTouches &&
        (event as unknown as TouchEvent).changedTouches.length > 0
      ) {
        const domEvent = event as unknown as TouchEvent;
        setMapContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.changedTouches[0].clientX - 2,
                mouseY: domEvent.changedTouches[0].clientY - 4
              }
            : null
        );
      }
    },
    [
      estateContextMenuPosition,
      setDomEvent,
      setMapContextMenuPosition,
      setRightClickedLocation
    ]
  );

  // ルート地点コンテキストメニュー表示
  const onRouteLocationContextMenu = useCallback(
    (
      event: Event,
      routeLocationLatLng: google.maps.LatLng,
      routeLocationStatus: RouteLocationStatus
    ) => {
      event.preventDefault();
      setDomEvent(event);
      setRightClickedLocation(routeLocationLatLng);

      if ((event as unknown as MouseEvent).clientX) {
        const domEvent = event as unknown as MouseEvent;
        setRouteLocationContextMenuInfo(
          routeLocationContextMenuInfo.position === null
            ? {
                routeLocationStatus: routeLocationStatus,
                position: {
                  mouseX: domEvent.clientX - 2,
                  mouseY: domEvent.clientY - 4
                }
              }
            : { routeLocationStatus: null, position: null }
        );
      } else if (
        (event as unknown as TouchEvent).changedTouches &&
        (event as unknown as TouchEvent).changedTouches.length > 0
      ) {
        const domEvent = event as unknown as TouchEvent;
        setRouteLocationContextMenuInfo(
          routeLocationContextMenuInfo.position === null
            ? {
                routeLocationStatus: routeLocationStatus,
                position: {
                  mouseX: domEvent.changedTouches[0].clientX - 2,
                  mouseY: domEvent.changedTouches[0].clientY - 4
                }
              }
            : { routeLocationStatus: null, position: null }
        );
      }
    },
    [
      routeLocationContextMenuInfo.position,
      setDomEvent,
      setRightClickedLocation,
      setRouteLocationContextMenuInfo
    ]
  );

  // 学校コンテキストメニュー表示
  const onSchoolContextMenu = useCallback(
    (event: Event, schoolLatLng: google.maps.LatLng) => {
      event.preventDefault();
      setDomEvent(event);
      setRightClickedLocation(schoolLatLng);

      if ((event as unknown as MouseEvent).clientX) {
        const domEvent = event as unknown as MouseEvent;

        setSchoolContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.clientX - 2,
                mouseY: domEvent.clientY - 4
              }
            : null
        );
      } else if ((event as unknown as CustomEvent)?.detail.clientX) {
        const domEvent = event as unknown as CustomEvent;

        setSchoolContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent?.detail.clientX - 2,
                mouseY: domEvent?.detail.clientY - 4
              }
            : null
        );
      } else if (
        (event as unknown as TouchEvent).changedTouches &&
        (event as unknown as TouchEvent).changedTouches.length > 0
      ) {
        const domEvent = event as unknown as TouchEvent;

        setSchoolContextMenuPosition(
          estateContextMenuPosition === null
            ? {
                mouseX: domEvent.changedTouches[0].clientX - 2,
                mouseY: domEvent.changedTouches[0].clientY - 4
              }
            : null
        );
      }
    },
    [
      estateContextMenuPosition,
      setDomEvent,
      setRightClickedLocation,
      setSchoolContextMenuPosition
    ]
  );

  // 物件コンテキストメニュークローズ
  const onEstateContextMenuClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      setRightClickedLocation(null);
      setEstateContextMenuPosition(null);
    },
    [setEstateContextMenuPosition, setRightClickedLocation]
  );

  // マップコンテキストメニュークローズ
  const onMapContextMenuClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      setRightClickedLocation(null);
      setMapContextMenuPosition(null);
    },
    [setMapContextMenuPosition, setRightClickedLocation]
  );

  // ルート地点コンテキストメニュークローズ
  const onRouteLocationContextMenuClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      setRightClickedLocation(null);
      setRouteLocationContextMenuInfo({
        routeLocationStatus: null,
        position: null
      });
    },
    [setRightClickedLocation, setRouteLocationContextMenuInfo]
  );

  // 物件コンテキストメニュークローズ
  const onSchoolContextMenuClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      setRightClickedLocation(null);
      setSchoolContextMenuPosition(null);
    },
    [setRightClickedLocation, setSchoolContextMenuPosition]
  );

  // 全コンテキストメニュークローズ
  const allContextMenuClose = useCallback(() => {
    onMapContextMenuClose({}, "backdropClick");
    onEstateContextMenuClose({}, "backdropClick");
    onRouteLocationContextMenuClose({}, "backdropClick");
    onSchoolContextMenuClose({}, "backdropClick");
  }, [
    onEstateContextMenuClose,
    onMapContextMenuClose,
    onRouteLocationContextMenuClose,
    onSchoolContextMenuClose
  ]);

  // 自店舗からのルートクリック時
  const onSetRouteFromMyPosition = useCallback(() => {
    if (props.myPosition) {
      const position = new google.maps.LatLng({
        lat: props.myPosition.lat,
        lng: props.myPosition.lng
      });

      setRouteFrom(
        position,
        props.routeLocations,
        allContextMenuClose,
        props.onRouteLocationsChange,
        props.onToggleSearchTrigger
      );
    }
  }, [
    allContextMenuClose,
    props.myPosition,
    props.onRouteLocationsChange,
    props.onToggleSearchTrigger,
    props.routeLocations,
    setRouteFrom
  ]);

  // 出発地にする（S）クリック時
  const onSetRouteFrom = useCallback(() => {
    setRouteFrom(
      rightClickedLocation,
      props.routeLocations,
      allContextMenuClose,
      props.onRouteLocationsChange,
      props.onToggleSearchTrigger
    );
  }, [
    allContextMenuClose,
    props.onRouteLocationsChange,
    props.onToggleSearchTrigger,
    props.routeLocations,
    setRouteFrom,
    rightClickedLocation
  ]);

  // 経由を追加クリック時
  const onAddRouteWaypoint = useCallback(() => {
    addRouteWaypoint(
      rightClickedLocation,
      props.routeLocations,
      allContextMenuClose,
      props.onRouteLocationsChange,
      props.onToggleSearchTrigger
    );
  }, [
    addRouteWaypoint,
    allContextMenuClose,
    props.onRouteLocationsChange,
    props.onToggleSearchTrigger,
    props.routeLocations,
    rightClickedLocation
  ]);

  // 経由地に設定クリック時
  const onSetRouteWaypoint = useCallback(() => {
    setRouteWaypoint(
      rightClickedLocation,
      props.routeLocations,
      allContextMenuClose,
      props.onRouteLocationsChange,
      props.onToggleSearchTrigger
    );
  }, [
    allContextMenuClose,
    props.onRouteLocationsChange,
    props.onToggleSearchTrigger,
    props.routeLocations,
    setRouteWaypoint,
    rightClickedLocation
  ]);

  // 目的地にする（E）クリック時
  const onSetRouteTo = useCallback(() => {
    setRouteTo(
      rightClickedLocation,
      props.routeLocations,
      allContextMenuClose,
      props.onRouteLocationsChange,
      props.onToggleSearchTrigger
    );
  }, [
    allContextMenuClose,
    props.onRouteLocationsChange,
    props.onToggleSearchTrigger,
    props.routeLocations,
    setRouteTo,
    rightClickedLocation
  ]);

  // ポイント削除クリック時
  const onDeleteRouteLocationWithLocation = useCallback(
    (location?: google.maps.LatLng | null) => {
      deleteRouteLocationWithLocation(
        location,
        rightClickedLocation,
        props.routeLocations,
        allContextMenuClose,
        props.onTravelModeClear,
        props.onRouteLocationsChange,
        props.onToggleSearchTrigger
      );
    },
    [
      allContextMenuClose,
      deleteRouteLocationWithLocation,
      props.onRouteLocationsChange,
      props.onToggleSearchTrigger,
      props.onTravelModeClear,
      props.routeLocations,
      rightClickedLocation
    ]
  );

  // ポイント削除クリック時
  const onDeleteRouteLocation = useCallback(() => {
    onDeleteRouteLocationWithLocation();
  }, [onDeleteRouteLocationWithLocation]);

  // ルートポイント変化時
  const onRouteLocationsChange = useCallback(
    (
      locations: RouteLocations,
      locationsResult: google.maps.DirectionsResult | null
    ) => {
      clearAllRouteLocationMarkers(properties.current);

      if (locationsResult) {
        // 結果がある場合
        const legs = locationsResult.routes
          .flatMap((x) => x.legs)
          .filter((x) => x.distance?.value !== 0 && x.duration?.value !== 0);

        if (legs.length > 0) {
          // 始点
          constructRoutePointMarker(
            map,
            legs[0].start_location,
            "from",
            "S",
            locations,
            onRouteLocationContextMenu,
            properties.current
          );

          // 経由地
          for (let i = 1; i < legs.length; i++) {
            constructRoutePointMarker(
              map,
              legs[i].start_location,
              "waypoints",
              i.toString(),
              locations,
              onRouteLocationContextMenu,
              properties.current
            );
          }

          // 終点
          constructRoutePointMarker(
            map,
            legs[legs.length - 1].end_location,
            "to",
            "E",
            locations,
            onRouteLocationContextMenu,
            properties.current
          );
        }
      } else {
        if (locations.from) {
          constructRoutePointMarker(
            map,
            locations.from,
            "from",
            "S",
            locations,
            onRouteLocationContextMenu,
            properties.current
          );
        }

        for (let i = 0; i < locations.waypoints.length; i++) {
          constructRoutePointMarker(
            map,
            locations.waypoints[i],
            "waypoints",
            (i + 1).toString(),
            locations,
            onRouteLocationContextMenu,
            properties.current
          );
        }

        if (locations.to) {
          constructRoutePointMarker(
            map,
            locations.to,
            "to",
            "E",
            locations,
            onRouteLocationContextMenu,
            properties.current
          );
        }
      }
    },
    [
      clearAllRouteLocationMarkers,
      constructRoutePointMarker,
      onRouteLocationContextMenu,
      map
    ]
  );

  // トラベルモード変化時
  const onTravelModeChange = useCallback(
    (travelMode: google.maps.TravelMode | null, locations: RouteLocations) => {
      if (travelMode) {
        const service = directionService;

        if (service) {
          const origin = locations.from
            ? locations.from
            : locations.waypoints.length > 0
            ? locations.waypoints[0]
            : locations.to
            ? locations.to
            : null;

          const destination = locations.to
            ? locations.to
            : locations.waypoints.length > 0
            ? locations.waypoints[locations.waypoints.length - 1]
            : locations.from
            ? locations.from
            : null;

          const waypoints =
            locations.waypoints.length === 0
              ? undefined
              : locations.waypoints.map(
                  (x) => ({ location: x } as google.maps.DirectionsWaypoint)
                );

          if (
            origin &&
            destination &&
            (origin.lat() !== destination.lat() ||
              origin.lng() !== destination.lng())
          ) {
            callRoute(
              service,
              origin,
              destination,
              waypoints,
              travelMode,
              props.optimizeWaypoints
            ).then((result) => {
              props.onRouteLocationsResultChange(result);
            });
          } else {
            props.onRouteLocationsResultChange(null);
          }
        }
      } else {
        props.onRouteLocationsResultChange(null);
      }
    },
    [props, directionService]
  );

  // ルート検索結果変化時
  const onRouteLocationsResultChange = useCallback(
    (locations: google.maps.DirectionsResult | null) => {
      if (properties.current.directionRenderer) {
        if (locations) {
          properties.current.directionRenderer.setDirections(locations);
          properties.current.directionRenderer.setMap(map);
        } else {
          properties.current.directionRenderer.setMap(null);
        }
      }
    },
    [map]
  );

  // ここに○○を追加クリック時
  const onCreateEstate = useCallback(
    (estateType: EstateTypeNameEnum, status: StatusNameEnum) => {
      const lat = rightClickedLocation?.lat() || undefined;
      const lng = rightClickedLocation?.lng() || undefined;

      if (props.onCreateEstate) {
        props.onCreateEstate(estateType, status, lat, lng);
      }

      allContextMenuClose();
    },
    [allContextMenuClose, props, rightClickedLocation]
  );

  // この○○を編集クリック時
  const onUpdateEstate = useCallback(
    (estate: MinimalEstate) => {
      if (props.onUpdateEstate) {
        props.onUpdateEstate(estate);
      }

      allContextMenuClose();
    },
    [allContextMenuClose, props]
  );

  // この○○を削除クリック時
  const onDeleteEstate = useCallback(
    (estate: MinimalEstate) => {
      if (props.onDeleteEstate) {
        props.onDeleteEstate(estate);
      }

      allContextMenuClose();
    },
    [allContextMenuClose, props]
  );

  // この場所のGoogle Mapリンクをコピー
  const onCopyLinkOfGoogleMap = useCallback(() => {
    if (rightClickedLocation) {
      navigator.clipboard.writeText(
        `https://www.google.com/maps?q=${rightClickedLocation.lat()},${rightClickedLocation.lng()}`
      );
    }

    allContextMenuClose();
  }, [allContextMenuClose, rightClickedLocation]);

  // ここにメモを追加クリック時
  const onCreateStickNote = useCallback(() => {
    if (rightClickedLocation) {
      if (props.onCreateStickyNote) {
        props.onCreateStickyNote(
          rightClickedLocation.lat(),
          rightClickedLocation.lng()
        );
      }
    }

    allContextMenuClose();
  }, [allContextMenuClose, props, rightClickedLocation]);

  const onFillingSchoolPolygonsChange = useCallback((filling: boolean) => {
    setFillingSchoolPolygons(filling);
  }, []);

  const onClearFillingSchoolPolygonsClick = useCallback(() => {
    setFillingSchoolPolygons(false);
  }, []);

  const onClearSelectedEstatesClick = useCallback(() => {
    setDspEstates([]);
  }, [setDspEstates]);

  const onResetPositionOfEstateInfoClick = useCallback(() => {
    setTriggerResetPosition((prev) => !prev);
  }, [setTriggerResetPosition]);

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }

    return onDestruct;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ルート検索モードを認識させる
  useEffect(() => {
    properties.current.searchRouteMode = props.searchRouteMode;
    clearAllRouteLocationMarkers(properties.current);
  }, [clearAllRouteLocationMarkers, props.searchRouteMode]);

  // 距離計測モードを認識させる
  useEffect(() => {
    properties.current.measureLengthMode = props.measureLengthMode;
  }, [props.measureLengthMode]);

  // 面積計測モードを認識させる
  useEffect(() => {
    properties.current.measureAreaMode = props.measureAreaMode;
  }, [props.measureAreaMode]);

  // 編集モードを認識させる
  useEffect(() => {
    properties.current.editMode = props.editMode;
  }, [props.editMode]);

  useEffect(() => {
    onMyPositionConfirmed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.myPosition, map]);

  useEffect(() => {
    onEstatesChange(
      props.estates,
      props.closedEstates,
      initialDrawEstatesRef.current
    );
    if (
      (props.estates || []).length !== 0 ||
      (props.closedEstates || []).length !== 0
    ) {
      initialDrawEstatesRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    props.closedEstates,
    props.estates,
    props.measureAreaMode,
    props.measureLengthMode,
    triggerSwitchStreetView
  ]);

  useEffect(() => {
    onSoldEstatesChange(props.zoom, props.soldEstates, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    props.zoom,
    props.soldEstates,
    props.measureAreaMode,
    props.measureLengthMode
  ]);

  useEffect(() => {
    onStickyNotesChange(props.zoom, props.stickyNotes, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.company?.id,
    props.username,
    map,
    props.zoom,
    props.stickyNotes,
    props.stickyNotesChangeDispatcher
  ]);

  useEffect(() => {
    onMarkerDspColumnsChange(
      props.estates,
      props.closedEstates,
      props.dspPrice,
      props.dspTsuboArea
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    props.closedEstates,
    props.dspPrice,
    props.dspTsuboArea,
    props.measureAreaMode,
    props.measureLengthMode,
    props.estates,
    triggerSwitchStreetView
  ]);

  useEffect(() => {
    applyMapStyle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    map,
    props.dspDoshasaigai,
    props.dspKouzui,
    props.dspPoi,
    props.dspTeiichitai,
    props.dspTsunami,
    props.dspTakashio,
    props.dspYouto
  ]);

  useEffect(() => {
    onPlacesChange(props.places);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.places, map]);

  useEffect(() => {
    onCurrentPositionChange(props.currentPosition, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPosition, map]);

  useEffect(() => {
    onRouteLocationsChange(props.routeLocations, props.routeLocationsResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.searchRouteMode,
    props.routeLocations,
    props.routeLocationsResult,
    map
  ]);

  useEffect(() => {
    onDeleteRouteLocationWithLocation(props.deleteRouteLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteRouteLocation]);

  useEffect(() => {
    onTravelModeChange(props.travelMode, props.routeLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.travelMode,
    props.routeLocations,
    props.optimizeWaypoints,
    props.searchTrigger,
    map
  ]);

  useEffect(() => {
    onRouteLocationsResultChange(props.routeLocationsResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.routeLocationsResult, map]);

  useEffect(() => {
    onDspEstateChange();
  }, [onDspEstateChange]);

  useEffect(() => {
    onZoomChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.zoom, map]);

  useEffect(() => {
    setCitiesOfSearchConditions(JSON.stringify(props.citiesOfSearchConditions));
  }, [props.citiesOfSearchConditions, setCitiesOfSearchConditions]);

  useEffect(() => {
    // エリア変更が行われた場合、初期検索と同じ動作をさせる
    initialDrawEstatesRef.current = true;
  }, [citiesOfSearchConditions]);

  // 小中学校区描画指示変更時
  useEffect(() => {
    if (!props.dspESchools && !props.dspJSchools) {
      setFillingSchoolPolygons(false);
    }
  }, [props.dspESchools, props.dspJSchools]);

  // オートコンプリートで物件が選択された時
  useEffect(() => {
    setDspEstates((estates) => {
      return addEstateToList(props.selectedEstateOnAutoComplete, estates);
    });
  }, [props.selectedEstateOnAutoComplete, setDspEstates]);

  // 物件変更が通知された時
  useEffect(() => {
    setDspEstates((estates) => {
      if (props.notifiedEstate && props.notifiedEstate.id) {
        const foundIndex = estates.findIndex(
          (x) =>
            x.blocks?.some((block) => block.id === props.notifiedEstate?.id) ||
            x.id === props.notifiedEstate?.id
        );

        if (foundIndex >= 0) {
          const foundBlockIndex = (estates[foundIndex].blocks ?? []).findIndex(
            (x) => x.id === props.notifiedEstate?.id
          );

          const newEstates = [...estates];

          if (foundBlockIndex >= 0) {
            const newBlocks = [...estates[foundIndex].blocks];

            newBlocks.splice(foundBlockIndex, 1, props.notifiedEstate);

            newEstates.splice(foundIndex, 1, {
              ...estates[foundIndex],
              blocks: newBlocks
            });
          } else {
            newEstates.splice(foundIndex, 1, props.notifiedEstate);
          }

          return newEstates;
        }
      }

      return estates;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notifiedEstate]);

  return {
    properties: properties.current,

    map,
    setMap,

    fillingSchoolPolygons,

    estateContextMenuPosition,
    setEstateContextMenuPosition,
    estateContextMenuEstate,
    setEstateContextMenuEstate,
    mapContextMenuPosition,
    setMapContextMenuPosition,
    routeLocationContextMenuInfo,
    setRouteLocationContextMenuInfo,
    schoolContextMenuPosition,
    setSchoolContextMenuPosition,
    rightClickedLocation,
    setRightClickedLocation,

    dspEstates,
    setDspEstates,

    selectingEstates,
    triggerResetPosition,
    triggerSwitchStreetView,

    domEvent,
    setDomEvent,

    onGoogleApiLoaded,
    onDestruct,
    onMyPositionConfirmed,
    onCurrentPositionChange,
    onPlacesChange,
    onZoomChange,

    onEstatesChange,
    onSoldEstatesChange,

    onStickyNotesChange,

    onMarkerDspColumnsChange,
    onEstateInfoCloseClick,
    onEstateInfoDragStart,
    onEstateInfoDragStop,

    onEstateContextMenu,
    onMapContextMenu,
    onRouteLocationContextMenu,
    onEstateContextMenuClose,
    onMapContextMenuClose,
    onRouteLocationContextMenuClose,
    onSchoolContextMenu,
    onSchoolContextMenuClose,

    onSetRouteFromMyPosition,
    onSetRouteFrom,
    onAddRouteWaypoint,
    onSetRouteWaypoint,
    onSetRouteTo,
    onDeleteRouteLocation,
    onDeleteRouteLocationWithLocation,
    onRouteLocationsChange,
    onTravelModeChange,
    onRouteLocationsResultChange,

    onCreateEstate,
    onUpdateEstate,
    onDeleteEstate,

    onCopyLinkOfGoogleMap,

    onCreateStickNote,
    constructMarkerClustererOfEstate,

    onFillingSchoolPolygonsChange,
    onClearFillingSchoolPolygonsClick,
    onClearSelectedEstatesClick,
    onResetPositionOfEstateInfoClick,

    allContextMenuClose
  } as const;
};