import { Box, FormControl, FormLabel } from "@mui/material";
import { StickyNoteColor } from "API";
import Blue from "assets/icons/sticky_note/memo_color_bl.svg";
import Red from "assets/icons/sticky_note/memo_color_re.svg";
import White from "assets/icons/sticky_note/memo_color_wh.svg";
import Yellow from "assets/icons/sticky_note/memo_color_ye.svg";
import React, { Fragment, useMemo } from "react";
import styles from "./ColorSwitchButtons.module.scss";
import { ColorSwitchButtonsProps } from "./interfaces";

const PrimitiveColorSwitchButtons = (props: ColorSwitchButtonsProps) => {
  const master: { id: StickyNoteColor; name: string }[] = useMemo(
    () => [
      { id: StickyNoteColor.white, name: "白" },
      { id: StickyNoteColor.blue, name: "青" },
      { id: StickyNoteColor.yellow, name: "黄" },
      { id: StickyNoteColor.red, name: "赤" }
    ],
    []
  );

  return (
    <FormControl>
      <FormLabel className={styles.label} id={`${props.id}-label`}>
        {props.label}
      </FormLabel>
      <Box className={styles.wrapper}>
        {master.map(
          (x) =>
            x && (
              <Fragment key={x.id}>
                <Box
                  className={`${styles.btn_switch01} ${styles.btn_square} ${
                    styles.click_act01
                  } ${props.value === x.id ? styles.on : styles.off}`}
                  onClick={() => props.onChange(x.id)}
                >
                  <Box
                    component="img"
                    src={
                      x.id === StickyNoteColor.white
                        ? White
                        : x.id === StickyNoteColor.blue
                        ? Blue
                        : x.id === StickyNoteColor.yellow
                        ? Yellow
                        : x.id === StickyNoteColor.red
                        ? Red
                        : undefined
                    }
                  />
                </Box>
              </Fragment>
            )
        )}
      </Box>
    </FormControl>
  );
};

export const ColorSwitchButtons = React.memo(
  PrimitiveColorSwitchButtons
) as typeof PrimitiveColorSwitchButtons;
