import { HazardMapType } from "common/functions/classes/HazardMapType";
import { DrawingId } from "common/types/DrawingId";
import { useCallback, useContext, useEffect, useState } from "react";
import { SearchMapContext } from "../../context";

const id: DrawingId = "kouzui";

export const useHooks = (on: boolean) => {
  const { map } = useContext(SearchMapContext);
  const [kouzuiMapType, setKouzuiMapType] = useState<HazardMapType | undefined>(
    undefined
  );

  // マップタイプ構築
  const constructKouzuiMapType = useCallback(() => {
    if (map) {
      const mapType = new HazardMapType(
        `${id}MapType`,
        map,
        [
          "https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/{z}/{x}/{y}.png"
        ],
        17
      );

      setKouzuiMapType(mapType);
    }
  }, [map]);

  // 描画
  const drawKouzui = useCallback(async () => {
    if (map && kouzuiMapType) {
      for (let i = 0; i < map.overlayMapTypes.getLength(); i++) {
        const mapType = map.overlayMapTypes.getAt(
          i
        ) as unknown as HazardMapType;

        if (mapType.id === `${id}MapType`) {
          map.overlayMapTypes.removeAt(i);
          break;
        }
      }

      if (on) {
        map.overlayMapTypes.insertAt(
          0,
          kouzuiMapType as unknown as google.maps.MapType
        );
      }
    }
  }, [kouzuiMapType, map, on]);

  useEffect(() => {
    constructKouzuiMapType();
  }, [constructKouzuiMapType]);

  useEffect(() => {
    drawKouzui();
  }, [drawKouzui]);

  return { map } as const;
};
