import { CalendarMonth } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import { ja } from "date-fns/locale";
import React, { useCallback, useMemo } from "react";
import { DatePickerCalendar } from "react-nice-dates";
import styles from "./DatePickerDialog.module.scss";
import { DatePickerDialogProps } from "./interfaces";

export const DatePickerDialog = React.memo((props: DatePickerDialogProps) => {
  const [date, setDate] = React.useState<number | null | undefined>(props.date);

  const dateValue = useMemo(() => (date ? new Date(date) : undefined), [date]);

  const onDateChange = useCallback((date: Date | null) => {
    if (date) {
      setDate(date.getTime());
    }
  }, []);

  const onOkClick = useCallback(() => {
    props.onOkClick(date);
  }, [date, props]);

  return (
    <>
      <Dialog
        style={props.style}
        className={props.className}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="calendar-title"
        aria-describedby="calendar-description"
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{ backdrop: { className: styles.backdrop } }}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader id="calendar-title" icon={<CalendarMonth />} />
        <DialogContent id="calendar-description">
          <DatePickerCalendar
            locale={ja}
            date={dateValue}
            modifiers={{
              today: (date) => date.toDateString() === new Date().toDateString()
            }}
            modifiersClassNames={{
              today: "nice-dates-today"
            }}
            onDateChange={onDateChange}
          />
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            variant="contained"
            className={styles.cancelButton}
            color="warning"
            onClick={props.onCloseClick}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            className={styles.okButton}
            color="warning"
            onClick={onOkClick}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
