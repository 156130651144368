import { Auth } from "components/Parts/Organisms/Auth";
import { DetailPage } from "components/Parts/Organisms/DetailPage";
import { DetailProps } from "components/Parts/Organisms/DetailPage/interfaces";
import React from "react";

export const Detail = React.memo((props: DetailProps) => (
  <Auth>
    <DetailPage {...props} />
  </Auth>
));
