import React from "react";
import { EstateInfo } from "./EstateInfo";
import { useHooks } from "./hooks";
import { EstatesInfoPluginProps } from "./interfaces";

export const EstatesInfoPlugin = React.memo(
  ({
    estates,
    triggerResetPosition,
    onCloseClick,
    onDragStart,
    onDragStop,
    onUpdateEstate,
    onDeleteEstate,
    onReportEstate,
    onDisplayEstate
  }: EstatesInfoPluginProps) => {
    const { map, projection } = useHooks();

    if (map) {
      return (
        <>
          {estates.map((estate) => (
            <EstateInfo
              key={estate.id}
              map={map}
              projection={projection.current}
              estate={estate}
              triggerResetPosition={triggerResetPosition}
              onCloseClick={() => onCloseClick(estate)}
              onDragStart={() => onDragStart(estate)}
              onDragStop={() => onDragStop(estate)}
              onUpdateEstate={onUpdateEstate}
              onDeleteEstate={onDeleteEstate}
              onReportEstate={onReportEstate}
              onDisplayEstate={onDisplayEstate}
            />
          ))}
        </>
      );
    }

    return null;
  }
);
