import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  Company,
  GetCompanyQuery,
  ListCompaniesBySearchInfoQuery,
  ListCompaniesBySearchInfoQueryVariables
} from "API";
import { generateClient } from "aws-amplify/api";
import { InfoNameEnum } from "common/enums/InfoNameEnum";
import {
  getCompany as getCompanyQuery,
  listCompaniesBySearchInfo
} from "graphql/queries";
import { isEmpty } from "lodash";
import { getSumoraAuthToken } from "../credentials";

export const getCompany = async (
  id: string | null | undefined
): Promise<GetCompanyQuery | undefined> => {
  if (id) {
    const client = generateClient();
    const companyData = (await client.graphql({
      ...graphqlOperation(getCompanyQuery, {
        id: id,
        infoName: InfoNameEnum.master
      }),
      authMode: "lambda",
      authToken: await getSumoraAuthToken()
    })) as GraphQLResult<GetCompanyQuery>;

    return companyData.data;
  }
};

const invokeGetCompanies = async (
  variables: ListCompaniesBySearchInfoQueryVariables
) => {
  const companies: Company[] = [];
  const innerVariables = { ...variables };

  let nextToken: string | null = "start";
  const client = generateClient();

  while (nextToken) {
    if (nextToken !== "start") {
      innerVariables.nextToken = nextToken;
    }

    let items: (Company | null)[] = [];

    const res = (await client.graphql({
      ...graphqlOperation(listCompaniesBySearchInfo, innerVariables),
      authMode: "lambda",
      authToken: await getSumoraAuthToken()
    })) as GraphQLResult<ListCompaniesBySearchInfoQuery>;

    if (res.data?.listCompaniesBySearchInfo?.items) {
      items = res.data?.listCompaniesBySearchInfo?.items;
    }

    nextToken = res.data?.listCompaniesBySearchInfo?.nextToken || null;

    // 返却値のセット
    items.forEach((company) => {
      if (company) {
        companies.push(company);
      }
    });
  }

  companies.sort((a, b) => a.id.localeCompare(b.id));

  return companies;
};

export const getCompanies = async (conditions: {
  licenserAndLicenseNo?: string;
  name?: string;
  branchName?: string;
}) => {
  const variables: ListCompaniesBySearchInfoQueryVariables = {
    infoName: InfoNameEnum.master,
    searchInfo: { eq: "-" }
  };
  if (conditions.name) {
    variables.infoName = InfoNameEnum.name;
    variables.searchInfo = { beginsWith: conditions.name };

    if (!isEmpty(conditions.branchName)) {
      variables.filter = { branchName: { contains: conditions.branchName } };
    }
  } else if (!isEmpty(conditions.licenserAndLicenseNo)) {
    variables.infoName = InfoNameEnum.licenserAndLicenseNo;
    variables.searchInfo = { beginsWith: conditions.licenserAndLicenseNo };
  }

  const companies = await invokeGetCompanies(variables);

  return companies.filter(
    (x) =>
      (isEmpty(conditions.licenserAndLicenseNo) ||
        `${x.licenser}${x.licenseNo
          ?.replace("第", "")
          .replace("号", "")}`.startsWith(
          conditions.licenserAndLicenseNo || ""
        )) &&
      (isEmpty(conditions.name) || x.name?.includes(conditions.name || "")) &&
      (isEmpty(conditions.branchName) ||
        x.name?.includes(conditions.branchName || ""))
  );
};

export const getCompaniesFromRecNo = async (recNo: number) => {
  const variables: ListCompaniesBySearchInfoQueryVariables = {
    infoName: InfoNameEnum.recNo,
    searchInfo: { eq: String(recNo) }
  };

  return invokeGetCompanies(variables);
};
