import { useCallback } from "react";
import { SearchMapStatesType } from "../states/maps";

export const useSearchUsageGuideDialogHandlers = (
  mapStates: SearchMapStatesType
) => {
  const onUsageGuideDialogOffClick = useCallback(() => {
    mapStates.setDspDoshasaigai(false);
    mapStates.setDspKouzui(false);
    mapStates.setDspTeiichitai(false);
    mapStates.setDspTsunami(false);
    mapStates.setDspTakashio(false);
    mapStates.setDspYouto(false);
  }, [mapStates]);

  return {
    onUsageGuideDialogOffClick
  } as const;
};

export type SearchUsageGuideDialogHandlersType = ReturnType<
  typeof useSearchUsageGuideDialogHandlers
>;
