import {
  CardHeader,
  CardHeaderProps,
  IconButton,
  IconButtonProps
} from "@mui/material";
import styles from "./CommonDialogComponents.module.scss";

export const CommonDialogCardHeader = (props: CardHeaderProps) => {
  return <CardHeader {...props} className={styles.cardHeader} />;
};

export const CommonDialogIconButton = (props: IconButtonProps) => {
  return <IconButton {...props} className={styles.iconButton} />;
};
