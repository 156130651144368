import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  CreatePublicEstatePdfFMutation,
  CreatePublicEstatePdfFMutationVariables,
  PublicEstate
} from "API";
import { generateClient } from "aws-amplify/api";
import { getSumoraAuthToken } from "common/functions/credentials";
import { base64ToBlob } from "common/functions/utilities";
import { MainContextContainer } from "components/Main";
import { createPublicEstatePdfF } from "graphql/mutations";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useParams } from "react-router-dom";
import { DetailProps } from "./interfaces";

export const useDetailHooks = (props: DetailProps) => {
  const initRef = useRef(true);

  const params = useParams();

  const mainContext = useContext(MainContextContainer);

  const [publicEstateIds, setPublicEstateIds] = useState<string[]>([]);
  const [estateIds, setEstateIds] = useState<string[]>([]);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [isPdfDownloaded, setIsPdfDownloaded] = useState(false);

  const waiting = useMemo(() => params.id === "waiting", [params.id]);

  const actualForAnonymous = useMemo(
    () => props.forAnonymous && !mainContext.isReadyToUse,
    [mainContext.isReadyToUse, props.forAnonymous]
  );

  const completed = useMemo(
    () =>
      !waiting &&
      publicEstateIds.length > 0 &&
      publicEstateIds.length === estateIds.length,
    [waiting, publicEstateIds, estateIds]
  );

  const onPublicEstateLoaded = useCallback((publicEstate: PublicEstate) => {
    setEstateIds(
      (estateIds) =>
        Array.from(new Set([...estateIds, publicEstate.estate?.id])) as string[]
    );
  }, []);

  const onDownloadPdfClick = useCallback(
    async (url: string) => {
      setIsDownloadingPdf(true);

      try {
        const client = generateClient();
        const res = (await client.graphql({
          ...graphqlOperation(createPublicEstatePdfF, {
            input: {
              url
            }
          } as CreatePublicEstatePdfFMutationVariables),
          authMode: "lambda",
          authToken: await getSumoraAuthToken()
        })) as GraphQLResult<CreatePublicEstatePdfFMutation>;

        if (res.data?.createPublicEstatePdfF?.pdf) {
          try {
            const fileName = `${
              estateIds.length === 0
                ? "物件"
                : estateIds.length === 1
                ? estateIds[0]
                : `${estateIds[0]}他`
            }.pdf`;

            const blob = base64ToBlob(
              res.data.createPublicEstatePdfF.pdf,
              "application/pdf"
            );

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.click();

            setIsPdfDownloaded(true);
          } catch (e) {
            alert("PDFのダウンロードに失敗しました。");
            window.console.error(e);
            return false;
          }
        }
      } catch (e) {
        alert("PDFのダウンロードに失敗しました。");
        window.console.error(e);
        return false;
      } finally {
        setIsDownloadingPdf(false);
      }
    },
    [estateIds]
  );

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (params.id === "waiting") {
      return;
    } else if (params.id) {
      const ids = params.id.split(",");
      setPublicEstateIds(ids);
    }
  }, [actualForAnonymous, params.id]);

  return {
    mainContext,
    publicEstateIds,
    waiting,
    isDownloadingPdf,
    isPdfDownloaded,
    completed,

    onPublicEstateLoaded,
    onDownloadPdfClick
  } as const;
};
