import { getReportKinds } from "common/functions/jsonMasters/reportKinds";
import { handleError } from "common/functions/utilities";
import { ReportKind } from "common/interfaces/ReportKind";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useReportKindHooks = (
  setFatal: ((error?: string | null | undefined) => void) | undefined
) => {
  const [reportKinds, setReportKinds] = useState<ReportKind[]>([]);

  const { data, error } = useSWR("reportKinds", () => getReportKinds());

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setReportKinds(data);
    }
  }, [data]);

  return { reportKinds, setReportKinds } as const;
};
