import { Box } from "@mui/material";
import { Fragment, memo, useCallback, useEffect, useState } from "react";
import styles from "../SearchMenu.module.scss";

export const SearchMenuButton = memo(
  (props: {
    titles: string[];
    imgSrc: string;
    status: boolean;
    onStatusChange?: (on: boolean) => void;
    onClick?: () => void;
  }) => {
    const [switchClassName, setSwitchClassName] = useState<string>(styles.off);

    const onClick = useCallback(() => {
      if (props.onStatusChange) {
        props.onStatusChange(!props.status);
      }

      if (props.onClick) {
        props.onClick();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onStatusChange, props.onClick, props.status]);

    useEffect(() => {
      setSwitchClassName(props.status ? styles.on : styles.off);
    }, [props.status]);

    return (
      <Box
        component="li"
        onClick={onClick}
        className={`SearchMenuButton ${switchClassName}`}
      >
        <Box component="figure">
          <img src={props.imgSrc} alt={props.titles.join("")} />
        </Box>
        <Box component="figcaption">
          {props.titles.map((title, index) => (
            <Fragment key={index}>
              {title}
              {index === props.titles.length - 1 ? null : <br />}
            </Fragment>
          ))}
        </Box>
      </Box>
    );
  }
);
