import { Box, Link } from "@mui/material";
import logoYoko4c from "assets/icons/logo_yoko_4c.svg";
import React from "react";
import styles from "./SumoraHeader.module.scss";
import { SumoraHeaderProps } from "./interfaces";

export const SumoraHeader = React.memo((props: SumoraHeaderProps) => {
  return (
    <Box component="section" className={styles.menu_header}>
      <Box className={styles.left}>
        <Box
          component="figure"
          className={`${styles.logo} ${
            props.logoVisible ? "visible" : "hidden"
          }`}
        >
          <Link href="/">
            <img src={logoYoko4c} alt="sumora" />
          </Link>
        </Box>
      </Box>
      <Box className={styles.right}>{props.children}</Box>
    </Box>
  );
});
