import { Estate } from "API";
import { InfoNameEnum } from "common/enums/InfoNameEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import {
  deleteEstate as invokeDeleteEstate,
  getEstate as invokeGetEstate,
  updateEstate as invokeUpdateEstate
} from "common/functions/api/estates";
import { latLngToCoordinates } from "common/functions/mapUtilities";
import { useAutoReducer } from "common/functions/reducers";
import { handleError } from "common/functions/utilities";
import { useCallback, useEffect, useState } from "react";

export const useEstateForLocationInputHooks = (
  id: string | null,
  username: string | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const [estate, initializeEstate, forceEstate, setEstate] =
    useAutoReducer<Estate>({} as Estate);

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  // 物件取得
  const loadEstate = useCallback(() => {
    if (id) {
      setBusy(true);

      invokeGetEstate(
        id,
        id.startsWith("C-") ? InfoNameEnum.condominium : InfoNameEnum.master
      )
        .then((value) => {
          if (value) {
            forceEstate({
              ...value,
              __typename: "Estate"
            });
          }
        })
        .catch((reason) => {
          setError(true);
          setDisable(true);
          handleError(reason, setFatal);
        })
        .finally(() => setBusy(false));
    }
  }, [forceEstate, id, setFatal]);

  const constructEstateFromInputValue = useCallback(() => {
    const sanitizedEstate = Object.assign(
      {},
      ...Object.entries(estate).map(([key, value]) => ({
        [key]: String(value) === "" ? null : value
      }))
    ) as Estate;

    if (sanitizedEstate.latitude && sanitizedEstate.longitude) {
      for (const zoom of [12, 14, 16]) {
        const { tileCoordinate } = latLngToCoordinates(
          new google.maps.LatLng({
            lat: sanitizedEstate.latitude,
            lng: sanitizedEstate.longitude
          }),
          zoom
        );

        if (tileCoordinate) {
          switch (zoom) {
            case 12:
              sanitizedEstate.tileOnZoom12 = `${tileCoordinate.x},${tileCoordinate.y}`;
              break;
            case 14:
              sanitizedEstate.tileOnZoom14 = `${tileCoordinate.x},${tileCoordinate.y}`;
              break;
            case 16:
              sanitizedEstate.tileOnZoom16 = `${tileCoordinate.x},${tileCoordinate.y}`;
              break;
          }
        }
      }
    }

    sanitizedEstate.confirmed =
      sanitizedEstate.status !== StatusNameEnum.下書き;

    return {
      ...sanitizedEstate,
      owner: username
    };
  }, [estate, username]);

  const updateEstate = useCallback(async () => {
    setBusy(true);

    const estate = constructEstateFromInputValue();

    const value = await invokeUpdateEstate(estate).catch((reason) => {
      setError(true);
      handleError(reason, setFatal);
    });

    if (value) {
      forceEstate({
        ...value,
        __typename: "Estate"
      } as Estate);
    }

    setBusy(false);

    return value;
  }, [constructEstateFromInputValue, forceEstate, setFatal]);

  const deleteEstate = useCallback(async () => {
    setBusy(true);

    const estate = constructEstateFromInputValue();

    const value = await invokeDeleteEstate(estate).catch((reason) => {
      setError(true);
      handleError(reason, setFatal);
    });

    if (value) {
      forceEstate({
        ...value,
        __typename: "Estate"
      } as Estate);
    }

    setBusy(false);

    return value;
  }, [constructEstateFromInputValue, forceEstate, setFatal]);

  useEffect(() => {
    loadEstate();
  }, [id, loadEstate]);

  return {
    estate,
    initializeEstate,
    forceEstate,
    setEstate,
    busy,
    error,
    disable,

    updateEstate,
    deleteEstate
  } as const;
};
