// 土砂災害警戒区域カラーチャート

import { DrawingColor } from "common/interfaces/DrawingColor";

export const doshasaigaiColors: DrawingColor[] = [
  {
    code: 11,
    name: "急傾斜地の崩壊(警戒区域)",
    strokeColor: "#cccccc",
    fillColor: "#ffed4c"
  },
  {
    code: 12,
    name: "急傾斜地の崩壊(特別警戒区域)",
    strokeColor: "#cccccc",
    fillColor: "#fb684c"
  },
  {
    code: 21,
    name: "土石流(警戒区域)",
    strokeColor: "#cccccc",
    fillColor: "#ecd86f"
  },
  {
    code: 22,
    name: "土石流(特別警戒区域)",
    strokeColor: "#cccccc",
    fillColor: "#c04c63"
  },
  {
    code: 31,
    name: "地滑り(警戒区域)",
    strokeColor: "#cccccc",
    fillColor: "#ffb74c"
  },
  {
    code: 32,
    name: "地滑り(特別警戒区域)",
    strokeColor: "#cccccc",
    fillColor: "#c94b94"
  }
];
