import { MenuItem } from "@mui/material";
import { Company } from "API";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { GroupEnum } from "common/enums/GroupEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { MinimalEstate } from "common/queries/minimalEstates";
import { ContextMenu } from "components/Parts/Atoms/ContextMenu";
import React from "react";
import { RouteLocationStatus } from "./types";

export const ContextMenuOnEstate = React.memo(
  (props: {
    estateContextMenuPosition: {
      mouseX: number;
      mouseY: number;
    } | null;
    estate: MinimalEstate | null | undefined;
    editMode: boolean;
    searchMode: SearchModeEnum | undefined;
    company: Company | undefined;
    groups: string[] | undefined;
    onEstateContextMenuClose: (
      event: {},
      reason: "backdropClick" | "escapeKeyDown"
    ) => void;
    onSetRouteFrom: (() => void) | undefined;
    onAddRouteWaypoint: (() => void) | undefined;
    onSetRouteTo: (() => void) | undefined;
    onUpdateEstate: (estate: MinimalEstate) => void;
    onDeleteEstate: (estate: MinimalEstate) => void;
  }) => {
    const items: JSX.Element[] = [];

    if (props.editMode && props.estate?.__typename === "Estate") {
      if (
        props.estate?.estateType === "？" ||
        ([
          EstateTypeNameEnum.土地,
          EstateTypeNameEnum.新築建売,
          EstateTypeNameEnum.中古戸建,
          EstateTypeNameEnum.事業用
        ].includes(props.estate?.estateType as EstateTypeNameEnum) &&
          props.estate?.blocks.length !== 0)
      ) {
        items.push(
          <MenuItem key="この物件は直接編集できません" disabled={true}>
            この物件は直接編集できません
          </MenuItem>
        );
      } else if (
        // ハウスメーカー、不動産会社は自社物件のみ編集可
        (props.groups?.includes(GroupEnum.HouseMaker) ||
          props.groups?.includes(GroupEnum.RealEstateAgency) ||
          props.groups?.includes(GroupEnum.RealEstateAgencyFree)) &&
        String(props.company?.recNo) !==
          props.estate?.companyId?.split("-")?.at(0) &&
        String(props.company?.recNo) !==
          props.estate?.subCompanyId?.split("-")?.at(0) &&
        String(props.company?.recNo) !==
          props.estate?.subCompany2Id?.split("-")?.at(0)
      ) {
        items.push(
          <MenuItem key="この物件は編集できません" disabled={true}>
            この物件は編集できません
          </MenuItem>
        );
      } else {
        items.push(
          ...[
            <MenuItem
              key={`この${props.estate?.estateType}を編集`}
              onClick={() =>
                props.onUpdateEstate(props.estate as MinimalEstate)
              }
            >
              {`この${props.estate?.estateType}を編集`}
            </MenuItem>,
            <MenuItem
              key={`この${props.estate?.estateType}を削除`}
              disabled={
                props.estate?.estateType === EstateTypeNameEnum.マンション
              }
              onClick={() =>
                props.onDeleteEstate(props.estate as MinimalEstate)
              }
            >
              {`この${props.estate?.estateType}を削除`}
            </MenuItem>
          ]
        );
      }
    } else {
      items.push(
        ...[
          <MenuItem
            key="この物件を出発地にする（S）"
            onClick={props.onSetRouteFrom}
          >
            この物件を出発地にする（S）
          </MenuItem>,
          <MenuItem
            key="この物件を目的地にする（E）"
            onClick={props.onSetRouteTo}
          >
            この物件を目的地にする（E）
          </MenuItem>,
          <MenuItem
            key="この物件を経由地に追加"
            onClick={props.onAddRouteWaypoint}
          >
            この物件を経由地に追加
          </MenuItem>
        ]
      );
    }

    return (
      <ContextMenu
        contextMenuPosition={props.estateContextMenuPosition}
        onClose={props.onEstateContextMenuClose}
      >
        {items}
      </ContextMenu>
    );
  }
);

export const ContextMenuOnMap = React.memo(
  (props: {
    mapContextMenuPosition: {
      mouseX: number;
      mouseY: number;
    } | null;
    searchRouteMode: boolean;
    myPosition: google.maps.LatLngLiteral | null;
    editMode: boolean;
    searchMode: SearchModeEnum | undefined;
    stickyNoteMode: boolean | undefined;
    onMapContextMenuClose: (
      event: {},
      reason: "backdropClick" | "escapeKeyDown"
    ) => void;
    onSetRouteFromMyPosition: (() => void) | undefined;
    onSetRouteFrom: (() => void) | undefined;
    onAddRouteWaypoint: (() => void) | undefined;
    onSetRouteTo: (() => void) | undefined;
    onCreateEstate: (
      estateType: EstateTypeNameEnum,
      status: StatusNameEnum
    ) => void;
    onCopyLinkOfGoogleMap: () => void;
    onCreateStickNote: () => void;
  }) => {
    const items: JSX.Element[] = [];

    if (props.editMode) {
      if (props.searchMode === SearchModeEnum.condominium) {
        items.push(
          <MenuItem key="この画面では他の物件を追加できません" disabled={true}>
            この画面では他の物件を追加できません
          </MenuItem>
        );
      } else {
        items.push(
          ...[
            EstateTypeNameEnum.土地,
            EstateTypeNameEnum.新築建売,
            EstateTypeNameEnum.中古戸建,
            EstateTypeNameEnum.事業用
          ].map((estateType) =>
            [
              SearchModeEnum.estate as string,
              SearchModeEnum.draft as string
            ].includes(props.searchMode || "") ? (
              <MenuItem
                key={`${estateType}, ${StatusNameEnum.下書き}`}
                onClick={() =>
                  props.onCreateEstate(estateType, StatusNameEnum.下書き)
                }
              >
                ここに{estateType}を追加
              </MenuItem>
            ) : (
              <MenuItem key={`${estateType}, cannot add`} disabled={true}>
                この画面では{estateType}を追加できません
              </MenuItem>
            )
          )
        );
      }
    } else {
      items.push(
        ...[
          <MenuItem key="出発地にする（S）" onClick={props.onSetRouteFrom}>
            出発地にする（S）
          </MenuItem>,
          <MenuItem key="目的地にする（E）" onClick={props.onSetRouteTo}>
            目的地にする（E）
          </MenuItem>,
          <MenuItem key="経由地に追加" onClick={props.onAddRouteWaypoint}>
            経由地に追加
          </MenuItem>,
          <MenuItem
            key="この場所のGoogle Mapリンクをコピー"
            onClick={props.onCopyLinkOfGoogleMap}
          >
            この場所のGoogle Mapリンクをコピー
          </MenuItem>,
          <MenuItem
            key="ここにメモを追加"
            disabled={props.stickyNoteMode === undefined}
            onClick={props.onCreateStickNote}
          >
            ここにメモを追加
          </MenuItem>
        ]
      );
    }

    return (
      <ContextMenu
        contextMenuPosition={props.mapContextMenuPosition}
        onClose={props.onMapContextMenuClose}
      >
        {items}
      </ContextMenu>
    );
  }
);

export const ContextMenuOnRouteMarker = React.memo(
  (props: {
    position: {
      mouseX: number;
      mouseY: number;
    } | null;
    routeLocationStatus: RouteLocationStatus;
    onRouteLocationContextMenuClose: (
      event: {},
      reason: "backdropClick" | "escapeKeyDown"
    ) => void;
    onSetRouteFrom: (() => void) | undefined;
    onSetRouteWaypoint: (() => void) | undefined;
    onSetRouteTo: (() => void) | undefined;
    onDeleteRouteLocation: (() => void) | undefined;
  }) => (
    <ContextMenu
      contextMenuPosition={props.position}
      onClose={props.onRouteLocationContextMenuClose}
    >
      <MenuItem
        onClick={props.onSetRouteFrom}
        disabled={props.routeLocationStatus === "from"}
      >
        このポイントを出発地に変更（S）
      </MenuItem>
      <MenuItem
        onClick={props.onSetRouteTo}
        disabled={props.routeLocationStatus === "to"}
      >
        このポイントを目的地に変更（E）
      </MenuItem>
      <MenuItem
        onClick={props.onSetRouteWaypoint}
        disabled={props.routeLocationStatus === "waypoints"}
      >
        このポイントを経由地に変更
      </MenuItem>
      <MenuItem onClick={props.onDeleteRouteLocation}>
        このポイントをルートから削除
      </MenuItem>
    </ContextMenu>
  )
);

export const ContextMenuOnSchool = React.memo(
  (props: {
    schoolContextMenuPosition: {
      mouseX: number;
      mouseY: number;
    } | null;
    onSchoolContextMenuClose: (
      event: {},
      reason: "backdropClick" | "escapeKeyDown"
    ) => void;
    onSetRouteFrom: (() => void) | undefined;
    onAddRouteWaypoint: (() => void) | undefined;
    onSetRouteTo: (() => void) | undefined;
  }) => {
    const items: JSX.Element[] = [];

    items.push(
      ...[
        <MenuItem
          key="この学校を出発地にする（S）"
          onClick={props.onSetRouteFrom}
        >
          この学校を出発地にする（S）
        </MenuItem>,
        <MenuItem
          key="この学校を目的地にする（E）"
          onClick={props.onSetRouteTo}
        >
          この学校を目的地にする（E）
        </MenuItem>,
        <MenuItem
          key="この学校を経由地に追加"
          onClick={props.onAddRouteWaypoint}
        >
          この学校を経由地に追加
        </MenuItem>
      ]
    );

    return (
      <ContextMenu
        contextMenuPosition={props.schoolContextMenuPosition}
        onClose={props.onSchoolContextMenuClose}
      >
        {items}
      </ContextMenu>
    );
  }
);
