import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextField
} from "@mui/material";
import Pin from "assets/icons/estate_location/pin.svg";
import Apartment from "assets/icons/ic_map_type_apartment.svg";
import Block from "assets/icons/ic_map_type_block.svg";
import Business from "assets/icons/ic_map_type_business.svg";
import Ground from "assets/icons/ic_map_type_ground.svg";
import New from "assets/icons/ic_map_type_new.svg";
import Used from "assets/icons/ic_map_type_used.svg";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { ReportKind } from "common/interfaces/ReportKind";
import { JsonMasterSwitchButtons } from "components/Parts/Atoms/JsonMasterSwitchButtons";
import { LocationMap } from "components/Parts/Molecules/LocationMap";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import styles from "./ReportInputDialog.module.scss";
import { ReportInputDialogHooksType } from "./hooks";

export const useInputFields = ({
  estate,
  report,

  specifyMessage,

  busy,
  forceDisable,

  reportKinds,

  map,
  location,
  center,
  zoom,
  places,

  address,

  onReportKindChange,
  onDescriptionChange,

  onAddressChange,
  onAddressToMapButtonClick,

  onMapChange,
  onMapReady,

  onLocationChange
}: ReportInputDialogHooksType) => {
  return useCallback(() => {
    const isCondominium = estate.estateType === EstateTypeNameEnum.マンション;

    const locationMap = (
      <Grid container className={styles.mapWrapper}>
        <Grid item xs={12}>
          <LocationMap
            mapDivId="location-map"
            location={location}
            center={center}
            zoom={zoom}
            places={places}
            draggable={!busy && !isCondominium}
            onChange={onMapChange}
            onMapReady={onMapReady}
            onLocationChange={onLocationChange}
            className={styles.map}
          />
        </Grid>
        <Grid item xs={12}>
          <small>
            緯度: {estate.latitude} / 経度: {estate.longitude}
          </small>
        </Grid>
        <Grid item xs={12}>
          <small>
            都道府県: {estate.prefecture || "？"} / 市区町村:{" "}
            {estate.city || "？"} / 町域: {estate.area || "？"}
          </small>
        </Grid>
      </Grid>
    );

    const inputFields: { title: string; value: JSX.Element }[] = [
      {
        title: "情報",
        value: (
          <Box className={styles.bkn_info}>
            <Box className={styles.box}>
              <Box className={styles.icon}>
                <Box
                  component="img"
                  src={
                    estate.estateType === EstateTypeNameEnum.土地
                      ? Ground
                      : estate.estateType === EstateTypeNameEnum.分譲地
                      ? Block
                      : estate.estateType === EstateTypeNameEnum.新築建売
                      ? New
                      : estate.estateType === EstateTypeNameEnum.中古戸建
                      ? Used
                      : estate.estateType === EstateTypeNameEnum.マンション
                      ? Apartment
                      : estate.estateType === EstateTypeNameEnum.事業用
                      ? Business
                      : undefined
                  }
                />
              </Box>
              <Box className={styles.dtl}>
                <Box className={styles.box}>
                  <Box className={styles.tit}>ID（物件番号）</Box>
                  <Box className={styles.tx}>{estate.id}</Box>
                </Box>
                <Box className={styles.box}>
                  <Box className={styles.tit}>物件名称</Box>
                  <Box className={styles.tx}>{estate.name}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      },
      {
        title: "-1",
        value: <Box className={styles.separator} />
      },
      {
        title: "報告内容",
        value: (
          <JsonMasterSwitchButtons<ReportKind>
            id="report-kind"
            label="報告内容"
            master={reportKinds}
            value={report.reportKind}
            disabled={forceDisable || busy}
            onChange={onReportKindChange}
          />
        )
      },
      {
        title: "-2",
        value: <Box className={styles.separator} />
      },
      {
        title: "",
        value: (
          <FormControl fullWidth>
            <Box className={styles.fieldWrapper}>
              <Box className={styles.field}>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  disabled={forceDisable || busy}
                  value={report.description || ""}
                  onChange={onDescriptionChange}
                  placeholder="内容を記入してください"
                />
              </Box>
            </Box>
          </FormControl>
        )
      },
      {
        title: "-3",
        value: <Box className={styles.separator} />
      },
      {
        title: "位置",
        value: (
          <FormControl fullWidth>
            <FormLabel className={styles.formLabel} id="location-label">
              物件の位置
              {isCondominium ? (
                <Box component="span" className={styles.warning}>
                  {" "}
                  （マンションの位置情報は報告できません）
                </Box>
              ) : (
                ""
              )}
            </FormLabel>
            <Box className={styles.fieldWrapper}>
              <Box className={styles.place}>
                <Box className={styles.field}>
                  <TextField
                    id="place-searcher"
                    fullWidth
                    disabled={forceDisable || busy || isCondominium}
                    placeholder="〒、住所の一部等を入力"
                    value={address}
                    onChange={onAddressChange}
                  />
                </Box>
                <FormControl>
                  <Button
                    disabled={
                      forceDisable ||
                      busy ||
                      isEmpty(estate.fullAddress) ||
                      isCondominium
                    }
                    variant="outlined"
                    onClick={onAddressToMapButtonClick}
                    className={styles.addressToMapButton}
                  >
                    <Box component="span" className={styles.buttonIcon}>
                      <Box component="img" src={Pin} />
                    </Box>
                    位置取得
                  </Button>
                </FormControl>
              </Box>
              <Box component="ul" className={styles.ki_maru_list}>
                <Box component="li">
                  ピンを動かして正しい位置を教えてください
                </Box>
              </Box>
              {locationMap}
            </Box>
          </FormControl>
        )
      }
    ];

    return inputFields;
  }, [
    estate.estateType,
    estate.latitude,
    estate.longitude,
    estate.prefecture,
    estate.city,
    estate.area,
    estate.id,
    estate.name,
    estate.fullAddress,
    location,
    center,
    zoom,
    places,
    busy,
    onMapChange,
    onMapReady,
    onLocationChange,
    reportKinds,
    report.reportKind,
    report.description,
    forceDisable,
    onReportKindChange,
    onDescriptionChange,
    address,
    onAddressChange,
    onAddressToMapButtonClick
  ]);
};
