import { StatusNameEnum } from "common/enums/StatusEnum";
import {
  incrementEstateAtomicCounter as invokeIncrementEstateAtomicCounter,
  incrementSearchAtomicCounter as invokeIncrementSearchAtomicCounter
} from "common/functions/api/atomicCounters";
import { handleError } from "common/functions/utilities";
import { MainContext } from "common/interfaces/MainContext";
import { MinimalEstate } from "common/queries/minimalEstates";
import { useCallback } from "react";

export const useSearchCounterMethods = (mainContext: MainContext) => {
  const incrementEstateAtomicCounter = useCallback(
    async (estate: MinimalEstate) => {
      const blocks = [
        ...(estate.blocks || []),
        ...(estate.blocks || []).flatMap((x) => x.blocks || [])
      ];

      if (blocks.length === 0 && estate.id) {
        if (estate.status === StatusNameEnum.公開) {
          await invokeIncrementEstateAtomicCounter(estate.id).catch((error) => {
            handleError(error, mainContext.setFatal);
          });
        }
      } else {
        await Promise.all(
          blocks.map((x) => {
            if (x.id && x.status === StatusNameEnum.公開) {
              return invokeIncrementEstateAtomicCounter(x.id);
            }

            return undefined;
          })
        ).catch((error) => {
          handleError(error, mainContext.setFatal);
        });
      }
    },
    [mainContext.setFatal]
  );

  const incrementSearchAtomicCounter = useCallback(async () => {
    await invokeIncrementSearchAtomicCounter().catch((error) => {
      handleError(error, mainContext.setFatal);
    });
  }, [mainContext.setFatal]);

  return {
    incrementEstateAtomicCounter,
    incrementSearchAtomicCounter
  } as const;
};

export type SearchCounterMethodsType = ReturnType<
  typeof useSearchCounterMethods
>;
