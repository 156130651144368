import { boundSearchableZoom } from "common/consts/estates";
import { GroupEnum } from "common/enums/GroupEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import {
  searchEstate as invokeSearchEstate,
  setCancelSearch
} from "common/functions/api/estates";
import { getEstateCounts } from "common/functions/jsonMasters/estateCounts";
import { boundsToTiles } from "common/functions/mapUtilities";
import { EstateCount } from "common/interfaces/EstateCount";
import { EstateSearchConditions } from "common/interfaces/EstateSearchConditions";
import { MainContext } from "common/interfaces/MainContext";
import { MinimalEstate } from "common/queries/minimalEstates";
import { mapKeys } from "lodash";
import { useCallback, useEffect } from "react";
import { SearchBehaviorStatesType } from "../states/behaviors";
import { SearchEstateSearchConditionStatesType } from "../states/estateSearchConditions";
import { SearchEstateStatesType } from "../states/estates";
import { SearchMapStatesType } from "../states/maps";
import { SearchEstateSearchStatesType } from "../states/searches";
import { SearchCounterMethodsType } from "./counters";

export const useSearchEstateMethods = (
  mainContext: MainContext,
  behaviorStates: SearchBehaviorStatesType,
  estateStates: SearchEstateStatesType,
  mapStates: SearchMapStatesType,
  estateSearchConditionStates: SearchEstateSearchConditionStatesType,
  estateSearchStates: SearchEstateSearchStatesType,
  counterMethods: SearchCounterMethodsType
) => {
  // 物件検索
  const searchEstate = useCallback(
    async (
      searchMode: SearchModeEnum,
      conditions: EstateSearchConditions,
      initialSearch: boolean,
      addedEstateStatuses: boolean,
      addedTiles: boolean,
      forceCurrentEstates?: MinimalEstate[]
    ) => {
      estateStates.setEstates([]);
      estateStates.setEstatesCount(0);

      behaviorStates.setBusy(true);
      behaviorStates.setInitialSearch(initialSearch);

      // 検索済み物件退避
      const currentEstates = [
        ...(forceCurrentEstates || estateStates.estates || [])
      ];

      const innerConditions = { ...conditions };

      if (addedEstateStatuses) {
        // 未検索の物件ステータスを抽出
        innerConditions.statuses = [
          ...conditions.statuses.filter(
            (x) =>
              !currentEstates.map((estate) => estate.status).includes(x.name)
          )
        ];
      }

      if (
        mainContext.groups?.includes(GroupEnum.Administrator) ||
        mainContext.groups?.includes(GroupEnum.HouseMaker) ||
        mainContext.groups?.includes(GroupEnum.RealEstateAgency) ||
        mainContext.groups?.includes(GroupEnum.Staff)
      ) {
        innerConditions.companies = [];
      } else if (mainContext.company) {
        innerConditions.companies = [mainContext.company];
      }

      const allEstateCounts: EstateCount[] = [];

      for (const city of innerConditions.cities) {
        for (const status of innerConditions.statuses) {
          const estateCount = await getEstateCounts(
            city.pref_code,
            city.city_code,
            status.name ?? "？"
          ).catch(() => undefined);

          if (estateCount) {
            allEstateCounts.push(estateCount);
          }
        }
      }

      const total =
        allEstateCounts?.reduce(
          (prev, estateCount) => prev + estateCount.count,
          0
        ) ?? 0;

      estateStates.setEstatesTotalCount(total);

      const estates = await invokeSearchEstate(
        searchMode,
        innerConditions,
        estateStates.setEstatesCount
      ).catch((error) => {
        mainContext.setFatal?.(error);
        return undefined;
      });

      if (estates) {
        if (addedEstateStatuses || addedTiles) {
          // 未検索の物件ステータス・タイルによる検索結果を既存の物件リストに追加
          const newEstates = [
            ...estates,
            ...(currentEstates as MinimalEstate[])
          ];
          const uniqueNewEstates =
            Object.values(mapKeys(newEstates, (x) => x.id)) ?? [];

          estateStates.setEstates(uniqueNewEstates);
        } else {
          estateStates.setEstates(estates);
        }

        // 検索カウンタ増分
        await counterMethods.incrementSearchAtomicCounter().catch((error) => {
          mainContext.setFatal?.(error);
        });
      }

      behaviorStates.setInitialSearch(false);
      behaviorStates.setBusy(false);
      estateSearchStates.setCancelSearch(false);
    },
    [
      behaviorStates,
      counterMethods,
      estateSearchStates,
      estateStates,
      mainContext
    ]
  );

  // 表示範囲内の物件検索
  const boundSearch = useCallback(
    (searchMode: SearchModeEnum) => {
      const bounds = mapStates.map?.getBounds();
      const zoom = mapStates.zoom;

      if (zoom < boundSearchableZoom) {
        // 範囲検索不可能なズーム値では処理しない
        estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom12();
        estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom14();
        estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom16();

        estateStates.setEstates([]);
        estateStates.setEstatesCount(0);
        estateStates.setFilteredPublicEstates([]);
        estateStates.setSoldEstates([]);
        return;
      }

      const conditions: EstateSearchConditions = {
        ...estateSearchConditionStates.estateSearchConditions,
        tilesOnZoom12: [],
        tilesOnZoom14: [],
        tilesOnZoom16: []
      };

      if (bounds) {
        // 現在のタイルを退避
        const currentTilesOnZoom12 = [
          ...estateSearchConditionStates.estateSearchConditions.tilesOnZoom12
        ];
        const currentTilesOnZoom14 = [
          ...estateSearchConditionStates.estateSearchConditions.tilesOnZoom14
        ];
        const currentTilesOnZoom16 = [
          ...estateSearchConditionStates.estateSearchConditions.tilesOnZoom16
        ];

        const useTileOnZoom12 = zoom < 14;
        const useTileOnZoom14 = zoom >= 14 && zoom < 16;

        const tiles = boundsToTiles(
          bounds,
          useTileOnZoom12 ? 12 : useTileOnZoom14 ? 14 : 16
        );

        if (useTileOnZoom12) {
          conditions.tilesOnZoom12 = tiles.filter(
            (x) => !currentTilesOnZoom12.includes(x)
          );
          estateSearchConditionStates.setEstateSearchConditions.tilesOnZoom12(
            tiles
          );
          estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom14();
          estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom16();
        } else if (useTileOnZoom14) {
          conditions.tilesOnZoom14 = tiles.filter(
            (x) => !currentTilesOnZoom14.includes(x)
          );
          estateSearchConditionStates.setEstateSearchConditions.tilesOnZoom14(
            tiles
          );
          estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom12();
          estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom16();
        } else {
          conditions.tilesOnZoom16 = tiles.filter(
            (x) => !currentTilesOnZoom16.includes(x)
          );
          estateSearchConditionStates.setEstateSearchConditions.tilesOnZoom16(
            tiles
          );
          estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom12();
          estateSearchConditionStates.clearEstateSearchConditions.tilesOnZoom14();
        }

        // 範囲外の物件を排除
        const currentEstates = [...(estateStates.estates || [])].filter((x) =>
          tiles.includes(
            (useTileOnZoom12
              ? x.tileOnZoom12
              : useTileOnZoom14
              ? x.tileOnZoom14
              : x.tileOnZoom16) || ""
          )
        );

        searchEstate(
          searchMode,
          conditions,
          false,
          false,
          true,
          currentEstates
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      estateSearchConditionStates.clearEstateSearchConditions,
      estateSearchConditionStates.estateSearchConditions,
      estateSearchConditionStates.setEstateSearchConditions,
      estateStates,
      mapStates.map,
      mapStates.zoom,

      behaviorStates,
      counterMethods,
      estateSearchStates,
      mainContext.company,
      mainContext.groups
    ]
  );

  // 検索を停止させるフラグを認識させる
  useEffect(() => {
    setCancelSearch(estateSearchStates.cancelSearch);
  }, [estateSearchStates.cancelSearch]);

  return {
    searchEstate,
    boundSearch
  } as const;
};

export type SearchEstateMethodsType = ReturnType<typeof useSearchEstateMethods>;
