import { Company } from "API";
import { getCompaniesFromRecNo } from "common/functions/api/companies";
import { handleError } from "common/functions/utilities";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useCompanyWithRecNoHooks = (
  recNo: number | null | undefined,
  setFatal: ((error?: string | null | undefined) => void) | undefined
) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [busy, setBusy] = useState(false);

  const { data, error, isLoading } = useSWR(
    `companies of recNo ${recNo}`,
    () => {
      if (recNo) {
        return getCompaniesFromRecNo(recNo);
      }
      return [];
    }
  );

  useEffect(() => {
    setBusy(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setCompanies(data);
    }
  }, [data]);

  return { companies, busy } as const;
};
