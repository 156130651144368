import { TokyoCoords } from "common/consts/googleMaps";
import { useCallback, useState } from "react";

const initialValues = {
  map: undefined as google.maps.Map | undefined,
  location: null as google.maps.LatLngLiteral | null,
  center: {
    lat: TokyoCoords.lat,
    lng: TokyoCoords.lng
  } as google.maps.LatLngLiteral,
  zoom: 19,
  places: undefined as google.maps.places.PlaceResult | undefined,
  placesService: undefined as google.maps.places.PlacesService | undefined
};

export const useMapHooks = () => {
  const [map, setMap] = useState<google.maps.Map | undefined>(
    initialValues.map
  );
  const [location, setLocation] = useState<google.maps.LatLngLiteral | null>(
    initialValues.location
  );
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(
    initialValues.center
  );
  const [zoom, setZoom] = useState<number>(initialValues.zoom);
  const [places, setPlaces] = useState<
    google.maps.places.PlaceResult | undefined
  >(initialValues.places);
  const [placesService, setPlacesService] = useState<
    google.maps.places.PlacesService | undefined
  >(initialValues.placesService);

  const initialize = useCallback(() => {
    setMap(initialValues.map);
    setLocation(initialValues.location);
    setCenter(initialValues.center);
    setZoom(initialValues.zoom);
    setPlaces(initialValues.places);
    setPlacesService(initialValues.placesService);
  }, []);

  return {
    map,
    setMap,
    location,
    setLocation,
    center,
    setCenter,
    zoom,
    setZoom,
    places,
    placesService,
    setPlacesService,

    initialize
  } as const;
};
