import { TokyoCoords } from "common/consts/googleMaps";
import { City } from "common/interfaces/City";
import { MainContext } from "common/interfaces/MainContext";
import { SchoolArea } from "common/interfaces/SchoolArea";
import { RouteLocations } from "components/Parts/Organisms/SearchMap/interfaces";
import { useSchoolPointHooks } from "hooks/schoolPointHooks";
import { useRef, useState } from "react";
import { initialRouteLocations } from "../../consts";

export const useSearchMapStates = (mainContext: MainContext) => {
  // マップ
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(TokyoCoords);
  const [zoom, setZoom] = useState(12);

  // 移動関連
  const [places, setPlaces] = useState<
    google.maps.places.PlaceResult | undefined
  >(undefined);
  const [currentPosition, setCurrentPosition] = useState<
    google.maps.LatLngLiteral | undefined
  >(undefined);
  const [busyForMove, setBusyForMove] = useState(false);

  // マーカーに表示するフィールド
  const [dspPrice, setDspPrice] = useState(true);
  const [dspTsuboArea, setDspTsuboArea] = useState(false);

  // 学校区描画
  const [dspESchools, setDspESchools] = useState(false);
  const [dspJSchools, setDspJSchools] = useState(false);
  const [selectedESchoolCities, setSelectedESchoolCities] = useState<City[]>(
    []
  );
  const [selectedJSchoolCities, setSelectedJSchoolCities] = useState<City[]>(
    []
  );
  const [schoolAreas, setSchoolAreas] = useState<SchoolArea[]>([]);
  const { schoolPoints } = useSchoolPointHooks(
    schoolAreas,
    mainContext.setFatal
  );

  // ハザード描画
  const [dspDoshasaigai, setDspDoshasaigai] = useState(false);
  const [dspKouzui, setDspKouzui] = useState(false);
  const [dspTeiichitai, setDspTeiichitai] = useState(false);
  const [dspTsunami, setDspTsunami] = useState(false);
  const [dspTakashio, setDspTakashio] = useState(false);

  // 用途地域描画
  const [dspYouto, setDspYouto] = useState(false);

  // 地物表示選択
  const [dspPoi, setDspPoi] = useState(false);

  // 公図描画
  const [dspMoj, setDspMoj] = useState(false);

  // 場所検索ボックス表示
  const [showSearchLocation, setShowSearchLocation] = useState(false);

  // IDボックス表示
  const [showSearchId, setShowSearchId] = useState(false);

  // 路線検索系
  const [searchRouteMode, setSearchRouteMode] = useState(false);
  const [routeLocations, setRouteLocations] = useState<RouteLocations>(
    initialRouteLocations
  );
  const [deleteRouteLocation, setDeleteRouteLocation] =
    useState<google.maps.LatLng | null>(null);
  const [routeLocationsResult, setRouteLocationsResult] =
    useState<google.maps.DirectionsResult | null>(null);
  const [optimizeWaypoints, setOptimizeWaypoints] = useState(false);
  const [travelMode, setTravelMode] = useState<google.maps.TravelMode | null>(
    null
  );

  // 計測系
  const [measureLengthMode, setMeasureLengthMode] = useState(false);
  const [measureAreaMode, setMeasureAreaMode] = useState(false);

  // 検索トリガー
  const [searchTrigger, setSearchTrigger] = useState(false);

  // 別タブオープン用リンク
  const anchorRef = useRef<HTMLAnchorElement>(null);

  return {
    map,
    setMap,
    center,
    setCenter,
    zoom,
    setZoom,

    places,
    setPlaces,
    currentPosition,
    setCurrentPosition,
    busyForMove,
    setBusyForMove,

    dspPrice,
    setDspPrice,
    dspTsuboArea,
    setDspTsuboArea,

    dspESchools,
    setDspESchools,
    dspJSchools,
    setDspJSchools,
    selectedESchoolCities,
    setSelectedESchoolCities,
    selectedJSchoolCities,
    setSelectedJSchoolCities,
    schoolAreas,
    setSchoolAreas,
    schoolPoints,

    dspDoshasaigai,
    setDspDoshasaigai,
    dspKouzui,
    setDspKouzui,
    dspTeiichitai,
    setDspTeiichitai,
    dspTsunami,
    setDspTsunami,
    dspTakashio,
    setDspTakashio,

    dspYouto,
    setDspYouto,

    dspPoi,
    setDspPoi,

    dspMoj,
    setDspMoj,

    showSearchLocation,
    setShowSearchLocation,

    showSearchId,
    setShowSearchId,

    searchRouteMode,
    setSearchRouteMode,
    routeLocations,
    setRouteLocations,
    deleteRouteLocation,
    setDeleteRouteLocation,
    routeLocationsResult,
    setRouteLocationsResult,
    optimizeWaypoints,
    setOptimizeWaypoints,
    travelMode,
    setTravelMode,

    measureLengthMode,
    setMeasureLengthMode,
    measureAreaMode,
    setMeasureAreaMode,

    searchTrigger,
    setSearchTrigger,

    anchorRef
  } as const;
};

export type SearchMapStatesType = ReturnType<typeof useSearchMapStates>;
