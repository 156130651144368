import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  CreateStickyNoteFMutation,
  CreateStickyNoteFMutationVariables,
  DeleteStickyNoteFMutation,
  DeleteStickyNoteFMutationVariables,
  GetStickyNoteQuery,
  GetStickyNoteQueryVariables,
  ListStickyNotesBySearchInfoQuery,
  ListStickyNotesBySearchInfoQueryVariables,
  StickyNote,
  UpdateStickyNoteFMutation,
  UpdateStickyNoteFMutationVariables
} from "API";
import { generateClient } from "aws-amplify/api";
import { StickyNoteSearchConditions } from "common/interfaces/StickyNoteSearchConditions";
import {
  createStickyNoteF as createStickyNoteFMutation,
  deleteStickyNoteF as deleteStickyNoteFMutation,
  updateStickyNoteF as updateStickyNoteFMutation
} from "graphql/mutations";
import {
  getStickyNote as getStickyNoteQuery,
  listStickyNotesBySearchInfo
} from "graphql/queries";
import { getSumoraAuthToken } from "../credentials";

// 検索を停止させるフラグ
let cancelSearch = false;

// メモ検索
export const searchStickyNote = async (
  conditions: StickyNoteSearchConditions,
  stickyNotesCountSetter?: (value: number | null) => void
) => {
  const stickyNotes: StickyNote[] = [];

  const variablesList = constructVariablesForStickyNote(conditions);

  const responses = variablesList.map(async (variables) => {
    let nextToken: string | null = "start";
    const client = generateClient();

    while (nextToken && !cancelSearch) {
      if (nextToken !== "start") {
        variables.nextToken = nextToken;
      }

      let items: (StickyNote | null)[] = [];

      const res = (await client.graphql({
        ...graphqlOperation(listStickyNotesBySearchInfo, variables),
        authMode: "lambda",
        authToken: await getSumoraAuthToken()
      })) as GraphQLResult<ListStickyNotesBySearchInfoQuery>;

      if (res.data?.listStickyNotesBySearchInfo?.items) {
        items = res.data?.listStickyNotesBySearchInfo?.items;
      }

      nextToken = res.data?.listStickyNotesBySearchInfo?.nextToken || null;

      // 返却値のセット
      items.forEach((x) => {
        if (x) {
          stickyNotes.push(x);
        }
      });

      if (stickyNotesCountSetter) {
        stickyNotesCountSetter(stickyNotes.length);
      }
    }
  });

  await Promise.all(responses);

  return stickyNotes;
};

// パラメータ構築
const constructVariablesForStickyNote = (
  conditions: StickyNoteSearchConditions
): ListStickyNotesBySearchInfoQueryVariables[] => {
  const innerConditions = { ...conditions };

  const variablesList: ListStickyNotesBySearchInfoQueryVariables[] = [];

  const tileOnZoom12Exists = innerConditions.tilesOnZoom12.length > 0;
  const tileOnZoom14Exists = innerConditions.tilesOnZoom14.length > 0;
  const tileOnZoom16Exists = innerConditions.tilesOnZoom16.length > 0;

  // Queryパラメータ構築
  for (const companyRecNo of [
    "-",
    ...innerConditions.companies.map((x) => x.recNo)
  ]) {
    // 位置範囲検索
    for (const tile of tileOnZoom12Exists
      ? innerConditions.tilesOnZoom12
      : tileOnZoom14Exists
      ? innerConditions.tilesOnZoom14
      : tileOnZoom16Exists
      ? innerConditions.tilesOnZoom16
      : []) {
      const variables: ListStickyNotesBySearchInfoQueryVariables = {
        infoName: `companyRecNoAndTileOnZoom${
          tileOnZoom12Exists
            ? "12"
            : tileOnZoom14Exists
            ? "14"
            : tileOnZoom16Exists
            ? "16"
            : ""
        }`,
        searchInfo: { eq: `${companyRecNo}#${tile}` },
        limit: 100
      };

      variablesList.push(variables);
    }
  }

  return variablesList;
};

// メモ取得
export const getStickyNote = async (id: string, infoName: string) => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(getStickyNoteQuery, {
      id: id,
      infoName: infoName
    } as GetStickyNoteQueryVariables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<GetStickyNoteQuery>;

  return res.data?.getStickyNote;
};

export const createStickyNote = async (stickyNote: StickyNote) => {
  const { __typename: _, ...input } = { ...stickyNote };
  const variables: CreateStickyNoteFMutationVariables = { input: input };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(createStickyNoteFMutation, variables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<CreateStickyNoteFMutation>;

  if (res.errors) {
    throw res.errors;
  }

  if (res.data?.createStickyNoteF?.errorMessage) {
    throw res.data.createStickyNoteF.errorMessage;
  }

  return res.data?.createStickyNoteF;
};

export const updateStickyNote = async (stickyNote: StickyNote) => {
  const { __typename: _, ...input } = { ...stickyNote };
  const variables: UpdateStickyNoteFMutationVariables = { input: input };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(updateStickyNoteFMutation, variables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<UpdateStickyNoteFMutation>;

  if (res.errors) {
    throw res.errors;
  }

  if (res.data?.updateStickyNoteF?.errorMessage) {
    throw res.data.updateStickyNoteF.errorMessage;
  }

  return res.data?.updateStickyNoteF;
};

export const deleteStickyNote = async (stickyNote: StickyNote) => {
  const variables: DeleteStickyNoteFMutationVariables = {
    input: {
      id: stickyNote.id,
      infoName: stickyNote.infoName,
      companyId: stickyNote.companyId
    }
  };
  const client = generateClient();

  const res = (await client.graphql({
    ...graphqlOperation(deleteStickyNoteFMutation, variables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<DeleteStickyNoteFMutation>;

  if (res.errors) {
    throw res.errors;
  }

  if (res.data?.deleteStickyNoteF?.errorMessage) {
    throw res.data.deleteStickyNoteF.errorMessage;
  }

  return res.data?.deleteStickyNoteF;
};
