export const menuButtonId = "menu-button";
export const menuDialogId = "menu-dialog";

export const searchMapId = "searchMap";
export const searchMapDivId = "mainMap";

export const initialRouteLocations = {
  from: null,
  waypoints: [],
  to: null
};
