import { Autocomplete, TextField } from "@mui/material";
import { MinimalEstate } from "common/queries/minimalEstates";
import { memo, useCallback, useEffect, useMemo } from "react";

export const EstateIdAutoComplete = memo(
  (props: {
    placeholder?: string;
    disabled?: boolean;
    open?: boolean;
    filteredPublicEstates: MinimalEstate[] | undefined;
    filteredClosedEstates: MinimalEstate[] | undefined;
    onEstateChange: (estate: MinimalEstate | null | undefined) => void;
  }) => {
    const id = "estate-id-autocomplete";

    const estates = useMemo(
      () => [
        ...(props.filteredPublicEstates || []),
        ...(props.filteredClosedEstates || [])
      ],
      [props.filteredClosedEstates, props.filteredPublicEstates]
    );

    const options = useMemo(() => {
      const idList = estates.map((x) => x.id) || [];

      idList.push(
        ...(estates.flatMap((x) => x.blocks?.map((block) => block.id)) || [])
      );

      idList.push(
        ...(estates.flatMap((x) =>
          x.blocks?.flatMap((block) => block.blocks?.map((child) => child.id))
        ) || [])
      );

      return [...new Set(idList.filter((x) => x) as string[]).values()].sort(
        (a, b) => a.localeCompare(b)
      );
    }, [estates]);

    const onChange = useCallback(
      (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
        const estate = estates.find(
          (x) =>
            x.id === value ||
            x.blocks?.some(
              (block) =>
                block.id === value ||
                block.blocks?.some((child) => child.id === value)
            )
        );
        props.onEstateChange(estate || null);
      },
      [estates, props]
    );

    useEffect(() => {
      props.onEstateChange(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    return props.open ? (
      <Autocomplete
        disabled={options.length === 0 || props.disabled}
        noOptionsText={<i>候補が見つかりません</i>}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            variant="standard"
            placeholder={props.placeholder}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            disabled={props.disabled}
          />
        )}
        options={options}
        onChange={onChange}
      />
    ) : null;
  }
);
