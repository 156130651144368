import {
  clearMarker,
  clearMarkerClusterer
} from "common/functions/mapUtilities";
import { useCallback } from "react";
import { SearchMapHandlerProperties } from "../../interfaces";

export interface MarkerDataBase {
  estateType?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  memoKind?: string | null;
  openLevel?: string | null;
  count?: number | null;
  captionText?: string | null;
  unitPriceText?: string | null;
  description?: string | null;
}

export const useUtilityMethods = () => {
  // 全物件マーカーの破棄
  const clearAllEstateMarkers = useCallback(
    (properties: SearchMapHandlerProperties) => {
      (Object.values(properties.estateMarkers) ?? [])
        .map((x) => x.marker)
        .forEach((marker) => {
          clearMarker(marker);
        });

      if (properties.markerClusterer) {
        try {
          properties.markerClusterer.clearMarkers();
        } catch (e) {
          window.console.info(e);
        }
        clearMarkerClusterer(properties.markerClusterer);
      }

      properties.estateMarkers = {};
      properties.circleIds = [];
    },
    []
  );

  // 全成約済み物件マーカーの破棄
  const clearAllSoldEstateMarkers = useCallback(
    (properties: SearchMapHandlerProperties) => {
      (Object.values(properties.soldEstateMarkers) ?? [])
        .map((x) => x.marker)
        .forEach((marker) => {
          clearMarker(marker);
        });

      properties.soldEstateMarkers = {};
    },
    []
  );

  // 全路線マーカーの破棄
  const clearAllRouteLocationMarkers = useCallback(
    (properties: SearchMapHandlerProperties) => {
      for (const routeLocationMarker of properties.routeLocationMarkers) {
        clearMarker(routeLocationMarker);
      }

      properties.routeLocationMarkers = [];
    },
    []
  );

  // 全メモマーカーの破棄
  const clearAllStickyNoteMarkers = useCallback(
    (properties: SearchMapHandlerProperties) => {
      (Object.values(properties.stickyNoteMarkers) ?? [])
        .map((x) => x.marker)
        .forEach((marker) => {
          clearMarker(marker);
        });

      properties.stickyNoteMarkers = {};
    },
    []
  );

  // 全メモ情報ウィンドウの破棄
  const clearAllStickyNoteInfoWindows = useCallback(
    (properties: SearchMapHandlerProperties) => {
      (Object.values(properties.stickyNoteInfoWindows) ?? [])
        .map((x) => x.infoWindow)
        .forEach((infoWindow) => {
          infoWindow.close();
        });

      properties.stickyNoteInfoWindows = {};
    },
    []
  );

  // データの等価性(マーカーありき)
  const equalsMarkerBase = useCallback(
    <T extends MarkerDataBase>(
      data: T | null,
      marker: {
        marker?: google.maps.marker.AdvancedMarkerElement;
        data: T;
      }
    ) =>
      // データあり
      data &&
      // 種別一致
      (marker.data.estateType || "") === (data.estateType || "") &&
      // 緯度一致
      (marker.data.latitude || 0) === (data.latitude || 0) &&
      // 経度一致
      (marker.data.longitude || 0) === (data.longitude || 0) &&
      // メモ種別一致
      (marker.data.memoKind || "") === (data.memoKind || "") &&
      // 公開範囲一致
      (marker.data.openLevel || "") === (data.openLevel || "") &&
      // 件数一致
      (marker.data.count || 0) === (data.count || 0) &&
      // マーカー文字列一致
      (marker.data.captionText || "") === (data.captionText || "") &&
      // 坪単価一致
      (marker.data.unitPriceText || "") === (data.unitPriceText || "") &&
      // 本文一致
      (marker.data.description || "") === (data.description || ""),
    []
  );

  // データの等価性(データありき)
  const equalsDataBase = useCallback(
    <T extends MarkerDataBase>(
      data: T,
      marker: {
        marker?: google.maps.marker.AdvancedMarkerElement;
        data: T;
      } | null
    ) =>
      // マーカーあり
      marker &&
      // 種別一致
      (marker.data.estateType || "") === (data.estateType || "") &&
      // 緯度一致
      (marker.data.latitude || 0) === (data.latitude || 0) &&
      // 経度一致
      (marker.data.longitude || 0) === (data.longitude || 0) &&
      // メモ種別一致
      (marker.data.memoKind || "") === (data.memoKind || "") &&
      // 公開範囲一致
      (marker.data.openLevel || "") === (data.openLevel || "") &&
      // 件数一致
      (marker.data.count || 0) === (data.count || 0) &&
      // マーカー文字列一致
      (marker.data.captionText || "") === (data.captionText || "") &&
      // 坪単価一致
      (marker.data.unitPriceText || "") === (data.unitPriceText || ""),
    []
  );

  return {
    clearAllEstateMarkers,
    clearAllSoldEstateMarkers,
    clearAllRouteLocationMarkers,
    clearAllStickyNoteMarkers,
    clearAllStickyNoteInfoWindows,
    equalsMarkerBase,
    equalsDataBase
  } as const;
};
