import { AutocompleteValue } from "@mui/material";
import { Area } from "common/interfaces/Area";
import { City } from "common/interfaces/City";
import { JsonMaster } from "common/interfaces/JsonMaster";
import { Prefecture } from "common/interfaces/Prefecture";

export const invokeConvertValue = <
  T extends JsonMaster | Prefecture | City | Area,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  multiple: boolean | undefined,
  jsonMasters: T[],
  value:
    | AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
    | string
    | string[]
    | undefined
) => {
  if (value) {
    if (typeof value === "string") {
      return (jsonMasters.find(
        (x) =>
          ("area_name" in x
            ? x.area_name
            : "city_name" in x
            ? x.city_name
            : "pref_name" in x
            ? x.pref_name
            : x.name) === value
      ) || null) as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;
    }

    if (value instanceof Array) {
      return (value
        .map((x) => {
          if (typeof x === "string") {
            return jsonMasters.find(
              (master) =>
                ("area_name" in master
                  ? master.area_name
                  : "city_name" in master
                  ? master.city_name
                  : "pref_name" in master
                  ? master.pref_name
                  : master.name) === x
            );
          }

          return x;
        })
        .filter((x) => x) || []) as AutocompleteValue<
        T,
        Multiple,
        DisableClearable,
        FreeSolo
      >;
    }
  }

  return (value || (multiple ? [] : null)) as AutocompleteValue<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >;
};
