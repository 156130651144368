import { City } from "common/interfaces/City";
import { Prefecture } from "common/interfaces/Prefecture";
import { getJsonAddressMasters } from "./common";

export const getCities = (prefCode: string, forAnonymous: boolean) =>
  getJsonAddressMasters<City>(`${prefCode}/cities`, forAnonymous);

export const getCitiesFromPrefs = async (
  prefs: Prefecture[],
  forAnonymous: boolean
) => {
  const cities: City[] = [];

  for (const prefecture of prefs) {
    const citiesOfPref = await getCities(prefecture.pref_code, forAnonymous);

    if (citiesOfPref) {
      cities.push(...citiesOfPref);
    }
  }

  if (cities) {
    cities.sort((a, b) =>
      `${a.pref_name}${a.city_name}`.localeCompare(
        `${b.pref_name}${b.city_name}`
      )
    );
    return cities;
  }

  return [];
};

export const getDefaultCities = (
  citiesMaster: City[],
  areas: (string | null)[] | null | undefined
) => {
  if (citiesMaster.length > 0 && areas) {
    const cities = Array.from(
      new Set(
        areas.map((area) => {
          if (area && area.length === 5) {
            return area;
          } else {
            return null;
          }
        })
      ).values()
    )
      .map((cityCode) =>
        citiesMaster.find((city) => city.city_code === cityCode)
      )
      .filter((city) => city !== undefined);

    return cities as City[];
  }

  return [];
};
