import { PanToolAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import React from "react";
import styles from "./ModeSelectDialog.module.scss";
import { useModeSelectDialogHooks } from "./hooks";
import { ModeSelectDialogProps } from "./interfaces";

export const ModeSelectDialog = React.memo((props: ModeSelectDialogProps) => {
  const { mode, busy, onModeClick, onOkClick } =
    useModeSelectDialogHooks(props);

  return (
    <>
      <Dialog
        style={props.style}
        className={`${props.className} ${styles.dialog}}`}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="mode-select-title"
        aria-describedby="mode-select-description"
        fullWidth
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{ backdrop: { className: styles.backdrop } }}
      >
        <DialogHeader id="mode-select-title" icon={<PanToolAlt />} />
        <DialogContent id="mode-select-description">
          <Box className={styles.paragraph}>
            <Box
              className={`${styles.button} ${
                mode === SearchModeEnum.all ? styles.on : styles.off
              }`}
              onClick={() => onModeClick(SearchModeEnum.all)}
            >
              {SearchModeEnum.all}
            </Box>
            <Box
              className={`${styles.button} ${
                mode === SearchModeEnum.domain ? styles.on : styles.off
              }`}
              onClick={() => onModeClick(SearchModeEnum.domain)}
            >
              {SearchModeEnum.domain}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            variant="contained"
            className={styles.cancelButton}
            disabled={busy}
            onClick={props.onCloseClick}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            className={styles.okButton}
            disabled={busy}
            onClick={onOkClick}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
