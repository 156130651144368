import { useTheme } from "@mui/material";
import { useCallback, useRef, useState } from "react";

export const useSearchRouteDialogHooks = () => {
  const theme = useTheme();
  const mainRef = useRef<HTMLDivElement>(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [expand, setExpand] = useState(true);

  const onClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      setShowQRCode(false);
    },
    []
  );

  const onCloseClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setShowQRCode(false);
    },
    []
  );

  const onShowQRCodeClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setShowQRCode(true);
    },
    []
  );

  return {
    mainRef,
    showQRCode,
    expand,
    setExpand,
    onClose,
    onCloseClick,
    onShowQRCodeClick,
    theme
  } as const;
};
