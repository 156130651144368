import { Prefecture } from "common/interfaces/Prefecture";
import React from "react";
import { useHooks } from "./hooks";

export const YoutoPlugin = React.memo(
  ({ on, drawingPrefs }: { on: boolean; drawingPrefs: Prefecture[] }) => {
    useHooks(on, drawingPrefs);

    return <></>;
  }
);
