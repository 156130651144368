import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../LicensePage.module.scss";

export const LicenseText = React.memo((props: { libraryName: string }) => {
  const [licenseText, setLicenseText] = useState<string>("");

  useEffect(() => {
    window.console.log(
      `${process.env.PUBLIC_URL}/${props.libraryName}.license.txt`
    );
    if (!props.libraryName) return;
    fetch(`${process.env.PUBLIC_URL}/${props.libraryName}.license.txt`).then(
      (response) => {
        response.text().then((text) => {
          window.console.log(text);
          setLicenseText(text);
        });
      }
    );
  }, [props.libraryName]);

  return (
    <Box component="pre" className={styles.licenseText}>
      <Box component="code">{licenseText}</Box>
    </Box>
  );
});
