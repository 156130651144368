export enum SearchModeEnum {
  draft = "下書き",
  estate = "物件",
  condominium = "マンションM",

  company = "特定業者管理物件",
  unknownPrefecture = "都道府県不明",
  unknownCity = "市区町村不明",
  unknownLocation = "位置不明",
  duplicateLocation = "位置重複",
  deleteDuplicateLocation = "位置重複削除",
  private = "非公開",
  deleted = "削除済み",

  all = "すべて",
  domain = "自社管理物件"
}
