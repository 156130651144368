import { Fab } from "@mui/material";
import colorOff from "assets/icons/btn_school_coloroff.svg";
import React from "react";
import styles from "./FillingSchoolPolygonsButton.module.scss";

export const FillingSchoolPolygonsButton = React.memo(
  (props: {
    fillingSchoolPolygons: boolean | undefined;
    onClearFillingSchoolPolygonsClick: () => void;
  }) => (
    <Fab
      onClick={props.onClearFillingSchoolPolygonsClick}
      className={styles.fillingSchoolPolygonsButton}
    >
      <img src={colorOff} alt="学校区の色クリア" />
    </Fab>
  )
);
