import { AboutPage } from "components/Parts/Organisms/AboutPage";
import { AboutProps } from "components/Parts/Organisms/AboutPage/interfaces";
import { Auth } from "components/Parts/Organisms/Auth";
import React from "react";

export const About = React.memo((props: AboutProps) => (
  <Auth>
    <AboutPage {...props} />
  </Auth>
));
