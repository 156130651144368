import { Box } from "@mui/material";
import { SumoraMap } from "components/Parts/Atoms/SumoraMap";
import React from "react";
import { useLocationMapHooks } from "./hooks";
import { LocationMapProps } from "./interfaces";

export const LocationMap = React.memo((props: LocationMapProps) => {
  const { onGoogleApiLoaded } = useLocationMapHooks(props);

  return (
    <>
      <Box
        style={props.style}
        sx={props.sx}
        className={props.className}
        id={props.mapDivId}
      >
        <SumoraMap
          draggable={props.draggable}
          center={props.center}
          zoom={props.zoom}
          streetViewControl={true}
          mapTypeControl={true}
          scaleControl={true}
          fullscreenControl={false}
          scrollwheel={true}
          onChange={props.onChange}
          onGoogleApiLoaded={onGoogleApiLoaded}
        />
      </Box>
    </>
  );
});
