import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { useCallback, useEffect, useState } from "react";
import { EstateTypeSelectDialogProps } from "./interfaces";

export const useEstateTypeSelectDialogHooks = (
  props: EstateTypeSelectDialogProps
) => {
  const [estateTypes, setEstateTypes] = useState<
    EstateTypeSelectDialogProps["estateTypes"]
  >(props.estateTypes);
  const [busy, setBusy] = useState(false);

  const onModeClick = useCallback((estateType: EstateTypeNameEnum) => {
    setEstateTypes((prev) => {
      if (prev.includes(estateType)) {
        return prev.filter((v) => v !== estateType).slice();
      } else {
        return [...prev, estateType];
      }
    });
  }, []);

  const onOkClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // OKボタンクリック時
      setBusy(true);
    },
    []
  );

  useEffect(() => {
    setEstateTypes(props.estateTypes);
  }, [props.estateTypes]);

  useEffect(() => {
    if (busy) {
      props.onOkClick(estateTypes);
      setBusy(false);
    }
  }, [busy, estateTypes, props]);

  return { estateTypes, busy, onModeClick, onOkClick } as const;
};
