import { Wrapper } from "@googlemaps/react-wrapper";
import { Box, Fade } from "@mui/material";
import { useHooks } from "./hooks";
import { SumoraMapProps } from "./interfaces";
import style from "./SumoraMap.module.scss";

const InnerMap = (props: SumoraMapProps) => {
  const { sx } = props;
  const { mapVisible, mapRef } = useHooks(props);

  return (
    <Box className={style.innerMap}>
      <Fade in={mapVisible} className={style.fade}>
        <Box component="div" ref={mapRef} id="sumora-map" sx={{ ...sx }}></Box>
      </Fade>
    </Box>
  );
};

export const SumoraMap = (props: SumoraMapProps) => {
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ""}
      libraries={["places", "marker"]}
    >
      <InnerMap {...props} />
    </Wrapper>
  );
};
