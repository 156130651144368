import { Handshake } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid
} from "@mui/material";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { ProgressBackdrop } from "../../Molecules/ProgressBackdrop";
import styles from "./AgreementDialog.module.scss";
import { useAgreementDialogHooks } from "./hooks";
import { AgreementDialogProps } from "./interfaces";

const idPrefix = "agreement-dialog";

export const AgreementDialog = React.memo((props: AgreementDialogProps) => {
  const {
    doc,
    agree,
    errorMessage,
    busy,

    onAgreeChange,
    onOkButtonClick,
    onLogoutButtonClick,

    loadDoc,
    nodeRef
  } = useAgreementDialogHooks(props);

  if (props.open) {
    loadDoc();

    return (
      <Dialog
        open={props.open}
        aria-labelledby={`${idPrefix}-title`}
        aria-describedby={`${idPrefix}-description`}
        maxWidth={false}
        disableEscapeKeyDown
        ref={nodeRef}
        className={styles.agreementDialog}
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{ backdrop: { className: styles.backdrop } }}
      >
        <Collapse in={props.errorMessage !== "" || errorMessage !== ""}>
          <Alert severity="error" className={styles.alert}>
            <AlertTitle>エラーが発生しました！</AlertTitle>
            {props.errorMessage}
            {errorMessage}
          </Alert>
        </Collapse>
        <DialogHeader id={`${idPrefix}-title`} icon={<Handshake />} />
        <DialogContent
          id={`${idPrefix}-description`}
          className={styles.content}
        >
          <Grid container rowSpacing={2} className={styles.container}>
            <Grid xs={12} item>
              <style>
                {`
                  .mark-down h3 {
                    text-align: center;
                  }
                `}
              </style>
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                components={{ h1: "h3" }}
                className="mark-down"
              >
                {doc}
              </ReactMarkdown>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Box className={styles.checkbox}>
            <FormControlLabel
              control={<Checkbox checked={agree} onChange={onAgreeChange} />}
              label="上記に同意しました"
            />
          </Box>
        </DialogActions>
        <DialogActions className={styles.actions}>
          <Button
            className={`${styles.button} ${styles.logout}`}
            fullWidth
            variant="contained"
            disabled={busy}
            onClick={onLogoutButtonClick}
          >
            ログアウト
          </Button>
          <Button
            className={`${styles.button} ${styles.ok}`}
            fullWidth
            variant="contained"
            disabled={busy || !agree}
            onClick={onOkButtonClick}
          >
            使用を開始する
          </Button>
        </DialogActions>
        <ProgressBackdrop open={busy || props.busy || false} />
      </Dialog>
    );
  } else {
    return null;
  }
});
