import { Search } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import {
  ChangeEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useState
} from "react";
import styles from "../SearchMenu.module.scss";

export const PlaceInput = memo(
  (props: {
    map: google.maps.Map | null | undefined;
    placeholder?: string;
    disabled?: boolean;
    open?: boolean;
    onPlacesChange: (places: google.maps.places.PlaceResult) => void;
  }) => {
    const id = "place-text-field";

    const [value, setValue] = useState("");
    const [composing, setComposing] = useState(false);

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    }, []);

    const onClick = useCallback(() => {
      if (props.map && google.maps && google.maps.places) {
        const service = new google.maps.places.PlacesService(props.map);

        service.findPlaceFromQuery(
          {
            query: value,
            language: "ja",
            fields: ["geometry", "name"]
          },
          (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              if (results && results.length > 0) {
                props.onPlacesChange(results[0]);
              }
            } else {
              props.onPlacesChange({});
            }
          }
        );
      }
    }, [props, value]);

    const onCompositionStart = useCallback(() => {
      setComposing(true);
    }, []);

    const onCompositionEnd = useCallback(() => {
      setComposing(false);
    }, []);

    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (composing) {
          return;
        }

        if (event.key === "Enter") {
          onClick();
        }
      },
      [composing, onClick]
    );

    useEffect(() => {
      props.onPlacesChange({});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    return props.open ? (
      <TextField
        id={id}
        type="text"
        variant="standard"
        placeholder={props.placeholder}
        fullWidth
        onChange={onChange}
        onCompositionStart={onCompositionStart}
        onCompositionEnd={onCompositionEnd}
        onKeyDown={onKeyDown}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton className={styles.goButton} onClick={onClick}>
              <Search />
            </IconButton>
          )
        }}
        disabled={props.disabled}
      />
    ) : null;
  }
);
