import { Box } from "@mui/material";
import btn_mapmenu_close from "assets/icons/map_menu/btn_mapmenu_close.svg";
import btn_mapmenu_open from "assets/icons/map_menu/btn_mapmenu_open.svg";
import { memo, useCallback, useEffect, useState } from "react";
import styles from "../SearchMenu.module.scss";

export const OpenCloseButton = memo(
  (props: {
    status: boolean;
    onStatusChange: (status: boolean) => void;
    onClick?: () => void;
  }) => {
    const [switchClassName, setSwitchClassName] = useState<
      typeof styles.on | typeof styles.off
    >(styles.off);

    const onClick = useCallback(() => {
      if (props.onStatusChange) {
        props.onStatusChange(!props.status);
      }

      if (props.onClick) {
        props.onClick();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onStatusChange, props.onClick, props.status]);

    useEffect(() => {
      setSwitchClassName(props.status ? styles.on : styles.off);
    }, [props.status]);

    return (
      <Box
        component="div"
        className={`${styles.btn_mapmenu} ${switchClassName}`}
        onClick={onClick}
      >
        <Box
          component="img"
          className={styles.open}
          src={btn_mapmenu_open}
          alt="メニューを開く"
        />
        <Box
          component="img"
          className={styles.close}
          src={btn_mapmenu_close}
          alt="メニューを閉じる"
        />
      </Box>
    );
  }
);
