import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  CreatePublicEstateFMutation,
  CreatePublicEstateFMutationVariables,
  GetPublicEstateQuery,
  GetPublicEstateQueryVariables
} from "API";
import { generateClient } from "aws-amplify/api";
import { createPublicEstateF as createPublicEstateFMutation } from "graphql/mutations";
import { getPublicEstate as getPublicEstateQuery } from "graphql/queries";
import { getSumoraAuthToken } from "../credentials";

// 一時公開物件取得
export const getPublicEstate = async (id: string, forAnonymous: boolean) => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(getPublicEstateQuery, {
      id: id
    } as GetPublicEstateQueryVariables),
    authMode: forAnonymous ? "iam" : "lambda",
    authToken: forAnonymous ? undefined : await getSumoraAuthToken()
  })) as GraphQLResult<GetPublicEstateQuery>;

  return res.data?.getPublicEstate;
};

// 一時公開物件作成
export const createPublicEstate = async (
  estateId: string,
  companyId: string
) => {
  const client = generateClient();
  const res = (await client.graphql({
    ...graphqlOperation(createPublicEstateFMutation, {
      input: {
        estateId,
        companyId
      }
    } as CreatePublicEstateFMutationVariables),
    authMode: "lambda",
    authToken: await getSumoraAuthToken()
  })) as GraphQLResult<CreatePublicEstateFMutation>;

  return res.data?.createPublicEstateF;
};
