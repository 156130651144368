import { getStatuses } from "common/functions/jsonMasters/statuses";
import { handleError } from "common/functions/utilities";
import { Status } from "common/interfaces/Status";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useStatusHooks = (
  includeNegotiating: boolean,
  includeDraft: boolean,
  includeCancel: boolean,
  includeMaster: boolean,
  setFatal: ((error?: string | null | undefined) => void) | undefined
) => {
  const [statuses, setStatuses] = useState<Status[]>([]);

  const { data, error } = useSWR(
    `statuses of ${includeNegotiating}, ${includeDraft}, ${includeCancel}, ${includeMaster}`,
    () =>
      getStatuses(
        includeNegotiating,
        includeDraft,
        includeCancel,
        includeMaster
      )
  );

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setStatuses(data);
    }
  }, [data]);

  return { statuses, setStatuses } as const;
};
