import {
  AutocompleteRenderInputParams,
  InputProps,
  TextField
} from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";

export const AutocompleteRenderInputTextBox = React.memo(
  (props: {
    params: AutocompleteRenderInputParams;
    label?: string;
    helperText?: string;
    errorMessage?: string;
    additionalErrorMessage?: string;
    referenceText?: string | null;
    required?: boolean;
    InputProps?: Partial<InputProps>;
  }) => (
    <TextField
      {...props.params}
      required={props.required}
      InputProps={{ ...props.params.InputProps, ...props.InputProps }}
      label={props.label}
      error={!isEmpty(props.errorMessage || props.additionalErrorMessage)}
      helperText={
        props.errorMessage || props.additionalErrorMessage || props.helperText
      }
    />
  )
);
