import { StickyNote } from "API";
import { useState } from "react";
import { DialogNames } from "../../types";

export const useSearchDialogStates = () => {
  // エリア切り替えダイアログ
  const [openSwitchAreaDialog, setOpenSwitchAreaDialog] = useState(false);

  // フィルターダイアログ
  const [showEstateFilterDialog, setShowEstateFilterDialog] = useState(false);
  const [estateFilterDialogZIndex, setEstateFilterDialogZIndex] = useState(100);
  const [estateFilterDialogSign, setEstateFilterDialogSign] = useState(0);

  // 移動ダイアログ
  const [showSelectDrawingSchoolDialog, setShowSelectDrawingSchoolDialog] =
    useState(false);
  const [selectDrawingSchoolDialogZIndex, setSelectDrawingSchoolDialogZIndex] =
    useState(100);
  const [selectDrawingSchoolDialogSign, setSelectDrawingSchoolDialogSign] =
    useState(0);

  // ルート検索ダイアログ
  const [searchRouteDialogZIndex, setSearchRouteDialogZIndex] = useState(100);
  const [searchRouteDialogSign, setSearchRouteDialogSign] = useState(0);

  // ダイアログのレイヤー
  const [layers, setLayers] = useState<DialogNames[]>([]);

  // 物件位置入力ダイアログ
  const [estateLocationInputDialogMode, setEstateLocationInputDialogMode] =
    useState<"update" | "delete" | undefined>(undefined);
  const [
    estateLocationInputDialogEstateId,
    setEstateLocationInputDialogEstateId
  ] = useState<string | undefined>(undefined);

  // 報告入力ダイアログ
  const [reportInputDialogMode, setReportInputDialogMode] = useState<
    "create" | undefined
  >(undefined);
  const [reportInputDialogEstateId, setReportInputDialogEstateId] = useState<
    string | undefined
  >(undefined);

  // メモ入力ダイアログ
  const [stickyNoteInputDialogStickyNote, setStickyNoteInputDialogStickyNote] =
    useState<StickyNote | undefined>(undefined);
  const [stickyNoteInputDialogMode, setStickyNoteInputDialogMode] = useState<
    "create" | "update" | "delete" | undefined
  >(undefined);
  const [
    stickyNoteInputDialogInitialLatitude,
    setStickyNoteInputDialogInitialLatitude
  ] = useState<number | undefined>(undefined);
  const [
    stickyNoteInputDialogInitialLongitude,
    setStickyNoteInputDialogInitialLongitude
  ] = useState<number | undefined>(undefined);

  return {
    openSwitchAreaDialog,
    setOpenSwitchAreaDialog,

    showEstateFilterDialog,
    setShowEstateFilterDialog,

    estateFilterDialogZIndex,
    setEstateFilterDialogZIndex,
    estateFilterDialogSign,
    setEstateFilterDialogSign,

    showSelectDrawingSchoolDialog,
    setShowSelectDrawingSchoolDialog,
    selectDrawingSchoolDialogZIndex,
    setSelectDrawingSchoolDialogZIndex,
    selectDrawingSchoolDialogSign,
    setSelectDrawingSchoolDialogSign,

    searchRouteDialogZIndex,
    setSearchRouteDialogZIndex,
    searchRouteDialogSign,
    setSearchRouteDialogSign,

    layers,
    setLayers,

    estateLocationInputDialogMode,
    setEstateLocationInputDialogMode,
    estateLocationInputDialogEstateId,
    setEstateLocationInputDialogEstateId,

    reportInputDialogMode,
    setReportInputDialogMode,
    reportInputDialogEstateId,
    setReportInputDialogEstateId,

    stickyNoteInputDialogStickyNote,
    setStickyNoteInputDialogStickyNote,
    stickyNoteInputDialogMode,
    setStickyNoteInputDialogMode,
    stickyNoteInputDialogInitialLatitude,
    setStickyNoteInputDialogInitialLatitude,
    stickyNoteInputDialogInitialLongitude,
    setStickyNoteInputDialogInitialLongitude
  } as const;
};

export type SearchDialogStatesType = ReturnType<typeof useSearchDialogStates>;
