import { clearMarker } from "common/functions/mapUtilities";
import { useCallback, useEffect, useRef, useState } from "react";
import { LocationMapProps } from "./interfaces";

export const useLocationMapHooks = (props: LocationMapProps) => {
  const initRef = useRef(true);
  const placeMarker = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );

  const [map, setMap] = useState<google.maps.Map | null>(null);

  // APIロード完了時
  const onGoogleApiLoaded = useCallback(
    (maps: {
      map: google.maps.Map | null | undefined;
      maps: any;
      ref: Element | null;
    }) => {
      if (maps.map) {
        setMap(maps.map);

        props.onMapReady(maps.map);

        const panorama = maps.map.getStreetView();
        panorama.setOptions({ addressControl: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Map破棄
  const onDestruct = useCallback(() => {
    clearMarker(placeMarker.current);

    if (map) {
      google.maps.event.clearInstanceListeners(map);
    }
  }, [map]);

  // 位置変化時
  const onLocationChange = useCallback(
    (location: google.maps.LatLngLiteral | null) => {
      clearMarker(placeMarker.current);

      if (map) {
        if (location) {
          const lat = location?.lat;
          const lng = location?.lng;

          if (map && lat !== undefined && lng !== undefined) {
            const pos = {
              lat: lat,
              lng: lng
            };

            map.setCenter(pos);

            placeMarker.current = new google.maps.marker.AdvancedMarkerElement({
              position: pos,
              gmpDraggable: props.draggable
            });

            placeMarker.current.addListener(
              "dragend",
              (event: { latLng: google.maps.LatLng; domEvent: MouseEvent }) => {
                const position = new google.maps.LatLng({
                  lat: event.latLng.lat(),
                  lng: event.latLng.lng()
                });

                props.onLocationChange(position);

                return false;
              }
            );

            placeMarker.current.map = map;
          }
        }
      }
    },
    [map, props]
  );

  // 場所検索結果変化時
  const onPlacesChange = useCallback(
    (places?: google.maps.places.PlaceResult) => {
      clearMarker(placeMarker.current);

      if (places) {
        const lat = places.geometry?.location?.lat();
        const lng = places.geometry?.location?.lng();

        if (lat !== undefined && lng !== undefined) {
          onLocationChange({
            lat: lat,
            lng: lng
          });
        }
      }
    },
    [onLocationChange]
  );

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }

    return onDestruct;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onPlacesChange(props.places);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.places, map]);

  useEffect(() => {
    onLocationChange(props.location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location, map]);

  return { onGoogleApiLoaded } as const;
};
