import { StickyNote, StickyNoteColor, StickyNoteIcon } from "API";
import ToolEdit from "assets/icons/estate_info/tool_edit.svg";
import ToolTrash from "assets/icons/estate_info/tool_trash.svg";
import BlueCart from "assets/icons/sticky_note/ic_memo_bl_cart.svg";
import BlueCaution from "assets/icons/sticky_note/ic_memo_bl_caution.svg";
import BlueCone from "assets/icons/sticky_note/ic_memo_bl_cone.svg";
import BlueKey from "assets/icons/sticky_note/ic_memo_bl_key.svg";
import BlueNormal from "assets/icons/sticky_note/ic_memo_bl_normal.svg";
import BlueParking from "assets/icons/sticky_note/ic_memo_bl_parking.svg";
import BluePerson from "assets/icons/sticky_note/ic_memo_bl_person.svg";
import BlueSale from "assets/icons/sticky_note/ic_memo_bl_sale.svg";
import BlueSecret from "assets/icons/sticky_note/ic_memo_bl_secret.svg";
import RedCart from "assets/icons/sticky_note/ic_memo_re_cart.svg";
import RedCaution from "assets/icons/sticky_note/ic_memo_re_caution.svg";
import RedCone from "assets/icons/sticky_note/ic_memo_re_cone.svg";
import RedKey from "assets/icons/sticky_note/ic_memo_re_key.svg";
import RedNormal from "assets/icons/sticky_note/ic_memo_re_normal.svg";
import RedParking from "assets/icons/sticky_note/ic_memo_re_parking.svg";
import RedPerson from "assets/icons/sticky_note/ic_memo_re_person.svg";
import RedSale from "assets/icons/sticky_note/ic_memo_re_sale.svg";
import RedSecret from "assets/icons/sticky_note/ic_memo_re_secret.svg";
import WhiteCart from "assets/icons/sticky_note/ic_memo_wh_cart.svg";
import WhiteCaution from "assets/icons/sticky_note/ic_memo_wh_caution.svg";
import WhiteCone from "assets/icons/sticky_note/ic_memo_wh_cone.svg";
import WhiteKey from "assets/icons/sticky_note/ic_memo_wh_key.svg";
import WhiteNormal from "assets/icons/sticky_note/ic_memo_wh_normal.svg";
import WhiteParking from "assets/icons/sticky_note/ic_memo_wh_parking.svg";
import WhitePerson from "assets/icons/sticky_note/ic_memo_wh_person.svg";
import WhiteSale from "assets/icons/sticky_note/ic_memo_wh_sale.svg";
import WhiteSecret from "assets/icons/sticky_note/ic_memo_wh_secret.svg";
import YellowCart from "assets/icons/sticky_note/ic_memo_ye_cart.svg";
import YellowCaution from "assets/icons/sticky_note/ic_memo_ye_caution.svg";
import YellowCone from "assets/icons/sticky_note/ic_memo_ye_cone.svg";
import YellowKey from "assets/icons/sticky_note/ic_memo_ye_key.svg";
import YellowNormal from "assets/icons/sticky_note/ic_memo_ye_normal.svg";
import YellowParking from "assets/icons/sticky_note/ic_memo_ye_parking.svg";
import YellowPerson from "assets/icons/sticky_note/ic_memo_ye_person.svg";
import YellowSale from "assets/icons/sticky_note/ic_memo_ye_sale.svg";
import YellowSecret from "assets/icons/sticky_note/ic_memo_ye_secret.svg";
import { OpenLevelNameEnum } from "common/enums/OpenLevelEnum";
import { useCallback, useMemo } from "react";

export const useStickyNoteMethods = () => {
  const zIndex = 10000;

  const memoIcon: {
    [color: string]: { [icon: string]: string };
  } = useMemo(() => {
    return {
      [StickyNoteColor.white]: {
        normal: WhiteNormal,
        sale: WhiteSale,
        person: WhitePerson,
        parking: WhiteParking,
        cart: WhiteCart,
        key: WhiteKey,
        caution: WhiteCaution,
        cone: WhiteCone,
        secret: WhiteSecret
      },
      [StickyNoteColor.blue]: {
        normal: BlueNormal,
        sale: BlueSale,
        person: BluePerson,
        parking: BlueParking,
        cart: BlueCart,
        key: BlueKey,
        caution: BlueCaution,
        cone: BlueCone,
        secret: BlueSecret
      },
      [StickyNoteColor.red]: {
        normal: RedNormal,
        sale: RedSale,
        person: RedPerson,
        parking: RedParking,
        cart: RedCart,
        key: RedKey,
        caution: RedCaution,
        cone: RedCone,
        secret: RedSecret
      },
      [StickyNoteColor.yellow]: {
        normal: YellowNormal,
        sale: YellowSale,
        person: YellowPerson,
        parking: YellowParking,
        cart: YellowCart,
        key: YellowKey,
        caution: YellowCaution,
        cone: YellowCone,
        secret: YellowSecret
      }
    };
  }, []);

  // メモマーカー構築
  const constructStickyNoteMarker = useCallback(
    (stickyNote: StickyNote | null | undefined) => {
      const content = document.createElement("div");

      content.innerHTML = `
      <div class="sticky-note-marker">
        <img src="${
          memoIcon[stickyNote?.color ?? StickyNoteColor.white][
            stickyNote?.icon ?? StickyNoteIcon.normal
          ]
        }" />
      </div>
      `;

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: stickyNote?.latitude || 0,
          lng: stickyNote?.longitude || 0
        },
        content: content
      });

      return marker;
    },
    [memoIcon]
  );

  const constructStickyNoteInfoWindow = useCallback(
    (
      username: string | null,
      companyId: string | null,
      stickyNote: StickyNote | null | undefined,
      onEditClick: ((stickyNote: StickyNote) => void) | undefined,
      onDeleteClick: ((stickyNote: StickyNote) => void) | undefined
    ) => {
      const editable =
        (stickyNote?.openLevel === OpenLevelNameEnum.プライベート &&
          stickyNote.owner === username) ||
        ((stickyNote?.openLevel === OpenLevelNameEnum.社内 ||
          stickyNote?.openLevel === OpenLevelNameEnum.公開) &&
          (stickyNote.companyId.split("-").at(0) || "x") ===
            (companyId?.split("-").at(0) || "y")) ||
        (stickyNote?.openLevel === OpenLevelNameEnum.支店内 &&
          (stickyNote.companyId || "x") === (companyId || "y"));

      const editButtonId = `sticky-note-info-window-edit-${
        stickyNote?.id || ""
      }`;
      const deleteButtonId = `sticky-note-info-window-delete-${
        stickyNote?.id || ""
      }`;

      const updatedAt = stickyNote?.updatedAt
        ? new Date(stickyNote.updatedAt)
            .toISOString()
            .slice(0, 10)
            .replaceAll("-", ".")
            .split(".")
            .map((x) => x.replace(/^0/g, ""))
            .join(".")
        : undefined;

      const editButton = onEditClick
        ? `
          <button
            id="${editButtonId}"
            class="sticky-note"
          >
            <img alt="編集" src="${ToolEdit}" />
          </button>
        `
        : "";

      const deleteButton = onDeleteClick
        ? `
          <button
            id="${deleteButtonId}"
            class="sticky-note"
          >
            <img alt="削除" src="${ToolTrash}" />
          </button>
        `
        : "";

      // url regex pattern
      const urlRegex = /(https?:\/\/[\w!?/+\-_~;.,*&@#$%()='[\]]+)/g;

      const content = `
        <div
          class="sticky-note-info-window"
        >
          ${
            stickyNote?.description
              ?.split("\n")
              .map(
                (x) =>
                  `<div>${x.replace(
                    urlRegex,
                    `<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>`
                  )}</div>`
              )
              .join("") || ""
          }
        </div>
        <div class="footer">
          <div
            class="updated-at"
          >
            ${updatedAt}
          </div>
          <div
            class="buttons"
          >
            ${editable ? editButton : ""}
            ${editable ? deleteButton : ""}
          </div>
        </div>
      `;

      const infoWindow = new google.maps.InfoWindow({
        content: content,
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + zIndex,
        disableAutoPan: true
      });

      infoWindow.addListener("domready", () => {
        const editButtonDOM = document.getElementById(editButtonId);

        if (editButtonDOM) {
          editButtonDOM.addEventListener("click", () => {
            if (stickyNote && onEditClick) {
              onEditClick(stickyNote);
            }
          });
        }

        const deleteButtonDOM = document.getElementById(deleteButtonId);

        if (deleteButtonDOM) {
          deleteButtonDOM.addEventListener("click", () => {
            if (stickyNote && onDeleteClick) {
              onDeleteClick(stickyNote);
            }
          });
        }
      });

      return infoWindow;
    },
    []
  );

  // メモリストへのフィッティング
  const fitBoundFromStickyNotes = useCallback(
    (map: google.maps.Map, stickyNotes: (StickyNote | null)[]) => {
      let bounds = null as google.maps.LatLngBounds | null;

      for (const stickyNote of stickyNotes.filter(
        (stickyNote) => stickyNote?.latitude && stickyNote?.longitude
      )) {
        if (bounds) {
          bounds.extend({
            lat: stickyNote?.latitude || 0,
            lng: stickyNote?.longitude || 0
          });
        } else {
          bounds = new google.maps.LatLngBounds(
            {
              lat: stickyNote?.latitude || 0,
              lng: stickyNote?.longitude || 0
            },
            {
              lat: stickyNote?.latitude || 0,
              lng: stickyNote?.longitude || 0
            }
          );
        }
      }

      if (bounds) {
        map.fitBounds(bounds);
      }
    },
    []
  );

  return {
    constructStickyNoteMarker,
    constructStickyNoteInfoWindow,
    fitBoundFromStickyNotes
  } as const;
};
