import {
  completionYMMax,
  completionYMMin,
  dateMax,
  dateMin,
  estatePriceMax,
  estatePriceMin,
  roomsMax,
  roomsMin,
  tsuboAreaMax,
  tsuboAreaMin
} from "common/consts/estates";
import { BldConditionSelectionEnum } from "common/enums/BldConditionSelectionEnum";
import { BldConditionTypeEnum } from "common/enums/BldConditionTypeEnum";
import { BuildingFloorsEnum } from "common/enums/BuildingFloorsEnum";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { PetEnum } from "common/enums/PetEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { squareToTsubo, toHalfWidth } from "common/functions/utilities";
import { EstateFilterConditions } from "common/interfaces/EstateFilterConditions";
import { MainContext } from "common/interfaces/MainContext";
import { MinimalEstate } from "common/queries/minimalEstates";
import { format } from "date-fns";
import { isEmpty } from "lodash";

// 条件によるフィルタ
export const filterEstate = (
  estate: MinimalEstate,
  filterConditions: EstateFilterConditions,
  myCompanyId: string | null | undefined
) => {
  const rooms = Number(
    toHalfWidth(
      estate.layout?.match(/ワンルーム/g)
        ? "1"
        : estate.layout?.match(/^[0-9０-９]+/g)?.[0] || "X"
    )
  );

  const estateType =
    estate.estateType &&
    filterConditions.estateTypes.map((x) => x.name).includes(estate.estateType);

  const bldConditionType = filterConditions.bldConditionSelections.some((x) => {
    const [companyRecNo] = myCompanyId?.split("-") || ["X", "X"];

    if (x.name === BldConditionSelectionEnum.あり他社) {
      return (
        !(estate.companyId?.startsWith(`${companyRecNo}-`) || false) &&
        estate.bldConditionType === BldConditionTypeEnum.建築条件あり
      );
    } else if (x.name === BldConditionSelectionEnum.あり自社) {
      return (
        (estate.companyId?.startsWith(`${companyRecNo}-`) || false) &&
        estate.bldConditionType === BldConditionTypeEnum.建築条件あり
      );
    } else {
      return (
        isEmpty(estate.bldConditionType) ||
        estate.bldConditionType === BldConditionTypeEnum.建築条件なし
      );
    }
  });

  const purposeSummaryB =
    (estate.purposeSummaryB ?? "") === "" ||
    (estate.purposeSummaryB &&
      filterConditions.purposeSummaryBs
        .map((x) => x.name)
        .includes(estate.purposeSummaryB));

  const companyRecNo =
    filterConditions.companyRecNo === 0 ||
    estate.companyId?.startsWith(`${filterConditions.companyRecNo}-`) ||
    estate.subCompanyId?.startsWith(`${filterConditions.companyRecNo}-`) ||
    estate.subCompany2Id?.startsWith(`${filterConditions.companyRecNo}-`);

  const tsuboAreaFrom =
    filterConditions.tsuboAreaFrom === tsuboAreaMin ||
    (estate.tsuboArea && estate.tsuboArea >= filterConditions.tsuboAreaFrom) ||
    (estate.squareArea &&
      squareToTsubo(estate.squareArea) >= filterConditions.tsuboAreaFrom);

  const tsuboAreaTo =
    filterConditions.tsuboAreaTo === tsuboAreaMax ||
    (estate.tsuboArea && estate.tsuboArea <= filterConditions.tsuboAreaTo) ||
    (estate.squareArea &&
      squareToTsubo(estate.squareArea) <= filterConditions.tsuboAreaTo);

  const estatePriceFrom =
    filterConditions.estatePriceFrom === estatePriceMin ||
    (estate.estatePrice &&
      estate.estatePrice >= filterConditions.estatePriceFrom);

  const estatePriceTo =
    filterConditions.estatePriceTo === estatePriceMax ||
    (estate.estatePrice &&
      estate.estatePrice <= filterConditions.estatePriceTo);

  const createdAtFrom =
    filterConditions.createdAtFrom === dateMin ||
    (estate.createdAt &&
      new Date(estate.createdAt).getTime() >= filterConditions.createdAtFrom);

  const createdAtTo =
    filterConditions.createdAtTo === dateMax ||
    (estate.createdAt &&
      new Date(estate.createdAt).getTime() <= filterConditions.createdAtTo);

  const pricedAtFrom =
    filterConditions.pricedAtFrom === dateMin ||
    ((estate.priceHistory ?? []).length > 1 &&
      Math.max(
        ...(estate.priceHistory ?? []).map((x) =>
          x?.pricedAt ? new Date(x.pricedAt).getTime() : 0
        )
      ) >= filterConditions.pricedAtFrom);

  const pricedAtTo =
    filterConditions.pricedAtTo === dateMax ||
    ((estate.priceHistory ?? []).length > 1 &&
      Math.max(
        ...(estate.priceHistory ?? []).map((x) =>
          x?.pricedAt ? new Date(x.pricedAt).getTime() : 0
        )
      ) <= filterConditions.pricedAtTo);

  const bothCreatedAtAndPricedAt =
    (filterConditions.createdAtFrom !== dateMin ||
      filterConditions.createdAtTo !== dateMax) &&
    (filterConditions.pricedAtFrom !== dateMin ||
      filterConditions.pricedAtTo !== dateMax);

  const alsoCreatedAtFrom =
    filterConditions.alsoCreatedAtFrom === dateMin ||
    (estate.createdAt &&
      new Date(estate.createdAt).getTime() >=
        filterConditions.alsoCreatedAtFrom);

  const alsoCreatedAtTo =
    filterConditions.alsoCreatedAtTo === dateMax ||
    (estate.createdAt &&
      new Date(estate.createdAt).getTime() <= filterConditions.alsoCreatedAtTo);

  const completionYMFrom =
    filterConditions.completionYMFrom ===
      Number(format(completionYMMin, "yyyyMM")) ||
    (estate.completionMonth &&
      estate.completionMonth >= (filterConditions.completionYMFrom || 0));

  const completionYMTo =
    filterConditions.completionYMTo ===
      Number(format(completionYMMax, "yyyyMM")) ||
    (estate.completionMonth &&
      estate.completionMonth <= (filterConditions.completionYMTo || 999999));

  const completionSchYearFrom =
    filterConditions.completionYMFrom ===
      Number(format(completionYMMin, "yyyyMM")) ||
    (estate.completionSchMonth &&
      estate.completionSchMonth >= (filterConditions.completionYMFrom || 0));

  const completionSchYearTo =
    filterConditions.completionYMTo ===
      Number(format(completionYMMax, "yyyyMM")) ||
    (estate.completionSchMonth &&
      estate.completionSchMonth <= (filterConditions.completionYMTo || 999999));

  const roomsFrom =
    filterConditions.roomsFrom === roomsMin ||
    (!isNaN(rooms) && rooms >= filterConditions.roomsFrom);

  const roomsTo =
    filterConditions.roomsTo === roomsMax ||
    (!isNaN(rooms) && rooms <= filterConditions.roomsTo);

  const petOk =
    filterConditions.petOk === false ||
    estate.estateType !== EstateTypeNameEnum.マンション ||
    estate.pet === PetEnum.OK;

  const flat =
    filterConditions.flat === false ||
    [
      EstateTypeNameEnum.新築建売,
      EstateTypeNameEnum.中古戸建,
      EstateTypeNameEnum.事業用
    ].includes(estate.estateType as EstateTypeNameEnum) === false ||
    estate.buildingFloors === BuildingFloorsEnum.平屋;

  const isGround = [
    EstateTypeNameEnum.土地,
    EstateTypeNameEnum.分譲地
  ].includes(estate.estateType as EstateTypeNameEnum);

  const isNewHouse =
    (filterConditions.completionYMFrom ===
      Number(format(completionYMMax, "yyyyMM")) ||
      filterConditions.completionYMTo ===
        Number(format(completionYMMax, "yyyyMM"))) &&
    estate.estateType === EstateTypeNameEnum.新築建売;

  return (
    estateType &&
    bldConditionType &&
    purposeSummaryB &&
    companyRecNo &&
    tsuboAreaFrom &&
    tsuboAreaTo &&
    estatePriceFrom &&
    estatePriceTo &&
    (bothCreatedAtAndPricedAt
      ? (createdAtFrom && createdAtTo) || (pricedAtFrom && pricedAtTo)
      : createdAtFrom && createdAtTo && pricedAtFrom && pricedAtTo) &&
    alsoCreatedAtFrom &&
    alsoCreatedAtTo &&
    (completionYMFrom || completionSchYearFrom || isGround || isNewHouse) &&
    (completionYMTo || completionSchYearTo || isGround || isNewHouse) &&
    (roomsFrom || isGround) &&
    (roomsTo || isGround) &&
    petOk &&
    flat
  );
};

// 同一マンションまとめ
export const condominiumBlocksOfEstates = (
  estates: MinimalEstate[],
  condominiumEstate: MinimalEstate
): { [key: string]: MinimalEstate } => {
  const blocks = estates
    .map((x) => x)
    .filter(
      (child) => child?.condominiumId === condominiumEstate.condominiumId
    );

  return {
    [condominiumEstate.condominiumId || ""]: {
      ...condominiumEstate,
      id: condominiumEstate.condominiumId,
      status: blocks.map((child) => child.status).includes(StatusNameEnum.公開)
        ? StatusNameEnum.公開
        : condominiumEstate.status,
      blocks: blocks
    }
  };
};

// 同一位置のグループ化
const groupingPositionsOfEstates = (
  estates: MinimalEstate[],
  latitude: number | null | undefined,
  longitude: number | null | undefined
) =>
  estates
    .map((x) => x)
    .filter(
      (child) => child?.latitude === latitude && child?.longitude === longitude
    );

export const constructEstateDict = (
  blocksEstateDict: { [id: string]: MinimalEstate },
  mainContext: MainContext,
  forSold: boolean
) => {
  // 位置が明確なもの
  const positionedBlockItemsOfEstates = (
    Object.values(blocksEstateDict) ?? []
  ).filter(
    (x) =>
      x?.latitude !== null &&
      x?.latitude !== undefined &&
      x?.longitude !== null &&
      x?.longitude !== undefined
  );

  // // 位置が不明確なもの
  // const unpositionedBlockItemsOfEstates = forSold
  //   ? []
  //   : Object.values(blocksEstateDict).filter(
  //       (x) =>
  //         x?.latitude === null ||
  //         x?.latitude === undefined ||
  //         x?.longitude === null ||
  //         x?.longitude === undefined
  //     );

  // // 位置が不明確なものを自社アイコンの周囲に並べるための距離・方角
  // const translation = { count: 0, direction: 0 };

  // // 位置が不明確なものを自社アイコンの周囲に並べるための距離・方角増分値
  // const translationAdditions = {
  //   distance: 5 * Math.ceil(unpositionedBlockItemsOfEstates.length / 10),
  //   direction:
  //     unpositionedBlockItemsOfEstates.length === 0
  //       ? 0
  //       : 360 / Math.ceil(unpositionedBlockItemsOfEstates.length / 10)
  // };

  // 物件リストの最終作成
  const blockItemsOfEstates = [
    ...positionedBlockItemsOfEstates
    // ...unpositionedBlockItemsOfEstates.map((x) => {
    //   // 位置不明のものは自社アイコンの周囲に並べる
    //   x.latitude = mainContext.company?.latitude;
    //   x.longitude = mainContext.company?.longitude;

    //   if (
    //     x?.latitude !== null &&
    //     x?.latitude !== undefined &&
    //     x?.longitude !== null &&
    //     x?.longitude !== undefined
    //   ) {
    //     const point = turf.point([x?.longitude || 0, x?.latitude || 0]);

    //     const movedPoint = turf.transformTranslate(
    //       point,
    //       translationAdditions.distance,
    //       translation.direction,
    //       {
    //         units: "meters"
    //       }
    //     );

    //     x.latitude = movedPoint.geometry.coordinates[1];
    //     x.longitude = movedPoint.geometry.coordinates[0];

    //     translation.count++;

    //     if (translation.count % 10 === 0) {
    //       translation.direction += translationAdditions.direction;
    //     }
    //   }

    //   return x;
    // })
  ];

  // 物件辞書の最終作成
  const estateDict = Object.assign(
    {},
    ...blockItemsOfEstates
      .map((x): { [key: string]: MinimalEstate } | null => {
        if (
          x?.latitude !== null &&
          x?.latitude !== undefined &&
          x?.longitude !== null &&
          x?.longitude !== undefined
        ) {
          // 同一位置のグループ化
          const blocks = groupingPositionsOfEstates(
            blockItemsOfEstates as MinimalEstate[],
            x.latitude,
            x.longitude
          );

          if (blocks.length > 1) {
            const estateType = blocks.every(
              (child) => child.estateType === EstateTypeNameEnum.土地
            )
              ? (EstateTypeNameEnum.土地 as string)
              : blocks.every(
                  (child) => child.estateType === EstateTypeNameEnum.分譲地
                )
              ? (EstateTypeNameEnum.分譲地 as string)
              : blocks.every(
                  (child) => child.estateType === EstateTypeNameEnum.新築建売
                )
              ? (EstateTypeNameEnum.新築建売 as string)
              : blocks.every(
                  (child) => child.estateType === EstateTypeNameEnum.中古戸建
                )
              ? (EstateTypeNameEnum.中古戸建 as string)
              : blocks.every(
                  (child) => child.estateType === EstateTypeNameEnum.マンション
                )
              ? (EstateTypeNameEnum.マンション as string)
              : blocks.every(
                  (child) => child.estateType === EstateTypeNameEnum.事業用
                )
              ? (EstateTypeNameEnum.事業用 as string)
              : "？";

            return {
              [`${x.latitude} ${x.longitude}`]: {
                ...x,

                name: x.name ? `${x.name} 他` : "",
                estateType: estateType,

                status: blocks
                  .map((child) => child.status)
                  .includes(StatusNameEnum.公開)
                  ? StatusNameEnum.公開
                  : x.status,

                blocks: blocks
              }
            };
          } else {
            return {
              [`${x.latitude} ${x.longitude}`]: x
            };
          }
        }

        return null;
      })
      .filter((x) => x)
  ) as { [position: string]: MinimalEstate };
  return estateDict;
};

// 緯度経度の誤差切り捨て
export const truncDegree = (degree: number | null | undefined) =>
  degree ? Math.trunc(degree * 50000) / 50000 : degree;
