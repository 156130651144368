import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box, Button, Grid } from "@mui/material";
import { ReactComponent as Logo } from "assets/icons/logo_yoko_4c.svg";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import React from "react";
import styles from "./LoginPage.module.scss";

export const LoginPage = React.memo(() => {
  return (
    <ContentMain>
      <Authenticator
        hideSignUp
        components={{
          Header: () => {
            return (
              <>
                <Box className={styles.headerBar}>
                  <Logo />
                </Box>
              </>
            );
          },
          Footer: () => {
            return (
              <Grid
                className={styles.footerBar}
                container
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    href="https://sumora.info/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    スモーラについて
                  </Button>
                </Grid>
                <Grid item>
                  <Button href="/about">注意事項</Button>
                </Grid>
                <Grid item>
                  <Button href="/license">ライセンス表記</Button>
                </Grid>
              </Grid>
            );
          }
        }}
        formFields={{
          forceNewPassword: {
            password: {
              label: "新しいパスワード",
              placeholder: "新しいパスワードを入力してください"
            }
          }
        }}
      />
    </ContentMain>
  );
});
