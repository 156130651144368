import { incrementWithCycle } from "common/functions/utilities";
import { EstateFilterConditions } from "common/interfaces/EstateFilterConditions";
import { useCallback } from "react";
import { removeLayer, unshiftLayer } from "../functions/layers";
import { SearchBehaviorStatesType } from "../states/behaviors";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchFilterConditionStatesType } from "../states/filterConditions";

export const useSearchEstateFilterDialogHandlers = (
  behaviorStates: SearchBehaviorStatesType,
  dialogStates: SearchDialogStatesType,
  filterConditionStates: SearchFilterConditionStatesType
) => {
  const onEstateFilterDialogClose = useCallback(() => {
    dialogStates.setShowEstateFilterDialog(false);
    dialogStates.setEstateFilterDialogSign(incrementWithCycle);
  }, [dialogStates]);

  const onEstateFilterDialogMouseDown = useCallback(() => {
    dialogStates.setEstateFilterDialogSign(incrementWithCycle);
  }, [dialogStates]);

  const onEstateFilterDialogSignChange = useCallback(
    (show: boolean) => {
      if (show) {
        dialogStates.setLayers((x) => unshiftLayer(x, "estateFilter"));
      } else {
        dialogStates.setLayers((x) => removeLayer(x, "estateFilter"));
      }
    },
    [dialogStates]
  );

  const onOkButtonClick = useCallback(
    (filterConditions: EstateFilterConditions) => {
      filterConditionStates.forceFilterConditions(filterConditions);
      behaviorStates.setOpenMenu(false);
    },
    [behaviorStates, filterConditionStates]
  );

  return {
    onEstateFilterDialogClose,
    onEstateFilterDialogMouseDown,
    onEstateFilterDialogSignChange,

    onOkButtonClick
  } as const;
};

export type SearchEstateFilterDialogHandlersType = ReturnType<
  typeof useSearchEstateFilterDialogHandlers
>;
