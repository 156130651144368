import { EstateLocationInputDialog } from "components/Parts/Organisms/EstateLocationInputDialog";
import { EstateLocationInputMode } from "components/Parts/Organisms/EstateLocationInputDialog/enums";
import { isEmpty } from "lodash";
import React from "react";
import { LocationInputDialogProps } from "./interface";

export const LocationInputDialog = React.memo(
  (props: LocationInputDialogProps) => {
    return (
      <EstateLocationInputDialog
        open={!isEmpty(props.mode)}
        okButtonCaption={
          props.mode === "update"
            ? "更新"
            : props.mode === "delete"
            ? "削除"
            : "-"
        }
        busy={false}
        mode={
          props.mode === "update"
            ? EstateLocationInputMode.Update
            : props.mode === "delete"
            ? EstateLocationInputMode.Delete
            : null
        }
        estateId={props.estate?.id || null}
        errorMessage=""
        onClose={props.onClose}
        onOkButtonClick={props.onOkButtonClick}
      />
    );
  }
);
