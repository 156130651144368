import { Box } from "@mui/material";
import { Estate } from "API";
import { StatusNameEnum } from "common/enums/StatusEnum";
import React from "react";
import QRCode from "react-qr-code";
import styles from "./DetailPage.subComponents.module.scss";

const mapSize = "640x640";
const zoom = 17;

export const ContentMap = React.memo(
  (props: { estate?: Estate; isLoading: boolean }) => (
    <Box className={styles.mapContent}>
      {props.estate?.status !== StatusNameEnum.下書き &&
      props.estate?.latitude &&
      props.estate?.longitude ? (
        <>
          <Box className={styles.mapWrapper}>
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${props.estate.latitude}%2c%20${props.estate.longitude}&markers=color:red%7C${props.estate.latitude}%2c%20${props.estate.longitude}&size=${mapSize}&zoom=${zoom}&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY}`}
              alt="MAP"
            />
          </Box>
          <QRCode
            className={styles.qrCode}
            value={`https://www.google.com/maps?q=${props.estate.latitude},${props.estate.longitude}`}
            level="L"
            size={64}
          />
        </>
      ) : props.isLoading ? (
        <Box className={styles.mapWrapper}>マップを取得中...</Box>
      ) : (
        <Box className={`${styles.mapWrapper} ${styles.noMap}`}>
          マップを表示できません
        </Box>
      )}
    </Box>
  )
);
