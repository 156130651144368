export enum InfoNameEnum {
  master = "master",
  name = "name",
  licenserAndLicenseNo = "licenserAndLicenseNo",
  recNo = "recNo",

  companyId = "companyId",
  nameRuby = "nameRuby",

  received = "received",
  condominium = "condominium",
  deleted = "deleted",
  condominiumId = "condominiumId",
  statusAndPrefectureAndCityAndPricedAt = "statusAndPrefectureAndCityAndPricedAt",
  statusAndTileOnZoom12 = "statusAndTileOnZoom12",
  statusAndTileOnZoom14 = "statusAndTileOnZoom14",
  statusAndTileOnZoom16 = "statusAndTileOnZoom16",
  statusAndCompanyId = "statusAndCompanyId",

  group = "group",
  email = "email"
}
