import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { MinimalEstate } from "common/queries/minimalEstates";

// 物件種別ごとのソート順
export const estateTypeNumber = (estate: MinimalEstate) =>
  estate.estateType === EstateTypeNameEnum.土地
    ? 1
    : estate.estateType === EstateTypeNameEnum.分譲地
    ? 2
    : estate.estateType === EstateTypeNameEnum.新築建売
    ? 3
    : estate.estateType === EstateTypeNameEnum.中古戸建
    ? 4
    : estate.estateType === EstateTypeNameEnum.マンション
    ? 5
    : estate.estateType === EstateTypeNameEnum.事業用
    ? 6
    : 7;

// 物件ステータスごとのソート順
export const statusNumber = (estate: MinimalEstate) =>
  estate.status === StatusNameEnum.公開
    ? 1
    : estate.status === StatusNameEnum.成約済み
    ? 2
    : 3;
