import axios from "axios";
import { MainContextContainer } from "components/Main";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { LicenseProps, Licenses } from "./interfaces";

export const useLicenseHooks = (props: LicenseProps) => {
  const initRef = useRef(true);

  const mainContext = useContext(MainContextContainer);

  const [licenses, setLicenses] = useState<Licenses>({});

  const onLoaded: () => void = useCallback(async () => {
    const response = await axios
      .get<Licenses>(`${process.env.PUBLIC_URL}/licenses.json`)
      .catch((err) => undefined);

    if (response) {
      setLicenses(response.data);
    }
  }, []);

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
      onLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    mainContext,
    licenses
  } as const;
};
