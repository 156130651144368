import { useCallback, useEffect, useState } from "react";
import { DownloadButtonProps } from "./interfaces";

export const useDownloadButtonHooks = (props: DownloadButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (props.completed) {
      setOpen(true);
    }
  }, [props.completed]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open]);

  return { open, handleTooltipClose } as const;
};
