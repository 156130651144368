import { Box } from "@mui/material";
import { BldConditionSelectionEnum } from "common/enums/BldConditionSelectionEnum";
import { BldConditionSelection } from "common/interfaces/BldConditionSelection";
import React, { useCallback } from "react";
import styles from "../EstateFilterDialog.module.scss";

export const BldConditionButtons = React.memo(
  (props: {
    bldConditionSelectionsMaster: BldConditionSelection[];
    bldConditionSelections: BldConditionSelection[];
    onBldConditionSelectionsChange: (values: BldConditionSelection[]) => void;
  }) => {
    const onClick = useCallback(
      (bldConditionSelection: BldConditionSelection) => {
        const newBldConditionSelections = [...props.bldConditionSelections];

        const foundIndex = newBldConditionSelections.findIndex(
          (x) => x.id === bldConditionSelection.id
        );

        if (foundIndex > -1) {
          newBldConditionSelections.splice(foundIndex, 1);
        } else {
          newBldConditionSelections.push(bldConditionSelection);
        }

        props.onBldConditionSelectionsChange(newBldConditionSelections);
      },
      [props]
    );

    return (
      <>
        {props.bldConditionSelectionsMaster.map((bldConditionSelection) => (
          <Box
            key={bldConditionSelection.id}
            className={`${styles.btn_switch01} ${styles.click_act01} ${
              props.bldConditionSelections.findIndex(
                (x) => x.id === bldConditionSelection.id
              ) > -1
                ? styles.on
                : styles.off
            } ${
              bldConditionSelection.name === BldConditionSelectionEnum.あり他社
                ? styles.tasha
                : bldConditionSelection.name ===
                  BldConditionSelectionEnum.あり自社
                ? styles.jisha
                : styles.nashi
            }`}
            onClick={() => onClick(bldConditionSelection)}
          >
            {bldConditionSelection.name}
          </Box>
        ))}
      </>
    );
  }
);
