import { Box, Typography } from "@mui/material";
import HeightButtonIcon from "assets/icons/estate_info/btn_height.svg";
import BtnKoKomadoClose from "assets/icons/estate_info/btn_kokomadoclose.svg";
import FloorNumIcon from "assets/icons/estate_info/ic_floornum.svg";
import HistoryIcon from "assets/icons/estate_info/ic_history.svg";
import LandIcon from "assets/icons/estate_info/ic_land.svg";
import RoomIcon from "assets/icons/estate_info/ic_room.svg";
import TotalFloorIcon from "assets/icons/estate_info/ic_totalfloor.svg";
import YOIcon from "assets/icons/estate_info/ic_yo.svg";
import ToolContact from "assets/icons/estate_info/tool_contact.svg";
import ToolEdit from "assets/icons/estate_info/tool_edit.svg";
import ToolGmap from "assets/icons/estate_info/tool_gmap.svg";
import ToolLink from "assets/icons/estate_info/tool_link.svg";
import ToolNavi from "assets/icons/estate_info/tool_navi.svg";
import ToolPrint from "assets/icons/estate_info/tool_print.svg";
import ToolReport from "assets/icons/estate_info/tool_report.svg";
import ToolTrash from "assets/icons/estate_info/tool_trash.svg";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { MinimalEstate } from "common/queries/minimalEstates";
import { format } from "date-fns";
import React from "react";
import { isMobile } from "react-device-detect";
import styles from "../EstateInfo.module.scss";
import { useHooks } from "./hooks";
import { BlockInfoProps } from "./interfaces";

export const BlockInfo = React.memo((props: BlockInfoProps) => {
  const {
    block,
    onDisplayEstate,
    onUpdateEstate,
    onDeleteEstate,
    onReportEstate,
    onResize
  } = props;

  const {
    infoRef,

    expandRoomPrice,
    clickedCount,
    openContact,
    openCompleteCopy,
    openPriceHistory,

    id,
    groundType,
    externalUrl,
    parking,
    pet,
    isAdministrator,
    isEditable,
    sold,
    bldConditionType,
    layout,
    purposeB,
    old,
    structure,
    companies,
    isCondominiumMaster,
    isCondominiumRoom,
    floorBlocks,
    priceHistory,

    onHistoryClick,
    onExpandRoomPriceClick,
    onLinkClick,
    onGMapClick,
    onNaviClick,
    onContactClick,
    onContactCloseClick,
    onPriceHistoryClick,
    onPriceHistoryCloseClick
  } = useHooks(block, onResize);

  const titlePart = (
    <Box
      component="h1"
      className={`swiper-no-swiping ${styles["swiper-no-swiping"]}`}
    >
      {id}
    </Box>
  );

  const condominiumNamePart = <Box className={styles.name}>{block?.name}</Box>;

  const pricePart = (
    <Box className={styles.price}>
      <Box className={styles.current}>
        <Typography component="big">
          {block?.estatePrice ? block.estatePrice.toLocaleString() : "?"}
        </Typography>
        <Typography component="small">万円</Typography>
      </Box>
      <Box
        className={`${styles.update} ${
          block?.priceBehavior === "down"
            ? styles.down
            : block?.priceBehavior === "up"
            ? styles.up
            : styles.none
        }`}
        component="div"
        onClick={
          block?.priceBehavior === "down" || block?.priceBehavior === "up"
            ? onPriceHistoryClick
            : undefined
        }
      >
        <Box className={styles.box}>
          {block?.priceBehavior === "down" || block?.priceBehavior === "up" ? (
            <Box className={styles.date}>
              {block?.previousPrice?.pricedAt
                ? format(new Date(block?.previousPrice?.pricedAt), "yyyy.MM.dd")
                : "-"}
            </Box>
          ) : null}
          <Box className={styles.last}>
            {block?.priceBehavior === "down" ||
            block?.priceBehavior === "up" ? (
              <>
                {block?.previousPrice?.price
                  ? block.previousPrice.price.toLocaleString()
                  : "-"}
                <small>万円</small>
              </>
            ) : (
              "変動なし"
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const areaPart = (
    <Box className={styles.size}>
      <Box className={styles.area}>
        <Typography component="big">{block?.tsuboArea ?? "-"}</Typography>
        <Typography component="small">坪</Typography>
      </Box>
      <Box className={styles.unit}>
        <Box className={styles.box}>
          <Box className={styles.unitprice}>
            {block?.unitPrice}
            <Typography component="small">万/坪</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const layoutPart =
    layout === "" ? null : <Box className={styles.layout}>{layout}</Box>;

  const purposeBPart =
    purposeB === "" ? null : <Box className={styles.purpose}>{purposeB}</Box>;

  const oldPart = (
    <Box className={styles.yo}>
      <Box className={styles.tit}>
        <img src={YOIcon} alt="築年数" />
      </Box>
      <Box className={styles.dtl}>
        <small className={styles.chiku}>築</small>
        <big>{old}</big>
        <small>年</small>
      </Box>
    </Box>
  );

  const floorPart = (
    <Box className={styles.floornum}>
      <Box className={styles.tit}>
        <img src={FloorNumIcon} alt="階数" />
      </Box>
      <Box className={styles.dtl}>
        <big>{block?.floor ?? "-"}</big>
        <small>F</small>
      </Box>
    </Box>
  );

  const tsuboAreaPart = (
    <Box className={styles.land}>
      <Box className={styles.tit}>
        <img src={LandIcon} alt="土地面積" />
      </Box>
      <Box className={styles.dtl}>
        <big>{block?.tsuboArea ?? "-"}</big>
        <small>坪</small>
      </Box>
    </Box>
  );

  const totalFloorPart = (
    <Box className={styles.totalfloor}>
      <Box className={styles.tit}>
        <img src={TotalFloorIcon} alt="延床面積" />
      </Box>
      <Box className={styles.dtl}>
        <big>{block?.totalArea ?? "-"}</big>
        <small>㎡</small>
      </Box>
    </Box>
  );

  const roomAreaPart = (
    <Box className={styles.totalfloor}>
      <Box className={styles.tit}>
        <img src={TotalFloorIcon} alt="専有面積" />
      </Box>
      <Box className={styles.dtl}>
        <big>{block?.squareArea ?? "-"}</big>
        <small>㎡</small>
      </Box>
    </Box>
  );

  const historyPart = (
    <Box className={styles.history}>
      <Box className={styles.tit}>
        <img src={HistoryIcon} alt="過去事例" />
      </Box>
      <Box className={styles.dtl}>
        <Box
          component="a"
          className={styles.click_act01}
          onClick={onHistoryClick}
        >
          過去事例
        </Box>
      </Box>
    </Box>
  );

  const roomPricePart = floorBlocks.length > 0 && (
    <Box
      className={`${styles.roomprice} ${expandRoomPrice ? styles.high : ""}`}
    >
      <Box component="dl" className={styles.tit}>
        <Box component="dt">
          <Box component="span" onClick={onExpandRoomPriceClick}>
            <img src={RoomIcon} alt="各室価格情報の展開" />
          </Box>
        </Box>
        <Box component="dd" onClick={onExpandRoomPriceClick}>
          <Box component="span">Floor／Room Price</Box>
          <img src={HeightButtonIcon} alt="各室価格情報の展開" />
        </Box>
      </Box>
      {floorBlocks.map((floor) => (
        <Box component="dl" key={floor.floor}>
          <Box component="dt">
            <Box component="span">{floor.floor}</Box>
          </Box>
          <Box component="dd">
            {floor.floorBlocks.map((floorBlock, index) => (
              <Box
                key={index}
                className={styles.btn_roomprice}
                onClick={() => props.onRoomPriceClick?.(floorBlock)}
              >
                <Box component="span" className={styles.dtl1}>
                  {floorBlock.estatePrice
                    ? floorBlock.estatePrice?.toLocaleString()
                    : "?"}
                </Box>
                <Box component="span" className={styles.dtl2}>
                  {floorBlock.layout ?? ""}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box
      id={id}
      ref={infoRef}
      className={`${styles.main} ${
        block?.estateType === EstateTypeNameEnum.土地
          ? styles.ground
          : block?.estateType === EstateTypeNameEnum.分譲地
          ? styles.block
          : block?.estateType === EstateTypeNameEnum.新築建売
          ? styles.new
          : block?.estateType === EstateTypeNameEnum.中古戸建
          ? styles.used
          : block?.estateType === EstateTypeNameEnum.事業用
          ? styles.business
          : block?.estateType === EstateTypeNameEnum.マンション &&
            isCondominiumMaster
          ? styles.apartment
          : block?.estateType === EstateTypeNameEnum.マンション
          ? styles.room
          : ""
      } ${sold ? styles.sold : ""}`}
      aria-label="carrousel-li-label"
    >
      {titlePart}
      <Box component="h2"></Box>
      <Box component="article">
        {isCondominiumMaster ? condominiumNamePart : pricePart}
        {block?.estateType === EstateTypeNameEnum.土地 ||
        block?.estateType === EstateTypeNameEnum.分譲地
          ? areaPart
          : null}
        {block?.estateType === EstateTypeNameEnum.新築建売 ||
        block?.estateType === EstateTypeNameEnum.中古戸建 ||
        block?.estateType === EstateTypeNameEnum.事業用 ? (
          <>
            {block?.estateType === EstateTypeNameEnum.事業用
              ? purposeBPart
              : layoutPart}
            <Box className={styles.about}>
              {block?.estateType === EstateTypeNameEnum.中古戸建 ||
              block?.estateType === EstateTypeNameEnum.事業用
                ? oldPart
                : null}
              {tsuboAreaPart}
              {totalFloorPart}
            </Box>
          </>
        ) : null}
        {isCondominiumRoom ? (
          <>
            {layoutPart}
            <Box className={styles.about}>
              {floorPart}
              {oldPart}
              {roomAreaPart}
            </Box>
          </>
        ) : null}
        {isCondominiumMaster ? (
          <>
            <Box className={styles.about}>
              {oldPart}
              {historyPart}
            </Box>
            {roomPricePart}
          </>
        ) : null}
        <Box className={styles.tag}>
          <ul>
            {[
              !isCondominiumRoom && bldConditionType && (
                <li key={1} className={styles.joken}>
                  {bldConditionType}
                </li>
              ),
              isCondominiumMaster && structure && (
                <li key={2} className={styles.structure}>
                  {structure}
                </li>
              ),
              !isCondominiumRoom && block?.buildingFloors && (
                <li key={3} className={styles.floors}>
                  {block?.buildingFloors}
                </li>
              ),
              !isCondominiumMaster && !isCondominiumRoom && structure && (
                <li key={4} className={styles.structure}>
                  {structure}
                </li>
              ),
              !isCondominiumMaster &&
                block?.estateType !== EstateTypeNameEnum.新築建売 &&
                block?.currentStatus && (
                  <li key={5} className={styles.genjo}>
                    現：{block?.currentStatus}
                  </li>
                ),
              groundType && (
                <li key={6} className={styles.chimoku}>
                  {groundType}
                </li>
              ),
              isCondominiumRoom && parking && (
                <li key={7} className={styles.hmfloors}>
                  {parking}
                </li>
              ),
              !isCondominiumRoom && pet && (
                <li key={8} className={styles.hmfloors}>
                  {pet}
                </li>
              )
            ]}
          </ul>
        </Box>
        {!isCondominiumRoom && (
          <Box className={styles.place}>
            <Box className={styles.add}>
              <Box
                component="span"
                className={`swiper-no-swiping ${styles["swiper-no-swiping"]}`}
              >
                {block?.fullAddress}
              </Box>
            </Box>
            <Box className={styles.acc}>
              <Box
                component="span"
                className={`swiper-no-swiping ${styles["swiper-no-swiping"]}`}
              >
                {block?.traffic}
              </Box>
            </Box>
          </Box>
        )}
        <Box className={styles.tools}>
          <ul>
            {isCondominiumMaster ? <li className={styles.kara}></li> : null}
            {!isCondominiumMaster && externalUrl && (
              <li
                className={`${styles.click_act01} ${styles.link}`}
                onClick={onLinkClick}
              >
                <img src={ToolLink} alt="LINK" />
              </li>
            )}
            {!isCondominiumMaster && (
              <li
                className={`${styles.click_act01} ${styles.print}`}
                onClick={() => onDisplayEstate?.(block as MinimalEstate)}
              >
                <img src={ToolPrint} alt="PRINT" />
              </li>
            )}
            <li
              className={`${styles.click_act01} ${styles.gmap}`}
              onClick={onGMapClick}
            >
              <img src={ToolGmap} alt="GMAP" />
            </li>
            <li
              className={`${styles.click_act01} ${styles.navi}`}
              onClick={onNaviClick}
            >
              <img src={ToolNavi} alt="NAVI" />
            </li>
            {!isCondominiumMaster && (
              <li
                className={`${styles.click_act01} ${styles.contact}`}
                onClick={onContactClick}
              >
                <img src={ToolContact} alt="CONTACT" />
              </li>
            )}
            {!isCondominiumMaster && <li className={styles.separator}>｜</li>}
            {!isCondominiumMaster && isEditable && (
              <li
                className={`${styles.click_act01} ${styles.edit}`}
                onClick={() => onUpdateEstate?.(block as MinimalEstate)}
              >
                <img src={ToolEdit} alt="EDIT" />
              </li>
            )}
            {!isCondominiumMaster && !isEditable && (
              <li
                className={`${styles.click_act01} ${styles.edit}`}
                onClick={() => onReportEstate?.(block as MinimalEstate)}
              >
                <img src={ToolReport} alt="EDIT" />
              </li>
            )}
            {!isCondominiumMaster && isAdministrator && (
              <li
                className={`${styles.click_act01} ${styles.trash}`}
                onClick={() => onDeleteEstate?.(block as MinimalEstate)}
              >
                <img src={ToolTrash} alt="TRASH" />
              </li>
            )}
            {isCondominiumMaster ? <li className={styles.kara}></li> : null}
          </ul>
        </Box>
        <Box className={styles.info}>
          <Box className={styles.entry}>
            {sold
              ? block?.updatedAt
                ? format(new Date(block?.updatedAt), "yyyy.MM.dd")
                : null
              : block?.createdAt
              ? format(new Date(block?.createdAt), "yyyy.MM.dd")
              : null}
          </Box>
          {block?.status === StatusNameEnum.公開 && clickedCount !== null ? (
            <Box className={styles.view}>{clickedCount}</Box>
          ) : null}
        </Box>
        <Box
          className={`${styles.bkn_kokomado} ${
            openContact ? styles.on : styles.off
          }`}
        >
          <Box className={styles.box}>
            <Box className={styles.scroll}>
              <Box
                className={`${styles.contactbox} ${
                  openContact ? styles.on : styles.off
                }`}
              >
                <Box className={styles.tit}>取扱業者</Box>
                {block?.mediationKind && (
                  <Box className={styles.type}>{block.mediationKind}</Box>
                )}
                <ul>
                  {companies.map((company, index) => (
                    <li
                      key={index}
                      className={`swiper-no-swiping ${styles["swiper-no-swiping"]}`}
                    >
                      <Box component="span" className={styles.com_name}>
                        {company.name}
                      </Box>
                      {company.tel && (
                        <Box component="span" className={styles.tel}>
                          {isMobile ? (
                            <a href={`tel:${company.tel}`}>
                              <Box component="span">{company.tel}</Box>
                            </a>
                          ) : (
                            <Box component="span">{company.tel}</Box>
                          )}
                        </Box>
                      )}
                    </li>
                  ))}
                </ul>
              </Box>
              <Box
                className={styles.kokomadoclose}
                onClick={onContactCloseClick}
              >
                <img src={BtnKoKomadoClose} alt="CLOSE" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className={`${styles.messagebox} ${
            openCompleteCopy ? styles.on : styles.off
          }`}
        >
          <Box className={styles.box}>
            <Box
              className={`${styles.gmapcopy_ok} ${
                openCompleteCopy ? styles.on : styles.off
              }`}
            >
              Google MAPのURLを
              <br />
              コピーしました
            </Box>
          </Box>
        </Box>
        <Box
          className={`${styles.bkn_kokomado} ${
            openPriceHistory ? styles.on : styles.off
          }`}
        >
          <Box className={styles.box}>
            <Box className={styles.scroll}>
              <Box
                className={`${styles.pricehistorybox} ${
                  openPriceHistory ? styles.on : styles.off
                }`}
              >
                <Box className={styles.tit}>価格履歴</Box>
                <ul>
                  {priceHistory?.map((price, index) => (
                    <li
                      key={index}
                      className={`swiper-no-swiping ${styles["swiper-no-swiping"]}`}
                    >
                      <Box component="span" className={styles.ind_date}>
                        {price?.pricedAt
                          ? format(new Date(price.pricedAt), "yyyy.MM.dd")
                          : "-"}
                      </Box>
                      <Box component="span" className={styles.ind_price}>
                        {price?.price ? price.price.toLocaleString() : "?"}{" "}
                        <small>万円</small>
                      </Box>
                    </li>
                  ))}
                </ul>
              </Box>
              <Box
                className={styles.kokomadoclose}
                onClick={onPriceHistoryCloseClick}
              >
                <img src={BtnKoKomadoClose} alt="CLOSE" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
