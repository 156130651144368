import { point } from "@turf/turf";
import { PublicEstate } from "API";
import { SchoolKindEnum } from "common/enums/SchoolKindEnum";
import { getPublicEstate } from "common/functions/api/publicEstates";
import { getSchoolAreas } from "common/functions/geoJsons/schoolAreas";
import { getUseDistrictsGeoJSON } from "common/functions/geoJsons/useDistricts";
import { getCities } from "common/functions/jsonMasters/cities";
import { getPrefectures } from "common/functions/jsonMasters/prefectures";
import { latLngToCoordinatesPrimitive } from "common/functions/mapUtilities";
import { UseDistrictProperty } from "common/interfaces/UseDistrictGeoJSON";
import { MainContextContainer } from "components/Main";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { isInnerOfFeature } from "../functions";
import { DetailProps } from "../interfaces";

export const useIndividualPageHooks = (
  props: DetailProps & {
    publicEstateId: string | null | undefined;
    onPublicEstateLoaded: (publicEstate: PublicEstate) => void;
  }
) => {
  const initRef = useRef(true);

  const mainContext = useContext(MainContextContainer);

  const [publicEstate, setPublicEstate] = useState<PublicEstate | undefined>(
    undefined
  );
  const [eSchools, setESchools] = useState<string[] | undefined>(undefined);
  const [jSchools, setJSchools] = useState<string[] | undefined>(undefined);
  const [useDistricts, setUseDistricts] = useState<
    Partial<UseDistrictProperty>[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUseDistricts, setIsLoadingUseDistricts] = useState(true);
  const [isLoadingSchools, setIsLoadingSchools] = useState(true);

  const roadInfos = useMemo(
    () => [
      publicEstate?.estate?.roadType ? `${publicEstate?.estate?.roadType}` : "",
      publicEstate?.estate?.drivewayPay
        ? `私道負担：${publicEstate?.estate?.drivewayPay}`
        : "",
      publicEstate?.estate?.roadDirection
        ? `向き：${publicEstate?.estate?.roadDirection}`
        : "",
      publicEstate?.estate?.roadWidth
        ? `幅：${publicEstate?.estate?.roadWidth} m`
        : ""
    ],
    [
      publicEstate?.estate?.drivewayPay,
      publicEstate?.estate?.roadDirection,
      publicEstate?.estate?.roadType,
      publicEstate?.estate?.roadWidth
    ]
  );

  const actualForAnonymous = useMemo(
    () => props.forAnonymous && !mainContext.isReadyToUse,
    [mainContext.isReadyToUse, props.forAnonymous]
  );

  const getSchools = useCallback(async () => {
    const schools: {
      eSchools: string[];
      jSchools: string[];
    } = {
      eSchools: ["-"],
      jSchools: ["-"]
    };

    const prefectures = await getPrefectures(actualForAnonymous).catch(
      () => undefined
    );

    if (publicEstate?.estate?.longitude && publicEstate?.estate?.latitude) {
      const estatePoint = point([
        publicEstate?.estate?.longitude,
        publicEstate?.estate?.latitude
      ]);

      if (prefectures) {
        const prefecture = prefectures.find(
          (x) => x.pref_name === publicEstate?.estate?.prefecture
        );

        if (prefecture) {
          const cites = await getCities(
            prefecture.pref_code,
            actualForAnonymous
          ).catch(() => undefined);

          if (cites) {
            const city = cites.find(
              (x) =>
                x.pref_name === publicEstate?.estate?.prefecture &&
                x.city_name === publicEstate?.estate?.city
            );

            if (city) {
              const eSchools = await getSchoolAreas(
                city.pref_code,
                city.city_code,
                SchoolKindEnum.ESchool,
                actualForAnonymous
              ).catch(() => undefined);

              if (eSchools) {
                schools.eSchools = eSchools.features
                  .filter((feature) => isInnerOfFeature(estatePoint, feature))
                  .map((x) => x.properties.school_name);
              }

              const jSchools = await getSchoolAreas(
                city.pref_code,
                city.city_code,
                SchoolKindEnum.JSchool,
                actualForAnonymous
              ).catch(() => undefined);

              if (jSchools) {
                schools.jSchools = jSchools.features
                  .filter((feature) => isInnerOfFeature(estatePoint, feature))
                  .map((x) => x.properties.school_name);
              }
            }
          }
        }
      }
    }

    return schools;
  }, [
    actualForAnonymous,
    publicEstate?.estate?.city,
    publicEstate?.estate?.latitude,
    publicEstate?.estate?.longitude,
    publicEstate?.estate?.prefecture
  ]);

  const getUseDistricts = useCallback(async () => {
    const useDistricts: {
      useDistricts: Partial<UseDistrictProperty>[];
    } = {
      useDistricts: [{ use_district_name: "-" }]
    };

    if (publicEstate?.estate?.longitude && publicEstate?.estate?.latitude) {
      const tile = latLngToCoordinatesPrimitive(
        publicEstate.estate?.latitude,
        publicEstate.estate?.longitude,
        20
      ).tileCoordinate;
      const tileOnZoom20 = `${tile.x},${tile.y}`;
      const estatePoint = point([
        publicEstate.estate?.longitude,
        publicEstate.estate?.latitude
      ]);

      const useDistrictJsons = await getUseDistrictsGeoJSON(
        tileOnZoom20,
        actualForAnonymous
      ).catch(() => undefined);

      if (useDistrictJsons) {
        useDistricts.useDistricts = useDistrictJsons.features
          .filter((feature) => isInnerOfFeature(estatePoint, feature))
          .map((x) => ({
            use_district_name: x.properties.use_district_name,
            building_coverage_ratio: x.properties.building_coverage_ratio,
            floor_area_ratio: x.properties.floor_area_ratio
          }));
      }
    }

    return useDistricts;
  }, [
    actualForAnonymous,
    publicEstate?.estate?.latitude,
    publicEstate?.estate?.longitude
  ]);

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (props.publicEstateId) {
      getPublicEstate(props.publicEstateId, actualForAnonymous)
        .then((value) => setPublicEstate(value ?? undefined))
        .catch((reason) => window.console.error(reason))
        .finally(() => setIsLoading(false));
    }
  }, [actualForAnonymous, props.publicEstateId]);

  useEffect(() => {
    if (publicEstate) {
      getSchools()
        .then((value) => {
          setESchools(value.eSchools);
          setJSchools(value.jSchools);
        })
        .catch((reason) => window.console.error(reason))
        .finally(() => setIsLoadingSchools(false));

      getUseDistricts()
        .then((value) => {
          setUseDistricts(value.useDistricts);
        })
        .catch((reason) => window.console.error(reason))
        .finally(() => setIsLoadingUseDistricts(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicEstate]);

  useEffect(() => {
    if (
      publicEstate &&
      isLoading === false &&
      isLoadingUseDistricts === false &&
      isLoadingSchools === false
    ) {
      props.onPublicEstateLoaded(publicEstate);
    }
  }, [isLoading, isLoadingSchools, isLoadingUseDistricts, props, publicEstate]);

  return {
    mainContext,
    publicEstate,
    eSchools,
    jSchools,
    useDistricts,
    roadInfos,
    isLoading
  } as const;
};
