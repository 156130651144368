import { createTheme } from "@mui/material";
import { Company } from "API";
import { GroupEnum } from "common/enums/GroupEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { parsePlaceResult } from "common/functions/placesUtilities";
import { Status } from "common/interfaces/Status";
import { MainContextContainer } from "components/Main";
import { ChangeEventValue } from "components/Parts/Atoms/SumoraMap/interfaces";
import { useCompanyQueryHooks } from "hooks/companyHooks";
import { useEstateForLocationInputHooks } from "hooks/estateHooks";
import { useMapHooks } from "hooks/mapHooks";
import { useStatusLimitedHooks } from "hooks/statusHooks";
import { isEmpty } from "lodash";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { EstateLocationInputMode } from "./enums";
import { EstateLocationInputDialogProps } from "./interfaces";

export const useEstateLocationInputDialogHooks = (
  props: EstateLocationInputDialogProps
) => {
  const initStatusRef = useRef(true);
  const initCompanyRef = useRef(true);

  const mainContext = useContext(MainContextContainer);

  const theme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          scrollPaper: {
            className: "twbasic01"
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            fontFamily: "inherit",
            fontWeight: "700 !important",
            color: "black",

            "&::before": {
              backgroundColor: "rgba(210, 200, 175, 1)",
              borderRadius: "1000px",
              content: '""',
              display: "inline-block",
              height: "1.5em",
              marginRight: "6px",
              width: "7px"
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&::before": {
              display: "none"
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            fontFamily: "inherit"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: "0px 5px !important"
          },
          input: {
            padding: "12px 5px !important"
          },
          notchedOutline: {
            border: "none"
          }
        }
      }
    }
  });

  const [defaultCompanyRecNo, setDefaultCompanyRecNo] = useState<
    number | null | undefined
  >(undefined);
  const [companyErrorMessage, setCompanyErrorMessage] = useState("");
  const [specifyMessage, setSpecifyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [forceDisable, setForceDisable] = useState(false);

  const {
    estate,
    initializeEstate,
    setEstate,
    busy: busyFoLocationInput,
    disable,

    updateEstate,
    deleteEstate
  } = useEstateForLocationInputHooks(
    props.estateId,
    mainContext.userInfo?.username,
    mainContext.setFatal
  );

  const { statuses, setStatuses } = useStatusLimitedHooks(
    estate.status,
    mainContext.setFatal
  );

  const {
    company: selectedCompany,
    initializeCompany: initializeSelectedCompany
  } = useCompanyQueryHooks(estate.companyId, mainContext.setFatal);

  const {
    map,
    setMap,
    location,
    setLocation,
    center,
    setCenter,
    zoom,
    setZoom,
    places,
    placesService,
    setPlacesService,

    initialize: initializeMapStates
  } = useMapHooks();

  const [address, setAddress] = useState(estate.fullAddress);

  const onStatusChange = useCallback(
    (value: Status | null | undefined) => {
      if (value) {
        setEstate.status((value as Status).name);
      }
    },
    [setEstate]
  );

  const onSelectedCompanyChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: NonNullable<string | Company>
    ) => {
      setEstate.companyId((value as Company | null)?.id || "");

      if (value as Company | null) {
        setCompanyErrorMessage("");
      }
    },
    [setEstate]
  );

  const onAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAddress(event.target.value);
    },
    []
  );

  const onPlacesChange: (
    places: google.maps.places.PlaceResult,
    latitude?: number | null,
    longitude?: number | null
  ) => Promise<void> = useCallback(
    async (places, latitude, longitude) => {
      const { location, prefecture, city, area, remain } =
        await parsePlaceResult(places, true);

      if (latitude && longitude) {
        setEstate.latitude(latitude);
        setEstate.longitude(longitude);
      } else if (location) {
        setEstate.latitude(location.lat());
        setEstate.longitude(location.lng());
      }

      const fullAddresses: string[] = [];

      setEstate.prefecture(prefecture);
      fullAddresses.push(prefecture);

      setEstate.city(city);
      fullAddresses.push(city);

      setEstate.area(area);
      fullAddresses.push(area);

      if (isEmpty(estate.address)) {
        setEstate.address(remain);
      }

      fullAddresses.push(remain);

      if (isEmpty(estate.fullAddress)) {
        setEstate.fullAddress(fullAddresses.join(""));
      }
    },
    [estate.address, estate.fullAddress, setEstate]
  );

  const onToPlaceClick = useCallback(
    (
      address: string | null | undefined,
      latitude?: number | null,
      longitude?: number | null
    ) => {
      setBusy(true);

      const usingPlacesService =
        placesService ?? (map && new google.maps.places.PlacesService(map));

      if (usingPlacesService) {
        if (!placesService) {
          setPlacesService(usingPlacesService);
        }

        usingPlacesService.textSearch(
          {
            query: address || undefined,
            type: "address"
          },
          (results) => {
            if (results?.at(0)) {
              if (results[0].place_id) {
                usingPlacesService?.getDetails(
                  {
                    placeId: results[0].place_id,
                    fields: ["geometry", "address_components", "website"]
                  },
                  (results2) => {
                    if (results2) {
                      onPlacesChange(results2, latitude, longitude).finally(
                        () => setBusy(false)
                      );
                    } else {
                      setSpecifyMessage("該当箇所を検索できませんでした。");
                      setBusy(false);
                    }
                  }
                );
              } else {
                onPlacesChange(results[0], latitude, longitude).finally(() =>
                  setBusy(false)
                );
              }
            } else {
              setSpecifyMessage("該当住所を検索できませんでした。");
              setBusy(false);
            }
          }
        );
      }
    },
    [map, onPlacesChange, placesService, setPlacesService]
  );

  const onAddressToMapButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onToPlaceClick(address);
    },
    [onToPlaceClick, address]
  );

  const onLocationToPlaceClick = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      onToPlaceClick(
        `${estate.latitude},${estate.longitude}`,
        estate.latitude,
        estate.longitude
      );
    },
    [onToPlaceClick, estate.latitude, estate.longitude]
  );

  const onMapChange = useCallback(
    (value: ChangeEventValue) => {
      setCenter(value.center);
      setZoom(value.zoom);
    },
    [setCenter, setZoom]
  );

  const onMapReady = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [setMap]
  );

  const onLocationChange = useCallback(
    (value: google.maps.LatLng) => {
      onToPlaceClick(`${value.lat()},${value.lng()}`, value.lat(), value.lng());
    },
    [onToPlaceClick]
  );

  const onClose = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      if (props.onClose) {
        props.onClose(event);
      }
    },
    [props]
  );

  const onOkButtonClick = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
    ) => {
      let error = false;

      if (
        props.mode !== EstateLocationInputMode.Delete &&
        (estate.status === StatusNameEnum.公開 ||
          estate.status === StatusNameEnum.成約済み ||
          estate.status === StatusNameEnum.マンションM)
      ) {
        if (
          estate.latitude === null ||
          estate.latitude === undefined ||
          estate.longitude === null ||
          estate.longitude === undefined
        ) {
          setErrorMessage("位置を指定してください。");
          error = true;
        }

        if (
          estate.prefecture === null ||
          estate.prefecture === undefined ||
          estate.city === null ||
          estate.city === undefined
        ) {
          setErrorMessage("都道府県、市区町村が特定されていません。");
          error = true;
        }
      }

      if (error) {
      } else {
        if (props.mode === EstateLocationInputMode.Delete) {
          setConfirm(true);
        } else if (props.mode === EstateLocationInputMode.Update) {
          updateEstate().then((value) => {
            if (value?.item) {
              props.onOkButtonClick(
                props.mode || EstateLocationInputMode.Update,
                {
                  ...value.item,
                  __typename: "Estate"
                }
              );

              onClose({} as any);
            }
          });
        }
      }
    },
    [
      props,
      estate.status,
      estate.latitude,
      estate.longitude,
      estate.prefecture,
      estate.city,
      updateEstate,
      onClose
    ]
  );

  const onConfirmDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setConfirm(false);
      deleteEstate()
        .then((value) => {
          if (value?.item) {
            props.onOkButtonClick(
              props.mode || EstateLocationInputMode.Delete,
              {
                ...value.item,
                __typename: "Estate"
              }
            );

            onClose({} as any);
          }
        })
        .finally();
    },
    [deleteEstate, onClose, props]
  );

  const onConfirmDeleteCloseClick = useCallback(() => {
    setConfirm(false);
  }, []);

  const onConfirmDeleteClose = onConfirmDeleteCloseClick;

  const onAdminEditButtonClick = useCallback(() => {
    if (mainContext.groups?.includes(GroupEnum.Administrator)) {
      window.open(
        window.location.origin.includes("localhost")
          ? `http://localhost:3009/edit/${estate.id}`
          : window.location.origin.includes("dev")
          ? `https://devctl.sumora.jp/edit/${estate.id}`
          : `https://ctl.sumora.jp/edit/${estate.id}`,
        "_blank"
      );
    }
  }, [mainContext.groups, estate.id]);

  useEffect(() => {
    if (!props.open) {
      initializeEstate();
      initializeSelectedCompany();
      initializeMapStates();

      setDefaultCompanyRecNo(undefined);
      setCompanyErrorMessage("");
      setSpecifyMessage("");
      setErrorMessage("");
      setBusy(false);
      setConfirm(false);
      setStatuses([]);

      initStatusRef.current = true;
      initCompanyRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (initCompanyRef.current && estate.id) {
      setDefaultCompanyRecNo(Number(estate.companyId?.split("-").at(0)));

      initCompanyRef.current = false;
    }
  }, [estate.companyId, estate.id]);

  useEffect(() => {
    if (
      estate.latitude === null ||
      estate.latitude === undefined ||
      estate.longitude === null ||
      estate.longitude === undefined
    ) {
      setLocation(null);
    } else {
      setLocation({
        lat: estate.latitude,
        lng: estate.longitude
      });
    }
  }, [setLocation, estate.latitude, estate.longitude]);

  useEffect(() => {
    if (estate.fullAddress) {
      setAddress(estate.fullAddress);
    }
  }, [estate.fullAddress]);

  useEffect(() => {
    if (map) {
      const service = new google.maps.places.PlacesService(map);
      setPlacesService(service);
    }
  }, [map, setPlacesService]);

  useEffect(() => {
    setBusy(busyFoLocationInput);
  }, [busyFoLocationInput]);

  useEffect(() => {
    setForceDisable(disable);
  }, [disable]);

  return {
    mainContext,
    theme,

    estate,

    defaultCompanyRecNo,
    setDefaultCompanyRecNo,
    selectedCompany,

    companyErrorMessage,
    specifyMessage,
    errorMessage,

    busy,
    setBusy,
    confirm,
    forceDisable,

    statuses,

    map,
    location,
    center,
    zoom,
    places,

    address,

    onStatusChange,
    onSelectedCompanyChange,

    onAddressChange,
    onPlacesChange,
    onAddressToMapButtonClick,
    onLocationToPlaceClick,

    onMapChange,
    onMapReady,

    onLocationChange,

    onOkButtonClick,
    onConfirmDeleteClick,
    onConfirmDeleteCloseClick,
    onConfirmDeleteClose,
    onAdminEditButtonClick,
    onClose
  } as const;
};

export type EstateLocationInputDialogHooksType = ReturnType<
  typeof useEstateLocationInputDialogHooks
>;
