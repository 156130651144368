import { useAutoReducer } from "common/functions/reducers";
import {
  defaultEstateSearchConditions,
  EstateSearchConditions
} from "common/interfaces/EstateSearchConditions";

export const useSearchEstateSearchConditionStates = () => {
  const [
    estateSearchConditions,
    initializeEstateSearchConditions,
    forceEstateSearchConditions,
    setEstateSearchConditions,
    clearEstateSearchConditions
  ] = useAutoReducer<EstateSearchConditions>(defaultEstateSearchConditions);

  return {
    estateSearchConditions,
    initializeEstateSearchConditions,
    forceEstateSearchConditions,
    setEstateSearchConditions,
    clearEstateSearchConditions
  } as const;
};

export type SearchEstateSearchConditionStatesType = ReturnType<
  typeof useSearchEstateSearchConditionStates
>;
