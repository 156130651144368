import { Autocomplete, Box, Typography } from "@mui/material";
import React from "react";
import { AutocompleteRenderInputTextBox } from "../AutocompleteRenderInputTextBox";
import { useCompanyAutocompleteHooks } from "./hooks";
import { CompanyAutocompleteProps } from "./interfaces";

const CompanyAutocompleteImplements = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>(
  props: CompanyAutocompleteProps<Multiple, DisableClearable>
) => {
  const {
    companies,
    busy,
    referenceStyles,
    getOptionLabel,
    getOptionSelected,
    convertValue,
    onInputChange
  } = useCompanyAutocompleteHooks<Multiple, DisableClearable>(
    props.multiple,
    props.setFatal,
    props.onOptionsLoad
  );

  const {
    label: _label,
    helperText: _helperText,
    errorMessage: _errorMessage,
    referenceText: _referenceText,
    required: _required,
    InputProps: _InputProps,
    onOptionsLoad: _onOptionsLoad,
    ...autocompleteProps
  } = props;

  delete autocompleteProps.setFatal;

  return (
    <>
      <Autocomplete
        {...autocompleteProps}
        freeSolo={true}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <AutocompleteRenderInputTextBox
            params={params}
            label={props.label || "業者を選択"}
            helperText={props.helperText}
            errorMessage={props.errorMessage}
            referenceText={props.referenceText}
            required={props.required}
            InputProps={props.InputProps}
          />
        )}
        options={companies}
        loading={busy}
        loadingText="検索しています..."
        value={convertValue(props.value)}
        onInputChange={onInputChange}
        isOptionEqualToValue={getOptionSelected}
        disabled={props.disabled}
        noOptionsText={<i>候補が見つかりません</i>}
      ></Autocomplete>
      <Box sx={referenceStyles}>
        <Typography variant="caption">{props.referenceText}</Typography>
      </Box>
    </>
  );
};

export const CompanyWithSearchStringAutocomplete = React.memo(
  CompanyAutocompleteImplements
) as typeof CompanyAutocompleteImplements;
