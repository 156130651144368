import { Box } from "@mui/material";
import btn_number from "assets/icons/map_menu/btn_number.svg";
import btn_search from "assets/icons/map_menu/btn_search.svg";
import { MinimalEstate } from "common/queries/minimalEstates";
import { memo } from "react";
import styles from "../SearchMenu.module.scss";
import { EstateIdAutoComplete } from "./EstateIdAutoComplete";
import { OftenMenuButton } from "./OftenMenuButton";
import { PlaceInput } from "./PlaceInput";

export const OftenMenu = memo(
  (props: {
    map: google.maps.Map | null | undefined;
    filteredPublicEstates: MinimalEstate[] | undefined;
    filteredClosedEstates: MinimalEstate[] | undefined;
    searchStatus: boolean;
    numberStatus: boolean;
    onSearchStatusChange: (on: boolean) => void;
    onNumberStatusChange: (on: boolean) => void;
    onPlacesChange: (places: google.maps.places.PlaceResult) => void;
    onEstateChange: (estate: MinimalEstate | null | undefined) => void;
  }) => {
    return (
      <Box component="div" className={styles.btn_often}>
        <OftenMenuButton
          title="場所を検索"
          imgSrc={btn_search}
          additionalClassName={styles.search}
          status={props.searchStatus}
          input={
            <PlaceInput
              map={props.map}
              placeholder="場所を検索"
              open={props.searchStatus}
              onPlacesChange={props.onPlacesChange}
            />
          }
          onStatusChange={props.onSearchStatusChange}
          onClick={() => {}}
        />
        <OftenMenuButton
          title="物件番号を入力"
          imgSrc={btn_number}
          additionalClassName={styles.number}
          status={props.numberStatus}
          input={
            <EstateIdAutoComplete
              placeholder="物件番号を入力"
              open={props.numberStatus}
              filteredPublicEstates={props.filteredPublicEstates}
              filteredClosedEstates={props.filteredClosedEstates}
              onEstateChange={props.onEstateChange}
            />
          }
          onStatusChange={props.onNumberStatusChange}
          onClick={() => {}}
        />
      </Box>
    );
  }
);
