import { createTheme } from "@mui/material";
import { StickyNote, StickyNoteColor, StickyNoteIcon } from "API";
import { useAutoReducer } from "common/functions/reducers";
import { OpenLevel } from "common/interfaces/OpenLevel";
import { MainContextContainer } from "components/Main";
import { ChangeEventValue } from "components/Parts/Atoms/SumoraMap/interfaces";
import { useMapHooks } from "hooks/mapHooks";
import { useOpenLevelsHooks } from "hooks/openLevelsHooks";
import { useStickyNoteHooks } from "hooks/stickyNoteHooks";
import { isEmpty } from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { StickyNoteInputMode } from "./enums";
import { StickyNoteInputDialogProps } from "./interfaces";

type StickyNoteErrorMessages = {
  [P in keyof StickyNote as `${P}ErrorMessage`]-?: string;
};

export const useStickyNoteInputDialogHooks = (
  props: StickyNoteInputDialogProps
) => {
  const nodeRef = useRef(null);

  const mainContext = useContext(MainContextContainer);

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            fontFamily: "inherit",
            fontWeight: "700 !important",
            color: "black",
            marginBottom: "0.5em",

            "&::before": {
              backgroundColor: "rgba(210, 200, 175, 1)",
              borderRadius: "1000px",
              content: '""',
              display: "inline-block",
              height: "1.5em",
              marginRight: "6px",
              width: "7px"
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&::before": {
              display: "none"
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            fontFamily: "inherit"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: "none"
          }
        }
      }
    }
  });

  const {
    stickyNote,
    initializeStickyNote,
    setStickyNote,
    busy: busyOfStickyNote,
    disable,

    createStickyNote,
    updateStickyNote,
    deleteStickyNote
  } = useStickyNoteHooks(
    props.stickyNoteId,
    mainContext.userInfo?.username,
    mainContext.company?.id,
    mainContext.setFatal
  );

  const [
    stickyNoteErrorMessages,
    initializeStickyNoteErrorMessages,
    ,
    setStickyNoteErrorMessages,
    clearStickyNoteErrorMessages
  ] = useAutoReducer<StickyNoteErrorMessages>({} as StickyNoteErrorMessages);

  const { openLevels } = useOpenLevelsHooks(mainContext.setFatal);

  const {
    map,
    setMap,
    location,
    setLocation,
    center,
    setCenter,
    zoom,
    setZoom,
    places,

    initialize: initializeMapStates
  } = useMapHooks();

  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [forceDisable, setForceDisable] = useState(false);

  const onMemoKindChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setStickyNote.memoKind(event.target.value);

      if (event.target.value !== "") {
        clearStickyNoteErrorMessages.memoKindErrorMessage();
      }
    },
    [clearStickyNoteErrorMessages, setStickyNote]
  );

  const onOpenLevelChange = useCallback(
    (value: OpenLevel | null | undefined) => {
      setStickyNote.openLevel(value?.name || null);

      if (value && value.name !== "") {
        clearStickyNoteErrorMessages.openLevelErrorMessage();
      }
    },
    [clearStickyNoteErrorMessages, setStickyNote]
  );

  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setStickyNote.description(event.target.value);

      if (event.target.value !== "") {
        clearStickyNoteErrorMessages.descriptionErrorMessage();
      }
    },
    [clearStickyNoteErrorMessages, setStickyNote]
  );

  const onIconChange = useCallback(
    (value: StickyNoteIcon | null | undefined) => {
      setStickyNote.icon(value);

      if (value) {
        clearStickyNoteErrorMessages.iconErrorMessage();
      }
    },
    [clearStickyNoteErrorMessages, setStickyNote]
  );

  const onColorChange = useCallback(
    (value: StickyNoteColor | null | undefined) => {
      setStickyNote.color(value);

      if (value) {
        clearStickyNoteErrorMessages.colorErrorMessage();
      }
    },
    [clearStickyNoteErrorMessages, setStickyNote]
  );

  const onMapChange = useCallback(
    (value: ChangeEventValue) => {
      setCenter(value.center);
      setZoom(value.zoom);
    },
    [setCenter, setZoom]
  );

  const onMapReady = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [setMap]
  );

  const onLocationChange = useCallback(
    (value: google.maps.LatLng) => {
      const latitude = value.lat();
      const longitude = value.lng();

      if (latitude && longitude) {
        setStickyNote.latitude(latitude);
        setStickyNote.longitude(longitude);
      }
    },
    [setStickyNote]
  );

  const onOkButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      let error = false;

      if (props.mode === null) {
        setErrorMessage("編集モードが不正です。");

        error = true;
      }

      if (props.mode !== StickyNoteInputMode.Delete) {
        if (isEmpty(mainContext.company?.id)) {
          setErrorMessage("ユーザーの業者IDを取得できませんでした。");

          error = true;
        }

        if (isEmpty(mainContext.userInfo?.username)) {
          setErrorMessage("ユーザー名を取得できませんでした。");

          error = true;
        }
      }

      if (!error) {
        if (props.mode === StickyNoteInputMode.Delete) {
          setConfirm(true);
        } else if (props.mode === StickyNoteInputMode.Create) {
          createStickyNote().then((value) => {
            if (value?.item) {
              props.onOkButtonClick(
                props.mode || StickyNoteInputMode.Create,
                value.item
              );
            }
          });
        } else if (props.mode === StickyNoteInputMode.Update) {
          updateStickyNote().then((value) => {
            if (value?.item) {
              props.onOkButtonClick(
                props.mode || StickyNoteInputMode.Update,
                value.item
              );
            }
          });
        }
      }
    },
    [
      createStickyNote,
      mainContext.company?.id,
      mainContext.userInfo?.username,
      props,
      updateStickyNote
    ]
  );

  const onConfirmDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setConfirm(false);
      deleteStickyNote()
        .then((value) => {
          if (value?.item) {
            props.onOkButtonClick(
              props.mode || StickyNoteInputMode.Delete,
              value.item
            );
          }
        })
        .finally();
    },
    [deleteStickyNote, props]
  );

  const onClose = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      if (props.onClose) {
        props.onClose(event);
      }
    },
    [props]
  );

  const onConfirmDeleteCloseClick = useCallback(() => {
    setConfirm(false);
    onClose({} as React.MouseEvent<HTMLButtonElement>);
  }, [onClose]);

  const onConfirmDeleteClose = onConfirmDeleteCloseClick;

  useEffect(() => {}, []);

  useEffect(() => {
    if (!props.open) {
      initializeStickyNote();
      initializeStickyNoteErrorMessages();
      initializeMapStates();
      setErrorMessage("");
      setBusy(false);
      setConfirm(false);
      setForceDisable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    if (
      props.mode === StickyNoteInputMode.Create &&
      props.initialLatitude &&
      props.initialLongitude
    ) {
      setStickyNote.latitude(props.initialLatitude);
      setStickyNote.longitude(props.initialLongitude);
    } else if (props.mode === StickyNoteInputMode.Delete) {
      setConfirm(true);
    }
  }, [
    props.mode,
    props.initialLatitude,
    props.initialLongitude,
    setStickyNote
  ]);

  useEffect(() => {
    const location = {
      lat: stickyNote.latitude,
      lng: stickyNote.longitude
    };
    setLocation(location);
  }, [setLocation, stickyNote.latitude, stickyNote.longitude]);

  useEffect(() => {
    setBusy(busyOfStickyNote);
  }, [busy, busyOfStickyNote]);

  useEffect(() => {
    setForceDisable(disable);
  }, [disable]);

  return {
    theme,

    stickyNote,
    openLevels,

    stickyNoteErrorMessages,
    setStickyNoteErrorMessages,
    map,
    location,
    center,
    zoom,
    places,

    errorMessage,
    busy,
    confirm,
    forceDisable,

    onMemoKindChange,
    onOpenLevelChange,
    onDescriptionChange,
    onIconChange,
    onColorChange,

    onMapChange,
    onMapReady,
    onLocationChange,

    onOkButtonClick,
    onConfirmDeleteClick,
    onConfirmDeleteCloseClick,
    onConfirmDeleteClose,
    onClose,

    nodeRef
  } as const;
};

export type StickyNoteInputDialogHooksType = ReturnType<
  typeof useStickyNoteInputDialogHooks
>;
