import {
  CardMembership,
  Explore,
  Info,
  PlaylistAddCheck,
  Refresh
} from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import LogoutIcon from "assets/icons/logout.svg";
import { MainContext } from "common/interfaces/MainContext";
import { MenuElement } from "components/Parts/Atoms/MenuElement";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import styles from "./HomePage.module.scss";

export const MenuButtonSearch = React.memo(
  (props: { mainContext: MainContext }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.mainMenuButton}>
        <MenuElement
          onClick={() => (window.location.href = "/search")}
          title="マップ"
          description="担当エリアの物件をマップで表示します。"
          icon={<Explore />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonSearchOwn = React.memo(
  (props: { mainContext: MainContext }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.mainMenuButton}>
        <MenuElement
          onClick={() => (window.location.href = "/search?own=true")}
          title="マップ(自社管理物件)"
          description={
            <>
              自社の管理物件のみをマップで表示します。
              <br />
              在庫管理にご利用ください。
            </>
          }
          icon={<Explore />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonCheckEstatesForAdmin = React.memo(
  (props: { mainContext: MainContext }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.mainMenuButton}>
        <MenuElement
          onClick={() =>
            window.open(
              window.location.origin.includes("localhost")
                ? "http://localhost:3009/check_estates"
                : window.location.origin.includes("dev")
                ? "https://devctl.sumora.jp/check_estates"
                : "https://ctl.sumora.jp/check_estates",
              "_blank"
            )
          }
          title="物件チェック(管理者用)"
          description="エリア情報等が不足している物件をチェックします。"
          icon={<PlaylistAddCheck />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonCheckEstates = React.memo(
  (props: { mainContext: MainContext; navigate: NavigateFunction }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.mainMenuButton}>
        <MenuElement
          onClick={() => props.navigate("check_estates")}
          title="物件一覧表示"
          description="ステータスが位置不明の物件について、位置情報をお知らせください。"
          icon={<PlaylistAddCheck />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonCondominium = React.memo(
  (props: { mainContext: MainContext; navigate: NavigateFunction }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.otherMenuButton}>
        <MenuElement
          onClick={() => props.navigate("condominium")}
          title="マップ(マンションマスタ)"
          description="担当エリアのマンションマスタをマップで表示します。"
          icon={<Explore />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonDraft = React.memo(
  (props: { mainContext: MainContext; navigate: NavigateFunction }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.otherMenuButton}>
        <MenuElement
          onClick={() => props.navigate("draft")}
          title="マップ(下書き)"
          description="担当エリアの下書き物件をマップで表示します。"
          icon={<Explore />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonAbout = React.memo(
  (props: { mainContext: MainContext; navigate: NavigateFunction }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.otherMenuButton}>
        <MenuElement
          onClick={() => props.navigate("about")}
          title="注意事項"
          description="注意事項を表示します。"
          icon={<Info />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonLicense = React.memo(
  (props: { mainContext: MainContext; navigate: NavigateFunction }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.otherMenuButton}>
        <MenuElement
          onClick={() => props.navigate("license")}
          title="ライセンス表記"
          description="ライセンス表記を表示します。"
          icon={<CardMembership />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonReload = React.memo(
  (props: { mainContext: MainContext }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.otherMenuButton}>
        <MenuElement
          onClick={() => {
            window.location.href = `${window.location.href}`;
          }}
          title="強制リロード"
          description="当サイトを強制リロードします。"
          icon={<Refresh />}
        />
      </Paper>
    </Grid>
  )
);

export const MenuButtonSignOut = React.memo(
  (props: { mainContext: MainContext }) => (
    <Grid xs={6} lg={3} item>
      <Paper className={styles.otherMenuButton}>
        <MenuElement
          onClick={props.mainContext.onSignOutClick}
          title="ログアウト"
          description="当サイトからログアウトします。"
          icon={<img src={LogoutIcon} alt="ログアウト" />}
        />
      </Paper>
    </Grid>
  )
);
