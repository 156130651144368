import { getUrl } from "aws-amplify/storage";
import axios from "axios";
import { MainContextContainer } from "components/Main";
import { useCallback, useContext, useEffect, useState } from "react";

export const useAboutHooks = () => {
  const mainContext = useContext(MainContextContainer);

  const [docs, setDocs] = useState<string[]>([]);

  const onCompanyAreasChange = useCallback(async () => {
    for (const key of [
      "docs/info.md",
      "docs/hazard.md",
      "docs/useDistrict.md",
      "docs/school.md",
      "docs/moj.md"
    ]) {
      const url = await getUrl({
        key: `public/${key}`,
        options: {
          accessLevel: "guest"
        }
      })
        .then((value) => value.url.href)
        .catch((err) => undefined);

      if (url) {
        const response = await axios.get<string>(url).catch((err) => undefined);

        if (response) {
          setDocs((x) => [...x, response.data]);
        }
      }
    }
  }, []);

  useEffect(() => {
    onCompanyAreasChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    mainContext,
    docs,

    onCompanyAreasChange
  } as const;
};
