import { City } from "common/interfaces/City";
import { EstateSearchConditions } from "common/interfaces/EstateSearchConditions";
import { Prefecture } from "common/interfaces/Prefecture";
import { useCallback } from "react";
import { SearchEstateMethodsType } from "../methods/estates";
import { SearchMasterMethodsType } from "../methods/masters";
import { SearchProps } from "../../interfaces";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchEstateSearchConditionStatesType } from "../states/estateSearchConditions";

export const useSearchSwitchAreaDialogHandlers = (
  props: SearchProps,
  dialogStates: SearchDialogStatesType,
  searchConditionStates: SearchEstateSearchConditionStatesType,
  masterMethods: SearchMasterMethodsType,
  searchMethods: SearchEstateMethodsType
) => {
  const onSwitchAreaDialogClose = useCallback(() => {
    dialogStates.setOpenSwitchAreaDialog(false);
  }, [dialogStates]);

  const onSwitchAreaDialogOkButtonClick = useCallback(
    (selectedPrefectures: Prefecture[], selectedCities: City[]) => {
      dialogStates.setOpenSwitchAreaDialog(false);

      const conditions = {
        ...searchConditionStates.estateSearchConditions,
        prefs: selectedPrefectures,
        cities: selectedCities
      } as EstateSearchConditions;

      searchConditionStates.setEstateSearchConditions.prefs(
        selectedPrefectures
      );
      searchConditionStates.setEstateSearchConditions.cities(selectedCities);

      searchMethods.searchEstate(
        props.searchMode,
        conditions,
        false,
        false,
        false
      );
    },
    [
      dialogStates,
      props.searchMode,
      searchConditionStates.estateSearchConditions,
      searchConditionStates.setEstateSearchConditions,
      searchMethods
    ]
  );

  return {
    onSwitchAreaDialogClose,

    onSwitchAreaDialogOkButtonClick
  } as const;
};

export type SearchSwitchAreaDialogHandlersType = ReturnType<
  typeof useSearchSwitchAreaDialogHandlers
>;
