import { useEffect, useRef, useState } from "react";
import { SumoraMapProps } from "./interfaces";

export const useHooks = (props: SumoraMapProps) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [mapId, setMapId] = useState<string | undefined>(
    process.env.REACT_APP_GOOGLE_MAP_ID_NORMAL_WITHOUT_POI
  );
  const [mapVisible, setMapVisible] = useState<boolean>(false);
  const mapRef = useRef<HTMLDivElement | null>(null);

  const { onGoogleApiLoaded, onChange, sx, ...options } = props;

  useEffect(() => {
    if (props.mono && props.dspPoi) {
      setMapId(process.env.REACT_APP_GOOGLE_MAP_ID_MONO_WITH_POI);
    } else if (props.mono && !props.dspPoi) {
      setMapId(process.env.REACT_APP_GOOGLE_MAP_ID_MONO_WITHOUT_POI);
    } else if (!props.mono && props.dspPoi) {
      setMapId(process.env.REACT_APP_GOOGLE_MAP_ID_NORMAL_WITH_POI);
    } else {
      setMapId(process.env.REACT_APP_GOOGLE_MAP_ID_NORMAL_WITHOUT_POI);
    }
  }, [props.dspPoi, props.mono]);

  useEffect(() => {
    if (mapRef.current) {
      setMapVisible(false);

      const newMap = new google.maps.Map(mapRef.current, {
        ...options,
        mapId: mapId,
        rotateControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        }
      });

      const confirmBounds = async () => {
        while (!newMap.getBounds()) {
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
      };

      confirmBounds().then(() => {
        setMap(newMap);

        if (props.onMapIdChanged && mapId) {
          props.onMapIdChanged(mapId);
        }

        if (mapId) {
          setTimeout(() => {
            newMap.panBy(1, 1);
            newMap.panBy(-1, -1);
          }, 200);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapId, props.onMapIdChanged]);

  useEffect(() => {
    if (map) {
      document.documentElement.style.setProperty(
        "--markerMaxZIndex",
        String(google.maps.Marker.MAX_ZINDEX)
      );

      onGoogleApiLoaded({ map, maps: [map], ref: mapRef.current });
      setTimeout(() => {
        setMapVisible(true);
      }, 100);
    }
  }, [map, onGoogleApiLoaded]);

  useEffect(() => {
    if (
      map &&
      typeof options.center?.lat === "number" &&
      typeof options.center?.lng === "number"
    ) {
      map.setOptions({
        center: { lat: options.center.lat, lng: options.center.lng }
      });
    }
  }, [map, options.center?.lat, options.center?.lng]);

  useEffect(() => {
    if (map) {
      map.setOptions({ zoom: options.zoom });
    }
  }, [map, options.zoom]);

  useEffect(() => {
    if (map) {
      map.addListener("idle", () => {
        const zoom = map.getZoom();
        const center = map.getCenter();

        if (zoom && center && onChange) {
          onChange({
            zoom: zoom,
            center: { lat: center.lat(), lng: center.lng() }
          });
        }
      });
    }
  }, [map, onChange]);

  return { map, mapId, mapVisible, mapRef } as const;
};
