import { Company } from "API";
import { City } from "./City";
import { Prefecture } from "./Prefecture";
import { Status } from "./Status";

export interface EstateSearchConditions {
  statuses: Status[];
  prefs: Prefecture[];
  cities: City[];
  companies: Company[];
  tilesOnZoom12: string[];
  tilesOnZoom14: string[];
  tilesOnZoom16: string[];

  bound: boolean;
}

export const defaultEstateSearchConditions = {
  statuses: [],
  prefs: [],
  cities: [],
  companies: [],
  tilesOnZoom12: [],
  tilesOnZoom14: [],
  tilesOnZoom16: [],

  bound: false
} as EstateSearchConditions;
