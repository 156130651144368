import { StatusEnum } from "common/enums/StatusEnum";
import { Status } from "common/interfaces/Status";
import { getJsonMasters } from "./common";

export const getStatuses = async (
  includeNegotiating: boolean,
  includeDraft: boolean,
  includeCancel: boolean,
  includeMaster: boolean
) =>
  (await getJsonMasters<Status>("statuses"))?.filter(
    (x) =>
      (includeNegotiating || x.id !== StatusEnum.商談中) &&
      (includeDraft || x.id !== StatusEnum.下書き) &&
      (includeCancel || x.id !== StatusEnum.非公開) &&
      (includeMaster || x.id !== StatusEnum.マンションM)
  );
