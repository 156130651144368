import { Box } from "@mui/material";
import ic_gps from "assets/icons/map_menu/ic_gps.svg";
import style from "./GpsButton.module.scss";
import { GpsButtonProps } from "./interfaces";

export const GpsButton = (props: GpsButtonProps) => {
  return (
    <Box component="div" className={style.btn_gps} onClick={props.onClick}>
      <Box component="img" src={ic_gps} alt="GPS" />
    </Box>
  );
};
