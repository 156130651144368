import { getAreas } from "./jsonMasters/areas";
import { getCities } from "./jsonMasters/cities";
import { getPrefectures } from "./jsonMasters/prefectures";

export const parsePlaceResult = async (
  places: google.maps.places.PlaceResult,
  regulateArea?: boolean
) => {
  let formattedAddress = places.formatted_address;

  if (places.address_components) {
    const country = places.address_components.find((x) =>
      x.types.includes("country")
    );

    const postalCode = places.address_components.find((x) =>
      x.types.includes("postal_code")
    );

    const prefecture = places.address_components.find((x) =>
      x.types.includes("administrative_area_level_1")
    );

    const district = places.address_components.find((x) =>
      x.types.includes("administrative_area_level_2")
    );

    const city1 = places.address_components.find((x) =>
      x.types.includes("locality")
    );

    const city2 = places.address_components.find((x) =>
      x.types.includes("sublocality_level_1")
    );

    const area1 = places.address_components.find((x) =>
      x.types.includes("sublocality_level_2")
    );

    const area2 = places.address_components.find((x) =>
      x.types.includes("sublocality_level_3")
    );

    const remains: string[] = [];

    const remain1 = places.address_components.find((x) =>
      x.types.includes("sublocality_level_4")
    );

    if (remain1) {
      remains.push(remain1.long_name);
    }

    const remain2 = places.address_components.find((x) =>
      x.types.includes("premise")
    );

    if (remain2) {
      remains.push(remain2.long_name);
    }

    if (country && postalCode && formattedAddress) {
      formattedAddress = formattedAddress.replace(
        `${country.long_name}、〒${postalCode.long_name}`,
        ""
      );
    }

    const prefectureFmt = prefecture?.long_name || "";

    const city = `${district?.long_name || ""}${city1?.long_name || ""}${
      city2?.long_name || ""
    }`;

    const area = `${area1?.long_name || ""}${area2?.long_name || ""}`;

    const remain = remains.join("−");

    if (regulateArea) {
      try {
        const prefectures = await getPrefectures(false);
        const foundPrefecture = prefectures?.find(
          (x) => x.pref_name === prefectureFmt
        );

        if (foundPrefecture) {
          const cities = await getCities(foundPrefecture.pref_code, false);
          const foundCity = cities?.find((x) => x.city_name === city);

          if (foundCity) {
            const areas = await getAreas(
              foundCity.pref_code,
              foundCity.city_code,
              false
            );

            const areaFmt = `${area}${remain}`;

            for (let i = areaFmt.length; i >= 0; i--) {
              const foundArea = areas?.find((x) => {
                if (areaFmt.substring(0, i) === x.area_name) {
                  return true;
                }

                return false;
              });

              if (foundArea) {
                return {
                  postalCode,
                  formattedAddress,
                  location: places.geometry?.location,
                  name: places.name || "",
                  prefecture: foundPrefecture.pref_name,
                  city: foundCity.city_name,
                  area: foundArea.area_name,
                  remain: areaFmt.replace(foundArea.area_name, ""),
                  tel: places.formatted_phone_number,
                  url: places.website
                };
              }
            }
          }
        }
      } catch (err) {}
    }

    return {
      postalCode,
      formattedAddress,
      location: places.geometry?.location,
      name: places.name || "",
      prefecture: prefectureFmt,
      city: city,
      area: area,
      remain: remain,
      tel: places.formatted_phone_number,
      url: places.website
    };
  }

  return {
    undefined,
    formattedAddress,
    location: places.geometry?.location,
    name: "",
    prefecture: "",
    city: "",
    area: "",
    remain: "",
    tel: undefined,
    url: undefined
  };
};
