import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { constructSoldEstate } from "common/functions/estates";
import { EstateFilterConditions } from "common/interfaces/EstateFilterConditions";
import { MinimalEstate } from "common/queries/minimalEstates";
import { MainContext } from "common/interfaces/MainContext";
import { useCallback } from "react";
import {
  condominiumBlocksOfEstates,
  constructEstateDict,
  filterEstate,
  truncDegree
} from "../functions/filters";

export const useSearchFilterMethods = (mainContext: MainContext) => {
  // フィルターを適用した物件リスト作成
  const getFilteredEstates = useCallback(
    (
      estates: MinimalEstate[] | undefined,
      filterConditions: EstateFilterConditions
    ) => {
      if (estates) {
        const items = estates
          .filter(
            (x) =>
              filterEstate(x, filterConditions, mainContext.user?.companyId) &&
              (!x.sold || x.estateType === EstateTypeNameEnum.マンション)
          )
          .map((x) => ({
            ...x,
            latitude: truncDegree(x.latitude),
            longitude: truncDegree(x.longitude)
          }))
          .map((x) => constructSoldEstate(x, false, false))
          .sort((a, b) => (a.recNo || 0) - (b.recNo || 0));

        // 同一マンションまとめ
        const blocksEstateDict = Object.assign(
          {},
          ...items
            .map((x) => {
              if (
                x?.condominiumId &&
                x.status !== SearchModeEnum.condominium &&
                x.estateType === EstateTypeNameEnum.マンション
              ) {
                // 同一マンション
                return condominiumBlocksOfEstates(items as MinimalEstate[], x);
              }

              return {
                [x.id || ""]: x
              };
            })
            .filter((x) => x)
        ) as { [id: string]: MinimalEstate };

        const estateDict = constructEstateDict(
          blocksEstateDict,
          mainContext,
          false
        );

        return {
          filteredPublicEstates: (Object.values(estateDict) ?? []).filter(
            (x) => x.status !== StatusNameEnum.成約済み
          ),
          filteredPublicEstatesCount: items.filter(
            (x) => x.status !== StatusNameEnum.成約済み
          ).length,

          filteredClosedEstates: (Object.values(estateDict) ?? []).filter(
            (x) => x.status === StatusNameEnum.成約済み
          ),
          filteredClosedEstatesCount: items.filter(
            (x) => x.status === StatusNameEnum.成約済み
          ).length
        };
      }

      return {
        filteredPublicEstates: estates,
        filteredPublicEstatesCount: 0,
        filteredClosedEstates: estates,
        filteredClosedEstatesCount: 0
      };
    },
    [mainContext]
  );

  return {
    getFilteredEstates
  } as const;
};
