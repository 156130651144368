import { Polyline, SquareFoot } from "@mui/icons-material";
import { Fab } from "@mui/material";
import React from "react";
import styles from "./MeasureModeButton.module.scss";

export const MeasureModeButton = React.memo(
  (props: {
    measureLengthMode: boolean;
    measureAreaMode: boolean;
    onQuitMeasureClick: () => void;
  }) => (
    <Fab
      onClick={props.onQuitMeasureClick}
      className={styles.measureModeButton}
    >
      {props.measureLengthMode ? <Polyline /> : <SquareFoot />}
    </Fab>
  )
);
