import React from "react";
import { useHooks } from "./hooks";
import { MeasurePluginProps } from "./interfaces";

export const MeasurePlugin = React.memo((props: MeasurePluginProps) => {
  const { map } = useHooks(props);

  if (map) {
    return <></>;
  }

  return null;
});
