import { useHooks } from "./hooks";

export const TsunamiPlugin = ({ on }: { on: boolean }) => {
  const { map } = useHooks(on);

  if (map) {
    return <></>;
  }

  return null;
};
