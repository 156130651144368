export const addMenuCloseHelper = (
  menuButtonId: string,
  menuDialogId: string,
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  window.addEventListener("mousedown", (ev) => {
    const menuButtonDiv = document.getElementById(menuButtonId);
    const menuDialogDiv = document.getElementById(menuDialogId);

    if (menuButtonDiv && menuDialogDiv && ev.target) {
      if (
        !menuButtonDiv.contains(ev.target as Element) &&
        !menuDialogDiv.contains(ev.target as Element)
      ) {
        // Google Places Autocompleteのサジェストがタッチできない問題に対処
        const pacItems = document.getElementsByClassName("pac-item");

        for (const pacItem of pacItems) {
          if (pacItem === ev.target || pacItem.contains(ev.target as Element)) {
            return false;
          }
        }

        setMenuOpen(false);
      }
    }
  });
};
