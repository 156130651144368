import { Box } from "@mui/material";
import React, { useCallback } from "react";
import styles from "../EstateFilterDialog.module.scss";

export const OtherConditionButton = React.memo(
  (props: {
    petOk: boolean;
    flat: boolean;
    myCompanyOnly: boolean;

    onPetOkChange: (checked: boolean) => void;
    onFlatChange: (checked: boolean) => void;
    onMyCompanyOnlyChange: (checked: boolean) => void;
  }) => {
    const onPetOkClick = useCallback(
      () => props.onPetOkChange(!props.petOk),
      [props]
    );

    const onFlatClick = useCallback(
      () => props.onFlatChange(!props.flat),
      [props]
    );

    const onMyCompanyOnlyClick = useCallback(
      () => props.onMyCompanyOnlyChange(!props.myCompanyOnly),
      [props]
    );

    return (
      <>
        <Box
          onClick={onPetOkClick}
          className={`${styles.btn_switch01} ${styles.click_act01} ${
            styles.pet
          } ${props.petOk ? styles.on : styles.off}`}
        >
          ペット可のみ
        </Box>
        <Box
          onClick={onFlatClick}
          className={`${styles.btn_switch01} ${styles.click_act01} ${
            styles.hiraya
          } ${props.flat ? styles.on : styles.off}`}
        >
          平屋のみ
        </Box>
        <Box
          onClick={onMyCompanyOnlyClick}
          className={`${styles.btn_switch01} ${styles.click_act01} ${
            styles.own
          } ${props.myCompanyOnly ? styles.on : styles.off}`}
        >
          自社物件のみ
        </Box>
      </>
    );
  }
);
