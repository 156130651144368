import { sleep } from "common/functions/utilities";
import { useCallback, useContext, useEffect, useRef } from "react";
import { SearchMapContext } from "../../context";

export const useHooks = () => {
  const { map } = useContext(SearchMapContext);
  const projection = useRef<google.maps.MapCanvasProjection | undefined>(
    undefined
  );

  const getProjection = useCallback(
    async (overlay: google.maps.OverlayView) => {
      for (let i = 0; i < 100; i++) {
        await sleep(200);

        projection.current = overlay.getProjection();

        if (projection.current) {
          break;
        }
      }
    },
    []
  );

  useEffect(() => {
    if (map) {
      const overlay = new google.maps.OverlayView();
      overlay.setMap(map);

      getProjection(overlay);

      return () => {
        try {
          overlay.setMap(null);
        } catch {}
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return { map, projection } as const;
};
