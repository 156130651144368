import { SchoolKindEnum } from "common/enums/SchoolKindEnum";

export class SchoolNameView extends google.maps.OverlayView {
  private position: google.maps.LatLng;
  private caption: string;
  private div?: HTMLElement;
  private schoolKind: SchoolKindEnum;

  constructor(
    position: google.maps.LatLng,
    caption: string,
    schoolKind: SchoolKindEnum
  ) {
    super();
    this.position = position;
    this.caption = caption;
    this.schoolKind = schoolKind;
  }

  onAdd() {
    const schoolName = this.caption.replaceAll("/<.+?>/", "");

    this.div = document.createElement("div");
    this.div.className = `school_name ${
      this.schoolKind === SchoolKindEnum.ESchool ? "e_school" : "j_school"
    }`;

    const div = document.createElement("div");
    div.innerHTML = schoolName.replaceAll("#", "<br>");

    div.style.width = `${
      Math.max(...schoolName.split("#").map((x) => x.length)) * 1.5
    }em`;

    this.div?.appendChild(div);

    const panes = this.getPanes()!;

    panes.overlayLayer.appendChild(this.div);
  }

  draw() {
    const overlayProjection = this.getProjection();
    const point = overlayProjection.fromLatLngToDivPixel(this.position);

    if (this.div) {
      const captions = this.caption.split("#");
      const captionLength = Math.max(...captions.map((x) => x.length));

      this.div.style.left = `calc(${point?.x || 0}px - ${captionLength / 2}em)`;
      this.div.style.top = `calc(${point?.y || 0}px - ${
        2 * captions.length
      }em ${this.schoolKind === SchoolKindEnum.ESchool ? "-" : "+"} 1.5em)`;
    }
  }

  onRemove() {
    if (this.div) {
      (this.div.parentNode as HTMLElement).removeChild(this.div);
      delete this.div;
    }
  }

  hide() {
    if (this.div) {
      this.div.style.visibility = "hidden";
    }
  }

  show() {
    if (this.div) {
      this.div.style.visibility = "visible";
    }
  }

  toggle() {
    if (this.div) {
      if (this.div.style.visibility === "hidden") {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  toggleDOM(map: google.maps.Map) {
    if (this.getMap()) {
      this.setMap(null);
    } else {
      this.setMap(map);
    }
  }

  PRESERVE_COMMENT_ABOVE = 1;
}
