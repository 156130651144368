import { StatusNameEnum } from "common/enums/StatusEnum";
import { constructSoldEstate } from "common/functions/estates";
import { EstateFilterConditions } from "common/interfaces/EstateFilterConditions";
import { MinimalEstate } from "common/queries/minimalEstates";
import { MainContext } from "common/interfaces/MainContext";
import { useCallback } from "react";
import { constructEstateDict, filterEstate } from "../functions/filters";
import { soldBlocksOfEstates } from "../functions/solds";

export const useSoldMethods = (mainContext: MainContext) => {
  // フィルターを適用した閲覧制限物件物件リスト作成
  const getSoldEstates = useCallback(
    (
      estates: MinimalEstate[] | undefined,
      filterConditions: EstateFilterConditions
    ) => {
      if (estates) {
        const items = estates
          .filter(
            (x) =>
              filterEstate(x, filterConditions, mainContext.user?.companyId) &&
              x.status === StatusNameEnum.成約済み
          )
          .map((x) => constructSoldEstate(x, true, false))
          .sort((a, b) => (a.recNo || 0) - (b.recNo || 0));

        // 同一タイル成約済み物件まとめ
        const blocksEstateDict = Object.assign(
          {},
          ...items
            .map((x) => soldBlocksOfEstates(items as MinimalEstate[], x))
            .filter((x) => x)
        ) as { [id: string]: MinimalEstate };

        const estateDict = constructEstateDict(
          blocksEstateDict,
          mainContext,
          true
        );

        return {
          soldEstates: Object.values(estateDict) ?? [],
          soldEstatesCount: items.length
        };
      }

      return {
        soldEstates: estates,
        soldEstatesCount: 0
      };
    },
    [mainContext]
  );

  return {
    getSoldEstates
  } as const;
};
