import { Close } from "@mui/icons-material";
import { Alert, AlertTitle, IconButton, Stack } from "@mui/material";
import {
  boundSearchableZoom,
  meshDisplayableZoom
} from "common/consts/estates";
import { stickyNoteSearchableZoom } from "common/consts/stickyNotes";
import React from "react";
import styles from "./LimitZoomsCollapse.module.scss";
import { useHooks } from "./hooks";

export const LimitZoomsCollapse = React.memo(
  (props: {
    bound: boolean;
    sold: boolean;
    stickyNote: boolean | undefined;
    zoom: number;
  }) => {
    const {
      openBound,
      openSold,
      openStickyNote,
      onCloseBound,
      onCloseSold,
      onCloseStickyNote
    } = useHooks();

    return (
      <Stack className={styles.limitZoomsCollapse} spacing={2}>
        {openBound && props.bound && props.zoom < boundSearchableZoom ? (
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onCloseBound}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>
              Zoom = {boundSearchableZoom} より地図範囲検索は有効になります
            </AlertTitle>
            (現在 Zoom = {props.zoom})
          </Alert>
        ) : null}
        {openSold && props.sold && props.zoom < meshDisplayableZoom ? (
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onCloseSold}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>
              Zoom = {meshDisplayableZoom} より過去事例表示は有効になります
            </AlertTitle>
            (現在 Zoom = {props.zoom})
          </Alert>
        ) : null}
        {openStickyNote &&
        props.stickyNote &&
        props.zoom < stickyNoteSearchableZoom ? (
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onCloseStickyNote}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>
              Zoom = {stickyNoteSearchableZoom} よりメモ表示は有効になります
            </AlertTitle>
            (現在 Zoom = {props.zoom})
          </Alert>
        ) : null}
      </Stack>
    );
  }
);
