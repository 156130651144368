import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ThemeProvider
} from "@mui/material";
import Report from "assets/icons/estate_info/tool_report.svg";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import { Confirm } from "components/Parts/Molecules/Confirm";
import { NoticeDialog } from "components/Parts/Molecules/NoticeDialog";
import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import styles from "./ReportInputDialog.module.scss";
import { useInputFields } from "./fields";
import { useReportInputDialogHooks } from "./hooks";
import { ReportInputDialogProps } from "./interfaces";

const idPrefix = "company-input-dialog";

export const ReportInputDialog = React.memo((props: ReportInputDialogProps) => {
  const hooks = useReportInputDialogHooks(props);

  const {
    theme,

    specifyMessage,

    errorMessage,

    busy,
    forceDisable,
    confirm,
    thanks,

    onOkButtonClick,
    onClose,
    onConfirmClick,
    onConfirmCloseClick,
    onConfirmClose,
    onThanksCloseClick,
    onThanksClose
  } = hooks;

  const listItems: { title: string; value: JSX.Element }[] =
    useInputFields(hooks)();

  const fields = useMemo(
    () =>
      listItems.map((inputField) => (
        <Grid xs={12} item key={inputField.title}>
          {inputField.value}
        </Grid>
      )),
    [listItems]
  );

  if (props.open) {
    return (
      <Dialog
        open={props.open}
        onClose={onClose}
        aria-labelledby={`${idPrefix}-title`}
        aria-describedby={`${idPrefix}-description`}
        maxWidth={false}
        disableEscapeKeyDown
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{
          backdrop: { className: styles.backdrop }
        }}
        componentsProps={{
          root: { className: styles.root }
        }}
      >
        <DialogHeader
          id={`${idPrefix}-title`}
          icon={
            <Box className={styles.icon}>
              <Box component="img" src={Report} />
            </Box>
          }
        />
        <DialogContent
          id={`${idPrefix}-description`}
          className={styles.dialogContent}
        >
          <Collapse in={props.errorMessage !== ""}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>エラーが発生しました！</AlertTitle>
              {props.errorMessage}
            </Alert>
          </Collapse>
          <Collapse in={!isEmpty(errorMessage)}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>入力エラーがあります。</AlertTitle>
              {errorMessage}
            </Alert>
          </Collapse>
          <ThemeProvider theme={theme}>{fields}</ThemeProvider>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            variant="contained"
            className={styles.cancelButton}
            disabled={busy || forceDisable}
            onClick={props.onClose}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            className={styles.okButton}
            variant="contained"
            disabled={busy || forceDisable}
            onClick={onOkButtonClick}
          >
            {props.okButtonCaption}
          </Button>
        </DialogActions>
        <Box className={styles.bottomError}>{specifyMessage}</Box>
        <ProgressBackdrop open={busy || props.busy || false} />
        <Confirm
          open={confirm}
          content="報告を送信します。"
          onClose={onConfirmClose}
          onCloseClick={onConfirmCloseClick}
          onOkClick={onConfirmClick}
        />
        <NoticeDialog
          open={thanks}
          content={"ご協力ありがとうございました。"}
          onClose={onThanksClose}
          onCloseClick={onThanksCloseClick}
        />
      </Dialog>
    );
  } else {
    return null;
  }
});
