import { Box } from "@mui/material";
import { Estate } from "API";
import React from "react";
import styles from "./DetailPage.subComponents.module.scss";

export const ContentBasic = React.memo((props: { estate?: Estate }) => (
  <Box className={styles.contentBasic}>
    <Box>所在地</Box>
    <Box>{props.estate?.fullAddress ?? "−"}</Box>

    <Box>交通</Box>
    <Box>{props.estate?.traffic ?? "−"}</Box>
  </Box>
));
