import { Box } from "@mui/material";
import { Estate } from "API";
import { UseDistrictProperty } from "common/interfaces/UseDistrictGeoJSON";
import React, { useMemo } from "react";
import styles from "./DetailPage.subComponents.module.scss";

export const ContentTail = React.memo(
  (props: {
    estate?: Estate;
    eSchools?: string[];
    jSchools?: string[];
    useDistricts?: Partial<UseDistrictProperty>[];
  }) => {
    const useDistricts = useMemo(() => {
      return props.useDistricts && props.useDistricts.length > 0
        ? props.useDistricts
            .map(
              (x) =>
                `${x.use_district_name ?? "-"} (建蔽${
                  x.building_coverage_ratio ?? "-"
                }%, 容積${x.floor_area_ratio ?? "-"}%)`
            )
            .join(", ")
        : "-";
    }, [props.useDistricts]);

    return (
      <>
        <Box className={styles.geoInfo}>
          <Box>用途地域</Box>
          <Box>{useDistricts}</Box>

          <Box>小学校区</Box>
          <Box>
            {(props.estate?.eSchool || props.eSchools || []).join(", ")}
          </Box>

          <Box>中学校区</Box>
          <Box>
            {(props.estate?.jSchool || props.jSchools || []).join(", ")}
          </Box>
        </Box>

        <Box className={styles.innerInfo}>
          <Box>物件ID</Box>
          <Box>{props.estate?.id}</Box>
        </Box>
      </>
    );
  }
);
