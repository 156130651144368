import { Navigation } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import React from "react";
import QRCode from "react-qr-code";
import styles from "./QRCodeView.module.scss";
import { QRCodeViewProps } from "./interfaces";

export const QRCodeView = React.memo((props: QRCodeViewProps) => {
  return (
    <>
      <Dialog
        style={props.style}
        className={props.className}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="qrcode-title"
        aria-describedby="qrcode-description"
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{ backdrop: { className: styles.backdrop } }}
        >
        <DialogHeader id="qrcode-title" icon={<Navigation />} />
        <DialogContent id="qrcode-description">
          <QRCode
            value={props.content}
            level="H"
            size={256}
            className={styles.qrCode}
          />
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            variant="contained"
            className={styles.cancelButton}
            color="warning"
            onClick={props.onCloseClick}
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
