import { Box, Grid, Link, Paper } from "@mui/material";
import { MainWrapper } from "components/Parts/Atoms/MainWrapper";
import { HeaderBar } from "components/Parts/Molecules/HeaderBar";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import React from "react";
import styles from "./LicensePage.module.scss";
import { useLicenseHooks } from "./hooks";
import { LicenseProps } from "./interfaces";
import { LicenseText } from "./subComponents/LicenseText";

export const LicensePage = React.memo((props: LicenseProps) => {
  const { mainContext, licenses } = useLicenseHooks(props);

  const items: JSX.Element[] = [];

  for (const key in licenses) {
    const license = licenses[key];
    items.push(
      <Grid xs={12} item key={key}>
        <Paper className={styles.licenseItem}>
          <h3>{key}</h3>
          <li>ライセンス: {license.licenses}</li>
          <li>
            リポジトリ:{" "}
            <Link
              href={license.repository}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              {license.repository}
            </Link>
          </li>
          <li>
            ライセンスURL:{" "}
            <Link
              href={license.licenseUrl}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
            >
              {license.licenseUrl}
            </Link>
          </li>
          {license.licenses === "ISC" && (
            <li>
              ライセンス本文: <LicenseText libraryName={key} />
            </li>
          )}
        </Paper>
      </Grid>
    );
  }

  return (
    <ContentMain>
      <HeaderBar
        authStatus={mainContext.authStatus}
        authUser={mainContext.authUser}
        user={mainContext.user}
        company={mainContext.company}
        userInfo={mainContext.userInfo}
        groups={mainContext.groups}
      />
      <MainWrapper>
        <Box component="h2">ライセンス表記</Box>
        <Grid container spacing={2}>
          {items}
        </Grid>
      </MainWrapper>
    </ContentMain>
  );
});
