import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { LabeledSwitchProps } from "./interfaces";

export const LabeledSwitch = React.memo((props: LabeledSwitchProps) => {
  return (
    <FormControlLabel
      style={props.style}
      className={props.className}
      control={
        <Switch
          onChange={props.onChange}
          checked={props.value}
          sx={{
            "& .MuiSwitch-thumb": {
              border: "solid black 2px"
            },
            "& .MuiSwitch-track": {
              border: "solid black 1px"
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "rgba(255, 220, 0, 1)"
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "rgba(255, 240, 0, 1)",
              opacity: 1
            }
          }}
        />
      }
      label={props.label}
    />
  );
});
