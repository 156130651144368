import { DialogNames } from "../../types";

export const removeLayer = (
  dialogNames: DialogNames[],
  dialogName: DialogNames
) => {
  const index = dialogNames.findIndex((x) => x === dialogName);

  if (index > -1) {
    dialogNames.splice(index, 1);
  }

  return dialogNames;
};

export const unshiftLayer = (
  dialogNames: DialogNames[],
  dialogName: DialogNames
) => {
  const newDialogNames = removeLayer(dialogNames, dialogName);

  newDialogNames.unshift(dialogName);

  return newDialogNames;
};
