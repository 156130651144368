import {
  Cluster,
  ClusterStats,
  MarkerClusterer
} from "@googlemaps/markerclusterer";
import gridClearPicIcon from "assets/icons/grid_clearpic.png";
import ClusterIcon from "assets/icons/ic_map_type_number.svg";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import {
  addEstateToList,
  calcUnitPrice,
  fromDt,
  getEstateIconUrl,
  toDt
} from "common/functions/estates";
import { MinimalEstate } from "common/queries/minimalEstates";
import { max, min } from "lodash";
import "long-press-event";
import { useCallback, useRef } from "react";
import { isMobile } from "react-device-detect";
import { SearchMapHandlerProperties } from "../../interfaces";

export const useEstateMethods = (companyRecNo: number | null | undefined) => {
  const markersLongPressed = useRef(false);

  const getTypeClass = useCallback(
    (estate: MinimalEstate | null | undefined) => {
      if (estate) {
        switch (estate.status) {
          case StatusNameEnum.商談中:
          case StatusNameEnum.成約済み:
            return "type_saled";
          default:
            switch (estate.estateType) {
              case EstateTypeNameEnum.土地:
                return "type_ground";
              case EstateTypeNameEnum.分譲地:
                return "type_block";
              case EstateTypeNameEnum.新築建売:
                return "type_new";
              case EstateTypeNameEnum.中古戸建:
                return "type_used";
              case EstateTypeNameEnum.マンション:
                return "type_apartment";
              case EstateTypeNameEnum.事業用:
                return "type_business";
            }
        }
      }

      return "type_mix";
    },
    []
  );

  const getCountInfo = useCallback(
    (estate: MinimalEstate | null | undefined) => {
      if (estate) {
        if (
          estate.blocks &&
          estate.blocks.length > 1 &&
          estate.latitude &&
          estate.longitude &&
          estate.estateType !== EstateTypeNameEnum.マンション
        ) {
          if (estate.blocks && estate.blocks.length > 1) {
            return {
              count: estate.blocks.length,
              class: [
                EstateTypeNameEnum.土地,
                EstateTypeNameEnum.分譲地,
                EstateTypeNameEnum.新築建売,
                EstateTypeNameEnum.中古戸建,
                EstateTypeNameEnum.マンション,
                EstateTypeNameEnum.事業用
              ].includes(estate.estateType as EstateTypeNameEnum)
                ? "on"
                : "in"
            };
          }
        }
      }
    },
    []
  );

  const getPrice = useCallback(
    (estate?: MinimalEstate | null | undefined): string | undefined => {
      if (estate) {
        if (estate.blocks && estate.blocks.length > 0) {
          const blocksColValue = estate.blocks
            .map((block) => Number(getPrice(block) || "0"))
            .filter((x) => x !== null && x !== undefined);

          if (min(blocksColValue) === max(blocksColValue)) {
            return String(min(blocksColValue));
          }

          return `${min(blocksColValue)}ー${max(blocksColValue)}`;
        }

        if (
          estate.__typename === "Estate" &&
          estate.estateType === EstateTypeNameEnum.マンション &&
          estate.sold
        ) {
          return String(
            (estate.estatePrice || 0) - ((estate.estatePrice || 0) % 100)
          );
        }

        return String(Math.round(estate.estatePrice || 0));
      }
    },
    []
  );

  const getArea = useCallback(
    (estate?: MinimalEstate | null | undefined): string | undefined => {
      if (estate) {
        if (estate.blocks && estate.blocks.length > 0) {
          const blocksColValue = estate.blocks
            .map((block) => Number(getArea(block) || "0"))
            .filter((x) => x !== null && x !== undefined);

          if (min(blocksColValue) === max(blocksColValue)) {
            return String(min(blocksColValue));
          }

          return `${min(blocksColValue)}ー${max(blocksColValue)}`;
        }

        if (
          estate.__typename === "Estate" &&
          estate.estateType === EstateTypeNameEnum.マンション &&
          estate.sold
        ) {
          return String(
            (estate.squareArea || 0) - ((estate.squareArea || 0) % 100)
          );
        }

        return String(Math.round(estate.tsuboArea || 0));
      }
    },
    []
  );

  const getNew = useCallback(
    (estate: MinimalEstate | null | undefined) =>
      (estate?.createdAt &&
        estate.createdAt >= fromDt() &&
        estate.createdAt <= toDt()) ||
      estate?.blocks?.some(
        (x) => x?.createdAt && x.createdAt >= fromDt() && x.createdAt <= toDt()
      )
        ? "new"
        : "",
    []
  );

  const getPriceChanged = useCallback(
    (estate: MinimalEstate | null | undefined) =>
      (estate?.__typename === "Estate" &&
        estate?.pricedAt &&
        estate.pricedAt !== estate?.createdAt &&
        estate.pricedAt >= fromDt() &&
        estate.pricedAt <= toDt()) ||
      estate?.blocks?.some(
        (x) =>
          x?.__typename === "Estate" &&
          x?.pricedAt &&
          x.pricedAt !== x?.createdAt &&
          x.pricedAt >= fromDt() &&
          x.pricedAt <= toDt()
      )
        ? "price"
        : "",
    []
  );

  const getMyEstate = useCallback(
    (
      estate: MinimalEstate | null | undefined,
      companyRecNo: number | null | undefined
    ) =>
      (estate?.status === StatusNameEnum.公開 &&
        companyRecNo &&
        (estate?.companyId?.startsWith(`${companyRecNo}-`) ||
          estate?.subCompanyId?.startsWith(`${companyRecNo}-`) ||
          estate?.subCompany2Id?.startsWith(`${companyRecNo}-`))) ||
      estate?.blocks?.some(
        (x) =>
          x?.status === StatusNameEnum.公開 &&
          companyRecNo &&
          (x?.companyId?.startsWith(`${companyRecNo}-`) ||
            x?.subCompanyId?.startsWith(`${companyRecNo}-`) ||
            x?.subCompany2Id?.startsWith(`${companyRecNo}-`))
      )
        ? `circle ${
            estate?.estateType === EstateTypeNameEnum.土地
              ? "ground"
              : estate?.estateType === EstateTypeNameEnum.分譲地
              ? "block"
              : estate?.estateType === EstateTypeNameEnum.新築建売
              ? "new"
              : estate?.estateType === EstateTypeNameEnum.中古戸建
              ? "used"
              : estate?.estateType === EstateTypeNameEnum.マンション
              ? "apartment"
              : estate?.estateType === EstateTypeNameEnum.事業用
              ? "business"
              : ""
          }`
        : "",
    []
  );

  // 物件マーカーの選択エフェクト操作
  const setSelectEffectOfEstateMarker = useCallback(
    (properties: SearchMapHandlerProperties, id: string, on: boolean) => {
      const marker = properties.estateMarkers[id]?.marker;
      const content =
        marker instanceof google.maps.Marker ? null : marker?.content;

      if (content?.hasChildNodes()) {
        const circleDiv = Array.from(content.childNodes)
          .filter((x) => x.nodeType === Node.ELEMENT_NODE)
          .find(
            (x) =>
              x instanceof HTMLElement && x.classList.contains("select_circle")
          );

        if (on) {
          if (
            circleDiv instanceof HTMLElement &&
            !circleDiv.classList.contains("on")
          ) {
            circleDiv.classList.add("on");
          }
        } else {
          if (
            circleDiv instanceof HTMLElement &&
            circleDiv.classList.contains("on")
          ) {
            circleDiv.classList.remove("on");
          }
        }
      }
    },
    []
  );

  // 物件マーカーのキャプション操作
  const setShowCaptionsOfEstateMarker = useCallback(
    (
      properties: SearchMapHandlerProperties,
      id: string | null | undefined,
      dspPrice: boolean,
      dspTsuboArea: boolean
    ) => {
      if (id) {
        const marker = properties.estateMarkers[id]?.marker;
        const content =
          marker instanceof google.maps.Marker ? null : marker?.content;

        if (content?.hasChildNodes()) {
          const labelDiv = Array.from(content.childNodes)
            .filter((x) => x.nodeType === Node.ELEMENT_NODE)
            .find(
              (x) =>
                x instanceof HTMLElement && x.classList.contains("icon_label")
            );

          if (labelDiv && labelDiv instanceof HTMLElement) {
            const wrapperDiv = Array.from(labelDiv.childNodes)
              .filter((x) => x.nodeType === Node.ELEMENT_NODE)
              .at(0);

            if (wrapperDiv) {
              const priceDiv = Array.from(wrapperDiv.childNodes)
                .filter((x) => x.nodeType === Node.ELEMENT_NODE)
                .find(
                  (x) =>
                    x instanceof HTMLElement && x.classList.contains("price")
                );

              const areaDiv = Array.from(wrapperDiv.childNodes)
                .filter((x) => x.nodeType === Node.ELEMENT_NODE)
                .find(
                  (x) =>
                    x instanceof HTMLElement && x.classList.contains("area")
                );

              if (priceDiv && priceDiv instanceof HTMLElement) {
                if (dspPrice) {
                  priceDiv.classList.add("show");
                } else {
                  priceDiv.classList.remove("show");
                }
              }

              if (areaDiv && areaDiv instanceof HTMLElement) {
                if (dspTsuboArea) {
                  areaDiv.classList.add("show");
                } else {
                  areaDiv.classList.remove("show");
                }
              }
            }
          }
        }
      }
    },
    []
  );

  // 物件マーカーのクリック有無操作
  const setClickedClassOfEstateMarker = useCallback(
    (properties: SearchMapHandlerProperties, id: string, on: boolean) => {
      const marker = properties.estateMarkers[id]?.marker;
      const content =
        marker instanceof google.maps.Marker ? null : marker?.content;

      if (content && content instanceof HTMLElement) {
        if (on) {
          content.classList.add("clicked");
        } else {
          content.classList.remove("clicked");
        }
      }
    },
    []
  );

  // 物件マーカー構築
  const constructEstateMarker = useCallback(
    (
      estate: MinimalEstate | null | undefined,
      onEstateContextMenu: (
        event: Event,
        estate: MinimalEstate | null | undefined,
        estateLatLng: google.maps.LatLng
      ) => void,
      setDspEstates: React.Dispatch<React.SetStateAction<MinimalEstate[]>>,
      properties: SearchMapHandlerProperties
    ) => {
      const countInfo = getCountInfo(estate);
      const content = document.createElement("div");
      content.classList.add("estate-marker");

      content.innerHTML = `
        <img class="estate-icon" src="${getEstateIconUrl(estate)}" />
        <div class="select_circle ${getTypeClass(estate)}" style="z-index: ${
        Number(google.maps.Marker.MAX_ZINDEX) + 5000
      }"></div>
        <div class="icon_number ${countInfo?.class ?? ""}">
          <div>${countInfo?.count ?? ""}</div>
        </div>
        <div class="icon_label ${getTypeClass(estate)}">
          <div>
            <div class="price">${getPrice(estate)}</div>
            <div class="area">${getArea(estate)}</div>
          </div>
        </div>
        <div class="icon_circle">
          <div class="${getMyEstate(estate, companyRecNo)}"></div>
        </div>
        <div class="icon_ud">
          <div class="${getNew(estate)}${getPriceChanged(estate)}"></div>
        </div>
        `;

      if (properties.measureLengthMode || properties.measureAreaMode) {
        content.style.pointerEvents = "none";
      }

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: estate?.latitude || 0,
          lng: estate?.longitude || 0
        },
        content: content
      });

      if (isMobile) {
        marker.element.setAttribute("data-long-press-delay", "300");
      }

      marker.addListener("click", (event: google.maps.MapMouseEvent) => {
        if (
          properties.measureLengthMode ||
          properties.measureAreaMode ||
          markersLongPressed.current
        ) {
        } else if (estate?.id) {
          setClickedClassOfEstateMarker(properties, estate.id, true);
          setDspEstates((estates) => {
            return addEstateToList(estate, estates);
          });
        }

        event.domEvent.cancelBubble = true;
        event.domEvent.stopPropagation();

        markersLongPressed.current = false;

        return false;
      });

      marker.element?.addEventListener("contextmenu", (event) => {
        if (properties.measureLengthMode || properties.measureAreaMode) {
        } else {
          onEstateContextMenu(
            event,
            estate,
            new google.maps.LatLng(
              estate?.latitude || 0,
              estate?.longitude || 0
            )
          );
        }

        event.stopPropagation();

        markersLongPressed.current = false;

        return false;
      });

      marker.element?.addEventListener("mousedown", (event) => {
        event.stopPropagation();
        return false;
      });

      if (isMobile) {
        marker.element?.addEventListener("long-press", (event) => {
          markersLongPressed.current = true;

          if (properties.measureLengthMode || properties.measureAreaMode) {
          } else {
            onEstateContextMenu(
              event,
              estate,
              new google.maps.LatLng(
                estate?.latitude || 0,
                estate?.longitude || 0
              )
            );
          }

          setTimeout(() => {
            markersLongPressed.current = false;
          }, 500);

          event.stopPropagation();

          return false;
        });
      }

      return marker;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getArea,
      getCountInfo,
      getNew,
      getMyEstate,
      getPrice,
      getPriceChanged,
      getTypeClass,
      setClickedClassOfEstateMarker
    ]
  );

  // 物件マーカー(ストリートビュー用)構築
  const constructEstateMarkerForStreetView = useCallback(
    (
      estate: MinimalEstate | null | undefined,
      setDspEstates: React.Dispatch<React.SetStateAction<MinimalEstate[]>>,
      properties: SearchMapHandlerProperties
    ) => {
      const marker = new google.maps.Marker({
        position: {
          lat: estate?.latitude || 0,
          lng: estate?.longitude || 0
        },
        opacity: 0.8,

        icon: {
          url: getEstateIconUrl(estate),
          scaledSize: new google.maps.Size(38, 44)
        },

        clickable: !(properties.measureLengthMode || properties.measureAreaMode)
      });

      marker.addListener("click", (event: google.maps.MapMouseEvent) => {
        if (
          properties.measureLengthMode ||
          properties.measureAreaMode ||
          markersLongPressed.current
        ) {
        } else if (estate?.id) {
          setClickedClassOfEstateMarker(properties, estate.id, true);
          setDspEstates((estates) => {
            return addEstateToList(estate, estates);
          });
        }

        event.domEvent.cancelBubble = true;
        event.domEvent.stopPropagation();

        markersLongPressed.current = false;

        return false;
      });

      return marker;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setClickedClassOfEstateMarker]
  );

  // 成約済み物件マーカー構築
  const constructSoldEstateMarker = useCallback(
    (
      estate: MinimalEstate | null | undefined,
      onSoldEstateMarkerClick: ((estate: MinimalEstate) => void) | undefined,
      properties: SearchMapHandlerProperties
    ) => {
      const unitPrice = calcUnitPrice(estate);
      const unitPriceElements = { int: "", dec: "" };

      if (unitPrice !== "") {
        unitPriceElements.int = unitPrice.split(".")[0];
        unitPriceElements.dec = unitPrice.split(".")[1];
      }

      // 文字無し = 65
      // ピリオド = 6 * (3 / 4)
      // 整数1文字 = 11
      // 小数1文字 = 11 * (3 / 4)

      const width =
        65 +
        unitPriceElements.int.length * 11 +
        6 * (3 / 4) +
        unitPriceElements.dec.length * 11 * (3 / 4);

      const content = document.createElement("div");

      content.innerHTML = `
          <div class="sold-estate-marker" style="width: ${width}px">
            <img src="${gridClearPicIcon}" />
            <div class="map_grid_btn">
              <div class="btn" style="width: ${width}px">
              ${
                unitPrice === ""
                  ? ""
                  : `
                <span class="value">${unitPriceElements.int}</span><span class="value dec">.${unitPriceElements.dec}</span>
              `
              }
                <small></small>
                <span class="link"></span>
              </div>
            </div>
          </div>
        `;

      if (properties.measureLengthMode || properties.measureAreaMode) {
        content.style.pointerEvents = "none !important";
      }

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: estate?.latitude || 0,
          lng: estate?.longitude || 0
        },

        content: content,
        zIndex: google.maps.Marker.MAX_ZINDEX + 5100
      });

      marker.addListener("click", (event: google.maps.MapMouseEvent) => {
        if (!properties.measureLengthMode && !properties.measureAreaMode) {
          if (estate && onSoldEstateMarkerClick) {
            onSoldEstateMarkerClick(estate);
          }
        }

        event.domEvent.cancelBubble = true;
        event.domEvent.stopPropagation();

        return false;
      });

      return marker;
    },
    []
  );

  // マーカークラスタラーのマーカー描画
  const renderMarkerOfMarkerClustererOfEstate = useCallback(
    (
      cluster: Cluster,
      measureLengthMode: boolean,
      measureAreaMode: boolean
    ): google.maps.marker.AdvancedMarkerElement => {
      const content = document.createElement("div");

      content.innerHTML = `
          <div class="marker-clusterer">
            <img src="${ClusterIcon}" />
            <div class="marker_clusterer_label">${String(cluster.count)}</div>
          </div>
        `;

      if (measureLengthMode || measureAreaMode) {
        content.style.pointerEvents = "none";
      }

      const markerForClusterer = new google.maps.marker.AdvancedMarkerElement({
        position: cluster.position,
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,

        content: content
      });

      return markerForClusterer;
    },
    []
  );

  // マーカークラスタラー構築
  const constructMarkerClustererOfEstate = useCallback(
    (
      map: google.maps.Map,
      measureLengthMode: boolean,
      measureAreaMode: boolean,
      markers: (google.maps.marker.AdvancedMarkerElement | google.maps.Marker)[]
    ) => {
      const markerClusterer = new MarkerClusterer({
        map: map,
        markers: markers,
        renderer: {
          render: (cluster: Cluster, stats: ClusterStats) =>
            renderMarkerOfMarkerClustererOfEstate(
              cluster,
              measureLengthMode,
              measureAreaMode
            )
        }
      });

      return markerClusterer;
    },
    [renderMarkerOfMarkerClustererOfEstate]
  );
  // ダイアログ表示物件変化時、選択エフェクト表示
  const showSelectedEffectOfEstate = useCallback(
    (
      map: google.maps.Map | null,
      dspEstates: MinimalEstate[],
      properties: SearchMapHandlerProperties,
      force: boolean
    ) => {
      // エフェクト有、選択物件なし
      const deleteCircleIds = properties.circleIds.filter(
        (id) => !dspEstates.find((x) => x.id === id) || force
      );

      // エフェクトなし、選択物件有
      const addEstates = dspEstates.filter(
        (x) => !properties.circleIds.find((id) => id === x.id) || force
      );

      // 不要エフェクト削除
      deleteCircleIds.forEach((circleId) => {
        setSelectEffectOfEstateMarker(properties, circleId, false);
      });

      properties.circleIds = properties.circleIds.filter(
        (id) => !deleteCircleIds.find((x) => x === id)
      );

      // 新規エフェクト追加
      addEstates.forEach((dspEstate) => {
        const id = dspEstate.id;

        if (map && id) {
          setSelectEffectOfEstateMarker(properties, id, true);

          properties.circleIds.push(id);
        }
      });
    },
    [setSelectEffectOfEstateMarker]
  );

  // 物件リスト変更時、計測モード変化時、およびマーカー表示文字列指定変化時にマーカー文字列表示
  const showCaptionsOnEstatesChangeAndMarkerDspColumnsChange = useCallback(
    (
      map: google.maps.Map | null,
      estates: (MinimalEstate | null)[] | undefined,
      closedEstates: (MinimalEstate | null)[] | undefined,
      dspPrice: boolean,
      dspTsuboArea: boolean,
      properties: SearchMapHandlerProperties
    ) => {
      if (map) {
        const allEstates = [...(estates || []), ...(closedEstates || [])];

        allEstates.forEach(
          (estate) =>
            estate &&
            setShowCaptionsOfEstateMarker(
              properties,
              estate.id,
              dspPrice,
              dspTsuboArea
            )
        );
      }
    },
    [setShowCaptionsOfEstateMarker]
  );

  // 物件リストへのフィッティング
  const fitBoundFromEstates = useCallback(
    (map: google.maps.Map, estates: (MinimalEstate | null)[]) => {
      let bounds = null as google.maps.LatLngBounds | null;

      for (const estate of estates.filter(
        (estate) => estate?.latitude && estate?.longitude
      )) {
        if (bounds) {
          bounds.extend({
            lat: estate?.latitude || 0,
            lng: estate?.longitude || 0
          });
        } else {
          bounds = new google.maps.LatLngBounds(
            {
              lat: estate?.latitude || 0,
              lng: estate?.longitude || 0
            },
            {
              lat: estate?.latitude || 0,
              lng: estate?.longitude || 0
            }
          );
        }
      }

      if (bounds) {
        map.fitBounds(bounds);
      }
    },
    []
  );

  return {
    constructEstateMarker,
    constructEstateMarkerForStreetView,
    constructSoldEstateMarker,
    renderMarkerOfMarkerClustererOfEstate,
    constructMarkerClustererOfEstate,
    showSelectedEffectOfEstate,
    showCaptionsOnEstatesChangeAndMarkerDspColumnsChange,
    fitBoundFromEstates
  } as const;
};
