import { getJsonMasters } from "common/functions/jsonMasters/common";
import { handleError } from "common/functions/utilities";
import { JsonMaster } from "common/interfaces/JsonMaster";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useJsonMasterHooks = <T extends JsonMaster>(
  jsonName: string | null,
  setFatal: ((error?: string | null | undefined) => void) | undefined
) => {
  const [jsonMasters, setJsonMasters] = useState<T[]>([]);

  const { data, error } = useSWR(jsonName, () => {
    if (jsonName) {
      return getJsonMasters<T>(jsonName);
    }

    return [];
  });

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setJsonMasters(data);
    }
  }, [data]);

  return { jsonMasters, setJsonMasters } as const;
};
