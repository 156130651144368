import { useCallback } from "react";
import { SearchDialogStatesType } from "../states/dialogs";
import { DialogNames } from "../../types";

export const useSearchBehaviorHandlers = (
  dialogStates: SearchDialogStatesType
) => {
  const onLayersChange = useCallback(
    (layers: DialogNames[]) => {
      let zIndex = 100;

      for (const layerName of [...layers].reverse()) {
        switch (layerName) {
          case "estateFilter":
            dialogStates.setEstateFilterDialogZIndex(zIndex);
            zIndex += 10;
            break;
          case "selectDrawingSchool":
            dialogStates.setSelectDrawingSchoolDialogZIndex(zIndex);
            zIndex += 10;
            break;
        }
      }
    },
    [dialogStates]
  );

  return {
    onLayersChange
  } as const;
};
