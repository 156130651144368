import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { MainContext } from "common/interfaces/MainContext";
import { Routings } from "components/Routings";
import { createContext, memo } from "react";
import styles from "./Main.module.scss";

export const MainContextContainer = createContext({} as MainContext);

export const Main = memo(() => {
  return (
    <>
      <CssBaseline />
      <Routings />
      <Box id="for-calc" component="div" className={styles.forCalc}></Box>
    </>
  );
});
