import { I18n } from "@aws-amplify/core";
import { headerHeight } from "common/consts/sizing";
import { format } from "date-fns";
import React from "react";
import { useLocation } from "react-router-dom";

//イクンリメント
export const incrementWithCycle = (current: number) =>
  (current === Number.MAX_VALUE ? 0 : current) + 1;

// スリープ
export const sleep = (msec: number) =>
  new Promise((resolve) => setTimeout(resolve, msec));

// エラーハンドラ
export const handleError = (
  error: unknown,
  setFatal?: (error: string | null | undefined) => void,
  forcedMessage?: string
) => {
  const errors =
    (error as { errors: { message: string }[] }).errors ||
    ((error as any).message
      ? [{ message: (error as any).message }]
      : [{ message: error }]);

  const message = errors
    .map((x) => x.message)
    .join("\n")
    .replace("Request failed with status code", "リクエストエラー");

  if (forcedMessage) {
    setFatal?.(`${forcedMessage} (${message})`);
  } else {
    setFatal?.(message);
  }

  window.console.error(error);
};

// 現在地の取得
export const getCurrentPosition = async (defaultLatLng: {
  lat: number;
  lng: number;
}): Promise<{ position: { lat: number; lng: number }; success: boolean }> => {
  try {
    const geolocation: Geolocation = navigator.geolocation;

    const position = await new Promise<any>((resolve, reject) => {
      geolocation.getCurrentPosition(resolve, reject);
    });

    return {
      position: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      },
      success: true
    };
  } catch (err) {
    return { position: defaultLatLng, success: false };
  }
};

// ㎡から坪に変換
export const squareToTsubo = (square: number) => {
  return Math.round((square / 3.305785) * 100) / 100;
};

// 坪から㎡に変換
// export const tsuboToSquare = (tsubo: number) => {
//   return Math.round(tsubo * 3.305785 * 100) / 100;
// };

// スライダーのMarks作成
export const marks = (min: number, max: number, step: number) => {
  const marks: { value: number; label: string; displayAlways: boolean }[] = [];

  for (let x = min; x <= max; x += step) {
    marks.push({
      value: x,
      label: String(x),
      displayAlways: x > min + step && x < max - step && x % (step * 2) === 0
    });
  }

  return marks;
};

// 選択肢作成
export const selectionItems = (
  min: number,
  max: number,
  step: number,
  minCaption: string,
  caption: string,
  maxCaption: string,
  options?: {
    forceMinCaption?: boolean;
    forceMaxCaption?: boolean;
  }
) => {
  const items: { value: string; caption: string }[] = [];

  for (let x = min; x <= max; x += step) {
    items.push({
      value: x.toString(),
      caption:
        x === min
          ? options?.forceMinCaption
            ? minCaption
            : `${x}${minCaption}`
          : x === max
          ? options?.forceMaxCaption
            ? maxCaption
            : `${x}${maxCaption}`
          : `${x}${caption}`
    });
  }

  return items;
};

// 範囲強制
export const forceRange = (value: number, min: number, max: number): number => {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return value;
  }
};

// クエリパラメータHook
export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

// その要素を残りのエリア一杯にするHeight
export const getFullRemainHeight = (id: string) => {
  const offsets = document.getElementById(id)?.getBoundingClientRect();

  if (offsets) {
    return `calc(98dvh - ${headerHeight} - ${offsets?.top}px)`;
  } else {
    return `calc(98dvh - ${headerHeight} - 50dvh)`;
  }
};

// 6桁年月のフォーマット
export const formatMonth = (month: number, customFormat?: string) =>
  String(month).length === 6
    ? format(
        new Date(
          `${String(month).substring(0, 4)}-${String(month).substring(4)}-01`
        ),
        customFormat ?? "yyyy年MM月"
      )
    : "";

// カラーコードをRGBに変換
export const hex2rgb = (hex: string) => {
  if (hex.slice(0, 1) === "#") {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3);
  }

  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map((str) =>
    parseInt(str, 16)
  );
};

// BASE64 to Blob
export const base64ToBlob = (base64: string, type: string) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);

  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  return new Blob([bom, buffer.buffer], { type });
};

// 半角に変換
export const toHalfWidth = (value: string) =>
  value
    .replace(/[Ａ-Ｚａ-ｚ０-９．]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    )
    .replace(/　/g, " ");

// amplify auth系コンポーネントに日本語適用
export const applyJapanese = (): void => {
  const dict = {
    ja: {
      "Back to Sign In": "サインイン画面に戻る",
      Confirm: "確認",
      "Confirm Sign Up": "サインアップの確認",
      "Confirmation Code": "確認コード",
      "Create Account": "新規登録",
      "Create a new account": "アカウントの新規登録",
      "Create account": "新規登録",
      Email: "メールアドレス",
      "Enter your code": "確認コードを入力してください",
      "Enter your Password": "パスワードを入力してください",
      "Enter your Username": "ユーザー名を入力してください",
      "Enter your username": "ユーザー名を入力してください",
      "Forgot your password?": "パスワードをお忘れの方 ",
      "Have an account? ": "アカウント登録済みの方 ",
      Hello: "こんにちは ",
      "Incorrect username or password.":
        "ユーザー名またはパスワードが異なります",
      "Lost your code?": "コードを紛失した方",
      "No account?": "アカウント未登録の方",
      Password: "パスワード",
      "Password *": "パスワード *",
      "Phone Number": "電話番号",
      "Resend Code": "確認コードの再送",
      "Reset Password": "パスワードのリセット",
      "Reset your password": "パスワードのリセット",
      "Send Code": "コードの送信",
      "Send code": "コードの送信",
      "Sign In": "サインイン",
      "Sign Out": "サインアウト",
      "Sign in": "サインイン",
      "Sign in to your account": "サインイン",
      "User does not exist.": "ユーザーが存在しません",
      Username: "ユーザー名",
      "Username *": "ユーザー名 *",
      "Username cannot be empty": "ユーザー名は必須入力です",
      "Username/client id combination not found.": "ユーザー名が見つかりません",
      "Change Password": "パスワードの変更",
      "New password": "新しいパスワード",
      "Confirm Password": "新しいパスワード(確認)",
      "Enter your new password": "新しいパスワードを入力してください",
      Change: "変更",
      "Email Address *": "Eメールアドレス *",
      "Enter your email address": "Eメールアドレスを入力してください",
      "Signing in": "サインインしています",
      "Please confirm your Password":
        "新しいパスワード(確認)を入力してください",
      Code: "確認コード",
      "New Password": "新しいパスワード",
      Submit: "送信"
    }
  };

  I18n.putVocabularies(dict);
  I18n.setLanguage("ja");
};
