import { Box, FormControl, FormLabel } from "@mui/material";
import { StickyNoteIcon } from "API";
import Cart from "assets/icons/sticky_note/memo_pic_cart.svg";
import Caution from "assets/icons/sticky_note/memo_pic_caution.svg";
import Cone from "assets/icons/sticky_note/memo_pic_cone.svg";
import Key from "assets/icons/sticky_note/memo_pic_key.svg";
import Normal from "assets/icons/sticky_note/memo_pic_normal.svg";
import Parking from "assets/icons/sticky_note/memo_pic_parking.svg";
import Person from "assets/icons/sticky_note/memo_pic_person.svg";
import Sale from "assets/icons/sticky_note/memo_pic_sale.svg";
import Secret from "assets/icons/sticky_note/memo_pic_secret.svg";
import React, { Fragment, useMemo } from "react";
import styles from "./IconSwitchButtons.module.scss";
import { IconSwitchButtonsProps } from "./interfaces";

const PrimitiveIconSwitchButtons = (props: IconSwitchButtonsProps) => {
  const master: { id: StickyNoteIcon; name: string }[] = useMemo(
    () => [
      { id: StickyNoteIcon.normal, name: "通常" },
      { id: StickyNoteIcon.parking, name: "駐車場" },
      { id: StickyNoteIcon.key, name: "鍵" },
      { id: StickyNoteIcon.cone, name: "コーン" },
      { id: StickyNoteIcon.caution, name: "注意" },
      { id: StickyNoteIcon.secret, name: "マル秘" },
      { id: StickyNoteIcon.sale, name: "セール" },
      { id: StickyNoteIcon.cart, name: "カート" },
      { id: StickyNoteIcon.person, name: "人" }
    ],
    []
  );

  return (
    <FormControl>
      <FormLabel className={styles.label} id={`${props.id}-label`}>
        {props.label}
      </FormLabel>
      <Box className={styles.wrapper}>
        {master.map(
          (x) =>
            x && (
              <Fragment key={x.id}>
                <Box
                  className={`${styles.btn_switch01} ${styles.btn_square} ${
                    styles.click_act01
                  } ${props.value === x.id ? styles.on : styles.off}`}
                  onClick={() => props.onChange(x.id)}
                >
                  <Box
                    component="img"
                    src={
                      x.id === StickyNoteIcon.normal
                        ? Normal
                        : x.id === StickyNoteIcon.parking
                        ? Parking
                        : x.id === StickyNoteIcon.key
                        ? Key
                        : x.id === StickyNoteIcon.cone
                        ? Cone
                        : x.id === StickyNoteIcon.caution
                        ? Caution
                        : x.id === StickyNoteIcon.secret
                        ? Secret
                        : x.id === StickyNoteIcon.sale
                        ? Sale
                        : x.id === StickyNoteIcon.cart
                        ? Cart
                        : x.id === StickyNoteIcon.person
                        ? Person
                        : undefined
                    }
                  />
                </Box>
              </Fragment>
            )
        )}
      </Box>
    </FormControl>
  );
};

export const IconSwitchButtons = React.memo(
  PrimitiveIconSwitchButtons
) as typeof PrimitiveIconSwitchButtons;
