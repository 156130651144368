import { incrementWithCycle } from "common/functions/utilities";
import { onAutocompleteChange } from "common/interfaces/AutocompleteHandlers";
import { City } from "common/interfaces/City";
import React, { useCallback } from "react";
import { removeLayer, unshiftLayer } from "../functions/layers";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchMapStatesType } from "../states/maps";

export const useSearchSelectDrawingDialogHandlers = (
  dialogStates: SearchDialogStatesType,
  mapStates: SearchMapStatesType
) => {
  const onSelectDrawingSchoolDialogClose = useCallback(() => {
    dialogStates.setShowSelectDrawingSchoolDialog(false);
    dialogStates.setSelectDrawingSchoolDialogSign(incrementWithCycle);
  }, [dialogStates]);

  const onSelectDrawingSchoolDialogMouseDown = useCallback(() => {
    dialogStates.setSelectDrawingSchoolDialogSign(incrementWithCycle);
  }, [dialogStates]);

  const onSelectDrawingSchoolDialogResetButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      mapStates.setSelectedESchoolCities([]);
      mapStates.setSelectedJSchoolCities([]);
    },
    [mapStates]
  );

  const onSelectDrawingSchoolDialogSignChange = useCallback(
    (show: boolean) => {
      if (show) {
        dialogStates.setLayers((x) => unshiftLayer(x, "selectDrawingSchool"));
      } else {
        dialogStates.setLayers((x) => removeLayer(x, "selectDrawingSchool"));
      }
    },
    [dialogStates]
  );

  const onSelectedESchoolCitiesChange: onAutocompleteChange<City | string> =
    useCallback(
      (_, value) => {
        if (Array.isArray(value)) {
          mapStates.setSelectedESchoolCities(value as City[]);
        }
      },
      [mapStates]
    );

  const onSelectedJSchoolCitiesChange: onAutocompleteChange<City | string> =
    useCallback(
      (_, value) => {
        if (Array.isArray(value)) {
          mapStates.setSelectedJSchoolCities(value as City[]);
        }
      },
      [mapStates]
    );

  return {
    onSelectDrawingSchoolDialogClose,
    onSelectDrawingSchoolDialogMouseDown,
    onSelectDrawingSchoolDialogResetButtonClick,
    onSelectDrawingSchoolDialogSignChange,

    onSelectedESchoolCitiesChange,
    onSelectedJSchoolCitiesChange
  } as const;
};
