import { Box, Grid, Stack } from "@mui/material";
import { ReactComponent as ApartIcon } from "assets/icons/map_menu/ic_apart.svg";
import { ReactComponent as BlockIcon } from "assets/icons/map_menu/ic_block.svg";
import ic_filter from "assets/icons/map_menu/ic_filter.svg";
import { ReactComponent as LandIcon } from "assets/icons/map_menu/ic_land.svg";
import { ReactComponent as BusinessIcon } from "assets/icons/map_menu/ic_menu_business.svg";
import { ReactComponent as NewIcon } from "assets/icons/map_menu/ic_new.svg";
import { ReactComponent as UsedIcon } from "assets/icons/map_menu/ic_used.svg";
import { SumoraHeader } from "components/Parts/Atoms/SumoraHeader";
import React from "react";
import styles from "./HeaderBar.module.scss";
import { HeaderBarProps } from "./interfaces";

export const HeaderBar = React.memo((props: HeaderBarProps) => {
  return (
    <SumoraHeader logoVisible={true}>
      {props.user && props.company ? (
        <Box className={styles.headerBar}>
          <Grid container spacing={0} columnSpacing={1}>
            <Grid item>
              <Stack direction="row" spacing={0.5}>
                {props.landStatus ? (
                  <Box component="div" className={styles.land}>
                    <LandIcon />
                  </Box>
                ) : null}
                {props.blockStatus ? (
                  <Box component="div" className={styles.block}>
                    <BlockIcon />
                  </Box>
                ) : null}
                {props.newStatus ? (
                  <Box component="div" className={styles.new}>
                    <NewIcon />
                  </Box>
                ) : null}
                {props.usedStatus ? (
                  <Box component="div" className={styles.used}>
                    <UsedIcon />
                  </Box>
                ) : null}
                {props.apartStatus ? (
                  <Box component="div" className={styles.apart}>
                    <ApartIcon />
                  </Box>
                ) : null}
                {props.businessStatus ? (
                  <Box component="div" className={styles.business}>
                    <BusinessIcon />
                  </Box>
                ) : null}
                {props.filterStatus ? (
                  <Box
                    component="div"
                    className={styles.filtering}
                    onClick={props.onFilterClick}
                  >
                    <img src={ic_filter} alt="絞り込み適用中" />
                  </Box>
                ) : null}
              </Stack>
            </Grid>
            <Grid item>
              <Box component="div" className={styles.company}>
                {props.company?.name || ""} / {props.company?.branchName || ""}
              </Box>
              <Box component="div" className={styles.name}>
                {props.user?.name}さん
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </SumoraHeader>
  );
});
