import selfIcon from "assets/icons/ic_map_type_usershop.svg";
import "long-press-event";
import { useCallback, useRef } from "react";
import { isMobile } from "react-device-detect";
import { SearchMapHandlerProperties } from "../../interfaces";

export const useMarkerMethods = () => {
  const markersLongPressed = useRef(false);

  // 自店舗の位置マーカー構築
  const constructMyPositionMarker = useCallback(
    (
      position: google.maps.LatLngLiteral,
      onMapContextMenu: (event: Event, latLng: google.maps.LatLng) => void,
      properties: SearchMapHandlerProperties
    ) => {
      const content = document.createElement("div");

      if (isMobile) {
        content.setAttribute("data-long-press-delay", "300");
      }

      content.innerHTML = `
          <div>
            <img src="${selfIcon}" class="my-position-marker" />
          </div>
        `;

      const myPositionMarker = new google.maps.marker.AdvancedMarkerElement({
        position: position,
        content: content
      });

      if (isMobile) {
        myPositionMarker.element.setAttribute("data-long-press-delay", "300");
      }

      myPositionMarker.addListener(
        "click",
        (event: google.maps.MapMouseEvent) => {
          event.domEvent.cancelBubble = true;
          event.domEvent.stopPropagation();

          markersLongPressed.current = false;

          return false;
        }
      );

      myPositionMarker.element?.addEventListener("contextmenu", (event) => {
        if (properties.measureLengthMode || properties.measureAreaMode) {
        } else if (myPositionMarker.position) {
          onMapContextMenu(
            event,
            new google.maps.LatLng(myPositionMarker.position)
          );
        }

        event.stopPropagation();

        markersLongPressed.current = false;

        return false;
      });

      myPositionMarker.element?.addEventListener("mousedown", (event) => {
        event.stopPropagation();
        return false;
      });

      if (isMobile) {
        myPositionMarker.element?.addEventListener("long-press", (event) => {
          markersLongPressed.current = true;

          if (properties.measureLengthMode || properties.measureAreaMode) {
          } else if (myPositionMarker.position) {
            onMapContextMenu(
              event,
              new google.maps.LatLng(myPositionMarker.position)
            );
          }

          setTimeout(() => {
            markersLongPressed.current = false;
          }, 500);

          event.stopPropagation();

          return false;
        });
      }

      return myPositionMarker;
    },
    []
  );

  // 指定場所マーカー構築
  const constructPlaceMarker = useCallback(
    (
      position: google.maps.LatLngLiteral,
      onMapContextMenu: (event: Event, latLng: google.maps.LatLng) => void,
      properties: SearchMapHandlerProperties
    ) => {
      const placeMarker = new google.maps.marker.AdvancedMarkerElement({
        position: position
      });

      if (isMobile) {
        placeMarker.element.setAttribute("data-long-press-delay", "300");
      }

      placeMarker.addListener("click", (event: google.maps.MapMouseEvent) => {
        event.domEvent.cancelBubble = true;
        event.domEvent.stopPropagation();

        markersLongPressed.current = false;

        return false;
      });

      placeMarker.element.addEventListener("contextmenu", (event) => {
        if (properties.measureLengthMode || properties.measureAreaMode) {
        } else if (placeMarker.position) {
          onMapContextMenu(event, new google.maps.LatLng(placeMarker.position));
        }

        event.stopPropagation();

        return false;
      });

      placeMarker.element?.addEventListener("mousedown", (event) => {
        event.stopPropagation();
        return false;
      });

      if (isMobile) {
        placeMarker.element?.addEventListener("long-press", (event) => {
          markersLongPressed.current = true;

          if (properties.measureLengthMode || properties.measureAreaMode) {
          } else if (placeMarker.position) {
            onMapContextMenu(
              event,
              new google.maps.LatLng(placeMarker.position)
            );
          }

          setTimeout(() => {
            markersLongPressed.current = false;
          }, 500);

          event.stopPropagation();

          return false;
        });
      }

      return placeMarker;
    },
    []
  );

  const showMarkersInBound = useCallback(
    (
      map: google.maps.Map,
      markers: (google.maps.marker.AdvancedMarkerElement | google.maps.Marker)[]
    ) => {
      const bounds = map.getBounds();
      const visibleMarkers = markers.filter(
        (marker) =>
          bounds &&
          marker &&
          bounds.contains(
            (marker instanceof google.maps.Marker
              ? marker.getPosition()
              : marker.position) || { lat: 0, lng: 0 }
          )
      );
      const hiddenMarkers = markers.filter(
        (marker) =>
          !(
            bounds &&
            marker &&
            bounds.contains(
              (marker instanceof google.maps.Marker
                ? marker.getPosition()
                : marker.position) || { lat: 0, lng: 0 }
            )
          )
      );
      visibleMarkers.forEach((marker) => {
        if (marker instanceof google.maps.Marker) {
          marker.setMap(map);
        } else {
          marker.map = map;
        }
      });
      hiddenMarkers.forEach((marker) =>
        marker instanceof google.maps.Marker
          ? marker.setMap(null)
          : (marker.map = null)
      );

      return visibleMarkers;
    },
    []
  );

  return {
    constructMyPositionMarker,
    constructPlaceMarker,
    showMarkersInBound
  } as const;
};
