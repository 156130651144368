import { SchoolKindEnum } from "common/enums/SchoolKindEnum";
import { getSchoolAreas as invokeGetSchoolAreas } from "common/functions/geoJsons/schoolAreas";
import { handleError } from "common/functions/utilities";
import { MainContext } from "common/interfaces/MainContext";
import { SchoolArea } from "common/interfaces/SchoolArea";
import { useCallback } from "react";
import { SearchMapStatesType } from "../states/maps";

export const useSearchSchoolAreaMethods = (
  mainContext: MainContext,
  mapStates: SearchMapStatesType
) => {
  // 学校区エリア描画
  const getSchoolAreas = useCallback(async () => {
    const selectedESchoolCities = mapStates.selectedESchoolCities.map((x) => ({
      prefCode: x.pref_code,
      cityCode: x.city_code,
      schoolKind: SchoolKindEnum.ESchool
    }));

    const selectedJSchoolCities = mapStates.selectedJSchoolCities.map((x) => ({
      prefCode: x.pref_code,
      cityCode: x.city_code,
      schoolKind: SchoolKindEnum.JSchool
    }));

    const schoolAreas: SchoolArea[] = [];

    for (const selectedSchoolCity of [
      ...selectedESchoolCities,
      ...selectedJSchoolCities
    ]) {
      const schoolArea = await invokeGetSchoolAreas(
        selectedSchoolCity.prefCode,
        selectedSchoolCity.cityCode,
        selectedSchoolCity.schoolKind as SchoolKindEnum,
        false
      ).catch((error) => {
        handleError(error, mainContext.setFatal);
        return undefined;
      });

      if (schoolArea) {
        schoolAreas.push(schoolArea);
      }
    }

    if (schoolAreas) {
      mapStates.setSchoolAreas(schoolAreas);
    }
  }, [mapStates, mainContext.setFatal]);

  return {
    getSchoolAreas
  } as const;
};
