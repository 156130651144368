import { Auth } from "components/Parts/Organisms/Auth";
import { SearchPage } from "components/Parts/Organisms/SearchPage";
import { SearchProps } from "components/Parts/Organisms/SearchPage/interfaces";
import React from "react";

export const Search = React.memo((props: SearchProps) => (
  <Auth>
    <SearchPage {...props} />
  </Auth>
));
