import { Box } from "@mui/material";
import { PublicEstate } from "API";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import React from "react";
import styles from "../DetailPage.module.scss";
import { DetailProps } from "../interfaces";
import { BusinessContent } from "../subComponents/BusinessContent";
import { CondominiumContent } from "../subComponents/CondominiumContent";
import { ContentCompany } from "../subComponents/ContentCompany";
import { ContentMap } from "../subComponents/ContentMap";
import { ContentWrapper } from "../subComponents/ContentWrapper";
import { GroundContent } from "../subComponents/GroundContent";
import { HouseContent } from "../subComponents/HouseContent";
import { useIndividualPageHooks } from "./hooks";

export const IndividualPage = React.memo(
  (
    props: DetailProps & {
      publicEstateId: string | null | undefined;
      onPublicEstateLoaded: (publicEstate: PublicEstate) => void;
    }
  ) => {
    const {
      publicEstate,
      eSchools,
      jSchools,
      useDistricts,
      roadInfos,
      isLoading
    } = useIndividualPageHooks(props);

    return (
      <Box className={styles.individualPage}>
        <Box className={styles.main}>
          <Box className={styles.content}>
            <Box className={styles.information}>
              {publicEstate?.estate?.estateType === EstateTypeNameEnum.土地 ||
              publicEstate?.estate?.estateType === EstateTypeNameEnum.分譲地 ? (
                <GroundContent
                  estate={publicEstate.estate}
                  eSchools={eSchools}
                  jSchools={jSchools}
                  useDistricts={useDistricts}
                  roadInfos={roadInfos}
                />
              ) : publicEstate?.estate?.estateType ===
                  EstateTypeNameEnum.新築建売 ||
                publicEstate?.estate?.estateType ===
                  EstateTypeNameEnum.中古戸建 ? (
                <HouseContent
                  estate={publicEstate.estate}
                  eSchools={eSchools}
                  jSchools={jSchools}
                  useDistricts={useDistricts}
                  roadInfos={roadInfos}
                />
              ) : publicEstate?.estate?.estateType ===
                EstateTypeNameEnum.マンション ? (
                <CondominiumContent
                  estate={publicEstate.estate}
                  eSchools={eSchools}
                  jSchools={jSchools}
                  useDistricts={useDistricts}
                  roadInfos={roadInfos}
                />
              ) : publicEstate?.estate?.estateType ===
                EstateTypeNameEnum.事業用 ? (
                <BusinessContent
                  estate={publicEstate.estate}
                  eSchools={eSchools}
                  jSchools={jSchools}
                  useDistricts={useDistricts}
                  roadInfos={roadInfos}
                />
              ) : isLoading ? (
                <ContentWrapper>
                  <Box className={styles.notFound}>読み込み中...</Box>
                  <Box></Box>
                </ContentWrapper>
              ) : (
                <ContentWrapper>
                  <Box className={styles.notFound}>
                    <Box>
                      申し訳ございません。
                      <br />
                      該当物件が見つかりませんでした。
                    </Box>
                  </Box>
                  <Box></Box>
                </ContentWrapper>
              )}
            </Box>
            <Box className={styles.map}>
              <ContentMap estate={publicEstate?.estate} isLoading={isLoading} />
            </Box>
          </Box>
          <Box className={styles.company}>
            <ContentCompany
              company={publicEstate?.distributer}
              estate={publicEstate?.estate}
              companyIndex={undefined}
              forAnonymous={props.forAnonymous}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);
