import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser
} from "aws-amplify/auth";
import { UserInfo } from "common/interfaces/UserInfo";

// Credentialステートの取得
export const getCredentialStates = async () => {
  try {
    const session = await fetchAuthSession();

    if (session) {
      if (session.credentials) {
        const tokens = session.tokens;

        if (
          tokens?.idToken &&
          tokens.idToken.payload["cognito:groups"] &&
          tokens.idToken.payload["event_id"]
        ) {
          const groups = tokens.idToken.payload["cognito:groups"]
            .toString()
            .split(",");
          const eventId = tokens.idToken.payload["event_id"].toString();
          const attributes = await fetchUserAttributes();

          const { username, userId: id } = await getCurrentUser();

          const userInfo: UserInfo = {
            id: id,
            username: username,
            attributes
          };

          return {
            credentials: session.credentials,
            userInfo,
            groups,
            eventId
          };
        }
      }
    }
  } catch (e) {
    window.console.log(e);
  }

  return undefined;
};

// SumoraAuth Tokenの取得
export const getSumoraAuthToken = async () =>
  `SumoraAuth ${(await fetchAuthSession()).tokens?.accessToken.toString()}`;
