import { incrementWithCycle } from "common/functions/utilities";
import { useCallback } from "react";
import { initialRouteLocations } from "../../consts";
import { removeLayer, unshiftLayer } from "../functions/layers";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchMapStatesType } from "../states/maps";

export const useSearchSearchRouteDialogHandlers = (
  dialogStates: SearchDialogStatesType,
  mapStates: SearchMapStatesType
) => {
  const onSearchRouteDialogClose = useCallback(() => {
    mapStates.setRouteLocations(initialRouteLocations);
    mapStates.setRouteLocationsResult(null);
    mapStates.setTravelMode(null);
    mapStates.setSearchRouteMode(false);
  }, [mapStates]);

  const onSearchRouteDialogMouseDown = useCallback(() => {
    dialogStates.setSearchRouteDialogSign(incrementWithCycle);
  }, [dialogStates]);

  const onSearchRouteDialogSignChange = useCallback(
    (show: boolean) => {
      if (show) {
        dialogStates.setLayers((x) => unshiftLayer(x, "searchRoute"));
      } else {
        dialogStates.setLayers((x) => removeLayer(x, "searchRoute"));
      }
    },
    [dialogStates]
  );

  const onSearchRouteButtonClick = useCallback(
    (travelMode: google.maps.TravelMode | null) => {
      mapStates.setTravelMode(travelMode);
    },
    [mapStates]
  );

  const onRouteLocationDelete = useCallback(
    (location: google.maps.LatLng) => {
      mapStates.setDeleteRouteLocation(location);
    },
    [mapStates]
  );

  const onOptimizeWaypointsChange = useCallback(
    (optimizeWaypoints: boolean) => {
      mapStates.setOptimizeWaypoints(optimizeWaypoints);
    },
    [mapStates]
  );

  return {
    onSearchRouteDialogClose,
    onSearchRouteDialogMouseDown,
    onSearchRouteDialogSignChange,
    onSearchRouteButtonClick,
    onRouteLocationDelete,
    onOptimizeWaypointsChange
  } as const;
};

export type SearchSearchRouteDialogHandlersType = ReturnType<
  typeof useSearchSearchRouteDialogHandlers
>;
