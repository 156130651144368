import { UseDistrictProperty } from "common/interfaces/UseDistrictGeoJSON";

export const convertUseDistrictName = (
  useDistrictProperty: UseDistrictProperty
): string => {
  switch (useDistrictProperty.use_district_code) {
    case 1:
      // 第一種低層住居専用地域
      return "1種低層";
    case 2:
      // 第二種低層住居専用地域
      return "2種低層";
    case 3:
      // 第一種中高層住居専用地域
      return "1種中高";
    case 4:
      // 第二種中高層住居専用地域
      return "2種中高";
    case 5:
      // 第一種住居地域
      return "1種住居";
    case 6:
      // 第二種住居地域
      return "2種住居";
    case 7:
      // 準住居地域
      return "準住居";
    case 8:
      // 近隣商業地域
      return "近隣商業";
    case 9:
      // 商業地域
      return "商業";
    case 10:
      // 準工業地域
      return "準工業";
    case 11:
      // 工業地域
      return "工業";
    case 12:
      // 工業専用地域
      return "工専";
    case 21:
    case 99:
      // 田園住居地域
      // 不明
      break;
  }

  return "指定無";
};
