import { Box } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import styles from "./SearchMenu.module.scss";
import { SearchMenuProps } from "./interfaces";
import { DrawingMenu } from "./subComponents/DrawingMenu";
import { EstateTypeMenu } from "./subComponents/EstateTypeMenu";
import { OftenMenu } from "./subComponents/OftenMenu";
import { OpenCloseButton } from "./subComponents/OpenCloseButton";
import { SearchConfigMenu } from "./subComponents/SearchConfigMenu";
import { ToolMenu } from "./subComponents/ToolMenu";

export const SearchMenu = memo((props: SearchMenuProps) => {
  const [searchStatus, setSearchStatus] = useState(false);
  const [numberStatus, setNumberStatus] = useState(false);
  const [menuClassName, setMenuClassName] = useState("");

  const onSearchButtonClick = useCallback(
    (status: boolean) => {
      if (status && numberStatus) {
        setNumberStatus(false);
      }

      setSearchStatus(status);
    },
    [numberStatus]
  );

  const onNumberButtonClick = useCallback(
    (status: boolean) => {
      if (status && searchStatus) {
        setSearchStatus(false);
      }

      setNumberStatus(status);
    },
    [searchStatus]
  );

  const onOutOfMenuClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.target === event.currentTarget) {
        props.onOpenStatusChange(false);
      }
    },
    [props]
  );

  useEffect(
    () =>
      setMenuClassName((prev) =>
        prev === "" && !props.open ? prev : props.open ? styles.on : styles.off
      ),
    [props.open]
  );

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onOpenStatusChange(false);
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [props]);

  return (
    <Box component="div" className={styles.box_mapmenu}>
      <Box component="div" className={styles.box_always}>
        <OpenCloseButton
          status={props.open}
          onStatusChange={props.onOpenStatusChange}
        />
        <OftenMenu
          map={props.map}
          filteredPublicEstates={props.filteredPublicEstates}
          filteredClosedEstates={props.filteredClosedEstates}
          searchStatus={searchStatus}
          numberStatus={numberStatus}
          onSearchStatusChange={onSearchButtonClick}
          onNumberStatusChange={onNumberButtonClick}
          onPlacesChange={props.onPlacesChange}
          onEstateChange={props.onEstateChange}
        />
      </Box>

      <Box
        component="div"
        className={`${styles.blank_mapmenu} ${menuClassName}`}
        onClick={onOutOfMenuClick}
      >
        <Box component="div" className={styles.box}>
          <Box component="div" className={styles.dtl}>
            <SearchConfigMenu
              switchStatus={props.switchStatus}
              filterStatus={props.filterStatus}
              newEstateStatus={props.newEstateStatus}
              updatePriceStatus={props.updatePriceStatus}
              historyStatus={props.historyStatus}
              priceStatus={props.priceStatus}
              sizeStatus={props.sizeStatus}
              eyeStatus={props.eyeStatus}
              onSwitchClick={props.onSwitchClick}
              onFilterClick={props.onFilterClick}
              onNewEstateStatusChange={props.onNewEstateStatusChange}
              onUpdatePriceStatusChange={props.onUpdatePriceStatusChange}
              onHistoryStatusChange={props.onHistoryStatusChange}
              onPriceStatusChange={props.onPriceStatusChange}
              onSizeStatusChange={props.onSizeStatusChange}
              onEyeStatusChange={props.onEyeStatusChange}
            />

            <EstateTypeMenu
              landStatus={props.landStatus}
              blockStatus={props.blockStatus}
              newStatus={props.newStatus}
              usedStatus={props.usedStatus}
              apartStatus={props.apartStatus}
              businessStatus={props.businessStatus}
              onLandStatusChange={props.onLandStatusChange}
              onBlockStatusChange={props.onBlockStatusChange}
              onNewStatusChange={props.onNewStatusChange}
              onUsedStatusChange={props.onUsedStatusChange}
              onApartStatusChange={props.onApartStatusChange}
              onBusinessStatusChange={props.onBusinessStatusChange}
            />

            {!props.isFree && (
              <>
                <DrawingMenu
                  shopStatus={props.shopStatus}
                  syoStatus={props.syoStatus}
                  chuStatus={props.chuStatus}
                  mountainStatus={props.mountainStatus}
                  floodStatus={props.floodStatus}
                  waveStatus={props.waveStatus}
                  highTideStatus={props.highTideStatus}
                  usageStatus={props.usageStatus}
                  kozuStatus={props.kozuStatus}
                  onShopStatusChange={props.onShopStatusChange}
                  onSyoStatusChange={props.onSyoStatusChange}
                  onChuStatusChange={props.onChuStatusChange}
                  onMountainStatusChange={props.onMountainStatusChange}
                  onFloodStatusChange={props.onFloodStatusChange}
                  onWaveStatusChange={props.onWaveStatusChange}
                  onHighTideStatusChange={props.onHighTideStatusChange}
                  onUsageStatusChange={props.onUsageStatusChange}
                  onKozuStatusChange={props.onKozuStatusChange}
                />

                <ToolMenu
                  measureStatus={props.measureStatus}
                  squareStatus={props.squareStatus}
                  naviStatus={props.naviStatus}
                  memoStatus={props.memoStatus}
                  onMeasureStatusChange={props.onMeasureStatusChange}
                  onSquareStatusChange={props.onSquareStatusChange}
                  onNaviStatusChange={props.onNaviStatusChange}
                  onMemoStatusChange={props.onMemoStatusChange}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
