import { Box } from "@mui/material";
import { SumoraMap } from "components/Parts/Atoms/SumoraMap";
import { DoshasaigaiPlugin } from "components/Parts/Organisms/SearchMap/plugins/DoshasaigaiPlugin";
import { KouzuiPlugin } from "components/Parts/Organisms/SearchMap/plugins/KouzuiPlugin";
import { MeasurePlugin } from "components/Parts/Organisms/SearchMap/plugins/MeasurePlugin";
import { SchoolPlugin } from "components/Parts/Organisms/SearchMap/plugins/SchoolPlugin";
import { Tile16Plugin } from "components/Parts/Organisms/SearchMap/plugins/Tile16Plugin";
import { TsunamiPlugin } from "components/Parts/Organisms/SearchMap/plugins/TsunamiPlugin";
import { YoutoPlugin } from "components/Parts/Organisms/SearchMap/plugins/YoutoPlugin";
import React, { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { ClearButtons } from "../../Molecules/ClearButtons";
import {
  ContextMenuOnEstate,
  ContextMenuOnMap,
  ContextMenuOnRouteMarker,
  ContextMenuOnSchool
} from "./components";
import { SearchMapContext } from "./context";
import { useSearchMapHooks } from "./hooks";
import { SearchMapProps } from "./interfaces";
import { EstatesInfoPlugin } from "./plugins/EstatesInfoPlugin";
import { MojPlugin } from "./plugins/MojPlugin";
import { TakashioPlugin } from "./plugins/TakashioPlugin";
import { CreatingPublicEstateProgressBackdrop } from "./subComponents/CreatingPublicEstateProgressBackdrop";
import { GettingCurrentLocationProgressBackdrop } from "./subComponents/GettingCurrentLocationProgressBackdrop";
import { LimitZoomsCollapse } from "./subComponents/LimitZoomsCollapse";
import { SearchingProgressBackdrop } from "./subComponents/SearchingProgressBackdrop";

export const SearchMap = React.memo((props: SearchMapProps) => {
  const hooks = useSearchMapHooks(props);
  const additionalMapProps = useMemo(
    () => (isMobile ? { gestureHandling: "greedy" } : {}),
    []
  );
  const isStreetView = useMemo(
    () => hooks.map?.getStreetView().getVisible() ?? false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hooks.map, hooks.triggerSwitchStreetView]
  );

  return (
    <SearchMapContext.Provider
      value={{
        map: hooks.map,

        myPositionMarker: hooks.properties.myPositionMarker,
        placeMarker: hooks.properties.placeMarker,
        estateMarkers: hooks.properties.estateMarkers,
        soldEstateMarkers: hooks.properties.soldEstateMarkers,
        markerClusterer: hooks.properties.markerClusterer,

        fillingSchoolPolygons: hooks.fillingSchoolPolygons,
        searchRouteMode: props.searchRouteMode,
        measureMode: props.measureLengthMode || props.measureAreaMode,

        constructMarkerClustererOfEstate:
          hooks.constructMarkerClustererOfEstate,

        onReconstructMarkerClusterer: (newMarkerClusterer) =>
          (hooks.properties.markerClusterer = newMarkerClusterer),
        onMapContextMenu: hooks.onMapContextMenu,
        onSchoolContextMenu: hooks.onSchoolContextMenu,
        onFillingSchoolPolygonsChange: hooks.onFillingSchoolPolygonsChange
      }}
    >
      <Box style={props.style} className={props.className} id={props.mapDivId}>
        <SumoraMap
          center={props.center}
          zoom={props.zoom}
          streetViewControl={true}
          mapTypeControl={true}
          scaleControl={true}
          fullscreenControl={false}
          scrollwheel={true}
          mono={
            props.dspDoshasaigai ||
            props.dspKouzui ||
            props.dspTeiichitai ||
            props.dspTsunami ||
            props.dspTakashio ||
            props.dspYouto
          }
          dspPoi={props.dspPoi}
          {...additionalMapProps}
          onGoogleApiLoaded={hooks.onGoogleApiLoaded}
        />
      </Box>
      <EstatesInfoPlugin
        estates={hooks.dspEstates}
        triggerResetPosition={hooks.triggerResetPosition}
        onCloseClick={hooks.onEstateInfoCloseClick}
        onDragStart={hooks.onEstateInfoDragStart}
        onDragStop={hooks.onEstateInfoDragStop}
        onUpdateEstate={props.onUpdateEstate}
        onDeleteEstate={props.onDeleteEstate}
        onReportEstate={props.onReportEstate}
        onDisplayEstate={props.onDisplayEstate}
      />
      <DoshasaigaiPlugin on={props.dspDoshasaigai} />
      <KouzuiPlugin on={props.dspKouzui} />
      <TsunamiPlugin on={props.dspTsunami} />
      <TakashioPlugin on={props.dspTakashio} />
      <YoutoPlugin on={props.dspYouto} drawingPrefs={props.drawingPrefs} />
      <Tile16Plugin on={props.dspTile16} />
      <SchoolPlugin
        schoolAreas={props.schoolAreas}
        schoolPoints={props.schoolPoints}
      />
      <MojPlugin on={props.dspMoj} />
      <MeasurePlugin
        measureLengthMode={props.measureLengthMode}
        measureAreaMode={props.measureAreaMode}
      />
      <SearchingProgressBackdrop
        busy={props.busy}
        news={props.news}
        own={props.own}
        estatesCount={props.estatesCount}
        estatesTotalCount={props.estatesTotalCount}
        onModalCancelClick={props.onModalCancelClick}
      />
      <GettingCurrentLocationProgressBackdrop busyForMove={props.busyForMove} />
      <CreatingPublicEstateProgressBackdrop
        creatingPublicEstate={props.creatingPublicEstate}
      />
      <LimitZoomsCollapse
        bound={props.boundMode}
        sold={props.soldMode}
        stickyNote={props.stickyNoteMode}
        zoom={props.zoom}
      />
      <ClearButtons
        isStreetView={isStreetView}
        measureLengthMode={props.measureLengthMode}
        measureAreaMode={props.measureAreaMode}
        fillingSchoolPolygons={hooks.fillingSchoolPolygons}
        selectingEstates={hooks.selectingEstates}
        onQuitMeasureClick={props.onQuitMeasureClick}
        onClearFillingSchoolPolygonsClick={
          hooks.onClearFillingSchoolPolygonsClick
        }
        onClearSelectedEstatesClick={hooks.onClearSelectedEstatesClick}
        onResetPositionOfEstateInfoClick={
          hooks.onResetPositionOfEstateInfoClick
        }
      />
      <ContextMenuOnEstate
        estateContextMenuPosition={hooks.estateContextMenuPosition}
        estate={hooks.estateContextMenuEstate}
        editMode={props.editMode}
        searchMode={props.searchMode}
        company={props.company}
        groups={props.groups}
        onEstateContextMenuClose={hooks.onEstateContextMenuClose}
        onSetRouteFrom={hooks.onSetRouteFrom}
        onAddRouteWaypoint={hooks.onAddRouteWaypoint}
        onSetRouteTo={hooks.onSetRouteTo}
        onUpdateEstate={hooks.onUpdateEstate}
        onDeleteEstate={hooks.onDeleteEstate}
      />
      <ContextMenuOnMap
        mapContextMenuPosition={hooks.mapContextMenuPosition}
        searchRouteMode={props.searchRouteMode}
        myPosition={props.myPosition}
        editMode={props.editMode}
        searchMode={props.searchMode}
        stickyNoteMode={props.stickyNoteMode}
        onMapContextMenuClose={hooks.onMapContextMenuClose}
        onSetRouteFromMyPosition={hooks.onSetRouteFromMyPosition}
        onSetRouteFrom={hooks.onSetRouteFrom}
        onAddRouteWaypoint={hooks.onAddRouteWaypoint}
        onSetRouteTo={hooks.onSetRouteTo}
        onCreateEstate={hooks.onCreateEstate}
        onCopyLinkOfGoogleMap={hooks.onCopyLinkOfGoogleMap}
        onCreateStickNote={hooks.onCreateStickNote}
      />
      <ContextMenuOnRouteMarker
        position={hooks.routeLocationContextMenuInfo.position}
        routeLocationStatus={
          hooks.routeLocationContextMenuInfo.routeLocationStatus
        }
        onRouteLocationContextMenuClose={hooks.onRouteLocationContextMenuClose}
        onSetRouteFrom={hooks.onSetRouteFrom}
        onSetRouteWaypoint={hooks.onSetRouteWaypoint}
        onSetRouteTo={hooks.onSetRouteTo}
        onDeleteRouteLocation={hooks.onDeleteRouteLocation}
      />
      <ContextMenuOnSchool
        schoolContextMenuPosition={hooks.schoolContextMenuPosition}
        onSchoolContextMenuClose={hooks.onSchoolContextMenuClose}
        onSetRouteFrom={hooks.onSetRouteFrom}
        onAddRouteWaypoint={hooks.onAddRouteWaypoint}
        onSetRouteTo={hooks.onSetRouteTo}
      />
    </SearchMapContext.Provider>
  );
});
