import { getPrefectures } from "common/functions/jsonMasters/prefectures";
import { handleError } from "common/functions/utilities";
import { Prefecture } from "common/interfaces/Prefecture";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const usePrefectureHooks = (
  setFatal: ((error?: string | null | undefined) => void) | undefined
) => {
  const [prefectures, setPrefectures] = useState<Prefecture[]>([]);
  const { data, error } = useSWR("prefectures", () => getPrefectures(false));

  useEffect(() => {
    if (error) {
      handleError(error, setFatal, "選択肢を読み込めませんでした。");
    }
  }, [error, setFatal]);

  useEffect(() => {
    if (data) {
      setPrefectures(data);
    }
  }, [data]);

  return { prefectures, setPrefectures } as const;
};
