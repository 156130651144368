import { ExpandMore } from "@mui/icons-material";
import { Button, CardContent, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { doshasaigaiColors } from "common/consts/doshasaigaiColors";
import { kouzuiColors } from "common/consts/kouzuiColors";
import { takashioColors } from "common/consts/takashioColors";
import { tsunamiColors } from "common/consts/tsunamiColors";
import { youtoColors } from "common/consts/youtoColors";
import { DrawingColor } from "common/interfaces/DrawingColor";
import { BoxShadowCard } from "components/Parts/Atoms/BoxShadowCard";
import React, { TouchEventHandler, useMemo } from "react";
import Draggable from "react-draggable";
import styles from "./UsageGuideDialog.module.scss";
import { Caption } from "./components";
import { useUsageGuideDialogHooks } from "./hooks";
import { UsageGuideDialogProps } from "./interfaces";

export const UsageGuideDialog = React.memo((props: UsageGuideDialogProps) => {
  const { mainRef, expand, setExpand } = useUsageGuideDialogHooks();

  const {
    doshasaigaiChart,
    kouzuiChart,
    tsunamiChart,
    takashioChart,
    youtoChart
  } = useMemo(() => {
    const caption = (title: string) => (
      <React.Fragment key={title}>
        <Grid item xs={12}>
          <Caption>
            <strong>{title}</strong>
          </Caption>
        </Grid>
      </React.Fragment>
    );

    const row = (drawingColor: DrawingColor) => (
      <React.Fragment key={drawingColor.code}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Caption>{drawingColor.name}</Caption>
        </Grid>
        <Grid item xs={2}>
          <Box
            className={styles.row}
            sx={{
              borderColor: drawingColor.strokeColor,
              backgroundColor: `${drawingColor.fillColor}4C`
            }}
          />
        </Grid>
      </React.Fragment>
    );

    const doshasaigaiChart: JSX.Element[] = [];

    if (props.dspDoshasaigai) {
      doshasaigaiChart.push(caption("土砂災害警戒区域"));
      doshasaigaiChart.push(...doshasaigaiColors.map((x) => row(x)));
    }

    const kouzuiChart: JSX.Element[] = [];

    if (props.dspKouzui) {
      kouzuiChart.push(caption("洪水浸水想定区域"));
      kouzuiChart.push(...kouzuiColors.map((x) => row(x)));
    }

    const takashioChart: JSX.Element[] = [];

    if (props.dspTakashio) {
      takashioChart.push(caption("高潮浸水想定区域"));
      takashioChart.push(...takashioColors.map((x) => row(x)));
    }

    const tsunamiChart: JSX.Element[] = [];

    if (props.dspTsunami) {
      tsunamiChart.push(caption("津波浸水想定区域"));
      tsunamiChart.push(...tsunamiColors.map((x) => row(x)));
    }

    const youtoChart: JSX.Element[] = [];

    if (props.dspYouto) {
      youtoChart.push(caption("用途地域"));
      youtoChart.push(...youtoColors.map((x) => row(x)));
    }
    return {
      doshasaigaiChart,
      kouzuiChart,
      takashioChart,
      tsunamiChart,
      youtoChart
    };
  }, [
    props.dspDoshasaigai,
    props.dspKouzui,
    props.dspTakashio,
    props.dspTsunami,
    props.dspYouto
  ]);

  if (props.show) {
    return (
      <Draggable
        nodeRef={mainRef}
        handle=".draggable-div"
        onMouseDown={props.onMouseDown}
      >
        <Box ref={mainRef} className={styles.cardWrapper}>
          <BoxShadowCard
            className={`${styles.usageGuideDialog} ${
              expand ? styles.expand : ""
            }`}
          >
            <CardContent className={styles.contentWrapper}>
              <Box
                textAlign="right"
                className={`draggable-div ${styles.content}`}
              >
                <Button
                  className={styles.onOffButton}
                  size="small"
                  variant="contained"
                  onClick={props.onOffClick}
                  onTouchStart={
                    props.onOffClick as TouchEventHandler<HTMLButtonElement>
                  }
                >
                  表示OFF
                </Button>
                <IconButton
                  size="small"
                  color="inherit"
                  className={styles.expandButton}
                  onClick={() => setExpand((x) => !x)}
                  onTouchStart={() => setExpand((x) => !x)}
                >
                  <ExpandMore
                    className={`${styles.expandIcon} ${
                      expand ? styles.expand : ""
                    }`}
                  />
                </IconButton>
              </Box>
              <Box
                className={`${styles.chartWrapper} ${
                  expand ? styles.expand : ""
                }`}
              >
                <Grid container rowSpacing={0.5} columnSpacing={2}>
                  {doshasaigaiChart}
                  {kouzuiChart}
                  {takashioChart}
                  {tsunamiChart}
                  {youtoChart}
                </Grid>
              </Box>
            </CardContent>
          </BoxShadowCard>
        </Box>
      </Draggable>
    );
  } else {
    return null;
  }
});
