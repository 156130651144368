import { Prefecture } from "common/interfaces/Prefecture";
import { getJsonAddressMasters } from "./common";

export const getPrefectures = (forAnonymous: boolean) =>
  getJsonAddressMasters<Prefecture>("prefectures", forAnonymous, (a, b) =>
    a.pref_code.localeCompare(b.pref_code)
  );

export const getDefaultPrefectures = (
  prefecturesMaster: Prefecture[],
  areas: (string | null)[] | null | undefined
) => {
  if (prefecturesMaster.length > 0 && areas) {
    const prefs = Array.from(
      new Set(
        areas.map((area) => {
          if (area && area.length >= 2) {
            return area.substring(0, 2);
          } else {
            return null;
          }
        })
      ).values()
    )
      .map((prefCode) =>
        prefecturesMaster.find((pref) => pref.pref_code === prefCode)
      )
      .filter((pref) => pref !== undefined);

    return prefs as Prefecture[];
  }

  return [];
};
