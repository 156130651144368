import { Auth } from "components/Parts/Organisms/Auth";
import { NotFoundPage } from "components/Parts/Organisms/NotFoundPage";
import { NotFoundProps } from "components/Parts/Organisms/NotFoundPage/interfaces";
import React from "react";

export const NotFound = React.memo((props: NotFoundProps) => (
  <Auth>
    <NotFoundPage {...props} />
  </Auth>
));
