import { useEffect, useState } from "react";
import { ClearButtonsProps } from "./interfaces";
import { isMobileOnly } from "react-device-detect";

type StackType =
  | "measure"
  | "fillingSchoolPolygons"
  | "selectingEstates"
  | "resetPositionOfEstateInfo"
  | "";

export const useHooks = (props: ClearButtonsProps) => {
  const [stack, setStack] = useState<StackType[]>([]);

  useEffect(() => {
    const newStack: StackType[] = [];

    if (props.measureLengthMode || props.measureAreaMode) {
      newStack.push("measure");
    }

    if (props.fillingSchoolPolygons) {
      newStack.push("fillingSchoolPolygons");
    }

    if (props.selectingEstates && !isMobileOnly) {
      newStack.push("selectingEstates");
      newStack.push("resetPositionOfEstateInfo");
    }

    if (newStack.length === 0) {
      setStack([]);
    } else {
      setStack((oldStack) => {
        const sortedOldStack = [...oldStack].sort();
        const sortedNewStack = [...newStack].sort();

        if (sortedOldStack.join("") === sortedNewStack.join("")) {
          return oldStack;
        }

        const constructedStack: StackType[] = [];

        oldStack.forEach((oldStackItem) => {
          if (newStack.includes(oldStackItem)) {
            constructedStack.push(oldStackItem);
          }
        });

        newStack.forEach((newStackItem) => {
          if (!oldStack.includes(newStackItem)) {
            constructedStack.push(newStackItem);
          }
        });

        return constructedStack;
      });
    }
  }, [
    props.fillingSchoolPolygons,
    props.measureAreaMode,
    props.measureLengthMode,
    props.selectingEstates
  ]);

  return { stack } as const;
};
