import { Backdrop, Box } from "@mui/material";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import React from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import Draggable from "react-draggable";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BlockInfo } from "./BlockInfo";
import styles from "./EstateInfo.module.scss";
import { useHooks } from "./hooks";
import { EstateInfoProps } from "./interfaces";

export const EstateInfo = React.memo((props: EstateInfoProps) => {
  const {
    mainRef,
    open,
    markerPixelPosition,
    page,
    position,
    dragging,

    isStreetView,

    blocks,
    currentBlock,
    sold,
    paginationEnabled,

    onSwiperInit,
    onSlideChange,
    onCloseClick,
    onPrevClick,
    onNextClick,
    onDragStart,
    onDragStop,
    onClick,
    onBlockInfoResize,
    onRoomPriceClick
  } = useHooks(props);

  const estateInfoInner = (
    <Box
      ref={mainRef}
      className={`${styles.bkn_komado} ${
        currentBlock?.estateType === EstateTypeNameEnum.土地
          ? styles.ground
          : currentBlock?.estateType === EstateTypeNameEnum.分譲地
          ? styles.block
          : currentBlock?.estateType === EstateTypeNameEnum.新築建売
          ? styles.new
          : currentBlock?.estateType === EstateTypeNameEnum.中古戸建
          ? styles.used
          : currentBlock?.estateType === EstateTypeNameEnum.事業用
          ? styles.business
          : currentBlock?.estateType === EstateTypeNameEnum.マンション &&
            currentBlock?.id?.startsWith("C")
          ? styles.apartment
          : currentBlock?.estateType === EstateTypeNameEnum.マンション
          ? styles.room
          : ""
      } ${sold ? styles.sold : ""} twbasic01 ${open ? styles.on : styles.off} ${
        dragging ? "" : styles.not_dragging
      } ${isMobile ? styles.isMobile : ""}`}
      sx={
        isMobileOnly || isStreetView
          ? {}
          : {
              position: "absolute",
              left: `calc(${markerPixelPosition?.x}px - 140px)`,
              bottom: `calc(100dvh - ${markerPixelPosition?.y}px)`
            }
      }
    >
      <Box className={`${styles.handle} draggable-div`}></Box>
      <Box className={`${styles.handle2} draggable-div`}></Box>
      <Box className={`${styles.handle3} draggable-div`}></Box>
      <Box className={`${styles.handle4} draggable-div`}></Box>
      <Box className={styles.close} onClick={onCloseClick}></Box>
      <Box className={styles.pagenation}>
        {paginationEnabled && (
          <>
            <Box
              component="span"
              className={styles.prev}
              onClick={onPrevClick}
            ></Box>
            <Box component="span" className={styles.page}>
              {page}
            </Box>
            <Box
              component="span"
              className={styles.next}
              onClick={onNextClick}
            ></Box>
          </>
        )}
      </Box>
      <Swiper
        className={styles.swiper}
        slidesPerView={blocks.length === 1 ? undefined : 1}
        autoHeight={true}
        grabCursor={true}
        longSwipes={true}
        longSwipesRatio={0.2}
        speed={200}
        loop={blocks.length !== 1}
        modules={[Pagination]}
        onSwiper={onSwiperInit}
        onSlideChange={onSlideChange}
        onClick={onClick}
      >
        {blocks.map((block, index) => (
          <SwiperSlide key={index}>
            <BlockInfo
              block={block}
              onDisplayEstate={props.onDisplayEstate}
              onUpdateEstate={props.onUpdateEstate}
              onDeleteEstate={props.onDeleteEstate}
              onReportEstate={props.onReportEstate}
              onResize={onBlockInfoResize}
              onRoomPriceClick={onRoomPriceClick}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );

  if (isMobileOnly || isStreetView) {
    return markerPixelPosition ? (
      <Backdrop
        open={true}
        className={`${styles.backdrop} ${
          isStreetView ? styles.streetView : ""
        }`}
      >
        {estateInfoInner}
      </Backdrop>
    ) : null;
  }

  return markerPixelPosition ? (
    <Draggable
      handle=".draggable-div"
      nodeRef={mainRef}
      defaultPosition={{ x: 0, y: 0 }}
      position={position}
      onStart={onDragStart}
      onStop={onDragStop}
    >
      {estateInfoInner}
    </Draggable>
  ) : null;
});
